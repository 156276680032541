import ProgressBar from "@ramonak/react-progress-bar";
import increment from "../../assets/images/account_dashboard/increament.svg"
import decrement from "../../assets/images/account_dashboard/decreament.svg"
import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL_PREBID } from "../../config/axios";
import { formatRoundoff } from "../../components/custom";

const InformationCards = () => {


    const [Cardsdata, setCardsdata] = useState([]);

    useEffect(() => {
        const getcarddata = async () => {
            try {
                const res = await axios.get(`${BASE_URL_PREBID}/project/accountdashboradstatus/`);
                setCardsdata(res.data);

            } catch (err) {
                // alert(err.message);
            }
        };
        getcarddata();
    }, []);



    const cardcolor = [
        {
            color: "#FF747C",
        },
        {
            color: "#06AD06",
        },
        {
            color: "#F7BA1E",
        },
        {
            color: "#2576BC",
        },
    ]

    return (
        <div className="infocards-flex">
            {Cardsdata.map((items, index) => (
                <div className="card-cont cards-shadow dash-card">
                    <div className="font-size-heading">{items.name}</div>
                    <div className="font-size-text font-weight600">{formatRoundoff(items.amt)}</div>
                    <div className="progressbar">
                        <ProgressBar completed={items.percent} maxCompleted={100} baseBgColor="#EFF4FB" bgColor={cardcolor[index].color} height="10px" isLabelVisible={false} />
                    </div>
                    <div className="card-perecent-cont">
                        <img src={items.changepercent.slice(0, 1) === "+" ? increment : decrement} alt="" />
                        <div className=" font-size-label" style={{ color: items.changepercent.slice(0, 1) === "+" ? "#06AD06" : "#F72B50" }}>{items.changepercent}%</div>
                        <div className="percentagelabel font-size-label">Since Last Week</div>
                    </div>
                </div>
            ))
            }

        </div >
    )
}

export default InformationCards