import React, { useState } from 'react'
import Dashboardnavbarcopy from '../../layout/Dashboardnavbar';
import Subcompany from './Subcompany';


const Settingsit = () => {

    const [toggleState, setToggleState] = useState(1);


    const getSelectedTitle = () => {
        switch (toggleState) {
            case 1:
                return 'Sub Company ';
            default:
                return '';
        }
    };


    return (
        <>
            <div>
                <Dashboardnavbarcopy name={getSelectedTitle()} url="Settings" />
                <div className="bloc-tabss  wfm-margin">
                    <button
                        className={toggleState === 1 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss"}
                        onClick={() => setToggleState(1)}
                    >
                        Sub Company
                    </button>


                </div>
                <div className="content-tabs">

                    {toggleState === 1 ? (

                        <Subcompany />
                    ) : null}







                </div>
            </div>

        </>
    )
}

export default Settingsit
