// ! *************** Sub Company Crud Models Start ***************
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Select from 'react-select';
import { BASE_URL } from "../../config/axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AddwithBlueCircle, AddwithWhiteCircle, DeleteDustbin, DropdownArrowOption, UpdatePencil } from "../AllSvg";
import { useNavigate } from "react-router-dom";
import CreatableSelect from 'react-select/creatable';


const Subcompanyss = ({ getSubCompany }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        title: "",
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["title"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // ?  ************** Validation End **************  ! //
    const [loading, setLoading] = useState(false); //loading logic
    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
            setLoading(true); //loading logic
            try {
                let res = await axios.post(`${BASE_URL}/project/subcompany/`, formData);

                if (res.status === 200) {
                    setShow(false);
                    await getSubCompany();
                } else {
                    alert(res);
                }
            } catch (err) {
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    return (
        <>
            <button className="models-button model-add" onClick={handleShow}>
                <AddwithBlueCircle />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                {/* <Modal.Header closeButton>
                <Modal.Title>Request Leave</Modal.Title>
              </Modal.Header> */}
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Birthday </h6> */}
                            <div className="flex-column">
                                <label htmlFor="title" className="form-labels  font-weight500    font-size-subheading">
                                    Title<span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    id="title"
                                    name="title"
                                    placeholder="Company Name"
                                    onChange={handleInputChange}
                                    value={formData.title}
                                    className={`form-input ${errors.title ? "error" : inputState.tile ? "success" : ""
                                        }`}
                                />
                                {errors.title && (
                                    <span className="error-message font-size-text ">{errors.title}</span>
                                )}
                                <div className="button-models">
                                    <button className="model-button   font-weight500   " onClick={handleClose}>
                                        Cancel
                                    </button>
                                    <button
                                        //   type="submit"
                                        className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                        onClick={handleFormSubmit}
                                        disabled={loading}
                                    >
                                        Submit
                                    </button>
                                </div>{" "}
                            </div>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};


const Subcompanys = () => {
    const [formData, setFormData] = useState({
        title: '',
        company_name: '',
        company_address: '',
        company_number: '',
        company_gst: '',
        company_logo: '',
    });
    const [subCompanyTitle, setSubCompanyTitle] = useState([]);

    const [isSubmitted, setIsSubmitted] = useState(false);

    const [show, setShow] = useState(false);

    const handleShow = () => {
        setShow(true);
        getCompanyName();
    }

    const handleClose = () => {
        setShow(false);

        setFormData({
            title: '',
            company_name: '',
            company_address: '',
            company_number: '',
            company_gst: '',
            company_logo: '',
        })
        window.location.reload();
        setErrors({});
    }

    const [errors, setErrors] = useState({});

    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};


        const requiredFields = [
            'title',
            'company_name',
            'company_address',
            'company_number',
            'company_gst',
            'company_logo',

        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);
        setIsSubmitted(true);


        return Object.keys(newErrors).length === 0;
    };
    const getCompanyName = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/project/subcompany/`,
            );
            // setAllProjectTypes(res.data);
            setSubCompanyTitle(Array.from(res.data).map(company => ({ value: company.title, label: company.title })));

        } catch (err) {
            alert(err.message);
        }

    }


    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            const formDataa = new FormData();
            formDataa.append('title', formData.title);
            formDataa.append('company_name', formData.company_name);
            formDataa.append('company_address', formData.company_address);
            formDataa.append('company_number', formData.company_number);
            formDataa.append('company_gst', formData.company_gst);
            formDataa.append('company_logo', formData.company_logo);
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
            setLoading(true); //loading logic
            try {
                let res = await axios.post(`${BASE_URL}/project/subcompany/`, formDataa, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })

                if (res.status === 200) {
                    handleClose();
                    toast.dismiss(loadingToastId);
                    toast.success("Company Details added successfully!");

                } else {
                    alert(res);
                }
            } catch (err) {
                // alert(err);
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!', err);
                    }
                } else {
                    toast.error('Error processing your request.');
                }
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }

        }
    };



    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value,
        });

        setFormData({
            ...formData,
            [name]: value,
        });
        if (value.trim()) {
            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[name];
                return updatedErrors;
            });
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData({ ...formData, company_logo: file });
        setErrors({ ...errors, company_logo: false });
    };

    const handleFileClear = (e, fieldName) => {
        e.stopPropagation();
        setFormData({ ...formData, [fieldName]: null }); // Clear the state
        document.querySelector('input[name="company_logo"]').value = ''; // Clear the input field
    };



    console.log("formData.company_logo");
    console.log(formData.company_logo);


    // Function to handle logo file change
    const handleLogoChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            if (file.type.startsWith('image/')) {
                setFormData({
                    ...formData,
                    logo: file,
                });
            } else {
                setErrors({
                    ...errors,
                    logo: 'Invalid file type. Please select an image file.',
                });
            }
        } else {
            setFormData({
                ...formData,
                logo: null,
            });
        }
    };




    const handleChange = (e) => {
        const { name, value, type, checked } = e.target || e;

        // Handle checkbox, CreatableSelect, or standard input change
        const inputValue = type === 'checkbox' ? checked : value;

        setInputState({
            ...inputState,
            [name]: type === 'checkbox' ? (checked ? 'green' : '') : value.trim() ? 'green' : '',
        });

        setFormData({
            ...formData,
            [name]: inputValue,
        });
    };

    return (
        <>
            <button className="models-button model-add" onClick={handleShow}>
                <AddwithBlueCircle />
            </button>

            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

            <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
                <Modal.Header closeButton>
                    <Modal.Title>Add Company Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='modal-body-padding'>
                        <form>
                            <div className='form-flex-wrap'>



                                <div className="flex-column form-group-select">
                                    <label className="form-labels">
                                        Company Short Name:<span className="required">*</span>
                                    </label>
                                    <CreatableSelect
                                        options={subCompanyTitle}
                                        value={formData.title ? { value: formData.title, label: formData.title } : null}
                                        onChange={(option) => handleChange({
                                            target: {
                                                name: 'title',
                                                value: option ? option.value : ''
                                            }
                                        })}
                                        placeholder="Select or type a new project type"
                                        // className={`form-input form-group-selection ${errors.project_type ? 'error' : formData.project_type ? 'success' : ''}`}
                                        formatCreateLabel={(inputValue) => `Create new Company Name: ${inputValue}`}
                                        isValidNewOption={(inputValue, selectValue, selectOptions) =>
                                            inputValue.trim() !== '' &&
                                            !selectOptions.find(option => option.label.toLowerCase() === inputValue.toLowerCase())
                                        }
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                width: '33vw',
                                                height: '50px',
                                                borderRadius: '10px',
                                                border: '1px solid #e3e3e3',
                                                margin: '10px 0px 20px 0px',
                                                color: '#4a4a4a',
                                                backgroundColor: '#f7f8fa',
                                                padding: '0px 10px',
                                            }),
                                            placeholder: (provided) => ({
                                                ...provided,
                                                color: '#4a4a4a',
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                borderRadius: '10px',
                                                marginTop: '0',
                                            }),
                                            input: (provided) => ({
                                                ...provided,
                                                color: '#4a4a4a',
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                color: '#4a4a4a',
                                            }),
                                        }}
                                    />
                                    {errors.title && (
                                        <span className="error-message">{errors.title}</span>
                                    )}
                                </div>
                                <div className="flex-column">
                                    <label>Company Full Name:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="company_name"
                                        value={formData.company_name}
                                        placeholder="Company Full Name"
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.company_name ? 'error' : inputState.company_name ? 'success' : ''}`}
                                    />
                                    {errors.company_name && (
                                        <span className="error-message">{errors.company_name}</span>
                                    )}
                                </div>
                                <div className="flex-column">
                                    <label>GST Number:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="company_gst"
                                        maxLength={15}
                                        placeholder="GST Number"
                                        value={formData.company_gst}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.company_gst ? 'error' : inputState.company_gst ? 'success' : ''}`}
                                    />
                                    {errors.company_gst && (
                                        <span className="error-message">{errors.company_gst}</span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label>Company Number:<span className="required">*</span></label>
                                    <input
                                        type="number"
                                        min={1}
                                        name="company_number"
                                        placeholder="Company Number"
                                        value={formData.company_number}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.company_number ? 'error' : inputState.company_number ? 'success' : ''}`}
                                    />
                                    {errors.company_number && (
                                        <span className="error-message">{errors.company_number}</span>
                                    )}
                                </div>
                                <div className="flex-column">
                                    <label>Company Address:<span className="required">*</span></label>
                                    <textarea
                                        type="text"
                                        min={1}
                                        name="company_address"
                                        placeholder="Company Address"
                                        value={formData.company_address}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.company_address ? 'error' : inputState.company_address ? 'success' : ''}`}
                                    />
                                    {errors.company_address && (
                                        <span className="error-message">{errors.company_address}</span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label>Company Logo (PNG File):</label>
                                    <div
                                        className={`file-upload-container form-input ${errors.company_logo ? 'error' : formData.company_logo ? 'success' : ''
                                            }`}
                                    >
                                        <label
                                            className={`file-label ${errors.company_logo ? 'error' : ''}`}
                                            style={{ marginTop: '10px' }}
                                        >
                                            <input
                                                type="file"
                                                name="company_logo"
                                                accept=".png"
                                                onChange={handleFileChange}
                                                className="file-input"
                                            />
                                            <div>
                                                {formData.company_logo && (
                                                    <span
                                                        className="file-clear"
                                                        onClick={(e) => handleFileClear(e, 'company_logo')}
                                                    >
                                                        Clear
                                                    </span>
                                                )}
                                            </div>
                                        </label>
                                    </div>
                                </div>

                            </div>
                            <div className="flex-row justify-around" >
                                <div className="button-models">
                                    <button type="button" onClick={handleClose} className="model-button-cancel model-button   font-weight500  ">Cancel</button>
                                </div>

                                <div className="button-models">
                                    <button onClick={handleSubmit} className="model-button model-button-submit">Submit</button>
                                </div>

                            </div>

                            <p className="error-message font-size-text margin-top40px">
                                {isSubmitted && Object.keys(errors).length > 0 && (
                                    Object.keys(errors).length > 3 ? (
                                        <h5 className="text-center" style={{ marginTop: "15px" }} >Please fill all mandatory fields!</h5>
                                    ) : (
                                        Object.keys(errors).map((field) =>
                                            field.charAt(0).toUpperCase() + field.slice(1)
                                        ).join(', ') + ' are required!'
                                    )
                                )}
                            </p>
                        </form>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
};


const Subcompanyupdate = ({ i, getSubCompany }) => {
    const [show, setShow] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [subCompanyTitle, setSubCompanyTitle] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        getCompanyName();
    }



    const [formData, setFormData] = useState({
        title: '',
        company_name: '',
        company_address: '',
        company_number: '',
        company_gst: '',
        company_logo: null,
    });

    useEffect(() => {
        setFormData(i);
    }, [i]);




    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["title"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);
        setIsSubmitted(true);


        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // const handleFileChange = (e) => {
    //     const { name } = e.target;
    //     const file = e.target.files[0];

    //     if (file) {
    //         if (file.type === 'image/png') {
    //             setFormData({
    //                 ...formData,
    //                 [name]: file,
    //             });
    //         } else {
    //             setErrors({
    //                 ...errors,
    //                 [name]: 'Invalid file type. Please select a png file.',
    //             });
    //         }
    //     } else {
    //         setFormData({
    //             ...formData,
    //             [name]: null,
    //         });
    //     }
    // };


    const getCompanyName = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/project/subcompany/`,
            );
            // setAllProjectTypes(res.data);
            setSubCompanyTitle(Array.from(res.data).map(company => ({ value: company.title, label: company.title })));

        } catch (err) {
            alert(err.message);
        }

    }





    const handleFileClear = (name) => {
        setFormData({
            ...formData,
            [name]: null,
        });
    };
    const [loading, setLoading] = useState(false); //loading logic

    // ?  ************** Validation End **************  ! //
    const handleFileChange = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];

        if (file) {
            if (file.type === 'image/png') {
                setFormData({
                    ...formData,
                    [name]: file, // Store the new file
                });
                setErrors({
                    ...errors,
                    [name]: null, // Clear any previous errors for this field
                });
            } else {
                setErrors({
                    ...errors,
                    [name]: 'Invalid file type. Please select a PNG file.',
                });
            }
        } else {
            // Retain the existing link if no file is selected
            setFormData({
                ...formData,
                [name]: formData[name], // Keep the existing link
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            const formDataa = new FormData();

            // Append form fields
            formDataa.append('title', formData.title);
            formDataa.append('company_name', formData.company_name);
            formDataa.append('company_address', formData.company_address);
            formDataa.append('company_number', formData.company_number);
            formDataa.append('company_gst', formData.company_gst);

            // Handle file or existing link for `company_logo`
            if (formData.company_logo instanceof File) {
                formDataa.append('company_logo', formData.company_logo); // Append the file
            }
            else if (formData.company_logo !== null || formData.company_logo !== "") {
                formDataa.append('company_logo', formData.company_logo); // Append the existing link
            }
            // else {
            //     formDataa.append('company_logo', formData.company_logo); 
            // }

            const loadingToastId = toast.loading("Loading: Please wait...");
            setLoading(true);

            try {
                const res = await axios.put(`${BASE_URL}/project/subcompany/${i.id}/`, formDataa, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (res.status === 200) {
                    handleClose();
                    toast.dismiss(loadingToastId);
                    toast.success("Company Details added successfully!");
                } else {
                    alert(res);
                }
            } catch (err) {
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach((message) => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }
            } finally {
                setLoading(false);
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target || e;

        // Handle checkbox, CreatableSelect, or standard input change
        const inputValue = type === 'checkbox' ? checked : value;

        setInputState({
            ...inputState,
            [name]: type === 'checkbox' ? (checked ? 'green' : '') : value.trim() ? 'green' : '',
        });

        setFormData({
            ...formData,
            [name]: inputValue,
        });
    };
    return (
        <>
            <button className="model-edit-button" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
                        fill="#145650"
                    />
                </svg>
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Update SubCompany {i.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='modal-body-padding'>
                        <form>
                            <div className='form-flex-wrap'>
                                <div className="flex-column form-group-select">
                                    <label className="form-labels">
                                        Company Name:<span className="required">*</span>
                                    </label>
                                    <CreatableSelect
                                        options={subCompanyTitle}
                                        value={formData.title ? { value: formData.title, label: formData.title } : null}
                                        onChange={(option) => handleChange({
                                            target: {
                                                name: 'title',
                                                value: option ? option.value : ''
                                            }
                                        })}
                                        placeholder="Select or type a new project type"
                                        // className={`form-input form-group-selection ${errors.project_type ? 'error' : formData.project_type ? 'success' : ''}`}
                                        formatCreateLabel={(inputValue) => `Create new Company Name: ${inputValue}`}
                                        isValidNewOption={(inputValue, selectValue, selectOptions) =>
                                            inputValue.trim() !== '' &&
                                            !selectOptions.find(option => option.label.toLowerCase() === inputValue.toLowerCase())
                                        }
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                width: '33vw',
                                                height: '50px',
                                                borderRadius: '10px',
                                                border: '1px solid #e3e3e3',
                                                margin: '10px 0px 20px 0px',
                                                color: '#4a4a4a',
                                                backgroundColor: '#f7f8fa',
                                                padding: '0px 10px',
                                            }),
                                            placeholder: (provided) => ({
                                                ...provided,
                                                color: '#4a4a4a',
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                borderRadius: '10px',
                                                marginTop: '0',
                                            }),
                                            input: (provided) => ({
                                                ...provided,
                                                color: '#4a4a4a',
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                color: '#4a4a4a',
                                            }),
                                        }}
                                    />
                                    {errors.title && (
                                        <span className="error-message">{errors.title}</span>
                                    )}
                                </div>
                                <div className="flex-column">
                                    <label>Company Full Name:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="company_name"
                                        value={formData.company_name}
                                        placeholder="Company Full Name"
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.company_name ? 'error' : inputState.company_name ? 'success' : ''}`}
                                    />
                                    {errors.company_name && (
                                        <span className="error-message">{errors.company_name}</span>
                                    )}
                                </div>
                                <div className="flex-column">
                                    <label>GST Number:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="company_gst"
                                        maxLength={15}
                                        placeholder="GST Number"
                                        value={formData.company_gst}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.company_gst ? 'error' : inputState.company_gst ? 'success' : ''}`}
                                    />
                                    {errors.company_gst && (
                                        <span className="error-message">{errors.company_gst}</span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label>Company Number:<span className="required">*</span></label>
                                    <input
                                        type="number"
                                        min={1}
                                        name="company_number"
                                        placeholder="Company Number"
                                        value={formData.company_number}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.company_number ? 'error' : inputState.company_number ? 'success' : ''}`}
                                    />
                                    {errors.company_number && (
                                        <span className="error-message">{errors.company_number}</span>
                                    )}
                                </div>
                                <div className="flex-column">
                                    <label>Company Address:<span className="required">*</span></label>
                                    <textarea
                                        type="text"
                                        min={1}
                                        name="company_address"
                                        placeholder="Company Address"
                                        value={formData.company_address}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.company_address ? 'error' : inputState.company_address ? 'success' : ''}`}
                                    />
                                    {errors.company_address && (
                                        <span className="error-message">{errors.company_address}</span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label>Company Logo (PNG File):</label>
                                    <div className={`file-upload-container form-input ${errors.registration_certificate ? 'error' : inputState.registration_certificate ? 'success' : ''}`}>
                                        <label className={`file-label ${errors.registration_certificate ? 'error' : ''}`} style={{ marginTop: "10px" }}>
                                            <input
                                                type="file"
                                                name="company_logo"
                                                accept=".png"
                                                onChange={handleFileChange}
                                                className={`file-input`}
                                            />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-upload" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z" />
                                                <path fill-rule="evenodd" d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z" />
                                            </svg>

                                            <span className="form-text-trasformation-uppercase" style={{ marginLeft: "20px" }}>
                                                {formData.company_logo ? formData.company_logo.name : 'No file selected'}
                                            </span>


                                            {formData.company_logo ? (<span className="file-clear" onClick={() => handleFileClear('company_logo')}>
                                                Clear
                                            </span>) : null}
                                        </label>
                                    </div>

                                </div>

                            </div>
                            <div className="flex-row justify-around" >
                                <div className="button-models">
                                    <button type="button" onClick={handleClose} className="model-button-cancel model-button   font-weight500  ">Cancel</button>
                                </div>

                                <div className="button-models">
                                    <button onClick={handleSubmit} className="model-button model-button-submit">Submit</button>
                                </div>

                            </div>

                            <p className="error-message font-size-text margin-top40px">
                                {isSubmitted && Object.keys(errors).length > 0 && (
                                    Object.keys(errors).length > 3 ? (
                                        <h5 className="text-center" style={{ marginTop: "15px" }} >Please fill all mandatory fields!</h5>
                                    ) : (
                                        Object.keys(errors).map((field) =>
                                            field.charAt(0).toUpperCase() + field.slice(1)
                                        ).join(', ') + ' are required!'
                                    )
                                )}
                            </p>
                        </form>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

const SubcompanyDelete = ({ i, getSubCompany }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();

        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
        try {
            let res = await axios.delete(`${BASE_URL}/wfm/subcompany/${i.id}/`);

            if (res.status === 200) {
                setShow(false);
                await getSubCompany();
            } else {
                alert(res);
            }
        } catch (err) {
            //toast Logic
            if (err.response) {
                toast.dismiss(loadingToastId);
                const errorData = err.response.data;

                if (typeof errorData.error === 'string') {
                    // Single error message
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    // Multiple error messages
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }
        } finally {
            toast.dismiss(loadingToastId);
        }
    };

    return (
        <>
            <button className="model-delete-button" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                        fill="#F72B50"
                    />
                </svg>
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Delete SubCompany {i.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        Are You Sure You Want to delete SubCompany {i.title}
                        <div className="button-models">
                            <button className="model-button   font-weight500    " onClick={handleClose}>
                                Cancel
                            </button>
                            <button
                                onClick={handleFormSubmitDelete}
                                className="model-button   font-weight500    model-button-delete"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};


const customSortByKey = (data, key) => {
    const regex = /([A-Za-z]+)|(\d+)|(\W+)/g;

    // Function to access a deeply nested object property using dot notation
    const getNestedValue = (obj, key) => {
        return key.split('.').reduce((o, i) => (o ? o[i] : undefined), obj);
    };

    // Custom sort function for object comparison
    const sortFunction = (a, b) => {
        // Get the values to be compared from the key (handle nested properties)
        const aValue = getNestedValue(a, key);
        const bValue = getNestedValue(b, key);

        if (!aValue || !bValue) return 0; // Return 0 if either value is undefined

        // Split the strings into alphabetic, numeric, and special character parts
        const aParts = aValue.match(regex);
        const bParts = bValue.match(regex);

        for (let i = 0; i < Math.min(aParts.length, bParts.length); i++) {
            const aPart = aParts[i];
            const bPart = bParts[i];

            // Check if both parts are numeric
            if (!isNaN(aPart) && !isNaN(bPart)) {
                const numA = Number(aPart);
                const numB = Number(bPart);
                if (numA !== numB) {
                    return numA - numB; // Compare numerically
                }
            } else if (aPart !== bPart) {
                return aPart.localeCompare(bPart); // Compare alphabetically or by special characters
            }
        }

        // If we reach here, the longer string is considered greater
        return aParts.length - bParts.length;
    };

    // Sort the data using the custom sort function
    return data.sort(sortFunction);
};


const EmployeebySubCompanyTable = ({ subcompany }) => {
    const [show, setShow] = useState(false);
    const [buffer, setBuffering] = useState(true); //buffering logic

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        getSubCompanyEmployees(subcompany.id);
    };

    const [subcompanyEmployees, setSubCompanyEmployees] = useState([]);

    const getSubCompanyEmployees = async (subcompanyId) => {
        setBuffering(true); //buffering logic // Start Buffering 
        try {
            const res = await axios.get(
                `${BASE_URL}/wfm/employeedetailsbysubcompany/${subcompanyId}/`
            );
            const sortedList = customSortByKey(res.data, "emp_code");

            setSubCompanyEmployees(sortedList);
        } catch (err) {
            //toast Logic
            if (err.response) {
                const errorData = err.response.data;
                if (typeof errorData.error === 'string') {
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };

    const [searchQuery, setSearchQuery] = useState("");
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // useEffect(() => {
    //   getSubCompanyEmployees();
    // }, []);

    const navigate = useNavigate();
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split('/');
    const url = urlParts[3]
    const Viewdetails = (emp_code, user_detail) => {
        navigate(`/${url}/userdetailscrud/${emp_code}/${user_detail}/`);
    };
    return (
        <>
            <button
                className=""
                title="View Employees"
                style={{ paddingLeft: "5px", color: "blue", fontSize: "14px" }}
                onClick={handleShow}
            >
                View
            </button>
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <>
                        {/* <div className="attendance-history-cont">
                <div className="attendance-heading  font-weight500    font-size-heading"></div>
              </div> */}
                        <div className="table-css-white-background">
                            <div className="table-heading-flex">
                                <div className="repo-heading font-weight500    font-size-heading">
                                    Employees in : <br />
                                    {subcompany.title}
                                </div>
                                <div className="flex-row">
                                    <div className="table-searchh">
                                        <div>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="11"
                                                height="12"
                                                viewBox="0 0 11 12"
                                                fill="none"
                                            >
                                                <circle
                                                    cx="5"
                                                    cy="5"
                                                    r="4.3"
                                                    stroke="#2B3674"
                                                    strokeWidth="1.4"
                                                />
                                                <line
                                                    x1="10.0101"
                                                    y1="11"
                                                    x2="8"
                                                    y2="8.98995"
                                                    stroke="#2B3674"
                                                    strokeWidth="1.4"
                                                    strokeLinecap="round"
                                                />
                                            </svg>
                                        </div>
                                        <div>
                                            <input
                                                placeholder="Search"
                                                className="navbar-inputt font-weight400  font-size-text"
                                                value={searchQuery}
                                                onChange={handleSearchChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <table className="table-css">
                                <thead>
                                    <tr className="custom-table-head-tr">
                                        <th className="align-left">ID</th>
                                        <th className="align-center">Emp Code</th>
                                        <th className="align-center"> Name </th>
                                        <th className="align-center">Employee View</th>
                                    </tr>
                                </thead>
                                {buffer ? <div className="spinner"></div> : //buffering logic
                                    <tbody>
                                        {subcompanyEmployees
                                            ?.filter(
                                                (e) =>
                                                    e.name
                                                        .toLowerCase()
                                                        .includes(searchQuery.toLowerCase()) ||
                                                    e.emp_code
                                                        .toString()
                                                        .includes(searchQuery.toLowerCase())
                                            )
                                            .sort((a, b) => a.emp_code - b.emp_code)
                                            .map((i, index) => (
                                                <React.Fragment key={index}>
                                                    {/* <tr className="tr-border-bottom">
                            <td colSpan="6"></td>
                          </tr> */}
                                                    <tr className="custom-table-head-td">
                                                        <td className="align-left">{index + 1}</td>
                                                        <td className="align-center">{i.emp_code}</td>
                                                        <td className="align-center">{i.name}</td>

                                                        <td className="align-center">
                                                            <button
                                                                className="employee-edit"
                                                                onClick={() =>
                                                                    Viewdetails(i.emp_code, i.user_detail)
                                                                }
                                                                title="View Profile"
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="40"
                                                                    height="40"
                                                                    viewBox="0 0 30 30"
                                                                    fill="none"
                                                                >
                                                                    <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                                                                    <path
                                                                        d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                                                        fill="#2576BC"
                                                                    />
                                                                </svg>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                    </tbody>
                                }
                            </table>
                        </div>
                    </>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};


// ? *************** SubComany Crud Models End ***************




export {
    Subcompanys,
    Subcompanyupdate,
    SubcompanyDelete
};
