import { Outlet } from 'react-router-dom';
import { sidebarData } from './RHsidebar';
import DashboardSidebar from "../../layout/DashboardSidebar";
import React, { useState } from 'react'


const RHDashboard = () => {

  const [isexpand, setExpanded] = useState(false);

  return (
    <>
      <div className="dashboard-main-section">
        <DashboardSidebar sidebarData={sidebarData} isexpand={isexpand} setExpanded={setExpanded} />
        <div className={isexpand ? "dashboard-main-content-sectionn" : "dashboard-main-content-sectionnn"}>
          <Outlet />
        </div>
      </div>
    </>
  );
};


export default RHDashboard
