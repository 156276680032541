// ************ ==== >>>>Root Routes <<<< ==== ************//
import Login from "../pages/website/Login";
import Forgotpassword from "../pages/website/Forgotpassword";
import Resetpassword from "../pages/website/Resetpassword";

// ************ ==== >>>> Employee Dashboard <<<< ==== ************//
import EmpDashboard from "../pages/prebid/Main/Dashboard";
import EmployeeDashboard from "../pages/prebid/PrebidDashboard";

// ************ ==== >>>> ReportingHead Dashboard <<<< ==== ************//
import RhDashboard from "../pages/reportinghead/Main/Dashboard";
import RHDashboard from "../pages/reportinghead/RHDashboard";

// ************ ==== >>>> HOD Dashboard <<<< ==== ************//
import HodDashboard from "../pages/hod/Main/Dashboard";
import HODDashboard from "../pages/hod/HodDashboard";
import HODManagement from "../components/EmployeeDetails/HODManagement";

// ************ ==== >>>> Account Dashboard <<<< ==== ************//
import AccountDashboard from "../pages/account/Main/dashboard";
import AccountMainDashboard from "../pages/account/AccountDashboard";

import ClientsPage from "../pages/account/Main/clientDetails/ClientsPage";
import CriticalIssues from "../components/Criticalissue/CriticalIssue";
import { InvoicePage } from "../components/Project/Invoice";
import DueProject from "../components/Project/DueProject";
import PriorityBidProject from "../components/Project/PriorityBidProject";

import Newprojectsdetails from "../components/Project/Newproject";
import Allclient from "../pages/account/Main/Allclient";
import Projectbyclient from "../pages/account/Main/Projectbyclient";

import AllProject from "../pages/account/Main/Allprojectdetails";
import AllProjectDetails from "../components/Project/Allprojectdetails";
import ProjectDetailsBilling from "../components/Project/ProjectDetailsBilling";
import { PerformaInvoice } from "../components/Project/PerformaInvoice";
import ImprestExpenseManagement from "../components/ImprestManagement/ImprestExpenseManagement";
import ExpenseSlipByExpense from "../components/ImprestManagement/ExpenseSheet/ExpenseSlipByExpense";
import { ProjectDetailsPage } from "../components/Project/ProjectDetailsPage";
import { ClientDetails } from "../components/AccountDashboardComp/ClientDetails";
import Settingsit from "../components/Setting/Setting";
import Ticket from "../components/TicketBooking/Ticket";
import Miscellaneous from "../components/miscellaneous/Miscellaneous";
import Timesheet from "../components/Timesheet/Timesheet";

export const routingData = [
  {
    role: "",
    isProtected: false,
    defaultRoute: "/",
    routes: [
      {
        path: "",
        component: <Login />,
      },
      {
        path: "login",
        component: <Login />,
      },
      {
        path: "forgotpassword/",
        component: <Forgotpassword />,
      },
      {
        path: "resetpassword/:uidb64/:token/",
        component: <Resetpassword />,
      },
    ],
  },

  // Employee Management
  {
    role: "Employee",
    department: "Pre Engineering",
    isProtected: true,
    defaultRoute: "ed/edDashboard",
    nestedRoutes: [
      {
        routes: [
          {
            path: "",
            component: <EmpDashboard />,
          },
          {
            path: "edDashboard",
            component: <EmpDashboard />,
          },
          {
            path: "criticalIssues",
            component: <CriticalIssues />,
          },
          {
            path: "AllProjectDetails",
            component: <AllProjectDetails />,
          },

          {
            path: "dueprojectdetails",
            component: <DueProject />,
          },
          {
            path: "prioritybidprojectdetails",
            component: <PriorityBidProject />,
          },

          {
            path: "clients",
            component: <ClientsPage />,
          },
          {
            path: "invoiceSlip/:id/",
            component: <InvoicePage />,
          },
          {
            path: "performainvoiceslip/:id/",
            component: <PerformaInvoice />,
          },
          {
            path: "newprojectdetails",
            component: <Newprojectsdetails />,
          },
          {
            path: "newprojectdetails",
            component: <Newprojectsdetails />,
          },
          {
            path: "projectDetailsPage",
            component: <ProjectDetailsPage />,
          },
          {
            path: "ViewProjectDetails/:id/",
            component: <Newprojectsdetails />,
          },
          {
            path: "imprestExpenseManagement",
            component: <ImprestExpenseManagement />,
          },
          {
            path: "invoicebyproject/:id/",
            component: <ProjectDetailsBilling />,
          },
          {
            path: "expenseSlip",
            component: <ExpenseSlipByExpense />,
          },
          {
            path: "setting",
            component: <Settingsit />,
          },
          {
            path: "ticket",
            component: <Ticket />,
          },
          {
            path: "miscellaneous",
            component: <Miscellaneous />,
          },
          {
            path: "timesheet",
            component: <Timesheet />,
          },
        ],
        path: "ed",
        component: <EmployeeDashboard />,
      },
    ],
  },

  // Reporting Management
  {
    role: "ReportingHead",
    department: "Pre Engineering",
    isProtected: true,
    defaultRoute: "tl/rhDashboard",
    nestedRoutes: [
      {
        routes: [
          {
            path: "",
            component: <RhDashboard />,
          },
          {
            path: "invoicebyproject/:id/",
            component: <ProjectDetailsBilling />,
          },
          {
            path: "rhDashboard",
            component: <RhDashboard />,
          },
          {
            path: "criticalIssues",
            component: <CriticalIssues />,
          },
          {
            path: "AllProjectDetails",
            component: <AllProjectDetails />,
          },
          {
            path: "projectDetailsPage",
            component: <ProjectDetailsPage />,
          },
          {
            path: "dueprojectdetails",
            component: <DueProject />,
          },
          {
            path: "prioritybidprojectdetails",
            component: <PriorityBidProject />,
          },
          {
            path: "clients",
            component: <ClientsPage />,
          },
          {
            path: "newprojectdetails",
            component: <Newprojectsdetails />,
          },
          {
            path: "newprojectdetails",
            component: <Newprojectsdetails />,
          },
          {
            path: "ViewProjectDetails/:id/",
            component: <Newprojectsdetails />,
          },
          {
            path: "imprestExpenseManagement",
            component: <ImprestExpenseManagement />,
          },
          {
            path: "expenseSlip",
            component: <ExpenseSlipByExpense />,
          },
          {
            path: "setting",
            component: <Settingsit />,
          },
          {
            path: "ticket",
            component: <Ticket />,
          },
          {
            path: "miscellaneous",
            component: <Miscellaneous />,
          },
          {
            path: "timesheet",
            component: <Timesheet />,
          },
        ],
        path: "tl",
        component: <RHDashboard />,
      },
    ],
  },

  // HOD
  {
    role: "HOD",
    department: "Pre Engineering",
    isProtected: true,
    defaultRoute: "hod/hodDashboard",
    nestedRoutes: [
      {
        routes: [
          {
            path: "",
            component: <HodDashboard />,
          },
          {
            path: "hodDashboard",
            component: <HodDashboard />,
          },
          {
            path: "criticalIssues",
            component: <CriticalIssues />,
          },
          {
            path: "AllProjectDetails",
            component: <AllProjectDetails />,
          },
          {
            path: "projectDetailsPage",
            component: <ProjectDetailsPage />,
          },
          {
            path: "dueprojectdetails",
            component: <DueProject />,
          },
          {
            path: "imprestExpenseManagement",
            component: <ImprestExpenseManagement />,
          },
          {
            path: "prioritybidprojectdetails",
            component: <PriorityBidProject />,
          },
          {
            path: "clients",
            component: <ClientsPage />,
          },
          {
            path: "newprojectdetails",
            component: <Newprojectsdetails />,
          },
          {
            path: "setting",
            component: <Settingsit />,
          },
          {
            path: "ticket",
            component: <Ticket />,
          },
          {
            path: "miscellaneous",
            component: <Miscellaneous />,
          },
          {
            path: "timesheet",
            component: <Timesheet />,
          },
          {
            path: "employeeManagement",
            component: <HODManagement />,
          },
        ],
        path: "hod",
        component: <HODDashboard />,
      },
    ],
  },

  // Acccounts Management
  {
    role: "Employee",
    department: "Accounts",
    isProtected: true,
    defaultRoute: "account/accountDashboard",
    nestedRoutes: [
      {
        routes: [
          {
            path: "",
            component: <AccountDashboard />,
          },

          {
            path: "accountDashboard",
            component: <AccountDashboard />,
          },
          {
            path: "clientmanagement",
            component: <ClientDetails />,
          },
          {
            path: "criticalIssues",
            component: <CriticalIssues />,
          },
          {
            path: "clients",
            component: <ClientsPage />, // Client Detail Page Not useful here
          },
          {
            path: "invoiceSlip/:id/",
            component: <InvoicePage />,
          },
          {
            path: "projectDetailsPage",
            component: <ProjectDetailsPage />,
          },
          {
            path: "performainvoiceslip/:id/",
            component: <PerformaInvoice />,
          },
          {
            path: "allclient",
            component: <Allclient />, // Useful
          },
          {
            path: "projectbyclient/:id/",
            component: <Projectbyclient />,
          },
          {
            path: "invoicebyproject/:id/",
            component: <ProjectDetailsBilling />,
          },
          {
            path: "allprojectdetails/",
            component: <AllProject />,
          },
          {
            path: "setting",
            component: <Settingsit />,
          },
          {
            path: "ticket",
            component: <Ticket />,
          },
          {
            path: "miscellaneous",
            component: <Miscellaneous />,
          },
          {
            path: "timesheet",
            component: <Timesheet />,
          },
        ],
        path: "account",
        component: <AccountMainDashboard />,
      },
    ],
  },
];
