import React, { useState, useEffect } from 'react'

import EmployeeDetails from './HodEmployeeDetails'
import Dashboardnavbarcopy from '../../layout/Dashboardnavbar'


const HODManagement = () => {


  const [toggleState, setToggleState] = useState(1);


  const getSelectedTitle = () => {
    switch (toggleState) {
      case 1:
        return 'Department Employees';
      default:
        return 'Department Employees';
    }
  };

  return (
    <div>
      <Dashboardnavbarcopy name={getSelectedTitle()} url="Workforce Managment" />
      <div className="bloc-tabss  wfm-margin">
        <button
          className={toggleState === 1 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss"}
          onClick={() => setToggleState(1)}
        >
          Department Employees
        </button>


      </div>
      <div className="content-tabs">


        {toggleState === 1 ? <EmployeeDetails /> : null}

      </div>
    </div>
  )
}

export default HODManagement
