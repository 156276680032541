import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { BASE_URL } from "../../config/axios";
import { ToastContainer, toast } from "react-toastify";
import usePermission from "../../config/permissions";
// import { handleErrorToast } from "../CustomFunctions";
import {
    AddwithWhiteCircle,
    CheckGreen,
    CrossRed,
    DeleteDustbin,
    DropdownArrow,
    PDFIcon,
} from "../AllSvg";

import { formattedDateLong } from "../Date";
import Dashboardnavbarcopy from "../../layout/Dashboardnavbar";
import { formatCurrencyIndian, handleErrorToast } from "../custom";

// ! *************** Sector Crud Models Start ***************

const AddTicket = ({ getSiteTickets }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setFormData({
            project_associate: "",
            project_client_associate: "",
            from_place: "",
            to_place: "",
            booking_docs: "",
            ticket_amount: "",
            booking_date: "",
            person_name: ""
        });
        setShow(false);
    };
    const handleShow = () => {
        getProjectListData()
        setShow(true)
    };

    const [formData, setFormData] = useState({
        project_associate: "",
        project_client_associate: "",
        from_place: "",
        to_place: "",
        booking_docs: "",
        ticket_amount: "",
        booking_date: "",
        person_name: ""
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["from_place", "to_place", "ticket_amount", "booking_docs", "booking_date", "person_name"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        console.log("newErrors")
        console.log(newErrors)

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            const formDataa = new FormData();

            Object.keys(formData).forEach((key) => {
                if (key !== "booking_docs" && formData[key]) {
                    formDataa.append(key, formData[key]);
                }
            });

            // formDataa.append("from_place", formData.from_place);
            // formDataa.append("title", formData.title);

            // formDataa.append("description", formData.description);
            // formDataa.append("publish_date", formData.publish_date);

            const symbols = "!@#$%^&*()_-+=";
            const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
            const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            const numbers = "0123456789";
            const now = new Date();
            const date = now
                .toLocaleDateString("en-GB")
                .split("/")
                .reverse()
                .join("-"); // Format: YYYY-MM-DD
            const time = now
                .toLocaleTimeString("en-GB", { hour12: false })
                .replace(/:/g, "-"); // Format: HH-MM-SS
            const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
            let randomCode = "";
            for (let i = 0; i < 8; i++) {
                const randomIndex = Math.floor(Math.random() * allChars.length);
                randomCode += allChars[randomIndex];
            }
            const originalFile = formData.booking_docs;
            const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile.name}`;
            const customFile = new File([originalFile], customFileName, {
                type: originalFile.type,
            });
            formDataa.append("booking_docs", customFile);

            try {
                let res = await axios.post(
                    `${BASE_URL}/project/ticketbooking/`,
                    formDataa,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                if (res.status === 200) {
                    await getSiteTickets();
                    handleClose();
                    toast.dismiss(loadingToastId);
                    toast.success("Ticket added successfully!");
                } else {
                    toast.dismiss(loadingToastId);
                    toast.error("Failed to add Ticket!");
                }
            } catch (err) {
                //toast Logic
                handleErrorToast(err);
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "project_associate") {
            setInputState({
                ...inputState,
                [name]: value.trim() ? "green" : "",
            });

            setFormData({
                ...formData,
                [name]: value,
                project_client_associate: "",
            });
        }
        else {
            setInputState({
                ...inputState,
                [name]: value.trim() ? "green" : "",
            });

            setFormData({
                ...formData,
                [name]: value,
            });
        };
        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            if (value) {
                delete updatedErrors[name];
            }
            return updatedErrors;
        });

    }

    const handleFileChange = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];

        if (file) {
            const validFileTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/jpeg', 'image/png'];

            if (validFileTypes.includes(file.type)) {
                setFormData({
                    ...formData,
                    [name]: file,
                });
                setErrors({
                    ...errors,
                    [name]: "", // Clear any previous errors for this field
                });
            } else {
                setErrors({
                    ...errors,
                    [name]: "Invalid file type. Please select a PDF or DOC file.",
                });
            }
        } else {
            setFormData({
                ...formData,
                [name]: null,
            });
        }
    };

    const handleFileClear = (name) => {
        setFormData({
            ...formData,
            [name]: null,
        });
    };


    const [filteredData, setFilteredData] = useState([]);
    const [buffer, setBuffering] = useState(true); //buffering logic
    const getProjectListData = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(`${BASE_URL}/project/projectfilterbysubcompany/null/`);
            setFilteredData(res.data);
        } catch (err) {
            handleErrorToast(err)
        } finally {
            setBuffering(false);// End Buffering
        }

    };

    const [clientslist, setClientsList] = useState([]);
    const getProjectClientList = async (projectID) => {
        setBuffering(true);
        try {
            const clientdetails = await axios.get(
                `${BASE_URL}/project/client-by-project/${projectID || "null"}/`
            );
            setClientsList(clientdetails.data);

        } catch (err) {
            // handleErrorToast(err)
        } finally {
            setBuffering(false);
        }
    };

    useEffect(() => {
        getProjectClientList(formData.project_associate);
    }, [formData.project_associate]);


    return (
        <>
            <button title="Add Ticket" className="upload-svg" onClick={handleShow}>
                <AddwithWhiteCircle />
                {" "}
                Ticket
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                {/* <Modal.Header closeButton>
            <Modal.Title>Request Leave</Modal.Title>
          </Modal.Header> */}
                <Modal.Header
                    closeButton
                    className="justify-center task-Tab-heading font-weight500    font-size-heading "
                >
                    <Modal.Title> Add Ticket</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            {/* <h6 className="task-Tab-heading font-weight500    font-size-heading">Ticket</h6> */}

                            <form className="form-flex-wrap">
                                <div className="flex-column form-group-select">
                                    <label className="form-labels">Project Associate:</label>
                                    <select
                                        name="project_associate"
                                        value={formData.project_associate}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.project_associate ? 'error' : inputState.project_associate ? 'success' : ''}`}
                                    >
                                        <option value="">Select a Project</option>
                                        {filteredData.map((project) => (
                                            <option key={project.id} value={project.pr_code}>{project.prebid_prcode}{project.project_short_name ? `-${project.project_short_name}` : null}-{project.project_short_name}</option>
                                        ))}
                                    </select>
                                    <DropdownArrow />
                                    {errors.project_associate && <span className="error-message">{errors.project_associate}</span>}
                                </div>

                                <div className="flex-column form-group-select">
                                    <label className="form-labels">Client Associate:</label>
                                    <select
                                        name="project_client_associate"
                                        value={formData.project_client_associate}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.project_client_associate ? 'error' : inputState.project_client_associate ? 'success' : ''}`}
                                    >
                                        <option value="">Select a Client</option>
                                        {clientslist.normal_client?.map((e) => (
                                            <option key={e.client} value={e.id}>{e.clientname}</option>
                                        ))}
                                        {clientslist.pr_project?.map((e) => (
                                            <option key={e.client} value={e.id}>PR-{e.clientname}</option>
                                        ))}
                                    </select>
                                    <DropdownArrow />
                                    {errors.project_client_associate && <span className="error-message">{errors.project_client_associate}</span>}
                                </div>


                                <div className="flex-row" style={{ width: "34vw", justifyContent: "space-between" }}>

                                    <div className="flex-column">
                                        <label
                                            htmlFor="from_place"
                                            className="form-labels  font-weight500    font-size-subheading" title="From Place"
                                        >
                                            From Place<span className="required">*</span>
                                        </label>
                                        <input
                                            id="from_place"
                                            title="From Place"
                                            type="text"
                                            name="from_place"
                                            maxLength={50}
                                            placeholder="From Place"
                                            onChange={handleInputChange}
                                            value={formData.from_place}
                                            className={`form-input-10vw ${errors.from_place ? "error" : inputState.from_place ? "success" : ""
                                                }`}
                                        />
                                        {errors.from_place && (
                                            <span className="error-message font-size-text ">
                                                {errors.from_place}
                                            </span>
                                        )}
                                    </div>
                                    <div className="flex-column">
                                        <label
                                            htmlFor="to_place"
                                            className="form-labels  font-weight500    font-size-subheading" title="To Place"
                                        >
                                            To Place<span className="required">*</span>
                                        </label>
                                        <input
                                            id="to_place"
                                            title="To Place"
                                            type="text"
                                            name="to_place"
                                            maxLength={50}
                                            placeholder="From Place"
                                            onChange={handleInputChange}
                                            value={formData.to_place}
                                            className={`form-input-10vw ${errors.to_place ? "error" : inputState.to_place ? "success" : ""
                                                }`}
                                        />
                                        {errors.to_place && (
                                            <span className="error-message font-size-text ">
                                                {errors.to_place}
                                            </span>
                                        )}
                                    </div>
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="booking_date"
                                        className="form-labels font-weight500    announce-date font-weight400  font-size-heading" title="Booking Date"
                                    >
                                        Booking Date<span className="required">*</span>
                                    </label>
                                    <input
                                        id="booking_date"
                                        title='Booking Date'
                                        type="date"
                                        name="booking_date"
                                        placeholder="date"
                                        onChange={handleInputChange}
                                        value={formData.booking_date}
                                        className={`form-input ${errors.booking_date
                                            ? "error"
                                            : inputState.booking_date
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.booking_date && (
                                        <span className="error-message font-size-text ">
                                            {errors.booking_date}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="person_name"
                                        className="form-labels font-weight500    announce-date font-weight400  font-size-heading" title="Person Name"
                                    >
                                        Person Name<span className="required">*</span>
                                    </label>
                                    <input
                                        id="person_name"
                                        title="Person Name"
                                        type="text"
                                        name="person_name"
                                        maxLength={50}
                                        placeholder="Person Name"
                                        onChange={handleInputChange}
                                        value={formData.person_name}
                                        className={`form-input ${errors.person_name ? "error" : inputState.person_name ? "success" : ""
                                            }`}
                                    />
                                    {errors.person_name && (
                                        <span className="error-message font-size-text ">
                                            {errors.person_name}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="ticket_amount"
                                        className="form-labels font-weight500 announce-date font-weight400  font-size-heading"
                                        title="ticket_amount"
                                    >
                                        Ticket Amount<span className="required">*</span>
                                    </label>
                                    <input
                                        placeholder="Ticket Amount"
                                        title="ticket_amount"
                                        type="number"
                                        min={0}
                                        name="ticket_amount"
                                        onChange={handleInputChange}
                                        value={formData.ticket_amount}
                                        className={`form-input font-weight400  font-size-subheading form-input-background ${errors.ticket_amount
                                            ? "error"
                                            : inputState.ticket_amount
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.ticket_amount && (
                                        <span className="error-message font-size-text ">
                                            {errors.ticket_amount}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="booking_docs"
                                        className="form-labels font-weight500    announce-date font-weight400  font-size-heading" title="Attachement"
                                    >
                                        Booking Proof<span className="required">*</span>
                                    </label>
                                    <input
                                        id="booking_docs"
                                        type="file"
                                        title="Attachment"
                                        name="booking_docs"
                                        accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                                        onChange={handleFileChange}
                                        className="file-input"
                                    />
                                    {errors.booking_docs && (
                                        <span className="error-message font-size-text ">
                                            <br />
                                            {errors.booking_docs}
                                        </span>
                                    )}
                                    {formData.booking_docs ? (
                                        <span
                                            className="file-clear"
                                            onClick={() => handleFileClear("booking_docs")}
                                        >
                                            Clear
                                        </span>
                                    ) : null}
                                </div>
                            </form>
                            <div className="button-models">
                                <button
                                    className="model-button font-weight500 model-button-cancel"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button font-weight500 model-button-leave font-weight500 font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

const ApproveTicket = ({ i, getTicket }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

        try {
            let res = await axios.put(
                `${BASE_URL}/project/ticketbookingupdate/${i.id}/`,
                {
                    status: "approved",
                }
            );

            if (res.status === 200) {
                await getTicket();
                setShow(false);
            } else {
                alert(res);
            }
        } catch (err) {
            //toast Logic
            handleErrorToast(err);
        } finally {
            toast.dismiss(loadingToastId);
        }
    };

    return (
        <>
            <button
                title="Approve Ticket"
                className="model-edit-button"
                onClick={handleShow}
            >
                <CheckGreen />
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Approve Ticket {i.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        Are You Sure You Want to Approve Ticket {i.title}
                        <div className="button-models">
                            <button
                                className="model-button   font-weight500    "
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleFormSubmit}
                                className="model-button   font-weight500    model-button-submit"
                            >
                                Approve
                            </button>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

const RejectTicket = ({ i, getTicket }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

        try {
            let res = await axios.put(
                `${BASE_URL}/project/ticketbookingupdate/${i.id}/`,
                {
                    status: "rejected",
                }
            );

            if (res.status === 200) {
                await getTicket();
                setShow(false);
            } else {
                alert(res);
            }
        } catch (err) {
            //toast Logic
            handleErrorToast(err);
        } finally {
            toast.dismiss(loadingToastId);
        }
    };

    return (
        <>
            <button
                title="Rejected Ticket"
                className="model-delete-button"
                onClick={handleShow}
            >
                <CrossRed />
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Rejected Ticket {i.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        Are You Sure You Want to rejected Ticket {i.title}
                        <div className="button-models">
                            <button
                                className="model-button   font-weight500    model-button-cancel"
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleFormSubmit}
                                className="model-button   font-weight500    model-button-delete"
                            >
                                Rejected
                            </button>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

const DeleteTicket = ({ i, getTicket }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

        try {
            let res = await axios.delete(
                `${BASE_URL}/project/ticketbookingupdate/${i.id}/`
            );

            if (res.status === 200) {
                await getTicket();
                setShow(false);
            } else {
                alert(res);
            }
        } catch (err) {
            //toast Logic
            handleErrorToast(err);
        } finally {
            toast.dismiss(loadingToastId);
        }
    };

    return (
        <>
            <button
                title="Delete Ticket"
                className="model-delete-button"
                onClick={handleShow}
            >
                <DeleteDustbin />
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete Ticket of "{i.person_name}"</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        Are You Sure You Want to delete ticket dated '{formattedDateLong(i.booking_date)}'
                        <div className="button-models">
                            <button
                                className="model-button   font-weight500    model-button-cancel "
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleFormSubmitDelete}
                                className="model-button   font-weight500    model-button-delete"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};




const Ticket = () => {
    const [buffer, setBuffering] = useState(true); //buffering logic


    const [toggleStatus, setToggleStatus] = useState("pending");
    const [ticketsData, setTicketsData] = useState([]);



    const getSiteTickets = async () => {
        setBuffering(true); // Start Buffering
        let url;
        try {
            // Set URL based on role
            // url = `${BASE_URL}/project/ticketbooking/`;
            url = `${BASE_URL}/project/ticketfilterbystatus/${toggleStatus}/`;

            // Make API request
            const sub = await axios.get(url);

            // Update state with the fetched data
            setTicketsData(sub.data);
        } catch (err) {
            // Handle different error scenarios here if needed
            handleErrorToast(err); // Toast Logic
        } finally {
            setBuffering(false); // End Buffering
        }
    };



    useEffect(() => {
        getSiteTickets();
    }, [toggleStatus]);


    const role = sessionStorage.getItem('role')
    const department = sessionStorage.getItem('department')



    return (
        <>
            <Dashboardnavbarcopy name="Ticket Table" url="Ticket Management" />

            <div className="content-tabs">
                <div className="table-css-white-background">
                    <div className="table-heading-flex">
                        <div className="repo-heading font-weight500 font-size-heading">
                            Ticket
                        </div>
                        <div className="field-cont">


                            <div className="table-searchh dropdown-container field-cont-div">
                                <select
                                    onChange={(e) => setToggleStatus(e.target.value)}
                                    value={toggleStatus}
                                    className="dropdown font-size-subheading  "
                                >
                                    <option value="null">All</option>
                                    <option value="pending">Pending For Approval</option>
                                    <option value="approved">Approved Ticket</option>
                                    <option value="rejected">Rejected Ticket</option>
                                </select>
                            </div>



                            {department === "Accounts" &&
                                <AddTicket
                                    getSiteTickets={getSiteTickets}
                                    sectorList={ticketsData}
                                />}
                        </div>
                    </div>
                    {/* <SectorsDetailForm /> */}
                    <div className="table-box height-73vh">
                        <table className="table-css">
                            <thead className="table-heading">
                                <tr className="custom-table-head-tr ">
                                    <th className="align-left">ID</th>
                                    <th className="align-center">Person Name</th>
                                    <th className="align-center">Booking Date</th>
                                    <th className="align-center">From Place</th>
                                    <th className="align-center">To Place</th>
                                    <th className="align-center">Ticket Amount</th>

                                    <th className="align-center">Status</th>
                                    <th className="align-center">Attachment</th>
                                    {/* <th className="align-center">Created By</th> */}
                                    {/* <th className="align-center">Created At</th> */}
                                    <th className="align-center width-5vw">Action</th>

                                    {/* <th className="align-right width-5vw">Delete</th> */}
                                </tr>
                            </thead>
                            {buffer ? (
                                <div className="spinner"></div>
                            ) : (
                                <tbody>
                                    {ticketsData
                                        // ?.filter((e) =>
                                        //     e.status === toggleStatus
                                        // )
                                        // .sort((a, b) =>
                                        //     a.booking_date.localeCompare(b.booking_date, "en", { ignorePunctuation: true })
                                        // )
                                        .map((i, index) => (
                                            <React.Fragment key={index}>
                                                <tr className="tr-border-bottom">
                                                    <td colSpan="6"></td>
                                                </tr>
                                                <tr className="custom-table-head-td">
                                                    <td className="align-left">{index + 1}</td>
                                                    <td className="align-center">
                                                        {i.person_name}
                                                    </td>

                                                    <td className="align-center form-text-trasformation-uppercase">
                                                        {i.booking_date ? formattedDateLong(i.booking_date) : "-"}
                                                    </td>

                                                    <td className="align-center ">{i.from_place}</td>
                                                    <td className="align-center ">{i.to_place}</td>
                                                    <td className="align-center ">{formatCurrencyIndian(i.ticket_amount)}</td>
                                                    <td className="align-center form-text-trasformation-uppercase">{i.status}</td>
                                                    <td className="align-center ">
                                                        {i.booking_docs ?
                                                            <a href={i.booking_docs} target="blank">
                                                                <PDFIcon />
                                                            </a>
                                                            : "- No Attachment -"
                                                        }
                                                    </td>
                                                    <td className="align-center">
                                                        {role === "HOD" ?
                                                            <div className=" flex-row justify-evenly">
                                                                {i.admin_approval_status === "approved" ? "" : <ApproveTicket i={i} getTicket={getSiteTickets} />}
                                                                {i.admin_approval_status === "rejected" ? "" : <RejectTicket i={i} getTicket={getSiteTickets} />}
                                                            </div>
                                                            :
                                                            <DeleteTicket i={i} getTicket={getSiteTickets} />
                                                        }
                                                    </td>



                                                </tr>
                                            </React.Fragment>
                                        ))}
                                </tbody>
                            )}
                        </table>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>



            </div>
        </>
    );
};

export default Ticket
