import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { BASE_URL, BASE_URL_PREBID } from "../../config/axios";
import Dashboardnavbarcopy from "../../layout/Dashboardnavbar";
import { ToastContainer, toast } from "react-toastify";
import { formatCurrencyIndian, formatRoundoffCrores, handleErrorToast, getProgressColor } from "../custom.jsx";
import { Eye, CircularGrid, DeleteDustbin, Sort, Filter, SearchBig, Attachment } from "../AllSvg.jsx";
import { formattedDateLong, formattedDateNoYear } from "../Date.jsx";
import { UploadProjectsBulk } from "../BulkModals";
import { PieChart, Pie, Cell } from 'recharts';

const DeleteProjectDocuments = ({ id, getJobappdata }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      console.log("Delete Expense Detail Api");
      let res = await axios.delete(
        `${BASE_URL}/project/projectupdate/${id.pr_code}/`
      );

      if (res.status === 200) {
        await getJobappdata("null");
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      handleErrorToast(err)
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleShow}>
        <DeleteDustbin />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are You Sure, You want to Delete
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
          <br />
          {id.prebid_prcode} - {id.project_name}

          <div className="button-models">

            <button
              onClick={handleFormSubmitDelete}
              className="model-button model-button-cancel"
            >
              Delete
            </button>
          </div>

        </Modal.Body>
      </Modal>
    </>
  );
};

const AllProjectDetails = () => {

  const [allProjectssData, setAllProjectsData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [search1, setSearch1] = useState("");

  const [sorting, setSorting] = useState("asc_pr"); // Default to "asc_pr"
  const [prProjects, setPrProjects] = useState("false");
  const [buffer, setBuffering] = useState(true); //buffering logic

  const [toggleStatus, setToggleStatus] = useState("null");

  const handleSortChange = (e) => setSorting(e.target.value);
  const handleStatusChange = (e) => setToggleStatus(e.target.value);


  useEffect(() => {
    const compareFunction = (a, b) => {
      const valA = a.prebid_prcode || ''; // Replace null with an empty string
      const valB = b.prebid_prcode || ''; // Replace null with an empty string
      const valM = a.project_short_name || ''; // Replace null with an empty string
      const valN = b.project_short_name || ''; // Replace null with an empty string

      if (sorting === "asc_pr") {
        return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
      } else if (sorting === "desc_pr") {
        return valB.localeCompare(valA, undefined, { numeric: true, sensitivity: 'base' });
      } else if (sorting === "asc_short_name") {
        // Ascending order
        if (!valM && valN) return 1; // Move null/empty values to the end
        if (valM && !valN) return -1; // Keep non-null values before null
        return valM.localeCompare(valN, undefined, { sensitivity: 'base' });
      }
      else if (sorting === "desc_short_name") {
        // Descending order
        if (!valM && valN) return -1; // Move null/empty values to the end
        if (valM && !valN) return 1; // Keep non-null values before null
        return valN.localeCompare(valM, undefined, { sensitivity: 'base' });
      }
    };

    const sortedData = [...allProjectssData].sort(compareFunction);
    setSortedData(sortedData);
  }, [sorting, allProjectssData]);




  const emp_code = sessionStorage.getItem('employee_id');
  const role = sessionStorage.getItem('role');
  const pr_project = sessionStorage.getItem('pr_project');

  const getProjectData = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL_PREBID}/project/projectfilterbyprojectstatus/${toggleStatus}/${role === "HOD" ? "null" : emp_code}/null/`
      );
      setAllProjectsData(res.data);
    } catch (err) {
      handleErrorToast(err)
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getProjectData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleStatus]);


  const navigate = useNavigate();

  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");

  const ViewAllProjectDetails = (projectID) => {
    navigate(`/${urlParts[3]}/projectDetailsPage/`, {
      state: { projectID },
    });
  };

  const handleSearch = () => {
    let filteredingData = allProjectssData;

    if (search1 !== "") {
      const lowercasesearch1 = search1?.toString().toLowerCase();
      filteredingData = filteredingData.filter(
        (employee) => {
          const codeMatch = employee?.prebid_prcode
            ?.toString().toLowerCase()
            .includes(lowercasesearch1.toLowerCase());

          const projectMatch = employee?.project_name
            ?.toString().toLowerCase()
            .includes(lowercasesearch1.toLowerCase());

          const shortProjectMatch = employee?.project_short_name
            ?.toString().toLowerCase()
            .includes(lowercasesearch1.toLowerCase());
          return codeMatch || projectMatch || shortProjectMatch;
        }
      );
    }
    setSortedData(filteredingData);
  };

  useEffect(() => {
    handleSearch();
  }, [search1]);

  return (
    <>

      <Dashboardnavbarcopy url="All Projects" name={"All Projects"} />

      <div className="content-tabs">
        <div className="attendance-subcont">
          <div className="field-cont">

            {pr_project === "true" ?
              <div title="Project Filter " className="field-cont-div ">
                <CircularGrid />

                <select
                  value={prProjects}
                  onChange={(e) => setPrProjects(e.target.value)}
                  className={`attendance-input-field width-15vw`}
                >
                  <option value="null">Select Project Type</option>
                  <option value="false">Projects</option>
                  <option value="true">PR Projects</option>

                </select>
                <hr className="field-cont-hr" />

              </div>
              : null}

            <div title="Sort Filter" className="field-cont-div">
              <Sort />
              <select
                className="attendance-input-field width-15vw   date-field"
                type="text"
                value={sorting}
                onChange={handleSortChange}
              >
                <option value="asc_pr">Asc. Project Code</option>
                <option value="desc_pr">Desc. Project Code</option>
                <option value="asc_short_name">Asc. Project Name</option>
                <option value="desc_short_name">Desc. Project Name</option>
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div title="Status Filter" className="field-cont-div">
              <Filter />
              <select
                className="attendance-input-field width-15vw   date-field"
                value={toggleStatus}
                onChange={handleStatusChange}
              >
                <option value="null">All Projects</option>
                <option value="inprogress">OnGoing</option>
                <option value="completed">Completed</option>
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <SearchBig />
              <input
                className="attendance-input-field width-15vw"
                placeholder="BY Project Code/Name"
                type="text"
                value={search1}
                onChange={(e) => setSearch1(e.target.value)}
              />
              <hr className="field-cont-hr" />
            </div>

          </div>
          <div className="btn-cont">
            <UploadProjectsBulk getProject={getProjectData} />
          </div>
        </div>
        <div className="attendance-box">
          <div className="datagrid-container">
            <div className="custom-table-background height-73vh">
              <div className="table-heading-flex">
                {/* <div className="repo-heading font-size-heading font-weight500">All Projects</div> */}
              </div>
              {/* <div className="table-heading-flex">
                <div className="bloc-tabs-with-white-bg bloc-tabs-with-lesswhite-bg">
                  {["All", "pending", "inprogress", "completed", "hold", "cancelled"].map(
                    (status) => (
                      <button
                        key={status}
                        className={
                          toggleState === (status === "All" ? "null" : status)
                            ? "dashboardcomponent-tabs dashboardcomponent-active-tabs"
                            : "dashboardcomponent-tabs white-tab"
                        }
                        onClick={() =>
                          setToggleState(status === "All" ? "null" : status)
                        }
                      >
                        {status.charAt(0).toUpperCase() + status.slice(1)}
                      </button>
                    )
                  )}
                </div>
              </div> */}
              {buffer ? <div className="spinner-small"></div> :
                <div className="table-box">
                  <table className="custom-table">
                    <thead className="table-heading">
                      <tr className="custom-table-head-trr">
                        <th className="table-heading-text ">Sr.</th>
                        <th className="table-heading-text text-center">Project Code</th>
                        <th className="table-heading-text width-5vw">Project Name</th>
                        <th className="table-heading-text ">Cost (in cr)</th>
                        <th className="table-heading-text ">Concern Person</th>
                        <th className="table-heading-text ">Portal Submission Date</th>
                        <th className="table-heading-text ">Tender ID</th>
                        <th className="table-heading-text ">Remark</th>
                        <th className="table-heading-text text-center">Project Link</th>
                        <th className="table-heading-text text-center">Client Details</th>

                        <th className="table-heading-text text-center">View Project Details</th>

                      </tr>
                    </thead>

                    <tbody>
                      {sortedData
                        .map((i, index) => (
                          <React.Fragment key={index}>
                            <tr className="tr-border-bottom">
                              <td colSpan="8"></td>
                            </tr>
                            <tr className="custom-table-head-tddd font-size-label font-weight500">
                              <td className="table-body-text">{index + 1}</td>
                              <td className="table-body-text">{i.prebid_prcode}</td>
                              <td className="table-body-text">{i.project_short_name}</td>

                              <td className="table-body-text">{formatRoundoffCrores(i.cost)}</td>
                              {/* <td className="table-body-text">{i.name_of_client}</td> */}
                              <td className="table-body-text">
                                {i.concern_person_name === "null"
                                  ? "-"
                                  : i.concern_person_name}
                              </td>
                              <td className="table-body-text">
                                {i.portal_submission_date === null
                                  ? ""
                                  : formattedDateLong(i.portal_submission_date)}
                              </td>
                              <td className="table-body-text">{i.tender_id}</td>

                              <td className="table-body-text">
                                {i.remark === "null" ? "-" : i.remark}
                              </td>
                              <td className="table-body-text align-center">
                                {i.link ?
                                  <>
                                    {/* <a href={i.link} target="blank" className="document-download-button  width-5vw  width-5vw">
                                      Link
                                    </a> */}
                                    <a href={i.link} target="blank" className="width-5vw">
                                      <Attachment />
                                    </a>
                                  </>
                                  :
                                  <>-</>
                                }
                              </td>
                              {prProjects !== "null" ? (
                                <td className="table-body-text">
                                  {prProjects === "false" && i.pr_project_false_clients?.length > 0 ? (
                                    <div className="employee-card-image">
                                      <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                        <thead>
                                          <tr className="">
                                            <td className="padding-bottom-5px">Client Name</td>
                                            <th style={{ padding: "0px 4px" }}>|</th>
                                            <td className="padding-bottom-5px">Work Order Date</td>
                                            <th style={{ padding: "0px 4px" }}>|</th>
                                            <td className="padding-bottom-5px">Target Date</td>
                                            <th style={{ padding: "0px 4px" }}>|</th>
                                            <td className="padding-bottom-5px">Due Date</td>
                                            <th style={{ padding: "0px 4px" }}>|</th>
                                            <td className="padding-bottom-5px">Status</td>
                                            <th style={{ padding: "0px 4px" }}>|</th>
                                            <td className="padding-bottom-5px">Progress</td>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {i.pr_project_false_clients.map((clientdata) => (
                                            <tr key={clientdata.id}>
                                              <td className="padding-bottom-5px">{clientdata.client_names}</td>
                                              <th style={{ padding: "0px 4px" }}>|</th>
                                              <td className="padding-bottom-5px">{formattedDateNoYear(clientdata.wo_date)}</td>
                                              <th style={{ padding: "0px 4px" }}>|</th>
                                              <td className="padding-bottom-5px">{formattedDateNoYear(clientdata.target_date)}</td>
                                              <th style={{ padding: "0px 4px" }}>|</th>
                                              <td className="padding-bottom-5px">{formattedDateNoYear(clientdata.due_date)}</td>
                                              <th style={{ padding: "0px 4px" }}>|</th>
                                              <td className="padding-bottom-5px form-text-trasformation-uppercase">{clientdata.status}</td>
                                              <th style={{ padding: "0px 4px" }}>|</th>
                                              <td className="padding-bottom-5px">
                                                <div style={{ margin: '5px 0px' }} >
                                                  <div className='justify-between' style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                                                    {/* Percent */}
                                                    <span style={{ marginLeft: '10px', fontWeight: 'normal' }}>{clientdata.workstage_progress?.progress_percentage}%</span>
                                                  </div>
                                                  <div style={{
                                                    width: '100%',
                                                    height: '5px',
                                                    backgroundColor: '#EFEFEF',
                                                    borderRadius: '5px',
                                                    position: 'relative',
                                                    border: '1px solid black',
                                                  }}>
                                                    <div style={{
                                                      width: `${clientdata.workstage_progress?.progress_percentage}%`,
                                                      height: '100%',
                                                      backgroundColor: getProgressColor(clientdata.workstage_progress?.progress_percentage),
                                                      borderRadius: '15px',
                                                      position: 'absolute',
                                                      top: 0,
                                                      left: 0,
                                                    }} />
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  ) : prProjects === "true" && i.pr_project_true_clients?.length > 0 ? (
                                    <div className="employee-card-image">
                                      <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                        <thead>
                                          <tr className="">
                                            <td className="padding-bottom-5px">Client Name</td>
                                            <th style={{ padding: "0px 4px" }}>|</th>
                                            <td className="padding-bottom-5px">Work Order Date</td>
                                            <th style={{ padding: "0px 4px" }}>|</th>
                                            <td className="padding-bottom-5px">Target Date</td>
                                            <th style={{ padding: "0px 4px" }}>|</th>
                                            <td className="padding-bottom-5px">Due Date</td>
                                            <th style={{ padding: "0px 4px" }}>|</th>
                                            <td className="padding-bottom-5px">Status</td>
                                            <th style={{ padding: "0px 4px" }}>|</th>
                                            <td className="padding-bottom-5px">Progress</td>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {i.pr_project_true_clients.map((clientdata) => (
                                            <tr className="table-row-highlight-green" key={clientdata.id}>
                                              <td className="padding-bottom-5px">PR-{clientdata.client_names}</td>
                                              <th style={{ padding: "0px 4px" }}>|</th>
                                              <td className="padding-bottom-5px">{formattedDateNoYear(clientdata.wo_date)}</td>
                                              <th style={{ padding: "0px 4px" }}>|</th>
                                              <td className="padding-bottom-5px">{formattedDateNoYear(clientdata.target_date)}</td>
                                              <th style={{ padding: "0px 4px" }}>|</th>
                                              <td className="padding-bottom-5px">{formattedDateNoYear(clientdata.due_date)}</td>
                                              <th style={{ padding: "0px 4px" }}>|</th>
                                              <td className="padding-bottom-5px form-text-trasformation-uppercase">{clientdata.status}</td>
                                              <th style={{ padding: "0px 4px" }}>|</th>
                                              <td className="padding-bottom-5px">
                                                <div style={{ margin: '5px 0px' }} >
                                                  <div className='justify-between' style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                                                    {/* Percent */}
                                                    <span style={{ marginLeft: '10px', fontWeight: 'normal' }}>{clientdata.workstage_progress?.progress_percentage}%</span>
                                                  </div>
                                                  <div style={{
                                                    width: '100%',
                                                    height: '5px',
                                                    backgroundColor: '#EFEFEF',
                                                    borderRadius: '5px',
                                                    position: 'relative',
                                                    border: '1px solid black',
                                                  }}>
                                                    <div style={{
                                                      width: `${clientdata.workstage_progress?.progress_percentage}%`,
                                                      height: '100%',
                                                      backgroundColor: getProgressColor(clientdata.workstage_progress?.progress_percentage),
                                                      borderRadius: '15px',
                                                      position: 'absolute',
                                                      top: 0,
                                                      left: 0,
                                                    }} />
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  ) : (
                                    <div className="align-center ">
                                      <p className="text-center">-</p>
                                    </div>
                                  )}
                                </td>)
                                : (
                                  <td className="table-body-text">
                                    {i.pr_project_false_clients?.length > 0 ? (
                                      <div className="employee-card-image">
                                        <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                          <thead>
                                            <tr className="">
                                              <td className="padding-bottom-5px">Client Name</td>
                                              <th style={{ padding: "0px 4px" }}>|</th>
                                              <td className="padding-bottom-5px">Work Order Date</td>
                                              <th style={{ padding: "0px 4px" }}>|</th>
                                              <td className="padding-bottom-5px">Target Date</td>
                                              <th style={{ padding: "0px 4px" }}>|</th>
                                              <td className="padding-bottom-5px">Due Date</td>
                                              <th style={{ padding: "0px 4px" }}>|</th>
                                              <td className="padding-bottom-5px">Status</td>
                                              <th style={{ padding: "0px 4px" }}>|</th>
                                              <td className="padding-bottom-5px">Progress</td>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {i.pr_project_false_clients?.map((clientdata) => (
                                              <tr key={clientdata.id}>
                                                <td className="padding-bottom-5px">{clientdata.client_names}</td>
                                                <th style={{ padding: "0px 4px" }}>|</th>
                                                <td className="padding-bottom-5px">{formattedDateNoYear(clientdata.wo_date)}</td>
                                                <th style={{ padding: "0px 4px" }}>|</th>
                                                <td className="padding-bottom-5px">{formattedDateNoYear(clientdata.target_date)}</td>
                                                <th style={{ padding: "0px 4px" }}>|</th>
                                                <td className="padding-bottom-5px">{formattedDateNoYear(clientdata.due_date)}</td>
                                                <th style={{ padding: "0px 4px" }}>|</th>
                                                <td className="padding-bottom-5px form-text-trasformation-uppercase">{clientdata.status}</td>
                                                <th style={{ padding: "0px 4px" }}>|</th>
                                                <td className="padding-bottom-5px">
                                                  <div style={{ margin: '5px 0px' }} >
                                                    <div className='justify-between' style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                                                      {/* Percent */}
                                                      <span style={{ marginLeft: '10px', fontWeight: 'normal' }}>{clientdata.workstage_progress?.progress_percentage}%</span>
                                                    </div>
                                                    <div style={{
                                                      width: '100%',
                                                      height: '5px',
                                                      backgroundColor: '#EFEFEF',
                                                      borderRadius: '5px',
                                                      position: 'relative',
                                                      border: '1px solid black',
                                                    }}>
                                                      <div style={{
                                                        width: `${clientdata.workstage_progress?.progress_percentage}%`,
                                                        height: '100%',
                                                        backgroundColor: getProgressColor(clientdata.workstage_progress?.progress_percentage),
                                                        borderRadius: '15px',
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                      }} />
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>
                                            ))}
                                            {i.pr_project_true_clients?.map((clientdata) => (
                                              <tr className="table-row-highlight-green"
                                                key={clientdata.id}>
                                                <td className="padding-bottom-5px">PR-{clientdata.client_names}</td>
                                                <th style={{ padding: "0px 4px" }}>|</th>
                                                <td className="padding-bottom-5px">{formattedDateNoYear(clientdata.wo_date)}</td>
                                                <th style={{ padding: "0px 4px" }}>|</th>
                                                <td className="padding-bottom-5px">{formattedDateNoYear(clientdata.target_date)}</td>
                                                <th style={{ padding: "0px 4px" }}>|</th>
                                                <td className="padding-bottom-5px">{formattedDateNoYear(clientdata.due_date)}</td>
                                                <th style={{ padding: "0px 4px" }}>|</th>
                                                <td className="padding-bottom-5px form-text-trasformation-uppercase">{clientdata.status}</td>
                                                <th style={{ padding: "0px 4px" }}>|</th>
                                                <td className="padding-bottom-5px">
                                                  <div style={{ margin: '5px 0px' }}>
                                                    <div className='justify-between' style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                                                      {/* Percent */}
                                                      <span style={{ marginLeft: '10px', fontWeight: 'normal' }}>{clientdata.workstage_progress?.progress_percentage}%</span>
                                                    </div>
                                                    <div style={{
                                                      width: '100%',
                                                      height: '5px',
                                                      backgroundColor: '#EFEFEF',
                                                      borderRadius: '5px',
                                                      position: 'relative',
                                                      border: '1px solid black',
                                                    }}>
                                                      <div style={{
                                                        width: `${clientdata.workstage_progress?.progress_percentage}%`,
                                                        height: '100%',
                                                        backgroundColor: getProgressColor(clientdata.workstage_progress?.progress_percentage),
                                                        borderRadius: '15px',
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                      }} />
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>

                                    ) : (
                                      <div className="align-center ">
                                        <p className="text-center">-</p>
                                      </div>
                                    )}
                                  </td>)}
                              <td className="align-center text-center"                              >
                                <button title="View Project Detail Page" className="width-5vw" onClick={(e) => ViewAllProjectDetails(i.pr_code)}>
                                  <Eye />
                                </button>
                              </td>


                            </tr>
                          </React.Fragment>
                        ))}
                    </tbody>
                  </table>
                </div>
              }
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default AllProjectDetails;
