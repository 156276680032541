import axios from "axios"
// import { Link } from "react-router-dom";

import { BASE_URL, BASE_URL_PREBID } from "../../../config/axios"
import React, { useEffect, useState } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import { useNavigate } from 'react-router-dom';
// import Createnewall from "../../../components/Modals/Createnewall";
import Dashboardnavbarcopy from "../../../layout/Dashboardnavbar";
import { formattedDateLong } from "../../../components/Criticalissue/Date";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { formattedDate } from "../../../components/Date";





// Announcements Tab

const TaskbyStatus = ({ status }) => {
  const [inprogresstask, setInprogresstask] = useState([]);

  useEffect(() => {
    const getInprogresstask = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/wfm/taskbyempp/${sessionStorage.getItem('employee_id')}/${status}/`);
        setInprogresstask(res.data);
      } catch (err) {
        // IMPORTANT: TURN THE BELOW COMMENT OFF!!!
        // alert(err.message);
      }
    };
    getInprogresstask();
  }, []);

  const createMarkup = (content) => {
    const linkRegex = /(?:https?|ftp):\/\/[^\s]+/g;
    return {
      __html: content?.replace(linkRegex, (url) => {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      }),
    };
  };

  function handlePriority(value) {

    if (value === 1) {
      return <button className="priority-button2 Color-tab-red">High</button>
    }
    else if (value === 2) {
      return <button className="priority-button2 Color-tab-yellow">Medium</button>
    }
    else if (value === 3) {
      return <button className="priority-button2 Color-tab-blue">Low</button>
    }
    else
      return <td className="align-center font-size-text font-weight400"></td>
  }


  return (
    <div className="dashcomp-task-cards" >

      {/* For Testing Purpose Only! */}
      {/* <div className="d">
              <h5>1. Announcement</h5>
              <img src={Calaneder} alt="Desk Icon"
                          style={{ color: 'red', marginRight: '8px',
                          width: '16px', height: '16px', marginTop: "-4px" }}
                      />
          </div> */}

      {inprogresstask.map((data, index) => {
        const formattedDate = new Date(data.date).toLocaleString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        });
        return (
          <div className=''>
            <div className="dashcomp-task-card-data">
              {/* <h3 className='dashcomp-announcement-heading  font-size-heading font-weight500'>{index + 1}.{"  "} By: {data.assignedbyname}</h3> */}

              <div style={{ display: "flex" }}>
                <h3 style={{ flex: 3 }} className='dashcomp-announcement-heading  font-size-heading font-weight500'>{index + 1}.{"  "} <span className="dashcomp-announcement-date  font-size-subheading"> By:</span> {data.assignedbyname}</h3>
                <h3 style={{ flex: 1 }} className='dashcomp-announcement-heading  font-size-heading font-weight500'>{"     "} <span className="dashcomp-announcement-date  font-size-subheading"> To:</span> {data.concern_personname}</h3>
              </div>
              {/* <span className="dashcomp-announcement-date  font-size-subheading">

                Due On:    {formattedDateLong(data.due_date)}
              </span> */}
              <div style={{ display: "flex" }}>
                <span style={{ flex: 3 }} className="dashcomp-announcement-date  font-size-subheading">

                  Due On:    {formattedDateLong(data.due_date)}
                </span>
                <span style={{ flex: 1 }} className="dashcomp-announcement-date  font-size-subheading">

                  Priority: {data.priority ? handlePriority(data.priority) : undefined}

                </span>

              </div>
              <p className="dashcomp-announcement-p">
                <div dangerouslySetInnerHTML={createMarkup(data.task)} />
              </p>
            </div>
          </div>
        )
      })
      }
    </div>
  )
}

// Main Component
const TaskInformation = (selectedName, selectedYear, selectedMonth, selectedquarter) => {
  console.log(selectedName, selectedYear, selectedMonth, selectedquarter, 'taskinformation')

  const navigate = useNavigate()

  const currentUrl = window.location.href;


  const urlParts = currentUrl.split('/');


  const baseUrl = "/" + urlParts[3] + `/criticalIssues`;

  const allproject = () => {
    navigate(baseUrl)
  }


  const [toggleState, setToggleState] = useState(1);
  return (
    <div className=' my-priorities
'>
      < div className='"dashboardcomponent-task 
' >

        <div className="dashboard-sectiona-a">
          <div>
            <h3 className="dashboard-sectiona-heading font-size-heading font-weight500">All Priorities</h3>
          </div>
          <div>
            <button className="allproject-dashboard  font-size-heading font-weight500" onClick={allproject}>View {">"}</button>

          </div>
        </div>
        <div>
          {/* <div className="bloc-tabss"> */}
          <div className="bloc-tabs-tasksss">
            <button
              className={toggleState === 1 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs"}
              onClick={() => setToggleState(1)}
            >
              Pending
            </button>
            <button
              className={toggleState === 2 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs"}
              onClick={() => setToggleState(2)}
            >
              In Progress
            </button>
            <button
              className={toggleState === 3 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs"}
              onClick={() => setToggleState(3)}
            >
              Completed
            </button>
            {/* <button
                        className={toggleState === 4 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs"}
                        onClick={() => setToggleState(4)}
                    >
                        View All Projects
                    </button> */}
          </div>
        </div>
        <div className="content-tabs-critical-issue-dashboard">
          {toggleState === 1 ? <TaskbyStatus status="pending" /> : null}
          {toggleState === 2 ? <TaskbyStatus status="inprocess" /> : null}
          {toggleState === 3 ? <TaskbyStatus status="completed" /> : null}
        </div>
      </div >
    </div >
  )
}


// New Projects
const NewProjects = (selectedName, selectedYear, selectedMonth, selectedquarter) => {
  console.log(selectedName, selectedYear, selectedMonth, selectedquarter, 'newproject')


  //  ****************    Api Request

  const [allnewproject, setAllnewproject] = useState([]);

  useEffect(() => {
    const getProjectStats = async () => {
      try {
        const res = await axios.get(`${BASE_URL_PREBID}/project/newprojects/`);
        const sortedData = res.data.sort((a, b) => new Date(b.wo_date) - new Date(a.wo_date));
        setAllnewproject(sortedData);
      } catch (err) {
        // alert(err.message);
      }
    };

    getProjectStats();

    const timer = setInterval(() => {
      getProjectStats();
    }, 120000);

    return () => clearInterval(timer);
  }, []);

  const navigate = useNavigate()

  const currentUrl = window.location.href;


  const urlParts = currentUrl.split('/');


  const baseUrl = "/" + urlParts[3] + `/newprojectdetails`;

  const allproject = () => {
    navigate(baseUrl)
  }


  return (
    <>
      <div className="new-projects-a width-45vw"
      >
        <div className="dashboardcomponent-task 
">
          <div className="dashboard-sectiona-a">
            <div>
              <h3 className="dashboard-sectiona-heading font-size-heading font-weight500">New Projects</h3>
            </div>
            <div>
              <button className="allproject-dashboard  font-size-heading font-weight500" onClick={allproject}>View {">"}</button>
            </div>
          </div>
          <div className="top-list-dashboard">
            <ul className="flex-between">
              <li>Project Name</li>
              <li>Date</li>
            </ul>
          </div>
          <div className="new-projects-dashboard">
            {allnewproject.map((item, index) => {



              const fullName = item?.concern_person_name;
              const parts = fullName?.split(' ');
              // const firstName = parts[0];


              return (
                <>
                  <div className="new-projects-dashboard-a">
                    <div className="new-projects-dashboard-a-b">
                      <h5 className="new-projects-dashboard-a-b-h3 font-size-heading font-weight500">{item.project_short_name ? item.project_short_name : item.project_name}</h5>
                      <div className="new-projects-dashboard-a-b-div flex-row">
                        <p className="new-projects-dashboard-a-b-p font-size-subheading ">{item.client_name} </p>
                        <p className="new-projects-dashboard-a-b-p font-size-subheading ">{fullName}</p>
                        <p className="new-projects-dashboard-a-b-p font-size-subheading ">{formattedDateLong(item.wo_date)}</p>
                      </div >
                    </div>
                  </div>
                </>
              )
            })}
          </div >
        </div >
      </div >
    </>
  )
}


// Due Projects
const DueProjects = (selectedName, selectedYear, selectedMonth, selectedquarter) => {
  console.log(selectedName, selectedYear, selectedMonth, selectedquarter, 'dueproject')

  //  ****************    Api Request

  const [allnewproject, setAllnewproject] = useState([]);

  useEffect(() => {
    const getProjectStats = async () => {
      try {
        const res = await axios.get(`${BASE_URL_PREBID}/project/dueprojectsdetails/`);
        setAllnewproject(res.data);
      } catch (err) {
        // alert(err.message);
      }
    };

    getProjectStats();

    const timer = setInterval(() => {
      getProjectStats();
    }, 120000);

    return () => clearInterval(timer);
  }, []);


  const navigate = useNavigate()

  const currentUrl = window.location.href;


  const urlParts = currentUrl.split('/');


  const baseUrl = "/" + urlParts[3] + `/dueprojectdetails`;

  const allproject = () => {
    navigate(baseUrl)
  }



  return (
    <>

      <div className="new-projects width-40vw"
      >
        <div className=" dashboardcomponent-task 
">

          <div className="dashboard-sectiona-a">
            <div>
              <h3 className="dashboard-sectiona-heading font-size-heading font-weight500">Due Project</h3>
            </div>
            <div>
              <button className="allproject-dashboard  font-size-heading font-weight500" onClick={allproject}>View {">"}</button>
            </div>
          </div>
          <div className="top-list-dashboard">
            <ul className="flex-between">
              <li>Project Name</li>
              <li>Due Date</li>
            </ul>
          </div>

          <div className="new-projects-dashboard">
            {allnewproject.map((item, index) => {

              const fullName = item?.concern_person_name;
              const parts = fullName?.split(' ');
              // const firstName = parts[0];
              console.log(parts)
              return (
                <>
                  <div
                    style={{
                      backgroundColor:
                        item.due_date === formattedDate ? "#c1fbaf" : "white",
                    }}
                    className="new-projects-dashboard-a">
                    <div className="new-projects-dashboard-a-b ">
                      <h1 className="dashcomp-announcement-heading  font-size-heading font-weight500">{index + 1}.</h1>
                      <h5 className="new-projects-dashboard-a-b-h3 font-size-heading font-weight500">{item.project_short_name ? item.project_short_name : item.project_name}</h5>

                      <div className="new-projects-dashboard-a-bxtween flex-row flex-between">
                        <p className="new-projects-dashboard-a-b-p font-size-subheading ">{item.client_name} </p>
                        <p className="new-projects-dashboard-a-b-p font-size-subheading ">{fullName}</p>
                        <p className="new-projects-dashboard-a-b-p font-size-subheading ">{item.due_date === null ? "" : formattedDateLong(item.due_date)}</p>
                        <br />
                      </div >
                    </div>
                  </div>
                </>
              )
            })}
          </div>
        </div >
      </div >
    </>
  )
}


// Due Projects
const PriorityBidProjects = (selectedName, selectedYear, selectedMonth, selectedquarter) => {

  const [allnewproject, setAllnewproject] = useState([]);

  const Applyfilter = () => {
    let filterdata = allnewproject
    console.log(filterdata, 'filterdata')
    if (selectedName.selectedName === 'month-wise') {


      filterdata = allnewproject.filter(project => {
        const projectDate = new Date(project.date);
        return projectDate.getMonth() === selectedName.selectedMonth && projectDate.getFullYear() === selectedYear;
      });
    }

    if (selectedName.selectedName === 'yearly-wise') {


      filterdata = allnewproject.filter(project => {
        const projectDate = new Date(project.date);
        return projectDate.getFullYear() === selectedName.selectedYear;
      });
    }
    if (selectedName.selectedName === 'quarterly-wise') {


      filterdata = allnewproject.filter(project => {
        const projectDate = new Date(project.date);
        const month = projectDate.getMonth();


        const isInQuarter = (selectedName.selectedQuarter === 1 && month >= 0 && month <= 2) ||
          (selectedName.selectedQuarter === 2 && month >= 3 && month <= 5) ||
          (selectedName.selectedQuarter === 3 && month >= 6 && month <= 8) ||
          (selectedName.selectedQuarter === 4 && month >= 9 && month <= 11);

        return isInQuarter && projectDate.getFullYear() === selectedName.selectedYear;
      });
    }

    if (selectedName.selectedName === 'overall') {

      filterdata = allnewproject;
    }




  }
  useEffect(() => {
    Applyfilter()
  }, [selectedName, allnewproject])
  //  ****************    Api Request


  useEffect(() => {
    const getProjectStats = async () => {
      try {
        const res = await axios.get(`${BASE_URL_PREBID}/project/priority-bid-date/`);
        setAllnewproject(res.data);
      } catch (err) {
        // alert(err.message);
      }
    };

    getProjectStats();

    const timer = setInterval(() => {
      getProjectStats();
    }, 120000);

    return () => clearInterval(timer);
  }, []);


  const navigate = useNavigate()

  const currentUrl = window.location.href;


  const urlParts = currentUrl.split('/');


  const baseUrl = "/" + urlParts[3] + `/prioritybidprojectdetails`;

  const allproject = () => {
    navigate(baseUrl)
  }



  return (
    <>

      <div className="new-projects width-40vw"
      >
        <div className=" dashboardcomponent-task ">

          <div className="dashboard-sectiona-a">
            <div>
              <h3 className="dashboard-sectiona-heading font-size-heading font-weight500">Add Tender Projects</h3>
            </div>
            <div>
              <button className="allproject-dashboard  font-size-heading font-weight500" onClick={allproject}>View {">"}</button>
            </div>
          </div>
          <div className="top-list-dashboard">
            <ul className="flex-between">
              <li>Project Name</li>
              <li>Due Date</li>
              <li>Project Name</li>
              <li>Due Date</li>
            </ul>
          </div>

          <div className="new-projects-dashboard">
            {allnewproject.map((item, index) => {

              const fullName = item?.concern_person_name;
              const parts = fullName?.split(' ');
              // const firstName = parts[0];
              console.log(parts)
              return (
                <>
                  <div
                    style={{
                      backgroundColor:
                        item.bid_tender_date === formattedDate ? "#c1fbaf" : "white",
                    }}
                    className="new-projects-dashboard-a">
                    <div className="new-projects-dashboard-a-b flex-row flex-between">
                      <h1 className="dashcomp-announcement-heading  font-size-heading font-weight500">{index + 1}.</h1>
                      <h5 className="new-projects-dashboard-a-b-h3 font-size-heading font-weight500">{item.project_short_name ? item.project_short_name : item.project_name}</h5>

                      <div className="new-projects-dashboard-a-b-div flex-row flex-between ">
                        <p className="new-projects-dashboard-a-b-p font-size-subheading ">{item.client_name} </p>
                        <p className="new-projects-dashboard-a-b-p font-size-subheading ">{fullName}</p>
                        <p className="new-projects-dashboard-a-b-p font-size-subheading ">{item.bid_tender_date === null ? "" : formattedDateLong(item.bid_tender_date)}</p>
                      </div >
                    </div>
                  </div>
                </>
              )
            })}
          </div>
        </div >
      </div >
    </>
  )
}


// Project Graph
const ProjectGraph = ({ allProjectsData }) => {


  return (
    <div className="balance-cont balance-chart-cont cont-col " style={{ margin: "10px, 20px", }}>

      <div className=" dashboardcomponent-task 
">
        <div className="justify-between cont-row align-cen bgwhite mary-10" style={{ padding: "10px 0" }}>
          <div>
            <div className="dashboard-sectiona-heading font-size-heading font-weight500">Project Summary</div>
          </div>

        </div>
        <div className="dashboard-graph">
          <ResponsiveContainer height={300} width="100%">
            <LineChart
              width={1000}
              height={300}
              data={allProjectsData}
              margin={{
                top: 5,
                right: 30,
                left: 0,
                bottom: 5,
              }}
            >
              <CartesianGrid horizontal="true" vertical="" strokeDasharray="10 6" />
              <XAxis dataKey="name" tickMargin={10} />
              <YAxis />
              <Tooltip />
              <Line legendType="dot" type="monotone" dataKey="count" stroke="#FF747C" strokeWidth={5} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div >
  )
}



// Project Cards
const Dashboard = () => {
  // Hero Section


  const [allProjectsDataa, setAllProjectsDataa] = useState({});

  const getprojectdata = async () => {
    try {
      const res = await axios.get(`${BASE_URL_PREBID}/project/projectcount/`);
      setAllProjectsDataa(res.data);

    } catch (err) {
      // alert(err.message);
    }
  };

  useEffect(() => {


    getprojectdata();
  }, []);

  const allProjectsData = [
    {
      name: "Total Projects",
      count: allProjectsDataa.total,
      status: "null"
    },
    {
      name: "Pending",
      count: allProjectsDataa.pending,
      status: "pending"
    },
    {
      name: "In Progress",
      count: allProjectsDataa.inprogress,
      status: "inprogress"
    },
    {
      name: "Completed",
      count: allProjectsDataa.completed,
      status: "completed"
    },
    {
      name: "Hold",
      count: allProjectsDataa.hold,
      status: "hold"
    },
    {
      name: "Cancelled",
      count: allProjectsDataa.cancelled,
      status: "cancelled"
    },
  ];


  const cardcolor = [

    {
      color: "#06AD06",
    },
    {
      color: "#F7BA1E",
    },


    {
      color: "#06AD06",
    },
    {
      color: "#FF747C",
    },
    {
      color: "#F7BA1E",
    },
    {
      color: "#FF747C",
    },
  ]

  const [today, setDate] = React.useState(new Date());
  React.useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date());
    }, 60 * 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);


  const hour = today.getHours();
  const wish = `Good ${(hour < 12 && "Morning") || (hour < 17 && "Afternoon") || "Evening"
    } `;

  const navigate = useNavigate();

  const currentUrl = window.location.href;
  const urlParts = currentUrl.split('/');

  const baseUrl = "/" + urlParts[3] + `/AllProjectDetails`;



  const handleCardClick = (status) => {
    navigate(baseUrl, { state: { status } });
  };

  const [selectedName, setSelectname] = useState('month-wise');
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedquarter, setSelectedquarter] = useState('');

  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 20;
  const endYear = currentYear + 20;
  // const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);
  const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => {
    const year = startYear + index;
    const nextYear = year + 1; // The next year in the range
    return `${year}-${nextYear.toString().slice(-2)}`; // e.g. "2022-23"
  });

  const monthOptions = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" }
  ];
  return (
    <>

      <Dashboardnavbarcopy url="Dashboard" name={wish} />
      <div className="workarea-background" style={{ marginTop: "20px" }}>

        <div className="infocards-flex">
          <label htmlFor="time-selection">Select Time Period:</label>
          <select id="time-selection" onChange={(e) => setSelectname(e.target.value)}
            value={selectedName}>

            <option value="month-wise">Month Wise</option>
            <option value="quarter-wise">Quarter Wise</option>
            <option value="year-wise">Year Wise</option>
            <option value="overall">Over all</option>

          </select>
        </div>

        {selectedName === "month-wise" && (<>
          <div title="Month" className="flex-column form-group-selectt">
            <label htmlFor="month" className="form-labels  font-weight500    font-size-subheading">
              Month<span className="required">*</span>
            </label>
            <br />
            <select
              type="text"
              id="month"
              name="month"
              placeholder="month"
              value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}
              className='form-input form-group-selection'
            >

              <option value="">Select a month</option>
              {monthOptions.map((month) => (
                <option key={month.id} value={month.id}>{month.name}</option>
              ))}
            </select>
            <div className="form-group-selection-arrow">
              {/* <DropdownArrowOption /> */}

            </div>

          </div>
          <div title="Year" className="flex-column form-group-selectt">
            <label htmlFor="year" className="form-labels  font-weight500    font-size-subheading">
              Year<span className="required">*</span>
            </label>
            <br />
            <select
              type="text"
              id="year"
              name="year"
              placeholder="year"
              value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}
            // className="form-input form-group-selection"
            >

              <option value="">Select a Year</option>
              {years.map((year) => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
            <div className="form-group-selection-arrow">
              {/* <DropdownArrowOption /> */}

            </div>

          </div></>)}

        {selectedName === "quarter-wise" &&
          <div title="Quarter" className="flex-column form-group-selectt">
            <label htmlFor="Quarter" className="form-labels  font-weight500    font-size-subheading">
              Quarter<span className="required">*</span>
            </label>
            <br />
            <select
              type="text"
              id="Quarter"
              name="Quarter"
              placeholder="Quarter"
              value={selectedquarter} onChange={(e) => setSelectedquarter(e.target.value)}
              className='form-input form-group-selection'
            >
              <option value="">Select quarter</option>
              <option value="1st-quarter">April - June</option>
              <option value="2st-quarter">July - September</option>
              <option value="3st-quarter">October - December</option>
              <option value="4st-quarter">Janueary - March</option>
            </select>
            <div className="form-group-selection-arrow">
              {/* <DropdownArrowOption /> */}

            </div>

            <div title="Year" className="flex-column form-group-selectt">
              <label htmlFor="year" className="form-labels  font-weight500    font-size-subheading">
                Year<span className="required">*</span>
              </label>
              <br />
              <select
                type="text"
                id="year"
                name="year"
                placeholder="year"
                value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}
              // className="form-input form-group-selection"
              >

                <option value="">Select a Year</option>
                {years.map((year) => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </select>
              <div className="form-group-selection-arrow">
                {/* <DropdownArrowOption /> */}

              </div>

            </div>

          </div>}


        {selectedName === "year-wise" &&
          <div title="Year" className="flex-column form-group-selectt">
            <label htmlFor="year" className="form-labels  font-weight500    font-size-subheading">
              Year<span className="required">*</span>
            </label>
            <br />
            <select
              type="text"
              id="year"
              name="year"
              placeholder="year"
              value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}
            // className="form-input form-group-selection"
            >

              <option value="">Select a Year</option>
              {years.map((year) => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
            <div className="form-group-selection-arrow">
              {/* <DropdownArrowOption /> */}

            </div>

          </div>
        }


        <div className="infocards-flex " style={{ width: "100%" }}>
          {allProjectsData.map((items, index) => {
            const percentage =
              allProjectsDataa.total > 0
                ? Math.round((items.count / allProjectsDataa.total) * 100)
                : 0; // Ensure no division by zero

            return (
              <div
                key={index}
                className="card-cont-emp mt20 cards-shadow card-hover"
                onClick={() => handleCardClick(items.status)}
              >
                <h4 className="font-size-heading">{items.name} <span>({percentage}%)</span></h4>
                <h4 className="font-size-heading font-weight600">{items.count} </h4>
                <div className="progressbar">
                  <ProgressBar
                    completed={percentage}
                    maxCompleted={100}
                    baseBgColor="#EFF4FB"
                    bgColor={cardcolor[index].color}
                    height="10px"
                    isLabelVisible={false}
                  />
                </div>

              </div>
            );
          })}

        </div>


        <div className="dasboard-sectionb" >
          <div className="dashboard-sectionb-a">
            <TaskInformation selectedName={selectedName} selectedYear={selectedYear} selectedMonth={selectedMonth} selectedquarter={selectedquarter} />
            <DueProjects selectedName={selectedName} selectedYear={selectedYear} selectedMonth={selectedMonth} selectedquarter={selectedquarter} />
          </div>
          <div className="dashboard-sectionb-a">


            <PriorityBidProjects selectedName={selectedName} selectedYear={selectedYear} selectedMonth={selectedMonth} selectedquarter={selectedquarter} />
            <NewProjects selectedName={selectedName} selectedYear={selectedYear} selectedMonth={selectedMonth} selectedquarter={selectedquarter} />

          </div>
        </div>
        <div className="dasboard-sectionb" style={{ marginBottom: "40px" }}>
          <div className="dashboard-sectionb-a">
            <ProjectGraph allProjectsData={allProjectsData} />
          </div>
        </div>
      </div >
    </>
  );
};

export default Dashboard;