import React, { useEffect, useState } from "react";
import usePermission from "../../config/permissions";
import axios from "axios";
import Dashboardnavbarcopy from "../../layout/Dashboardnavbar";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "../../config/axios";
import { Modal } from "react-bootstrap";
import { AddwithWhiteCircle, UpdatePencil, SearchBig, DropdownArrow } from "../AllSvg";
import { UploadClientNameBulk, UpdateClientNameBulk } from "../BulkModals";
import { Allclient } from "../Modals/Client";



const AddClientName = ({ getClientName }) => {
    const [formData, setFormData] = useState({
        title: '',
        sub_company: '',
    });

    const [isSubmitted, setIsSubmitted] = useState(false);

    const [show, setShow] = useState(false);

    const handleShow = () => {

        setShow(true);
        getCompany();
    }

    const handleClose = () => {
        setShow(false);

        setFormData({
            name: '',
        })
        setErrors({});
    }

    const [errors, setErrors] = useState({});

    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};


        const requiredFields = [
            'title',
            'sub_company',



        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);
        setIsSubmitted(true);


        return Object.keys(newErrors).length === 0;
    };


    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {

            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
            setLoading(true); //loading logic
            try {
                let res = await axios.post(`${BASE_URL}/project/clientName/`, formData)

                if (res.status === 200) {
                    getClientName();
                    handleClose();
                    toast.dismiss(loadingToastId);
                    toast.success("Client Name Added successfully!");

                } else {
                    alert(res);
                }
            } catch (err) {
                // alert(err);
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }

        }
    };
    const [company, setCompany] = useState([]);

    const getCompany = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/project/subcompany/`);
            setCompany(res.data);
        } catch (error) {
            console.error(error);

        }
    }




    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value,
        });

        setFormData({
            ...formData,
            [name]: value,
        });
        if (value.trim()) {
            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[name];
                return updatedErrors;
            });
        }
    };



    return (
        <>
            <button className='upload-svg' onClick={handleShow}>
                <AddwithWhiteCircle /> {"Client Name"}
            </button>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <Modal show={show} onHide={handleClose} dialogClassName="dialog-modal-add-client-details">
                <Modal.Header closeButton>
                    <Modal.Title>Add Client Name</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <form>
                            <div className='form-flex-wrap' style={{ justifyContent: "space-between" }}>

                                {/* <div className="bdy-div flex-cont justify-around"> */}
                                <div className="flex-column ">
                                    <label>Client Name:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="title"
                                        value={formData.title}
                                        placeholder="Client Name"
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.title ? 'error' : inputState.title ? 'success' : ''}`}
                                    />
                                    {errors.title && <span className="error-message">{errors.title}</span>}
                                </div>
                                <div className="flex-column form-group-select">
                                    <label>Sub Company:
                                    </label>
                                    <select
                                        name="sub_company"
                                        value={formData.sub_company}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.sub_company ? 'error' : inputState.sub_company ? 'success' : ''}`}
                                    >
                                        <option value="null">Select a Sub Company</option>
                                        {company.map(item => (
                                            <option key={item.id} value={item.id}>
                                                {item.title}
                                            </option>
                                        ))}
                                    </select>


                                    <DropdownArrow />

                                    {errors.sub_company && (
                                        <span className="error-message">{errors.sub_company}</span>
                                    )}

                                </div>

                                {/* </div> */}




                            </div>
                            <div className="flex-row justify-around" >
                                <div className="button-models">
                                    <button type="button" onClick={handleClose} className="model-button-cancel model-button   font-weight500  ">Cancel</button>
                                </div>

                                <div className="button-models">
                                    <button onClick={handleSubmit} className="model-button model-button-submit">Submit</button>
                                </div>

                            </div>

                            <p className="error-message font-size-text margin-top40px">
                                {isSubmitted && Object.keys(errors).length > 0 && (
                                    Object.keys(errors).length > 3 ? (
                                        <h5 className="text-center" style={{ marginTop: "15px" }} >Please fill all mandatory fields!</h5>
                                    ) : (
                                        Object.keys(errors).map((field) =>
                                            field.charAt(0).toUpperCase() + field.slice(1)
                                        ).join(', ') + ' are required!'
                                    )
                                )}
                            </p>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
const UpdateClientName = ({ i, getClientName }) => {
    const [formData, setFormData] = useState({
        title: '',
        sub_company: '',
    });

    const [isSubmitted, setIsSubmitted] = useState(false);

    const [show, setShow] = useState(false);

    const handleShow = () => {
        setShow(true);
        setFormData(i);
        getCompany();

    }

    const handleClose = () => {
        setShow(false);
        setErrors({});
    }

    const [errors, setErrors] = useState({});

    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};


        const requiredFields = [
            'title',
            'sub_company',
        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);
        setIsSubmitted(true);


        return Object.keys(newErrors).length === 0;
    };


    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {

            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
            setLoading(true); //loading logic
            try {
                let res = await axios.put(`${BASE_URL}/project/clientName/${i.id}/`, formData);

                if (res.status === 200) {
                    getClientName();
                    handleClose();
                    toast.dismiss(loadingToastId);
                    toast.success("Client Name Updated successfully!");

                } else {
                    alert(res);
                }
            } catch (err) {
                // alert(err);
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }

        }
    };
    const [company, setCompany] = useState([]);

    const getCompany = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/project/subcompany/`);
            setCompany(res.data);
        } catch (error) {
            console.error(error);

        }
    }





    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value,
        });

        setFormData({
            ...formData,
            [name]: value,
        });
        if (value.trim()) {
            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[name];
                return updatedErrors;
            });
        }
    };




    return (
        <>
            <button className='model-edit-button' onClick={handleShow}>
                <UpdatePencil />
            </button>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <Modal show={show} onHide={handleClose} dialogClassName="dialog-modal-add-client-details">
                <Modal.Header closeButton>
                    <Modal.Title>Update Client Name</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <form>
                            <div className='form-flex-wrap' style={{ justifyContent: "space-between" }}>

                                <div className="bdy-div flex-row justify-around">
                                    <div className="flex-column ">
                                        <label>Client Name:<span className="required">*</span></label>
                                        <input
                                            type="text"
                                            name="title"
                                            value={formData.title}
                                            onChange={handleInputChange}
                                            className={`form-input    ${errors.title ? 'error' : inputState.title ? 'success' : ''}`}
                                        />
                                        {errors.title && <span className="error-message">{errors.title}</span>}
                                    </div>
                                    <div className="flex-column form-group-select">
                                        <label>Sub Company:
                                        </label>
                                        <select
                                            name="sub_company"
                                            value={formData.sub_company}
                                            onChange={handleInputChange}
                                            className={`form-input   form-group-selection  ${errors.sub_company ? 'error' : inputState.sub_company ? 'success' : ''}`}
                                        >
                                            <option value="null">Select a Sub Company</option>
                                            {company.map(item => (
                                                <option key={item.id} value={item.id}>
                                                    {item.title}
                                                </option>
                                            ))}
                                        </select>


                                        <div className={`form-group-selection-arrow ${errors.sub_company ? 'error-arrow' : ''}`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                            </svg>
                                        </div>
                                        {errors.sub_company && (
                                            <span className="error-message">{errors.sub_company}</span>
                                        )}

                                    </div>

                                </div>




                            </div>
                            <div className="flex-row justify-around" >
                                <div className="button-models">
                                    <button type="button" onClick={handleClose} className="model-button-cancel model-button   font-weight500  ">Cancel</button>
                                </div>

                                <div className="button-models">
                                    <button onClick={handleSubmit} className="model-button model-button-submit">Update</button>
                                </div>

                            </div>

                            <p className="error-message font-size-text margin-top40px">
                                {isSubmitted && Object.keys(errors).length > 0 && (
                                    Object.keys(errors).length > 3 ? (
                                        <h5 className="text-center" style={{ marginTop: "15px" }} >Please fill all mandatory fields!</h5>
                                    ) : (
                                        Object.keys(errors).map((field) =>
                                            field.charAt(0).toUpperCase() + field.slice(1)
                                        ).join(', ') + ' are required!'
                                    )
                                )}
                            </p>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
const ClientNameDelete = ({ i, getClient }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();

        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
        try {
            let res = await axios.delete(`${BASE_URL}/project/clientName/${i.id}/`);

            if (res.status === 200) {
                setShow(false);
                await getClient();
                handleClose();
                toast.dismiss(loadingToastId);
                toast.success("Client data deleted successfully!");

            } else {
                alert(res);
            }
        } catch (err) {
            //toast Logic
            if (err.response) {
                toast.dismiss(loadingToastId);
                const errorData = err.response.data;

                if (typeof errorData.error === 'string') {
                    // Single error message
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    // Multiple error messages
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }
        } finally {
            toast.dismiss(loadingToastId);
        }
    };

    return (
        <>
            <button className="model-delete-button" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                        fill="#F72B50"
                    />
                </svg>
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Delete client name {i.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        Are You Sure You Want to delete client name {i.title}
                        <div className="button-models">
                            <button className="model-button   font-weight500    " onClick={handleClose}>
                                Cancel
                            </button>
                            <button
                                onClick={handleFormSubmitDelete}
                                className="model-button   font-weight500    model-button-delete"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};
const ClientNames = () => {
    const [buffer, setBuffering] = useState(true); //buffering logic

    const [clientName, setClientName] = useState([]);
    const getClientName = async () => {
        setBuffering(true); //buffering logic // Start Buffering

        try {
            const res = await axios.get(`${BASE_URL}/project/clientName/`);
            setClientName(res.data);
        } catch (err) {
            //toast Logic
            if (err.response) {
                const errorData = err.response.data;

                if (typeof errorData.error === 'string') {
                    // Single error message
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    // Multiple error messages
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }
        } finally {
            setBuffering(false);// End Buffering
        }
    }

    useEffect(() => {
        getClientName();
    }, []);
    const [searchQuery, setSearchQuery] = useState("");
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            {/*  ********** Client Details **********  */}
            <div >
                <div className="attendance-box">
                    <div className="datagrid-container">
                        <div className="custom-table-background" style={{ height: "66vh" }}>
                            <div className="">
                                <div className="">
                                    <div className="table-box ">
                                        <div className="flex-row table-heading" style={{ justifyContent: "end" }}>
                                            <div className="table-searchh">
                                                <div>
                                                    <SearchBig />
                                                </div>
                                                <div>
                                                    <input
                                                        placeholder="Search"
                                                        className="navbar-inputt font-weight400  font-size-text"
                                                        value={searchQuery}
                                                        onChange={handleSearchChange}
                                                    />
                                                </div>
                                            </div>

                                            <AddClientName getClientName={getClientName} />
                                            <UploadClientNameBulk getClientNames={getClientName} />
                                            {/* <UpdateClientNameBulk getClientNames={getClientName}/> */}
                                        </div>
                                    </div>
                                    <div className="table-box" style={{ height: "52vh" }}>
                                        <table className="custom-table">
                                            <thead>
                                                <tr className="custom-table-head-tr">
                                                    <th className="table-heading ">S.no.</th>
                                                    <th className="table-heading text-center">Client Name</th>
                                                    <th className="table-heading text-center">Company</th>
                                                    <th className="table-heading text-center">Action</th>
                                                </tr>
                                            </thead>
                                            {buffer ? <div className="spinner"></div> : //buffering logic
                                                <tbody>

                                                    {clientName
                                                        ?.filter((e) =>
                                                            e.title?.toLowerCase().includes(searchQuery.toLowerCase())
                                                        )
                                                        .sort((a, b) =>
                                                            a.title?.localeCompare(b.title, "en", { ignorePunctuation: true })
                                                        )

                                                        .map((i, index) => (
                                                            <React.Fragment key={index}>
                                                                <tr className="tr-border-bottom">
                                                                    <td colSpan="8"></td>
                                                                </tr>
                                                                <tr className="custom-table-head-td">
                                                                    <td className="table-body ">{index + 1}.</td>
                                                                    <td className="table-body text-center">{i.title}</td>
                                                                    <td className="table-body text-center">{i.subcompany_name}</td>
                                                                    <td className="table-body justify-center flex-row">
                                                                        <UpdateClientName i={i} getClientName={getClientName} />
                                                                        <ClientNameDelete i={i} getClient={getClientName} />
                                                                    </td>
                                                                </tr>
                                                            </React.Fragment>
                                                        ))}
                                                </tbody>
                                            }
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}







const ClientDetails = () => {
    const [toggleState, setToggleState] = useState(1);
    const getSelectedTitle = () => {
        switch (toggleState) {

            case 1:
                return 'Client Names';
            case 2:
                return 'Client Details';
            default:
                return 'Client Names';
        }
    };
    return (
        <>
            <Dashboardnavbarcopy url="Client Management" name={getSelectedTitle()} />
            <div>
                <div className="bloc-tabss  wfm-margin">
                    <button
                        className={toggleState === 1 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
                        onClick={() => setToggleState(1)}
                    >
                        Client Names
                    </button>
                    <button
                        className={toggleState === 2 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
                        onClick={() => setToggleState(2)}
                    >
                        Client Details
                    </button>
                </div>
                <div className="content-tabs">
                    {toggleState === 1 ? <ClientNames /> : null}
                    {toggleState === 2 ? <Allclient /> : null}
                </div>
            </div>
        </>
    );

}

export { ClientDetails }