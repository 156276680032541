import { useState } from "react";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import * as XLSX from "xlsx";
import { IMAGE_URL } from "../config/axios";
import { PDFIcon, Attachment, Eye, AddwithBlueCircle } from "./AllSvg";

const handleErrorToast = (err, loadingToastId = null) => {
    console.log("err");
    console.log(err);

    if (loadingToastId) {
        toast.dismiss(loadingToastId);
    }

    if (err.response) {
        const errorData = err.response.data;

        // Check if there's a generic message field
        if (errorData.message) {
            toast.error(`Error: ${errorData.message}`);
        } else if (errorData.detail && errorData.messages) {
            const detail = errorData.detail;
            const code = errorData.code;
            const messages = errorData.messages;

            toast.error(`Error: ${detail} (Code: ${code})`);

            messages.forEach((messageObj) => {
                const tokenClass = messageObj.token_class || "Unknown Token";
                const tokenType = messageObj.token_type || "Unknown Type";
                const message = messageObj.message || "Unknown Error";

                toast.error(
                    `Token Class: ${tokenClass}, Type: ${tokenType} - ${message}`
                );
            });
        } else if (typeof errorData.error === "string") {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === "object" && errorData !== null) {
            Object.entries(errorData).forEach(([field, messages]) => {
                messages.forEach((message) => toast.error(`"${field}": ${message}`));
            });
        } else if (typeof errorData === "string" && errorData.includes("<html")) {
            // Handling HTML error page
            const parser = new DOMParser();
            const doc = parser.parseFromString(errorData, "text/html");
            const pageTitle =
                doc.querySelector("title")?.innerText || "Unknown Error";

            toast.error(`Error: ${pageTitle}`);
        } else {
            toast.error("Error: Failed to Process!");
        }
    } else {
        toast.error("Error processing your request.");
    }
};

const monthOptions = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
];
const getMonthName = (monthNumber) => {
    const month = monthOptions.find((m) => m.id.toString() === monthNumber);
    return month ? month.name : "";
};

const formatRoundoffComplete = (amount) => {
    const roundedAmount = Math.round(amount);
    return new Intl.NumberFormat("en-IN", {
        style: "decimal",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(roundedAmount);
};

const formatRoundoff2D = (amount) => {
    const roundedAmount = amount;
    return new Intl.NumberFormat("en-IN", {
        style: "decimal",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    }).format(roundedAmount);
};

const formatRoundoff3D = (amount) => {
    const roundedAmount = amount;
    return new Intl.NumberFormat("en-IN", {
        style: "decimal",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    }).format(roundedAmount);
};

const formatCurrencyIndian = (amount) => {
    if (amount === null || amount === undefined) return "-";
    const hasDecimals = amount % 1 !== 0;

    return new Intl.NumberFormat("en-IN", {
        maximumSignificantDigits: 21,
        minimumFractionDigits: hasDecimals ? 2 : 0,
        maximumFractionDigits: hasDecimals ? 2 : 0,
    }).format(amount);
};

const formatRoundoffCrores = (amount) => {
    if (amount === null || amount === undefined) return "-";

    if (amount < 10000000) {
        return new Intl.NumberFormat("en-IN", {
            maximumSignificantDigits: 21,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(amount);
    }
    const amountInCr = amount / 10000000;
    return `${amountInCr.toFixed(2)} Cr`;
};

// const formatRoundoff = (amount) => {
//     if (amount === null || amount === undefined) return "-";

//     // Function to format the number with Indian number system
//     const formatIndianStyle = (num) => {
//         let numStr = num.toString();
//         let lastThreeDigits = numStr.slice(-3);
//         let otherDigits = numStr.slice(0, -3);

//         if (otherDigits) {
//             lastThreeDigits = "," + lastThreeDigits;
//         }

//         // Split other digits into groups of two and add commas
//         const formattedOtherDigits = otherDigits.replace(
//             /\B(?=(\d{2})+(?!\d))/g,
//             ","
//         );

//         return formattedOtherDigits + lastThreeDigits;
//     };

//     return formatIndianStyle(amount);
// };

const formatRoundoff = (amount) => {
    const roundedAmount = Math.round(amount); // Round off to the nearest whole number
    return new Intl.NumberFormat("en-IN", {
        style: "decimal",
        minimumFractionDigits: 0, // No decimal places
        maximumFractionDigits: 0,
    }).format(roundedAmount);
};

const formatToIndianCurrency = (amount) =>
    new Intl.NumberFormat("en-IN", {
        maximumSignificantDigits: 21,
        style: "decimal",
        minimumFractionDigits: amount % 1 === 0 ? 0 : 2,
    }).format(amount);

const sortProjects = (data, codeKey) => {
    const splitCodeParts = (code) => {
        if (!code || typeof code !== "string") {
            return { projectType: "", numericPart: 0, company: "" };
        }

        // Split the code into parts based on "/"
        const parts = code.trim().split("/");

        // Treat everything before the numeric part as the project type
        const projectType = parts.slice(0, -2).join("/"); // Everything before the numeric part
        // const numericPart = parts[parts.length - 2] ? parseInt(parts[parts.length - 2], 10) : 0; // Second last part (numeric part)
        const numericPart = parts[parts.length - 2]
            ? parts[parts.length - 2].padStart(3, "0")
            : "000";
        const company = parts[parts.length - 1] || ""; // Last part as company (e.g., "CIPL", "SP")

        return { projectType, numericPart, company };
    };

    return data.sort((a, b) => {
        const aParts = splitCodeParts(a[codeKey]);
        const bParts = splitCodeParts(b[codeKey]);

        // Compare project type (everything before the numeric part)
        if (aParts.projectType !== bParts.projectType) {
            return aParts.projectType.localeCompare(bParts.projectType);
        }

        // Compare company name (e.g., "CIPL" vs "SP")
        if (aParts.company !== bParts.company) {
            return aParts.company.localeCompare(bParts.company);
        }

        // Compare numeric part (e.g., 1 vs 2)
        // return aParts.numericPart - bParts.numericPart;
        // return Number(aParts.numericPart) - Number(bParts.numericPart);
        // Compare numeric part with validation for NaN
        const numA = Number(aParts.numericPart);
        const numB = Number(bParts.numericPart);

        if (!isNaN(numA) && !isNaN(numB)) {
            return numA - numB; // Compare numerically if both are valid numbers
        } else {
            // If not valid numbers, fallback to localeCompare
            // return aParts.numericPart.toString().localeCompare(bParts.numericPart.toString());
            return aParts.numericPart.localeCompare(bParts.numericPart); // Compare as strings if not valid
        }
    });
};

const calculateTotal = (data, getFieldValue) => {
    return data.reduce((total, item) => {
        return total + Number(getFieldValue(item));
    }, 0);
};

const customSortProjects = (data, codeKey) => {
    const splitCodeParts = (code) => {
        if (!code || typeof code !== "string") {
            return { firstPart: "", numericPart: 0, alphaPart: "" };
        }

        // Split the code into two parts: before and after the last "/"
        const parts = code.trim().split("/");
        const firstPart = parts.slice(0, -1).join("/"); // Everything before the last "/"
        const lastPart = parts.pop(); // The last part after the "/"

        // Match numeric and alphabetic parts from the last part (e.g., "001CM" -> 001 and CM)
        const match = lastPart.match(/^(\d+)?([A-Za-z]+)$/); // Adjust regex to prioritize alphabetic part
        if (match) {
            return {
                firstPart, // Part before the last "/"
                numericPart: match[1] ? parseInt(match[1], 10) : 0, // Numeric part (if present)
                alphaPart: match[2] || "", // Alphabetic part
            };
        }

        return { firstPart, numericPart: 0, alphaPart: "" };
    };

    return data.sort((a, b) => {
        const aParts = splitCodeParts(a[codeKey]);
        const bParts = splitCodeParts(b[codeKey]);

        // Compare the first part (e.g., "AE/IE" vs "OM")
        if (aParts.firstPart !== bParts.firstPart) {
            return aParts.firstPart.localeCompare(bParts.firstPart);
        }

        // If the first parts are the same, compare the alphabetic part (e.g., "CM" vs "SP")
        if (aParts.alphaPart !== bParts.alphaPart) {
            return aParts.alphaPart.localeCompare(bParts.alphaPart);
        }

        // If alphabetic parts are the same, compare the numeric part (e.g., "001" vs "002")
        return aParts.numericPart - bParts.numericPart;
    });
};

const ascSortByKey = (data, key) => {
    const regex = /([A-Za-z]+)|(\d+)|(\W+)/g;

    // Function to access a deeply nested object property using dot notation
    const getNestedValue = (obj, key) => {
        return key.split(".").reduce((o, i) => (o ? o[i] : undefined), obj);
    };

    // Custom sort function for object comparison
    const sortFunction = (a, b) => {
        // Get the values to be compared from the key (handle nested properties)
        const aValue = getNestedValue(a, key);
        const bValue = getNestedValue(b, key);

        if (!aValue || !bValue) return 0; // Return 0 if either value is undefined

        // Split the strings into alphabetic, numeric, and special character parts
        const aParts = aValue.match(regex);
        const bParts = bValue.match(regex);

        for (let i = 0; i < Math.min(aParts.length, bParts.length); i++) {
            const aPart = aParts[i];
            const bPart = bParts[i];

            // Check if both parts are numeric
            if (!isNaN(aPart) && !isNaN(bPart)) {
                const numA = Number(aPart);
                const numB = Number(bPart);
                if (numA !== numB) {
                    return numA - numB; // Compare numerically
                }
            } else if (aPart !== bPart) {
                return aPart.localeCompare(bPart); // Compare alphabetically or by special characters
            }
        }

        // If we reach here, the longer string is considered greater
        return aParts.length - bParts.length;
    };

    // Sort the data using the custom sort function
    return data.sort(sortFunction);
};

const downloadAsExcel = (
    tableRef,
    sheetName = "Sheet1",
    fileName = "download.xls"
) => {
    if (!tableRef || !tableRef.current) {
        console.error("Invalid table reference");
        return;
    }

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([]);

    let rowIndex = 0;

    for (let row of tableRef.current.rows) {
        const rowData = [];
        for (let cell of row.cells) {
            const nestedTable = cell.querySelector("table");

            if (nestedTable) {
                // Format nested table data into a single cell
                let nestedData = "";
                for (let nestedRow of nestedTable.rows) {
                    for (let nestedCell of nestedRow.cells) {
                        nestedData += nestedCell.innerText + "\n"; // Append with line breaks
                    }
                }
                rowData.push(nestedData.trim()); // Add formatted nested data into the cell
            } else {
                rowData.push(cell.innerText); // Normal cells
            }
        }

        if (rowData.length > 0) {
            XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: rowIndex });
            rowIndex++;
        }
    }

    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    const workbookBinary = XLSX.write(workbook, {
        bookType: "xls",
        type: "binary",
    });
    const s2ab = (str) => {
        const buf = new ArrayBuffer(str.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < str.length; i++) view[i] = str.charCodeAt(i) & 0xff;
        return buf;
    };

    const buffer = s2ab(workbookBinary);
    const blob = new Blob([buffer], { type: "application/vnd.ms-excel" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
};

const inputMaxLimit = (name, value, maxValue) => {
    const numericValue = value.trim() ? parseFloat(value) : 0;

    if (numericValue <= maxValue) {
        return { success: true, error: "" };
    } else {
        return {
            success: false,
            error: `Value must be less than or equal to ${maxValue}`,
        };
    }
};

const inputMinLimit = (name, value, minValue) => {
    const numericValue = value.trim() ? parseFloat(value) : 0;

    if (numericValue >= minValue) {
        return { success: true, error: "" };
    } else {
        return {
            success: false,
            error: `Value must be more than or equal to ${minValue}`,
        };
    }
};

const formatDateTime = (datetimeStr) => {
    const dateObj = new Date(datetimeStr);

    const day = String(dateObj.getDate()).padStart(2, "0");
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const year = dateObj.getFullYear();

    const dateFormatted = `${day}/${month}/${year}`;
    const timeFormatted = dateObj.toLocaleTimeString();

    const defaultFormat = `${dateFormatted}(${timeFormatted})`;
    return {
        toString: () => defaultFormat,
        full: `${dateFormatted} ${timeFormatted}`,
        date: dateFormatted,
        time: timeFormatted,
    };
};

const customSortByKey = (data, key) => {
    const regex = /([A-Za-z]+)|(\d+)|(\W+)/g;
    const getNestedValue = (obj, key) => {
        return key.split(".").reduce((o, i) => (o ? o[i] : undefined), obj);
    };
    const sortFunction = (a, b) => {
        const aValue = getNestedValue(a, key);
        const bValue = getNestedValue(b, key);

        if (!aValue || !bValue) return 0;
        const aParts = aValue.match(regex);
        const bParts = bValue.match(regex);

        for (let i = 0; i < Math.min(aParts.length, bParts.length); i++) {
            const aPart = aParts[i];
            const bPart = bParts[i];

            if (!isNaN(aPart) && !isNaN(bPart)) {
                const numA = Number(aPart);
                const numB = Number(bPart);
                if (numA !== numB) {
                    return numA - numB;
                }
            } else if (aPart !== bPart) {
                return aPart.localeCompare(bPart);
            }
        }
        return aParts.length - bParts.length;
    };
    return data.sort(sortFunction);
};

const getFileNameFromLink = (url) => {
    try {
        const ImgFileUrl =
            "https://cipl-aimantra.s3.amazonaws.com/" ||
            "https://cipl-aimantra.s3.ap-south-1.amazonaws.com/";
        // const ImgFileUrl = "https://cipl-aimantra.s3.amazonaws.com/" || "https://cipl-aimantra.s3.ap-south-1.amazonaws.com/";

        if (url.startsWith(ImgFileUrl)) {
            const pathAfterBase = url.slice(ImgFileUrl.length);

            const fileName = pathAfterBase
                .split("/")
                .slice(1)
                .join("/")
                .split("?")[0];

            return fileName;
        }

        return url;
    } catch (error) {
        console.error("Error extracting file name:", error);
        return "";
    }
};

const ViewFile = ({ doc, filename, filesrc }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    const isImage = (url) => {
        return /\.(jpg|jpeg|png|gif|bmp|webp)(\?.*)?$/.test(url);
    };
    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <button
                title={"View File"}
                style={{ cursor: "pointer" }}
                onClick={handleShow}
            >
                <Attachment />
            </button>
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{filename}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="flex-row justify-center">
                            {/* <img className="bill-img" src={i.bill} alt={`Bill Image of ${i.item_name}`} /> */}
                            {isImage(filesrc) ? (
                                <img
                                    className="bill-img"
                                    src={`${IMAGE_URL}${filesrc}`}
                                    alt={`File/ Image of ${filename}`}
                                />
                            ) : (
                                <a
                                    href={`${IMAGE_URL}${filesrc}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <PDFIcon />
                                    Open Document
                                </a>
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

const ViewOtherFile = ({ doc, filename, filesrc }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    const isImage = (url) => {
        return /\.(jpg|jpeg|png|gif|bmp|webp)(\?.*)?$/.test(url);
    };
    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <button
                title={"View File"}
                style={{ cursor: "pointer" }}
                onClick={handleShow}
            >
                {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 16.933 16.933"
                    id="bill">
                    <path
                        d="M1.854 0C.819 0 0 .83 0 1.852c-.002 4.94.002 9.878.002 14.816 0 .221.256.345.429.207l1.488-1.19 1.488 1.19a.265.265 0 0 0 .33 0l1.488-1.19 1.49 1.19a.265.265 0 0 0 .33 0l1.489-1.19 1.488 1.19a.265.265 0 0 0 .33 0l1.487-1.19 1.488 1.19c.174.139.43.015.43-.207l.002-8.733h2.91a.26.26 0 0 0 .263-.263V1.854A1.85 1.85 0 0 0 15.083 0Zm0 .53h11.932c-.35.337-.556.8-.556 1.324l-.002 14.265-1.222-.98a.265.265 0 0 0-.33 0l-1.49 1.191-1.488-1.191a.264.264 0 0 0-.33 0L6.878 16.33 5.391 15.14a.265.265 0 0 0-.33 0L3.575 16.33 2.085 15.14a.264.264 0 0 0-.33 0l-1.224.98L.53 1.852c0-.77.634-1.322 1.324-1.322zm13.228 0c.73 0 1.324.586 1.324 1.324v5.554H13.76V1.854c0-.738.592-1.324 1.322-1.324zM3.707 3.439c-.133 0-.265.089-.265.266v.287c-.599.116-1.058.63-1.058 1.252 0 .42.217.672.48.799.264.127.552.147.816.174.265.027.506.06.639.125.133.064.181.15.181.32 0 .406-.34.743-.782.746-.384-.014-.658-.23-.784-.623-.106-.339-.614-.175-.503.162.152.475.48.844 1.011.96v.294c0 .354.53.354.53 0v-.282c.598-.116 1.058-.635 1.058-1.257 0-.388-.18-.654-.48-.799-.264-.126-.552-.146-.817-.173-.264-.028-.502-.056-.639-.126-.122-.062-.181-.142-.181-.32 0-.41.34-.742.783-.745.421.016.666.26.783.622.107.339.615.175.504-.162-.152-.474-.5-.857-1.012-.959v-.295c0-.177-.132-.266-.264-.266zm2.645.265c-.355 0-.355.527 0 .53h4.763c.353 0 .353-.53 0-.53zm0 1.852c-.355 0-.355.53 0 .53h4.763c.352 0 .353-.53 0-.53zm0 1.852c-.355 0-.355.527 0 .527h4.763c.352 0 .352-.527 0-.527zM2.649 9.26c-.356 0-.356.537 0 .529h8.466c.352 0 .352-.53 0-.53zm0 1.852c-.356 0-.356.53 0 .53h8.466c.352 0 .352-.53 0-.53zm0 1.852c-.356 0-.356.529 0 .53h8.466c.352 0 .352-.53 0-.53z"
                        fill="#2576BC"
                    >
                    </path>
                </svg> */}
                <Eye />
            </button>
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{filename}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <h1 className="task-Tab-heading align-center  font-weight500  font-size-heading">
                            <Modal.Title> File :</Modal.Title>{" "}
                        </h1>
                        <h6 className="text-center">{getFileNameFromLink(filesrc)}</h6>
                        <br />
                        <div className="flex-row justify-center">
                            {/* <img className="bill-img" src={i.bill} alt={`Bill Image of ${i.item_name}`} /> */}
                            {isImage(filesrc) ? (
                                <img
                                    className="bill-img"
                                    src={`${IMAGE_URL}${filesrc}`}
                                    alt={`File/ Image of ${filename}`}
                                />
                            ) : (
                                <a href={filesrc} target="_blank" rel="noreferrer">
                                    <PDFIcon />
                                    Open Document
                                </a>
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

const ViewEmployeeList = ({ titlename, sort, list }) => {
    const [show, setShow] = useState(false);
    const [showemployee, setShowemployee] = useState(false);
    const [employeenumbershow, Setemployeenumbershow] = useState(3)
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    const handleShowemployee = (empCode) => {
        setShowemployee(empCode);
    };

    return (
        <>
            <button
                title={"View List"}
                style={{ cursor: "pointer" }}
                onClick={handleShow}
            >
                <div className="overlap-image-div " style={{}}>
                    {list?.slice(0, employeenumbershow).map((i, index) => (
                        <>

                            <div className="outer-overlap-image-box" key={index} >
                                <div
                                    className="overlap-image-box"
                                    onMouseEnter={() => handleShowemployee(i.emp_code)}
                                    onMouseLeave={() => handleShowemployee(null)}
                                    style={{ transform: showemployee === i.emp_code ? "scale(1.2)" : "scale(0.9)" }}
                                >
                                    <img
                                        src={
                                            i.profilepic
                                                ? i.profilepic
                                                : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                        }
                                        alt={`${i.name} pic`}
                                        className="profile-img overlap-img"

                                    />
                                </div>
                                {showemployee === i.emp_code && (
                                    <div className="overlap-employee-details">
                                        {i.emp_code}
                                        <br />
                                        {i.name}
                                    </div>
                                )}
                            </div>
                        </>
                    ))}
                    {list && list.length > employeenumbershow && (
                        <div className="dots-container">
                            {Array.from({ length: Math.ceil((list.length - employeenumbershow) / 1) }).map((_, i) => (
                                <span key={i} className="dot"></span>
                            ))}
                        </div>
                    )}
                    {list && list.length > employeenumbershow && (
                        <div className="add-more-container">
                            <span className="add-more-text">+More</span>
                        </div>
                    )}

                </div>
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{titlename}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <table className="table-css">
                            <thead>
                                <tr className="custom-table-head-invoices">
                                    <th className="align-center font-size-text font-weight600">
                                        Sr no.
                                    </th>
                                    <th className="align-center font-size-text font-weight600">
                                        Profile pic
                                    </th>
                                    <th className="align-center font-size-text font-weight600">
                                        Employee code
                                    </th>
                                    <th className="align-center font-size-text font-weight600">
                                        Employee Name
                                    </th>
                                </tr>{" "}
                            </thead>
                            <tbody>
                                {list?.map((i, index) => (
                                    <>
                                        <tr className="custom-table-head-td">
                                            <td className="align-left">{index + 1}</td>
                                            <td className="align-center">
                                                <img
                                                    src={
                                                        i.profilepic
                                                            ? i.profilepic
                                                            : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                                    }
                                                    alt={`${i.name} pic`}
                                                    className="profile-img"
                                                />
                                            </td>
                                            <td className="align-center">{i.emp_code}</td>
                                            <td className="align-center">{i.name}</td>
                                        </tr>
                                    </>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

const getProgressColor = (percentage) => {
    // if (percentage < 10) return '#ff747c';
    if (percentage < 33) return '#ff747c';
    if (percentage < 66) return '#f7ba1e';
    return '#06ad06';
};

export {
    calculateTotal,
    formatRoundoff,
    formatRoundoff2D,
    formatRoundoff3D,
    handleErrorToast,
    formatCurrencyIndian,
    formatRoundoffCrores,
    sortProjects,
    ascSortByKey,
    downloadAsExcel,
    inputMaxLimit,
    inputMinLimit,
    getMonthName,
    formatDateTime,
    customSortByKey,
    ViewFile,
    ViewOtherFile,
    ViewEmployeeList,
    getProgressColor,
};
