import React, { useState } from "react";
import { BASE_URL, IMAGE_URL } from "../../config/axios";
import { handleErrorToast } from "../custom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

const EmployeeDetailCard = ({ getAllEmployee, filteredEmployee }) => {
    const [loading, setLoading] = useState(false); // Loading logic

    const handlePRChange = async (empCode, currentPRStatus) => {
        setLoading(true); // Set loading state
        const loadingToastId = toast.loading("Loading: Please wait..."); // Toast logic

        try {
            const res = await axios.put(`${BASE_URL}/wfm/ourcompanyuserdetail/${empCode}/`, {
                pr_project: !currentPRStatus, // Toggle the PR status
            });

            if (res.status === 200) {
                toast.update(loadingToastId, {
                    render: "PR status updated successfully!",
                    type: "success",
                    isLoading: false,
                    autoClose: 3000,
                });
                await getAllEmployee(); // Refresh the employee list
            }
        } catch (err) {
            handleErrorToast(err, loadingToastId); // Handle error toast
        } finally {
            setLoading(false); // Reset loading state
            toast.dismiss(loadingToastId);
        }
    };

    return (
        <div className="employee-cards">
            {filteredEmployee
                .sort((a, b) => a.emp_code - b.emp_code)
                .map((e) => (
                    <div className="employee-card" key={e.emp_code}>
                        <div className="employee-card-a">
                            <div className="employee-card-image">
                                <div className="employee-card-image-background">
                                    <img
                                        src={
                                            e.profilepic
                                                ? `${IMAGE_URL}${e.profilepic}`
                                                : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                        }
                                        alt="card-pic"
                                        className="employee-card-imageee"
                                    />
                                </div>
                                <div className="employee-card-icons">
                                    <div className="employee-card-name font-weight500 font-size-subheading">
                                        {e.name}
                                    </div>
                                    <div className="employee-card-designation font-weight400 font-size-subheading">
                                        {e.designation_name}
                                    </div>
                                    <div className="employee-card-name font-weight500 font-size-subheading">
                                        Emp Code: {e.emp_code}
                                    </div>
                                </div>
                            </div>

                            <div className="flex-row">
                                <div className="flex-column">
                                    <div className="flex-row">
                                        <div className="checkbox-toggle">
                                            <input
                                                type="checkbox"
                                                id={`toggle_pr_${e.emp_code}`}
                                                name="pr_toggle"
                                                checked={e.pr_project || false} // Use e.pr_project for default value
                                                onChange={() => handlePRChange(e.emp_code, e.pr_project)}
                                            />
                                            <label htmlFor={`toggle_pr_${e.emp_code}`}></label>
                                        </div>
                                        <hr
                                            style={{ marginTop: "10px" }}
                                            className="field-cont-hr"
                                        />
                                        <label
                                            htmlFor={`toggle_pr_${e.emp_code}`}
                                            className="form-labels font-weight500 font-size-subheading"
                                        >
                                            {e.pr_project ? "View" : "Hide"} PR
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="employee-card-b">
                            <div className="employee-card-b-flex">
                                <div className="cart-subdiv-flex">
                                    <span className="employee-card-b-heading font-weight400 font-size-label">
                                        Department :
                                    </span>
                                    <span className="employee-card-b-text font-weight400 font-size-label">
                                        {e.department_name}
                                    </span>
                                </div>
                                <div className="cart-subdiv-flex">
                                    <span className="employee-card-b-heading font-weight400 font-size-label">
                                        Email :
                                    </span>
                                    <span className="employee-card-b-text font-weight400 font-size-label">
                                        {e.user_detail}
                                    </span>
                                </div>
                            </div>
                            <div className="employee-card-b-flex">
                                <div className="cart-subdiv-flex">
                                    <span className="employee-card-b-heading font-weight400 font-size-label">
                                        Location :
                                    </span>
                                    <span className="employee-card-b-text font-weight400 font-size-label">
                                        {e.location_name}
                                    </span>
                                </div>
                                <div className="cart-subdiv-flex">
                                    <span className="employee-card-b-heading font-weight400 font-size-label">
                                        Mobile No :
                                    </span>
                                    <span className="employee-card-b-text font-weight400 font-size-label">
                                        {e.mobile_number}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
    );
};

export default EmployeeDetailCard;
