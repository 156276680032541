import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../config/axios';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import EmployeeDetailCard from './EmployeeDetailCard';
import { customSortByKey } from '../custom';
import { Active, SearchBig } from '../AllSvg';

const EmployeeDetails = () => {


  // *********** Employee Details Api Start ***********
  const [allemployee, setAllemployee] = useState([])
  const [filteredEmployee, setFilteredEmployee] = useState([]);

  const [buffer, setBuffering] = useState(false); //buffering logic
  const getAllEmployee = async () => {
    setBuffering(true); //buffering logic
    try {
      const res = await axios.get(`${BASE_URL}/wfm/ourcompanydep/${sessionStorage.getItem("departmentid")}/`);

      const sortedList = customSortByKey(res.data, "emp_code");

      setAllemployee(sortedList)

    } catch (err) {
      // alert(err.message);
    } finally {
      setBuffering(false); //buffering logic
    }
  };


  useEffect(() => {
    getAllEmployee();
  }, []);

  const refreshdata = async () => {
    setBuffering(true); //buffering logic
    await getAllEmployee();
    setBuffering(false); //buffering logic
  };

  // *********** Employee Details Api End ***********


  // *********** Filter Logic ***********

  const [selectedStatus, setSelectedStatus] = useState("active");
  const [jobTitle, setJobTitle] = useState('');

  const [selectedName, setName] = useState('');


  const applyFilters = () => {
    // Filter employees based on selectedStatus and jobTitle
    let filteredData = allemployee;
    if (selectedName) {
      // Use includes for a case-insensitive search for the name only
      const lowercaseSelectedName = selectedName.toLowerCase();
      filteredData = filteredData.filter(employee => {
        const nameMatch = employee.name.toLowerCase().includes(lowercaseSelectedName);
        const empCodeMatch = employee.emp_code.toString().includes(selectedName);
        return nameMatch || empCodeMatch;
      });
    }
    if (selectedStatus !== '') {
      filteredData = filteredData.filter(employee => employee.employee_status === selectedStatus);
    }

    if (selectedDesi) {
      const lowercaseSelectedName = selectedDesi.toLowerCase();

      filteredData = filteredData.filter(employee => {
        const desiMatch = employee.designation_name.toLowerCase().includes(lowercaseSelectedName);
        return desiMatch;
      });
    }
    if (jobTitle !== '') {
      // Use includes for a case-insensitive search
      filteredData = filteredData.filter(employee => employee.designation_name.toLowerCase().includes(jobTitle.toLowerCase()));
    }
    setFilteredEmployee(filteredData);

    console.log(filteredData)
  };
  const [selectedDesi, setDesi] = useState('');


  useEffect(() => {

    applyFilters();
  }, [selectedStatus, selectedName, jobTitle, allemployee, selectedDesi]);



  const navigate = useNavigate()
  const Viewdetails = (emp_code, user_detail) => {
    navigate(`/hod/userdetailscrud/${emp_code}/${user_detail}/`)
  }


  return (
    <div>

      <div>
        <div className="attendance-subcont">
          <div className="field-cont">
            <div className="field-cont-div">

              <Active />
              <select className="attendance-input-field width-10vw   date-field" type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="">All</option>

                <option value="active">Active</option>
                <option value="inactive">Inactive</option>



              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <SearchBig />

              <input className="attendance-input-field width-10vw  " placeholder='Name or Code' type="text" value={selectedName}
                onChange={(e) => setName(e.target.value)} />

              <hr className="field-cont-hr" />
            </div>


            <div className="field-cont-div">
              <SearchBig />

              <input className="attendance-input-field width-10vw  " placeholder='Designation' type="text" value={selectedDesi}
                onChange={(e) => setDesi(e.target.value)} />
            </div>

          </div>
          <div className="btn-cont">


          </div>
        </div>
      </div>
      <div className='filter-showing'>
        <div>Showing  {filteredEmployee.length} of {allemployee.length} </div>
        <div>
          {buffer ?
            <div className="spinner-vsmall"></div>
            :
            <div
              className="refresh-button"
              onClick={refreshdata}
              disabled={buffer} //buffering logic
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="refresh-icon"
                fill="#fff"
                viewBox="0 0 24 24"
                id="refresh"
              >
                <path d="M21 21a1 1 0 0 1-1-1V16H16a1 1 0 0 1 0-2h5a1 1 0 0 1 1 1v5A1 1 0 0 1 21 21zM8 10H3A1 1 0 0 1 2 9V4A1 1 0 0 1 4 4V8H8a1 1 0 0 1 0 2z"></path>
                <path d="M12 22a10 10 0 0 1-9.94-8.89 1 1 0 0 1 2-.22 8 8 0 0 0 15.5 1.78 1 1 0 1 1 1.88.67A10 10 0 0 1 12 22zM20.94 12a1 1 0 0 1-1-.89A8 8 0 0 0 4.46 9.33a1 1 0 1 1-1.88-.67 10 10 0 0 1 19.37 2.22 1 1 0 0 1-.88 1.1z"></path>
              </svg>
            </div>
          }
        </div>
      </div>

      {buffer ?
        <div className="spinner"></div> :
        <EmployeeDetailCard getAllEmployee={getAllEmployee} filteredEmployee={filteredEmployee} Viewdetails={Viewdetails} />
      }


    </div>
  )
}

export default EmployeeDetails
