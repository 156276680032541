import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { BASE_URL } from "../../config/axios";
import { ToastContainer, toast } from "react-toastify";

import usePermission from "../../config/permissions";
// import { handleErrorToast } from "../CustomFunctions";
import {
    AddwithWhiteCircle,
    CheckGreen,
    CrossRed,
    DeleteDustbin,
    DropdownArrow,
    PDFIcon,
    UpdatePencil
} from "../AllSvg";

import { formattedDateLong } from "../Date";
import Dashboardnavbarcopy from "../../layout/Dashboardnavbar";
import { formatCurrencyIndian, handleErrorToast } from "../custom";

// ! *************** Sector Crud Models Start ***************

const AddTimesheet = ({ getTimesheet }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setFormData({
            task_id: "",
            in_time: "",
            out_time: "",
            date: "",
            employee: sessionStorage.getItem("employee_id"),
            project: "",
            remark: "",
            total_hour_day: "",
            total_hour_week: "",
            status: "pending",
            workstage: "",
        });
        setShow(false);
    };
    const handleShow = () => {
        getProjectListData();
        if (formData.employee != "") {
            getEmployeeTaskList(formData.employee);
        }
        setShow(true);
    };
    const months = [
        { text: "January", value: 1 },
        { text: "February", value: 2 },
        { text: "March", value: 3 },
        { text: "April", value: 4 },
        { text: "May", value: 5 },
        { text: "June", value: 6 },
        { text: "July", value: 7 },
        { text: "August", value: 8 },
        { text: "September", value: 9 },
        { text: "October", value: 10 },
        { text: "November", value: 11 },
        { text: "December", value: 12 },
    ];

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 10 }, (_, i) => currentYear - i);
    const emp_code = sessionStorage.getItem('employee_id');
    const role = sessionStorage.getItem('role');

    const [formData, setFormData] = useState({
        task_id: "",
        in_time: "",
        out_time: "",
        date: "",
        employee: sessionStorage.getItem("employee_id"),
        project: "",
        remark: "",
        total_hour_day: "",
        total_hour_week: "",
        status: "pending",
        workstage: "",
    });

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};

        const requiredFields = ["in_time", "out_time"];

        requiredFields.forEach((field) => {
            console.log(formData[field]);
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);
        console.log(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                const formDataToSend = new FormData();

                Object.keys(formData).forEach((key) => {
                    if (formData[key]) {
                        formDataToSend.append(key, formData[key]);
                    }
                });

                let res = await axios.post(
                    `${BASE_URL}/project/timesheet/`,
                    formData
                );

                if (res.status === 200) {
                    await getTimesheet();
                    handleClose();
                    toast.dismiss(loadingToastId);
                    toast.success("Timesheet added successfully!");
                } else {
                    toast.dismiss(loadingToastId);
                    toast.error("Failed to add Timesheet!");
                }
            } catch (err) {
                //toast Logic
                handleErrorToast(err);
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        } else {
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, files } = e.target;
        // console.log(files[0])
        let newValue;
        if (type === "file") {
            console.log("file", files[0]);
            newValue = files.length > 0 ? files : null;
        } else {
            newValue = value;
        }

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });
        if (name === "Timesheet_type") {
            setFormData({
                ...FormData,
                [name]: newValue,
                month: "",
                project: "",
            });
        }
        setFormData({
            ...formData,
            [name]: newValue,
        });
    };

    const [projectListData, setProjectList] = useState([]);
    const [tasksList, setTasksList] = useState([]);
    const [workStageList, setWorkStageList] = useState([]);

    const [buffer1, setBuffering1] = useState(true); //buffering logic
    const getProjectListData = async () => {
        setBuffering1(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                // `${BASE_URL}/project/projectfilterbysubcompany/null/`
                `${BASE_URL}/project/projectfilterbyprojectstatus/null/${role === "HOD" ? "null" : emp_code}/null/`
            ); setProjectList(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering1(false); // End Buffering
        }
    };

    const [buffer2, setBuffering2] = useState(true); //buffering logic
    const getEmployeeTaskList = async (employeeid) => {
        setBuffering2(true); //buffering logic // Start Buffering

        try {
            const taskiddata = await axios.get(
                `${BASE_URL}/wfm/taskbyemp/${employeeid || "null"}/`
            );
            setTasksList(taskiddata.data);
        } catch (err) {
            // handleErrorToast(err);
        } finally {
            setBuffering2(false); // End Buffering
        }
    };

    const [buffer3, setBuffering3] = useState(true); //buffering logic
    const getWorkStageList = async (projectid, employeeid) => {
        setBuffering3(true); //buffering logic // Start Buffering

        try {
            const workstages = await axios.get(
                `${BASE_URL}/project/workstagelist/${projectid || "null"}/${employeeid || "null"}/`
            );
            setWorkStageList(workstages.data);
        } catch (err) {
            // handleErrorToast(err);
        } finally {
            setBuffering3(false); // End Buffering
        }
    };

    useEffect(() => {
        if (formData.project != "" && formData.employee != "") {
            getWorkStageList(formData.project, formData.employee);
        }
    }, [formData.project, formData.employee]);

    useEffect(() => {
        setFormData({
            ...formData,
            Timesheet_type: "ProjectbyWise",
        });
    }, []);

    return (
        <>
            <button title="Add Timesheet Entry" className="upload-svg" onClick={handleShow}>
                <AddwithWhiteCircle /> Timesheet
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="ourcompany-model"
            >
                <Modal.Header
                    closeButton
                    className="justify-center task-Tab-heading font-weight500    font-size-heading "
                >
                    <Modal.Title>Add Timesheet Entry</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="register-from">
                        <div className="form-flex-wrap">
                            <div className="flex-column form-group-select">
                                <label
                                    htmlFor="Timesheet_type"
                                    className="form-labels font-weight500 announce-date font-weight400 font-size-heading"
                                    title="Timesheet_type"
                                >
                                    Task
                                </label>
                                {buffer2 ?
                                    <div className="form-loader">
                                        <div className="bar-loader"></div>
                                    </div>
                                    :
                                    <>
                                        <select
                                            name="task_id"
                                            value={formData.task_id}
                                            onChange={handleInputChange}
                                            className={`form-input form-group-selection ${errors.task_id
                                                ? "error"
                                                : inputState.project
                                                    ? "success"
                                                    : ""
                                                }`}
                                        >
                                            <option value="null">Select Task</option>
                                            {tasksList?.map((task) => (
                                                <option key={task.id} value={task.id}>
                                                    {task.task}
                                                </option>
                                            ))}
                                        </select>
                                        <DropdownArrow />
                                        {errors.task_id && (
                                            <span className="error-message font-size-text">
                                                {errors.task_id}
                                            </span>
                                        )}
                                    </>}
                            </div>
                            <div className="flex-column form-group-select">
                                <label className="form-labels font-weight500 font-size-subheading">
                                    Project:
                                </label>
                                {buffer1 ?
                                    <div className="form-loader">
                                        <div className="bar-loader"></div>
                                    </div>
                                    :
                                    <>
                                        <select
                                            name="project"
                                            value={formData.project}
                                            onChange={handleInputChange}
                                            className={`form-input form-group-selection ${errors.project
                                                ? "error"
                                                : inputState.project
                                                    ? "success"
                                                    : ""
                                                }`}
                                        >
                                            <option value="">Select a Project</option>
                                            {projectListData.map((project) => (
                                                <option key={project.id} value={project.pr_code}>
                                                    {project.prebid_prcode}
                                                    {project.project_short_name
                                                        ? `-${project.project_short_name}`
                                                        : null}
                                                </option>
                                            ))}
                                        </select>
                                        <DropdownArrow />
                                        {errors.project && (
                                            <span className="error-message">{errors.project}</span>
                                        )}
                                    </>}
                            </div>

                            <div className="flex-column form-group-select">
                                <label className="form-labels font-weight500 font-size-subheading">
                                    Workstage:
                                </label>
                                {buffer3 ?
                                    <div className="form-loader">
                                        <div className="bar-loader"></div>
                                    </div>
                                    :
                                    <>
                                        <select
                                            name="workstage"
                                            value={formData.workstage}
                                            onChange={handleInputChange}
                                            className={`form-input form-group-selection ${errors.workstage
                                                ? "error"
                                                : inputState.workstage
                                                    ? "success"
                                                    : ""
                                                }`}
                                        >
                                            <option value="">Select Workstage</option>
                                            {workStageList.map((project) => (
                                                <option key={project.wid} value={project.wid}>
                                                    {project.workStages}
                                                </option>
                                            ))}
                                        </select>
                                        <DropdownArrow />
                                        {errors.workStages && (
                                            <span className="error-message">{errors.workStages}</span>
                                        )}
                                    </>}
                            </div>
                            <div
                                className="flex-column form-group-select"
                                style={{
                                    marginRight: "0px",
                                }}
                            >
                                <label className="form-labels">
                                    Date:<span className="required">*</span>
                                </label>
                                <input
                                    type="date"
                                    name="date"
                                    value={formData.date}
                                    onChange={handleInputChange}
                                    className="form-input"
                                />
                            </div>
                            <div className="flex-row multi-day-input">
                                <div className="flex-column multi-day">
                                    <label className="form-labels  font-weight500 font-size-subheading">
                                        In Time <span className="required">*</span>
                                    </label>
                                    <input
                                        className="multi-day-input1 form-input-background"
                                        type="time"
                                        name="in_time"
                                        value={formData.in_time}
                                        step="1"
                                        min="09:30:00"
                                        max="16:30:00"
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="flex-column multi-day">
                                    <label className="form-labels  font-weight500    font-size-subheading">
                                        Out Time <span className="required">*</span>
                                    </label>
                                    <input
                                        className="multi-day-input1 form-input-background"
                                        type="time"
                                        name="out_time"
                                        step="1"
                                        min="09:30:00"
                                        max="16:30:00"
                                        value={formData.out_time}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>


                            <div className="flex-column">
                                <label
                                    htmlFor="remark"
                                    className="form-labels font-weight500 announce-date font-weight400 font-size-heading"
                                    title="Remark"
                                >
                                    Remark:
                                </label>
                                <input
                                    placeholder="Remark text "
                                    title="remark"
                                    id="remark"
                                    type="text"
                                    name="remark"
                                    onChange={handleInputChange}
                                    value={formData.remark}
                                    className={`form-input font-weight400 font-size-subheading form-input-background ${errors.remark ? "error" : inputState.remark ? "success" : ""
                                        }`}
                                />
                                {errors.remark && (
                                    <span className="error-message font-size-text">
                                        {errors.remark}
                                    </span>
                                )}
                            </div>
                            {/* <div className="flex-column">
                                <label
                                    htmlFor="total_hour_day"
                                    className="form-labels font-weight500 announce-date font-weight400 font-size-heading"
                                    title="Total works hours (per day)"
                                >
                                    Total works hours (per day):
                                </label>
                                <input
                                    placeholder="Total works hours (per day)"
                                    title="total_hour_day"
                                    id="total_hour_day"
                                    type="number"
                                    min={0}
                                    onWheel={(e) => e.target.blur()}
                                    name="total_hour_day"
                                    onChange={handleInputChange}
                                    value={formData.total_hour_day}
                                    className={`form-input font-weight400 font-size-subheading form-input-background ${errors.total_hour_day
                                        ? "error"
                                        : inputState.total_hour_day
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.total_hour_day && (
                                    <span className="error-message font-size-text">
                                        {errors.total_hour_day}
                                    </span>
                                )}
                            </div>
                            <div className="flex-column">
                                <label
                                    htmlFor="total_hour_week"
                                    className="form-labels font-weight500 announce-date font-weight400 font-size-heading"
                                    title="Total works hours (per week)"
                                >
                                    Total works hours (per week):
                                </label>
                                <input
                                    placeholder="Total works hours (per day)"
                                    title="total_hour_week"
                                    id="total_hour_week"
                                    type="number"
                                    name="total_hour_week"
                                    min={0}
                                    onWheel={(e) => e.target.blur()}
                                    onChange={handleInputChange}
                                    value={formData.total_hour_week}
                                    className={`form-input font-weight400 font-size-subheading form-input-background ${errors.total_hour_week
                                        ? "error"
                                        : inputState.total_hour_week
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.total_hour_week && (
                                    <span className="error-message font-size-text">
                                        {errors.total_hour_week}
                                    </span>
                                )}
                            </div> */}
                        </div>
                    </form>
                    <div className="button-models">
                        <button
                            className="model-button font-weight500 model-button-cancel"
                            onClick={handleClose}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="model-button font-weight500 model-button-leave font-weight500 font-size-heading"
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                            Add
                        </button>
                    </div>

                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

const EditTimesheet = ({ i, getTimesheet }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setFormData({
            task_id: "",
            in_time: "",
            out_time: "",
            date: "",
            employee: sessionStorage.getItem("employee_id"),
            project: "",
            remark: "",
            total_hour_day: "",
            total_hour_week: "",
            status: "pending",
            workstage: "",
        });
        setShow(false);
    };
    useEffect(() => {
        setFormData(i)
    }, [show])
    const handleShow = () => {
        getProjectListData();
        if (formData.employee != "") {
            getEmployeeTaskList(formData.employee);
        }
        setShow(true);
    };
    const months = [
        { text: "January", value: 1 },
        { text: "February", value: 2 },
        { text: "March", value: 3 },
        { text: "April", value: 4 },
        { text: "May", value: 5 },
        { text: "June", value: 6 },
        { text: "July", value: 7 },
        { text: "August", value: 8 },
        { text: "September", value: 9 },
        { text: "October", value: 10 },
        { text: "November", value: 11 },
        { text: "December", value: 12 },
    ];

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 10 }, (_, i) => currentYear - i);
    const emp_code = sessionStorage.getItem('employee_id');
    const role = sessionStorage.getItem('role');

    const [formData, setFormData] = useState({
        task_id: "",
        in_time: "",
        out_time: "",
        date: "",
        employee: sessionStorage.getItem("employee_id"),
        project: "",
        remark: "",
        total_hour_day: "",
        total_hour_week: "",
        status: "pending",
        workstage: "",
    });

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};

        const requiredFields = ["in_time", "out_time"];

        requiredFields.forEach((field) => {
            console.log(formData[field]);
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);
        console.log(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                const formDataToSend = new FormData();

                Object.keys(formData).forEach((key) => {
                    if (formData[key]) {
                        formDataToSend.append(key, formData[key]);
                    }
                });

                let res = await axios.put(
                    `${BASE_URL}/project/timesheetupdate/${i.id}/`,
                    formData
                );

                if (res.status === 200) {
                    await getTimesheet();
                    handleClose();
                    toast.dismiss(loadingToastId);
                    toast.success("Timesheet Updated successfully!");
                } else {
                    toast.dismiss(loadingToastId);
                    toast.error("Failed to add Timesheet!");
                }
            } catch (err) {
                //toast Logic
                handleErrorToast(err);
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        } else {
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, files } = e.target;
        // console.log(files[0])
        let newValue;
        if (type === "file") {
            console.log("file", files[0]);
            newValue = files.length > 0 ? files : null;
        } else {
            newValue = value;
        }

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });
        if (name === "Timesheet_type") {
            setFormData({
                ...FormData,
                [name]: newValue,
                month: "",
                project: "",
            });
        }
        setFormData({
            ...formData,
            [name]: newValue,
        });
    };

    const [projectListData, setProjectList] = useState([]);
    const [tasksList, setTasksList] = useState([]);
    const [workStageList, setWorkStageList] = useState([]);

    const [buffer1, setBuffering1] = useState(true); //buffering logic
    const getProjectListData = async () => {
        setBuffering1(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                // `${BASE_URL}/project/projectfilterbysubcompany/null/`
                `${BASE_URL}/project/projectfilterbyprojectstatus/null/${role === "HOD" ? "null" : emp_code}/null/`
            ); setProjectList(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering1(false); // End Buffering
        }
    };

    const [buffer2, setBuffering2] = useState(true); //buffering logic
    const getEmployeeTaskList = async (employeeid) => {
        setBuffering2(true); //buffering logic // Start Buffering

        try {
            const taskiddata = await axios.get(
                `${BASE_URL}/wfm/taskbyemp/${employeeid || "null"}/`
            );
            setTasksList(taskiddata.data);
        } catch (err) {
            // handleErrorToast(err);
        } finally {
            setBuffering2(false); // End Buffering
        }
    };

    const [buffer3, setBuffering3] = useState(true); //buffering logic
    const getWorkStageList = async (projectid, employeeid) => {
        setBuffering3(true); //buffering logic // Start Buffering

        try {
            const workstages = await axios.get(
                `${BASE_URL}/project/workstagelist/${projectid || "null"}/${employeeid || "null"}/`
            );
            setWorkStageList(workstages.data);
        } catch (err) {
            // handleErrorToast(err);
        } finally {
            setBuffering3(false); // End Buffering
        }
    };

    useEffect(() => {
        if (formData.project != "" && formData.employee != "") {
            getWorkStageList(formData.project, formData.employee);
        }
    }, [formData.project, formData.employee]);

    useEffect(() => {
        setFormData({
            ...formData,
            Timesheet_type: "ProjectbyWise",
        });
    }, []);

    return (
        <>
            <button
                title="Approve Timesheet"
                className="model-edit-button"
                onClick={handleShow}
            >
                <UpdatePencil />
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="ourcompany-model"
            >
                <Modal.Header
                    closeButton
                    className="justify-center task-Tab-heading font-weight500    font-size-heading "
                >
                    <Modal.Title>Edit Timesheet Entry</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="register-from">
                        <div className="form-flex-wrap">
                            <div className="flex-column form-group-select">
                                <label
                                    htmlFor="Timesheet_type"
                                    className="form-labels font-weight500 announce-date font-weight400 font-size-heading"
                                    title="Timesheet_type"
                                >
                                    Task
                                </label>
                                {buffer2 ?
                                    <div className="form-loader">
                                        <div className="bar-loader"></div>
                                    </div>
                                    :
                                    <>
                                        <select
                                            name="task_id"
                                            value={formData.task_id}
                                            onChange={handleInputChange}
                                            className={`form-input form-group-selection ${errors.task_id
                                                ? "error"
                                                : inputState.project
                                                    ? "success"
                                                    : ""
                                                }`}
                                        >
                                            <option value="null">Select Task</option>
                                            {tasksList?.map((task) => (
                                                <option key={task.id} value={task.id}>
                                                    {task.task}
                                                </option>
                                            ))}
                                        </select>
                                        <DropdownArrow />
                                        {errors.task_id && (
                                            <span className="error-message font-size-text">
                                                {errors.task_id}
                                            </span>
                                        )}
                                    </>}
                            </div>
                            <div className="flex-column form-group-select">
                                <label className="form-labels font-weight500 font-size-subheading">
                                    Project:
                                </label>
                                {buffer1 ?
                                    <div className="form-loader">
                                        <div className="bar-loader"></div>
                                    </div>
                                    :
                                    <>
                                        <select
                                            name="project"
                                            value={formData.project}
                                            onChange={handleInputChange}
                                            className={`form-input form-group-selection ${errors.project
                                                ? "error"
                                                : inputState.project
                                                    ? "success"
                                                    : ""
                                                }`}
                                        >
                                            <option value="">Select a Project</option>
                                            {projectListData.map((project) => (
                                                <option key={project.id} value={project.pr_code}>
                                                    {project.prebid_prcode}
                                                    {project.project_short_name
                                                        ? `-${project.project_short_name}`
                                                        : null}
                                                </option>
                                            ))}
                                        </select>
                                        <DropdownArrow />
                                        {errors.project && (
                                            <span className="error-message">{errors.project}</span>
                                        )}
                                    </>}
                            </div>

                            <div className="flex-column form-group-select">
                                <label className="form-labels font-weight500 font-size-subheading">
                                    Workstage:
                                </label>
                                {buffer3 ?
                                    <div className="form-loader">
                                        <div className="bar-loader"></div>
                                    </div>
                                    :
                                    <>
                                        <select
                                            name="workstage"
                                            value={formData.workstage}
                                            onChange={handleInputChange}
                                            className={`form-input form-group-selection ${errors.workstage
                                                ? "error"
                                                : inputState.workstage
                                                    ? "success"
                                                    : ""
                                                }`}
                                        >
                                            <option value="">Select Workstage</option>
                                            {workStageList.map((project) => (
                                                <option key={project.wid} value={project.wid}>
                                                    {project.workStages}
                                                </option>
                                            ))}
                                        </select>
                                        <DropdownArrow />
                                        {errors.workStages && (
                                            <span className="error-message">{errors.workStages}</span>
                                        )}
                                    </>}
                            </div>
                            <div
                                className="flex-column form-group-select"
                                style={{
                                    marginRight: "0px",
                                }}
                            >
                                <label className="form-labels">
                                    Date:<span className="required">*</span>
                                </label>
                                <input
                                    type="date"
                                    name="date"
                                    value={formData.date}
                                    onChange={handleInputChange}
                                    className="form-input"
                                />
                            </div>
                            <div className="flex-row multi-day-input">
                                <div className="flex-column multi-day">
                                    <label className="form-labels  font-weight500 font-size-subheading">
                                        In Time <span className="required">*</span>
                                    </label>
                                    <input
                                        className="multi-day-input1 form-input-background"
                                        type="time"
                                        name="in_time"
                                        value={formData.in_time}
                                        step="1"
                                        min="09:30:00"
                                        max="16:30:00"
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="flex-column multi-day">
                                    <label className="form-labels  font-weight500    font-size-subheading">
                                        Out Time <span className="required">*</span>
                                    </label>
                                    <input
                                        className="multi-day-input1 form-input-background"
                                        type="time"
                                        name="out_time"
                                        step="1"
                                        min="09:30:00"
                                        max="16:30:00"
                                        value={formData.out_time}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>


                            <div className="flex-column">
                                <label
                                    htmlFor="remark"
                                    className="form-labels font-weight500 announce-date font-weight400 font-size-heading"
                                    title="Remark"
                                >
                                    Remark:
                                </label>
                                <input
                                    placeholder="Remark text "
                                    title="remark"
                                    id="remark"
                                    type="text"
                                    name="remark"
                                    onChange={handleInputChange}
                                    value={formData.remark}
                                    className={`form-input font-weight400 font-size-subheading form-input-background ${errors.remark ? "error" : inputState.remark ? "success" : ""
                                        }`}
                                />
                                {errors.remark && (
                                    <span className="error-message font-size-text">
                                        {errors.remark}
                                    </span>
                                )}
                            </div>
                            {/* <div className="flex-column">
                                <label
                                    htmlFor="total_hour_day"
                                    className="form-labels font-weight500 announce-date font-weight400 font-size-heading"
                                    title="Total works hours (per day)"
                                >
                                    Total works hours (per day):
                                </label>
                                <input
                                    placeholder="Total works hours (per day)"
                                    title="total_hour_day"
                                    id="total_hour_day"
                                    type="number"
                                    min={0}
                                    onWheel={(e) => e.target.blur()}
                                    name="total_hour_day"
                                    onChange={handleInputChange}
                                    value={formData.total_hour_day}
                                    className={`form-input font-weight400 font-size-subheading form-input-background ${errors.total_hour_day
                                        ? "error"
                                        : inputState.total_hour_day
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.total_hour_day && (
                                    <span className="error-message font-size-text">
                                        {errors.total_hour_day}
                                    </span>
                                )}
                            </div>
                            <div className="flex-column">
                                <label
                                    htmlFor="total_hour_week"
                                    className="form-labels font-weight500 announce-date font-weight400 font-size-heading"
                                    title="Total works hours (per week)"
                                >
                                    Total works hours (per week):
                                </label>
                                <input
                                    placeholder="Total works hours (per day)"
                                    title="total_hour_week"
                                    id="total_hour_week"
                                    type="number"
                                    name="total_hour_week"
                                    min={0}
                                    onWheel={(e) => e.target.blur()}
                                    onChange={handleInputChange}
                                    value={formData.total_hour_week}
                                    className={`form-input font-weight400 font-size-subheading form-input-background ${errors.total_hour_week
                                        ? "error"
                                        : inputState.total_hour_week
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.total_hour_week && (
                                    <span className="error-message font-size-text">
                                        {errors.total_hour_week}
                                    </span>
                                )}
                            </div> */}
                        </div>
                    </form>
                    <div className="button-models">
                        <button
                            className="model-button font-weight500 model-button-cancel"
                            onClick={handleClose}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="model-button font-weight500 model-button-leave font-weight500 font-size-heading"
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                            Update
                        </button>
                    </div>

                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

const ApproveTimesheet = ({ i, getTimesheet }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    const handleFormSubmitRejected = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

        try {
            let res = await axios.put(
                `${BASE_URL}/project/timesheetupdate/${i.id}/`,
                {
                    status: "approved",
                }
            );

            if (res.status === 200) {
                await getTimesheet();
                setShow(false);
                toast.dismiss(loadingToastId);
                toast.success("Timesheet rejected ");
            } else {
                alert(res);
            }
        } catch (err) {
            //toast Logic
            handleErrorToast(err);
        } finally {
            toast.dismiss(loadingToastId);
        }
    };

    const createMarkup = (content) => {
        const linkRegex = /(?:https?|ftp):\/\/[^\s]+/g;
        return {
            __html: content?.replace(linkRegex, (url) => {
                return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
            }),
        };
    };

    return (
        <>
            <button
                title="Approve Timesheet"
                className="model-edit-button"
                onClick={handleShow}
            >
                <CheckGreen />
            </button>
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Approve Timesheet Entry</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        Are You Sure You Want to Approve Timesheet Entry for Task:
                        <br />
                        <div dangerouslySetInnerHTML={createMarkup(i.task_name)} />

                        <div className="button-models">
                            <button
                                className="model-button   font-weight500  model-button-cancel"
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleFormSubmitRejected}
                                className="model-button   font-weight500    model-button-delete"
                            >
                                Rejected
                            </button>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

const RejectTimesheet = ({ i, getTimesheet }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    const handleFormSubmitRejected = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

        try {
            let res = await axios.put(
                `${BASE_URL}/project/timesheetupdate/${i.id}/`,
                {
                    status: "rejected",
                }
            );

            if (res.status === 200) {
                await getTimesheet();
                setShow(false);
                toast.dismiss(loadingToastId);
                toast.success("Timesheet rejected ");
            } else {
                alert(res);
            }
        } catch (err) {
            //toast Logic
            handleErrorToast(err);
        } finally {
            toast.dismiss(loadingToastId);
        }
    };

    const createMarkup = (content) => {
        const linkRegex = /(?:https?|ftp):\/\/[^\s]+/g;
        return {
            __html: content?.replace(linkRegex, (url) => {
                return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
            }),
        };
    };

    return (
        <>
            <button
                title="RejectTimesheet"
                className="model-delete-button"
                onClick={handleShow}
            >
                <CrossRed />
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Reject Timesheet {i.header_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        Are You Sure You Want to Reject Timesheet Entry for Task:
                        <br />
                        <div dangerouslySetInnerHTML={createMarkup(i.task_name)} />

                        <div className="button-models">
                            <button
                                className="model-button   font-weight500   model-button-cancel"
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleFormSubmitRejected}
                                className="model-button   font-weight500    model-button-delete"
                            >
                                Rejected
                            </button>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

const DeleteTimesheet = ({ i, getTimesheet }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

        try {
            let res = await axios.delete(
                `${BASE_URL}/project/timesheetupdate/${i.id}/`
            );

            if (res.status === 200) {
                await getTimesheet();
                setShow(false);
                toast.dismiss(loadingToastId);
                toast.success("Timesheet deleted successfully");
            } else {
                alert(res);
            }
        } catch (err) {
            //toast Logic
            handleErrorToast(err);
        } finally {
            toast.dismiss(loadingToastId);
        }
    };

    return (
        <>
            <button
                title="Delete Timesheet"
                className="model-delete-button"
                onClick={handleShow}
            >
                <DeleteDustbin />
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete Timesheet of "{i.header_name}"</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        Are You Sure You Want to delete Timesheet ?
                        <div className="button-models">
                            <button
                                className="model-button   font-weight500    model-button-cancel "
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleFormSubmitDelete}
                                className="model-button   font-weight500    model-button-delete"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

const Timesheet = () => {
    const [buffer, setBuffering] = useState(true); //buffering logic
    const employee = sessionStorage.getItem("employee_id")
    const [toggleStatus, setToggleStatus] = useState("pending");
    const [timeSheetData, setTimeSheetData] = useState([]);
    const role = sessionStorage.getItem("role");

    const getTimesheet = async () => {
        setBuffering(true); // Start Buffering
        let url;
        try {
            // url = `${BASE_URL}/project/timesheetsbyemployee/${role === "HOD" ? "null" : employee}/`;
            url = `${BASE_URL}/project/timesheetsfilter/${role === "HOD" ? "null" : employee}/${toggleStatus}/`;

            const sub = await axios.get(url);

            setTimeSheetData(sub.data);
        } catch (err) {
            handleErrorToast(err); // Toast Logic
        } finally {
            setBuffering(false); // End Buffering
        }
    };

    useEffect(() => {
        if (employee != "") {
            getTimesheet();
        }

    }, [toggleStatus, employee]);
    const months = [
        { text: "January", value: 1 },
        { text: "February", value: 2 },
        { text: "March", value: 3 },
        { text: "April", value: 4 },
        { text: "May", value: 5 },
        { text: "June", value: 6 },
        { text: "July", value: 7 },
        { text: "August", value: 8 },
        { text: "September", value: 9 },
        { text: "October", value: 10 },
        { text: "November", value: 11 },
        { text: "December", value: 12 },
    ];


    const createMarkup = (content) => {
        const linkRegex = /(?:https?|ftp):\/\/[^\s]+/g;
        return {
            __html: content?.replace(linkRegex, (url) => {
                return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
            }),
        };
    };

    return (
        <>
            <Dashboardnavbarcopy name="Timesheet Table" url="timesheet" />

            <div className="content-tabs">
                <div className="table-css-white-background">
                    <div className="table-heading-flex">
                        <div className="repo-heading font-weight500 font-size-heading">
                            Timesheet Table
                        </div>
                        <div className="field-cont">
                            <div className="table-searchh dropdown-container field-cont-div">
                                <select
                                    onChange={(e) => setToggleStatus(e.target.value)}
                                    value={toggleStatus}
                                    className="dropdown font-size-subheading  "
                                >
                                    <option value="null">All</option>
                                    <option value="pending">Pending</option>
                                    <option value="approved">Approved</option>
                                    <option value="rejected">Rejected</option>
                                </select>
                            </div>

                            <AddTimesheet
                                getTimesheet={getTimesheet}
                                sectorList={timeSheetData}
                            />
                        </div>
                    </div>
                    {/* <SectorsDetailForm /> */}
                    <div className="table-box height-73vh">
                        <table className="table-css">
                            <thead className="table-heading">
                                <tr className="custom-table-head-tr">
                                    <th className="align-left">Sr. no.</th>
                                    <th className="align-center  width-15vw">Task ID</th>
                                    <th className="align-center">In Time</th>
                                    <th className="align-center">Out Time</th>
                                    <th className="align-center">Employee</th>
                                    <th className="align-center">Project</th>
                                    {/* <th className="align-center">Status</th> */}
                                    <th className="align-center">Document Upload</th>
                                    <th className="align-center">Link</th>
                                    <th className="align-center">Remark</th>
                                    <th className="align-right">Total Hours (Day)</th>
                                    <th className="align-right">Total Hours (Week)</th>
                                    <th className="align-center">Work Stage</th>

                                    <th className="align-center width-5vw">Action</th>

                                    {/* <th className="align-right width-5vw">Delete</th> */}
                                </tr>
                            </thead>
                            {buffer ? (
                                <div className="spinner"></div>
                            ) : (
                                <tbody>
                                    {timeSheetData
                                        // ?.filter((e) =>
                                        //     e.status === toggleStatus
                                        // )
                                        // .sort((a, b) =>
                                        //     a.booking_date.localeCompare(b.booking_date, "en", { ignorePunctuation: true })
                                        // )
                                        .map((i, index) => (
                                            <React.Fragment key={index}>
                                                <tr className="tr-border-bottom">
                                                    <td colSpan="6"></td>
                                                </tr>
                                                <tr className="custom-table-head-td">
                                                    <td className="align-left">{index + 1}</td>

                                                    <td className="align-left width-15vw">
                                                        {" "}
                                                        <div dangerouslySetInnerHTML={createMarkup(i.task_name)} />
                                                        {/* {i.task_name ? i.task_name : "-"} */}
                                                    </td>

                                                    <td className="align-center">
                                                        {i.in_time ? i.in_time : "-"}
                                                    </td>

                                                    <td className="align-center">
                                                        {i.out_time ? i.out_time : "-"}
                                                    </td>

                                                    <td className="align-center form-text-trasformation-uppercase">
                                                        {i.employee ? i.employee : "-"}
                                                    </td>

                                                    <td className="align-center form-text-trasformation-uppercase">
                                                        {i.project_name ? i.project_name : ""}
                                                    </td>

                                                    {/* <td className="align-center form-text-trasformation-uppercase">
                                                        {i.status ? i.status : "-"}
                                                    </td> */}

                                                    <td className="align-center form-text-trasformation-uppercase">
                                                        {i.document_upload ? (
                                                            <a href={i.document_upload}>
                                                                <PDFIcon />
                                                            </a>
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </td>

                                                    <td className="align-center form-text-trasformation-uppercase">
                                                        {i.link ? (
                                                            <a
                                                                href={i.link}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {i.link}
                                                            </a>
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </td>

                                                    <td className="align-center form-text-trasformation-uppercase">
                                                        {i.remark ? i.remark : "-"}
                                                    </td>

                                                    <td className="align-right">
                                                        {i.total_hour_day ? i.total_hour_day : "-"}
                                                    </td>

                                                    <td className="align-right">
                                                        {i.total_hour_week ? i.total_hour_week : "-"}
                                                    </td>

                                                    <td className="align-center form-text-trasformation-uppercase">
                                                        {i.workstage_name ? i.workstage_name : "-"}
                                                    </td>


                                                    <td className="align-center">
                                                        {role === "HOD" ?
                                                            <div className="flex-row justify-evenly">
                                                                <ApproveTimesheet
                                                                    i={i}
                                                                    getTimesheet={getTimesheet}
                                                                />
                                                                <RejectTimesheet
                                                                    i={i}
                                                                    getTimesheet={getTimesheet}
                                                                />
                                                            </div> :
                                                            <div className="flex-row justify-evenly">
                                                                <EditTimesheet
                                                                    i={i}
                                                                    getTimesheet={getTimesheet}
                                                                />
                                                                <DeleteTimesheet
                                                                    i={i}
                                                                    getTimesheet={getTimesheet}
                                                                />
                                                            </div>}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                </tbody>
                            )}
                        </table>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </div>
        </>
    );
};

export default Timesheet;
