import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import {

    Subcompanys,
    Subcompanyupdate,
    SubcompanyDelete,

} from "./Subcompanycrud";
import { BASE_URL } from "../../config/axios";
import axios from "axios";
import { formattedDateLong } from "../Date";
import usePermission from "../../config/permissions";
import { ToastContainer, toast } from "react-toastify";
import { Arrow, Office, Search, SearchBig, SearchSmall } from "../AllSvg";



const Subcompany = () => {
    const { PERMISSION_ROLE, PERMISSION_AUTHORITY } = usePermission(); // Use the custom hook
    const [buffer, setBuffering] = useState(true); //buffering logic

    const [SubCompany, setSubCompany] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };
    const getSubCompany = async () => {
        setBuffering(true); //buffering logic // Start Buffering 
        try {
            // const res = await axios.get(`${BASE_URL}/wfm/subcompanywfm/`);
            // const res = await axios.get(`${BASE_URL}/wfm/subcompanyfilterbysubcompanycount/${sessionStorage.getItem('company_id')}/active/`);
            const res = await axios.get(
                `${BASE_URL}/project/subcompany/`
            );
            setSubCompany(res.data);
        } catch (err) {
            //toast Logic
            if (err.response) {
                const errorData = err.response.data;
                if (typeof errorData.error === 'string') {
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };

    useEffect(() => {
        getSubCompany();
    }, []);

    return (
        <div className="table-css-white-background">
            <div className="table-heading-flex">
                <div className="repo-heading font-weight500    font-size-heading">Sub Company</div>
                <div className="flex-row">
                    <div className="table-searchh">
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="11"
                                height="12"
                                viewBox="0 0 11 12"
                                fill="none"
                            >
                                <circle
                                    cx="5"
                                    cy="5"
                                    r="4.3"
                                    stroke="#2B3674"
                                    strokeWidth="1.4"
                                />
                                <line
                                    x1="10.0101"
                                    y1="11"
                                    x2="8"
                                    y2="8.98995"
                                    stroke="#2B3674"
                                    strokeWidth="1.4"
                                    strokeLinecap="round"
                                />
                            </svg>
                        </div>
                        <div>
                            <input
                                placeholder="Search"
                                className="navbar-inputt font-weight400  font-size-text"
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </div>
                    </div>

                    <Subcompanys getSubCompany={getSubCompany} />

                </div>
            </div>
            <table className="table-css">
                <thead>
                    <tr className="custom-table-head-tr">
                        <th className="table-heading">S.no.</th>
                        <th className="table-heading">Company Name</th>
                        <th className="table-heading">Company Address</th>
                        <th className="table-heading">Company Number</th>
                        <th className="table-heading">Company GST</th>
                        <th className="table-heading">Project Count</th>
                        {/* <th className="table-heading">Project View</th> */}
                        <th className="table-heading">Action</th>
                    </tr>
                </thead>
                {buffer ? <div className="spinner"></div> : //buffering logic
                    <tbody>
                        {SubCompany?.filter((e) =>
                            e.title.toLowerCase().includes(searchQuery.toLowerCase())
                        )
                            .sort((a, b) =>
                                a.title.localeCompare(b.title, "en", { ignorePunctuation: true })
                            )
                            .map((i, index) => (
                                <React.Fragment key={index}>
                                    <tr className="tr-border-bottom">
                                        <td colSpan="6"></td>
                                    </tr>
                                    <tr className="custom-table-head-td">
                                        <td className="table-body">{index + 1}.</td>
                                        <td className="table-body">{i.title ? i.title : "-"}</td>
                                        <td className="table-body">{i.company_address ? i.company_address : "-"}</td>
                                        <td className="table-body">{i.company_number ? i.company_number : "-"}</td>
                                        <td className="table-body">{i.company_gst ? i.company_gst : "-"}</td>
                                        <td className="table-body">{i.project_count ? i.project_count : "-"}</td>
                                        {/* <td className="table-body">
                                            <EmployeebySubCompanyTable subcompany={i} />
                                        </td> */}
                                        <td className="table-body">
                                            <Subcompanyupdate i={i} getSubCompany={getSubCompany} />

                                            {PERMISSION_AUTHORITY ? <SubcompanyDelete i={i} getSubCompany={getSubCompany} /> : null}
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                    </tbody>
                }
            </table>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
    );
};


export default Subcompany