import React, { useState, useEffect } from 'react'
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { BASE_URL_PREBID } from "../../config/axios"
import Select from "react-select";
import axios from "axios"
import { ToastContainer, toast } from "react-toastify";
import { formattedDate } from '../Date';
import { handleErrorToast } from '../custom'


import CreatableSelect from 'react-select/creatable';

const UpdateProjectDetaiils = ({ id, modalData, allEmployeeData, getProjectDetails }) => {

    //   ****************   Modal Section
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        setErrors({});
    }
    const handleShow = () => {
        getUniqueProjectDetails();
        setShow(true);
        setFormData(modalData);
        getClientDetails();
        getSubcompany();
    };
    const [loading, setLoading] = useState(false); //loading logic
    const [formData, setFormData] = useState({
        project_name: '',
        project_short_name: '',
        prebid_prcode: '',
        client_name: '',
        tender_id: '',
        location: '',
        project_type: '',
        concern_person: '',
        concern_person_name: '',
        opening_date: 'null',
        cost: '',
        length: '',
        our_position: '',
        l1_position: '',
        remark: '',
        confirm_by: '',
        confirmation_mode: '',
        portal_submission_date: 'null',
        pr_project: false,
        sub_company: "null",

    });

    useEffect(() => {
        setFormData(modalData)
    }, [modalData]);

    console.log("modalData")
    console.log(modalData)
    console.log("formData")
    console.log(formData)

    const allLocationData = [
        { id: '1', name: 'Andaman and Nicobar Islands' },
        { id: '2', name: 'Andhra Pradesh' },
        { id: '3', name: 'Arunachal Pradesh' },
        { id: '4', name: 'Assam' },
        { id: '5', name: 'Bihar' },
        { id: '6', name: 'Chandigarh' },
        { id: '7', name: 'Chhattisgarh' },
        { id: '8', name: 'Dadra and Nagar Haveli and Daman and Diu' },
        { id: '9', name: 'Delhi' },
        { id: '10', name: 'Goa' },
        { id: '11', name: 'Gujarat' },
        { id: '12', name: 'Haryana' },
        { id: '13', name: 'Himachal Pradesh' },
        { id: '14', name: 'Jammu and Kashmir' },
        { id: '15', name: 'Jharkhand' },
        { id: '16', name: 'Karnataka' },
        { id: '17', name: 'Kerala' },
        { id: '18', name: 'Ladakh' },
        { id: '19', name: 'Lakshadweep' },
        { id: '20', name: 'Madhya Pradesh' },
        { id: '21', name: 'Maharashtra' },
        { id: '22', name: 'Manipur' },
        { id: '23', name: 'Meghalaya' },
        { id: '24', name: 'Mizoram' },
        { id: '25', name: 'Nagaland' },
        { id: '26', name: 'Odisha' },
        { id: '27', name: 'Puducherry' },
        { id: '28', name: 'Punjab' },
        { id: '29', name: 'Rajasthan' },
        { id: '30', name: 'Sikkim' },
        { id: '31', name: 'Tamil Nadu' },
        { id: '32', name: 'Telangana' },
        { id: '33', name: 'Tripura' },
        { id: '34', name: 'Uttar Pradesh' },
        { id: '35', name: 'Uttarakhand' },
        { id: '36', name: 'West Bengal' },
    ];


    const [errors, setErrors] = useState({});

    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};

        const requiredFields = [
            // 'project_name',
            // 'client',
            // 'concern_person',
            // 'project_type',
            // 'location',
            // 'contract_mode',
            // 'confirmation_mode',
            // 'confirm_by',
            // 'prebid_prcode'

        ];

        requiredFields.forEach(field => {
            if (!formData[field]) {
                newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const role = sessionStorage.getItem('role');
    const department = sessionStorage.getItem('department');

    const [SubcompanyList, setSubcompanyList] = useState([]);
    const getSubcompany = async () => {
        try {
            const res = await axios.get(`${BASE_URL_PREBID}/project/subcompany/`);

            setSubcompanyList(res.data);
        } catch (err) {
            console.error("Error fetching subcompany data:", err.message);
            alert(err.message);
        }
    };

    const [allClientData, setAllClientData] = useState([]);
    const getClientDetails = async () => {
        try {
            const res = await axios.get(`${BASE_URL_PREBID}/project/clientName/`);

            setAllClientData(res.data || []); // Ensure it's an array
        } catch (err) {
            console.error("Error fetching client data:", err.message);
            alert(err.message);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {


            if (formData.location === 'Other') {
                setFormData({
                    ...formData,
                    location: formData.otherLocation,
                });


            }
            const UpdatedFormData = {
                ...formData,
                username: sessionStorage.getItem('name')
            }
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(`${BASE_URL_PREBID}/project/projectupdate/${modalData.pr_code}/`, UpdatedFormData,)

                if (res.status === 200) {
                    await getProjectDetails();
                    handleClose();
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId)
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        } else {
            console.log('Form contains errors:', errors);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? 'green' : '',
        });

        // Update the 'location' or 'otherLocation' field based on the 'name' of the input
        if (name === 'location' || name === 'otherLocation') {
            setFormData({
                ...formData,
                [name]: value,
            });
        } else if (name === 'concern_person') {
            // If the input name is 'concern_person', split the value to get both code and name
            const [code, name] = value.split(' - ');

            setFormData({
                ...formData,
                concern_person: code, // Store code in 'concern_person'
                concern_person_name: name, // Store name in 'concern_person_name'
            });
        } else {
            // Handle other fields (e.g., project_name, client, etc.) as usual
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleLocationChange = (event) => {
        const { name, value } = event.target;

        // If the selected location is "Other," clear the input field value
        if (value === "Other") {
            setFormData({
                ...formData,
                location: value, // Update the select field value
                otherLocation: "", // Clear the input field value
            });
        } else {
            setFormData({
                ...formData,
                location: value,
            });
        }
    };



    const handleChange = (e) => {
        const { name, value, type, checked } = e.target || e;

        // Handle checkbox, CreatableSelect, or standard input change
        const inputValue = type === 'checkbox' ? checked : value;

        setInputState({
            ...inputState,
            [name]: type === 'checkbox' ? (checked ? 'green' : '') : value.trim() ? 'green' : '',
        });

        setFormData({
            ...formData,
            [name]: inputValue,
        });
    };

    const [allProjectTypes, setAllProjectTypes] = useState([]);
    const getUniqueProjectDetails = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL_PREBID}/project/unique_project_type/`,
            );
            setAllProjectTypes(Array.from(res.data).filter(type => type !== null).map(type => ({ value: type, label: type })));

        } catch (err) {
            alert(err.message);
        }

    }


    const options = allEmployeeData
        .sort((a, b) => parseInt(a.emp_code) - parseInt(b.emp_code))
        .map((e) => ({
            value: e.emp_code,
            label: `${e.emp_code} - ${e.name}`,
        }));


    const handleChangeAssignedTo = (selected) => {
        // const assignedto = selected.length > 0 ? selected?.map((item) => item.value) : "";
        const assignedto = selected?.map((item) => item.value) || [];
        setFormData((prevState) => ({
            ...prevState,
            assignedto,
        }));
    };

    return (
        <>

            <button className='upload-svg' onClick={handleShow}>
                Update Details {" "}
                <svg xmlns="http://www.w3.org/2000/svg"
                    width="20" height="20" enable-background="new 0 0 32 32" viewBox="0 0 32 32" fill='white'
                    id="edit">
                    <path d="M3.5,24h15A3.51,3.51,0,0,0,22,20.487V12.95a1,1,0,0,0-2,0v7.537A1.508,1.508,0,0,1,18.5,22H3.5A1.508,1.508,0,0,1,2,20.487V5.513A1.508,1.508,0,0,1,3.5,4H11a1,1,0,0,0,0-2H3.5A3.51,3.51,0,0,0,0,5.513V20.487A3.51,3.51,0,0,0,3.5,24Z"></path><path d="M9.455,10.544l-.789,3.614a1,1,0,0,0,.271.921,1.038,1.038,0,0,0,.92.269l3.606-.791a1,1,0,0,0,.494-.271l9.114-9.114a3,3,0,0,0,0-4.243,3.07,3.07,0,0,0-4.242,0l-9.1,9.123A1,1,0,0,0,9.455,10.544Zm10.788-8.2a1.022,1.022,0,0,1,1.414,0,1.009,1.009,0,0,1,0,1.413l-.707.707L19.536,3.05Zm-8.9,8.914,6.774-6.791,1.4,1.407-6.777,6.793-1.795.394Z"></path></svg>
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="dialog-modal-assign-task dialog-model" >

                <Modal.Header closeButton>
                    <Modal.Title className="modal-title">Update {formData?.project_short_name} Project </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

                    <div className='modal-body-padding'>
                        <form>
                            <div className='form-flex-wrap'>
                                <div className="flex-column form-group-select">
                                    <label className="form-labels">Sub Company:<span className="required">*</span></label>
                                    <select
                                        name="sub_company"
                                        value={formData.sub_company}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection  ${errors.sub_company ? 'error' : inputState.sub_company ? 'success' : ''}`}
                                    >
                                        <option value="">Select a Sub Company</option>
                                        {
                                            SubcompanyList.length > 0 ?
                                                (SubcompanyList.map((e, index) => (
                                                    <option key={index} value={e.id}>{e.title}</option>
                                                ))) : (
                                                    <option value="">No Sub Company Available</option>
                                                )
                                        }

                                    </select>
                                    <div className={`form-group-selection-arrow ${errors.client ? 'error-arrow' : ''}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    {errors.sub_company && (
                                        <span className="error-message">{errors.sub_company}</span>
                                    )}

                                </div>

                                <div className="flex-column">
                                    <label className="form-labels">Project Code:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="prebid_prcode"
                                        value={formData.prebid_prcode}
                                        readOnly={formData.prebid_prcode ? true : false}
                                        onChange={handleInputChange}
                                        className={`form-input  ${errors.prebid_prcode ? 'error' : inputState.prebid_prcode ? 'success' : ''}`}
                                    />
                                    {errors.prebid_prcode && <span className="error-message">{errors.prebid_prcode}</span>}
                                </div>

                                <div className="flex-column">
                                    <label className="form-labels">Project Description:<span className="required">*</span></label>
                                    <textarea
                                        type="text"
                                        name="project_name"
                                        value={formData.project_name}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.project_name ? 'error' : inputState.project_name ? 'success' : ''}`}
                                    />
                                    {errors.name && <span className="error-message">{errors.name}</span>}
                                </div>

                                {department === "Accounts" ?
                                    <>
                                    </>
                                    :
                                    <div className="flex-column">
                                        <label className="form-labels">Project Short Name:<span className="required">*</span></label>
                                        <input
                                            type="text"
                                            maxLength={100}
                                            name="project_short_name"
                                            value={formData.project_short_name}
                                            onChange={handleInputChange}
                                            className={`form-input ${errors.project_short_name ? 'error' : inputState.project_short_name ? 'success' : ''}`}
                                        />
                                        {errors.project_short_name && <span className="error-message">{errors.project_short_name}</span>}
                                    </div>
                                }

                                <div className="flex-column form-group-select">
                                    <label className="form-labels">
                                        Project Type:<span className="required">*</span>
                                    </label>
                                    <CreatableSelect
                                        options={allProjectTypes}
                                        value={formData.project_type ? { value: formData.project_type, label: formData.project_type } : null}
                                        onChange={(option) => handleChange({
                                            target: {
                                                name: 'project_type',
                                                value: option ? option.value : ''
                                            }
                                        })}
                                        placeholder="Select or type a new project type"
                                        // className={`form-input form-group-selection ${errors.project_type ? 'error' : formData.project_type ? 'success' : ''}`}
                                        formatCreateLabel={(inputValue) => `Create new project type: ${inputValue}`}
                                        isValidNewOption={(inputValue, selectValue, selectOptions) =>
                                            inputValue.trim() !== '' &&
                                            !selectOptions.find(option => option.label.toLowerCase() === inputValue.toLowerCase())
                                        }
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                width: '33vw',
                                                height: '50px',
                                                borderRadius: '10px',
                                                border: '1px solid #e3e3e3',
                                                margin: '10px 0px 20px 0px',
                                                color: '#4a4a4a',
                                                backgroundColor: '#f7f8fa',
                                                padding: '0px 10px',
                                            }),
                                            placeholder: (provided) => ({
                                                ...provided,
                                                color: '#4a4a4a',
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                borderRadius: '10px',
                                                marginTop: '0',
                                            }),
                                            input: (provided) => ({
                                                ...provided,
                                                color: '#4a4a4a',
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                color: '#4a4a4a',
                                            }),
                                        }}
                                    />
                                    {errors.project_type && (
                                        <span className="error-message">{errors.project_type}</span>
                                    )}
                                </div>

                                {/* <div className="flex-column form-group-select">
                                    <label className="form-labels">Client Name:<span className="required">*</span></label>
                                    <select
                                        name="client_name"
                                        value={formData.client_name}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection  ${errors.client_name ? 'error' : inputState.client_name ? 'success' : ''}`}
                                    >
                                        <option value="">Select a Client</option>
                                        {
                                            allClientData.length > 0 ?
                                                (allClientData.map((e, index) => (
                                                    <option key={index} value={e.id}>{e.title}</option>
                                                ))) : (
                                                    <option value="">No Clients Available</option>
                                                )
                                        }

                                    </select>
                                    <div className={`form-group-selection-arrow ${errors.client ? 'error-arrow' : ''}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    {errors.client && (
                                        <span className="error-message">{errors.client}</span>
                                    )}

                                </div> */}

                                <div className="flex-column form-group-select">
                                    <label className="form-labels">Concern Person:<span className="required">*</span></label>
                                    <select
                                        name="concern_person"
                                        value={`${formData.concern_person} - ${formData.concern_person_name}`}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection form-input-background ${errors.concern_person ? 'error' : inputState.concern_person ? 'success' : ''}`}
                                    >
                                        <option value="">Select a concern_person</option>
                                        {allEmployeeData.length > 0 ? (
                                            allEmployeeData.sort((a, b) => a.emp_code - b.emp_code).map((e, index) => (
                                                <option key={index} value={`${e.emp_code} - ${e.name}`}>{e.emp_code} - {e.name}</option>
                                            ))
                                        ) : (
                                            <option value="">No Employee Available</option>
                                        )}
                                    </select>
                                    <div className={`form-group-selection-arrow ${errors.concern_person ? 'error-arrow' : ''}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    {errors.concern_person && (
                                        <span className="error-message">{errors.concern_person}</span>
                                    )}

                                </div>

                                <div className="flex-column form-group-select">
                                    <label className="form-labels">
                                        Assigned to:<span className="required">*</span>
                                    </label>
                                    {/* <Select
                                        options={options}
                                        isMulti
                                        onChange={handleChangeAssignedTo}
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                width: '33vw',
                                                minHeight: '50px',
                                                borderRadius: '10px',
                                                border: '1px solid #e3e3e3',
                                                margin: '10px 0px 20px 0px',
                                                color: '#4a4a4a',
                                                backgroundColor: '#f7f8fa',
                                                padding: '0px 10px',
                                            }),
                                            placeholder: (provided) => ({
                                                ...provided,
                                                color: '#4a4a4a',
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                borderRadius: '10px',
                                                marginTop: '0',
                                            }),
                                            input: (provided) => ({
                                                ...provided,
                                                color: '#4a4a4a',
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                color: '#4a4a4a',
                                            }),
                                        }}
                                    /> */}

                                    <Select
                                        options={options}
                                        isMulti
                                        onChange={handleChangeAssignedTo}
                                        value={formData.assignedto?.map((code) => ({
                                            value: code,
                                            label: options.find((opt) => opt.value === code)?.label || code,
                                        }))}
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                width: '33vw',
                                                minHeight: '50px',
                                                borderRadius: '10px',
                                                border: '1px solid #e3e3e3',
                                                margin: '10px 0px 20px 0px',
                                                color: '#4a4a4a',
                                                backgroundColor: '#f7f8fa',
                                                padding: '0px 10px',
                                            }),
                                            placeholder: (provided) => ({
                                                ...provided,
                                                color: '#4a4a4a',
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                borderRadius: '10px',
                                                marginTop: '0',
                                            }),
                                            input: (provided) => ({
                                                ...provided,
                                                color: '#4a4a4a',
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                color: '#4a4a4a',
                                            }),
                                        }}
                                    />

                                    {errors.person && (
                                        <span className="error-message font-size-text ">{errors.person}</span>
                                    )}
                                </div>


                                <div className="flex-column">
                                    <label className="form-labels">Tender Id:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="tender_id"
                                        value={formData.tender_id}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.tender_id ? 'error' : inputState.tender_id ? 'success' : ''}`}
                                    />
                                    {/* {errors.contact_person && <span className="error-message">{errors.contact_person}</span>} */}
                                </div>


                                <div className="flex-column">
                                    <label>Cost ( In Crores):<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="cost"
                                        value={formData.cost}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.cost ? 'error' : inputState.cost ? 'success' : ''}`}
                                    />

                                </div>


                                <div className="flex-column">
                                    <label className="form-labels">Length (in KMS):<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="length"
                                        value={formData.length}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.length ? 'error' : inputState.length ? 'success' : ''}`}
                                    />
                                </div>

                                <div className="flex-column ">
                                    <label className="form-labels">Link:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="link"
                                        value={formData.link}
                                        onChange={handleInputChange}
                                        className={`form-input${errors.link ? 'error' : inputState.link ? 'success' : ''}`}
                                    />
                                    {errors.link && (
                                        <span className="error-message">{errors.link}</span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label className="form-labels">Opening Date:<span className="required">*</span></label>
                                    <input
                                        type="date"
                                        name="opening_date"
                                        value={formData.opening_date}
                                        onChange={handleInputChange}
                                        className={`form-input  ${errors.opening_date ? 'error' : inputState.opening_date ? 'success' : ''}`}
                                    />
                                    {errors.opening_date && <span className="error-message">{errors.opening_date}</span>}
                                </div>
                                <div className="flex-column">
                                    <label className="form-labels">Portal Submission Date:<span className="required">*</span></label>
                                    <input
                                        min={formData.opening_date}
                                        type="date"
                                        name="portal_submission_date"
                                        value={formData.portal_submission_date}
                                        onChange={handleInputChange}
                                        className={`form-input  ${errors.portal_submission_date ? 'error' : inputState.portal_submission_date ? 'success' : ''}`}
                                    />
                                    {errors.portal_submission_date && (
                                        <span className="error-message">{errors.portal_submission_date}</span>
                                    )}
                                </div>





                                <div className="flex-column form-group-select">
                                    <label className="form-labels">Location:<span className="required">*</span></label>
                                    {formData.location !== 'Other' ? (
                                        <>
                                            <select
                                                name="location"
                                                value={formData.location}
                                                onChange={handleLocationChange}
                                                className={`form-input form-group-selection ${errors.client ? 'error' : inputState.client ? 'success' : ''}`}
                                            >
                                                <option value="">Select a Location</option>
                                                {
                                                    allLocationData.length > 0 ?
                                                        (allLocationData.map((e, index) => (
                                                            <option key={index} value={e.name}>{e.name}</option>
                                                        ))) : (
                                                            <option value="">No Locations Available</option>
                                                        )
                                                }
                                                <option value="Other">Other</option>
                                            </select><div className={`form-group-selection-arrow ${errors.location ? 'error-arrow' : ''}`}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                            </div></>) : (
                                        <input
                                            type="text"
                                            name="location"
                                            value={formData.location}
                                            onChange={handleInputChange}
                                            placeholder="Enter Other Location"
                                            className={`form-input  ${errors.location ? 'error' : ''}`}
                                        />
                                    )}
                                    {errors.location && (
                                        <span className="error-message">{errors.location}</span>
                                    )}
                                </div>
                                <div className="flex-column">
                                    <label className="form-labels">L1 Position:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="l1_position"
                                        value={formData.l1_position}
                                        placeholder='L1 Position'
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.l1_position ? 'error' : inputState.l1_position ? 'success' : ''}`}
                                    />
                                    {errors.l1_position && <span className="error-message">{errors.l1_position}</span>}
                                </div>
                                <div className="flex-column">
                                    <label className="form-labels">Our Position:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="our_position"
                                        value={formData.our_position}
                                        placeholder='Our Position'
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.our_position ? 'error' : inputState.our_position ? 'success' : ''}`}
                                    />
                                    {errors.our_position && <span className="error-message">{errors.our_position}</span>}
                                </div>




                                <div className="flex-column form-group-select">
                                    <label className="form-labels">Contract Mode:<span className="required">*</span></label>
                                    <select
                                        name="contract_mode"
                                        value={formData.contract_mode}
                                        onChange={handleInputChange}
                                        className={`form-input  form-group-selection ${errors.contract_mode ? 'error' : inputState.contract_mode ? 'success' : ''}`}
                                    >
                                        <option value="">Select a Contract Mode</option>
                                        <option value="epc">EPC</option>
                                        <option value="ham">HAM</option>
                                        <option value="bot">BOT</option>



                                    </select>
                                    <div className={`form-group-selection-arrow ${errors.contract_mode ? 'error-arrow' : ''}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    {errors.contract_mode && (
                                        <span className="error-message ">{errors.contract_mode}</span>
                                    )}

                                </div>

                                <div className="flex-column">
                                    <label className="form-labels">Remark:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="remark"
                                        value={formData.remark}
                                        onChange={handleInputChange}
                                        className={`form-input  ${errors.remark ? 'error' : inputState.remark ? 'success' : ''}`}
                                    />
                                    {/* {errors.contact_person && <span className="error-message">{errors.contact_person}</span>} */}
                                </div>





                                <div className="flex-column form-group-select">
                                    <label className="form-labels">Priority:</label>
                                    <select
                                        name="priority"
                                        value={formData.priority}
                                        onChange={handleInputChange}
                                        className={`form-input  form-group-selection ${errors.priority ? 'error' : inputState.priority ? 'success' : ''}`}
                                    >
                                        <option value="">Select a Priority</option>
                                        <option value="low">Low</option>
                                        <option value="medium">Medium</option>
                                        <option value="high">High</option>



                                    </select>
                                    <div className={`form-group-selection-arrow ${errors.priority ? 'error-arrow' : ''}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    {errors.priority && (
                                        <span className="error-message ">{errors.priority}</span>
                                    )}

                                </div>
                            </div>

                            <div className="button-models">
                                <button onClick={handleSubmit} className="model-button model-button-submit">Submit</button>
                            </div>
                        </form>
                    </div>

                </Modal.Body>

            </Modal>

        </>
    );
};


export default UpdateProjectDetaiils;