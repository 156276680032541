import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { BASE_URL_PREBID } from '../../config/axios';
import { formatRoundoff } from "../custom";
import { DropdownArrow } from "../AllSvg";

const BalanceSummary1 = () => {
    const [statsDuration, setStatsDuration] = useState("2024");
    const [data, setData] = useState([]);

    useEffect(() => {


        const getnewclientdata = async () => {
            try {
                const res = await axios.get(`${BASE_URL_PREBID}/project/balance-summary-view/`);
                setData(res.data);

            } catch (err) {
                alert(err.message);
            }
        };
        getnewclientdata();
    }, [statsDuration]);

    return (
        <div className="balance-cont balance-chart-cont cont-col">
            <div className="dashboardcomponent-task">
                <div className="justify-between cont-row align-cen bgwhite mary-10">
                    <div>
                        <div className="repo-heading font-size-heading font-weight500card-heading font-sizeheading">Balance Summary</div>
                    </div>
                    <div className="dashboard-priorities-select" >
                        <select
                            value={statsDuration}
                            onChange={(e) => setStatsDuration(e.target.value)}
                            className="dashboard-sectiona-selection"
                        >
                            <option value="2024">2024</option>
                            <option value="2023">2023</option>
                            <option value="2022">2022</option>
                            <option value="2021">2021</option>
                        </select>
                        <DropdownArrow />
                    </div>
                </div>

                <ResponsiveContainer height={300} width="100%">
                    <LineChart
                        width={1000}
                        height={300}
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid horizontal="true" vertical="" strokeDasharray="10 6" />
                        <XAxis dataKey="name" tickMargin={10} />
                        <YAxis />
                        <Tooltip />
                        <Legend verticalAlign='top' height={50} margin={{ left: 100 }} />
                        <Line legendType="dot" type="monotone" dataKey="Pending" stroke="#FF747C" strokeWidth={5} Dot={10} />
                        <Line legendType="dot" type="monotone" dataKey="Received" stroke="#2576BC" strokeWidth={5} />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div >
    );
};



const BalanceSummary = () => {
    const [statsDuration, setStatsDuration] = useState("2024");
    const [data, setData] = useState([]);

    console.log("data");
    console.log(data);

    useEffect(() => {
        const getnewclientdata = async () => {
            try {
                const res = await axios.get(`${BASE_URL_PREBID}/project/balance-summary-view/`);

                const transformedData = res.data.map((item) => ({
                    ...item,
                    ReceivedFormatted: formatRoundoff(item.Received), // Formatted value
                    PendingFormatted: formatRoundoff(item.Pending), // Formatted value
                }));

                setData(transformedData);
            } catch (err) {
                alert(err.message);
            }
        };

        getnewclientdata();
    }, [statsDuration]);

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const { name, ReceivedFormatted, PendingFormatted } = payload[0].payload;
            return (
                <div className="custom-tooltip">
                    <p><strong>{name}</strong></p>
                    <p>Received: {ReceivedFormatted}</p>
                    <p>Pending: {PendingFormatted}</p>
                </div>
            );
        }
        return null;
    };

    const formatYAxis = (value) => {
        return new Intl.NumberFormat("en-IN", {
            style: "decimal",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value);
    };


    return (
        <div className="balance-cont balance-chart-cont-balance cont-col">
            <div className="dashboardcomponent-task">
                <div className="justify-between cont-row align-cen bgwhite mary-10">
                    <div>
                        <div className="repo-heading font-size-heading font-weight500card-heading font-sizeheading">Balance Summary</div>
                    </div>
                    <div className="dashboard-priorities-select">
                        <select
                            value={statsDuration}
                            onChange={(e) => setStatsDuration(e.target.value)}
                            className="dashboard-sectiona-selection"
                        >
                            <option value="2024">2024</option>
                            <option value="2023">2023</option>
                            <option value="2022">2022</option>
                            <option value="2021">2021</option>
                        </select>
                        <DropdownArrow />
                    </div>
                </div>

                <ResponsiveContainer height={300} width="100%">
                    <LineChart
                        width={1000}
                        height={300}
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid horizontal="true" vertical="" strokeDasharray="10 6" />
                        <XAxis dataKey="name" tickMargin={10} />
                        <YAxis tickFormatter={formatYAxis} width={80} />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend verticalAlign="top" height={50} margin={{ left: 100 }} />
                        <Line legendType="dot" type="monotone" dataKey="Pending" stroke="#FF747C" strokeWidth={5} Dot={10} />
                        <Line legendType="dot" type="monotone" dataKey="Received" stroke="#2576BC" strokeWidth={5} />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default BalanceSummary;
