// import "./App.css";x
import "./Appp.css";
// import "./assets/css/layout/Modal.css"
import Auth from "./routes/Auth";
import React, { useEffect } from "react";
import { routingData } from "./routes/routing";
import { Routes, Route } from "react-router-dom";
import PageNotFound from "./pages/PageNotFound";
import RedirectRouter from "./routes/RedirectRouter";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const recursiveRouter = (props) => {
    const { nestedRoutes, routes, isProtected, role } = props;
    const authenticatedRouted = () => {
      return (
        <>
          {routes &&
            routes.map((e, index) => (
              <Route key={index} path={e.path} element={e.component} />
            ))}
          {nestedRoutes &&
            nestedRoutes.map((e, index) => (
              <Route path={e.path} element={e.component} key={index}>
                {recursiveRouter(e)}
              </Route>
            ))}
        </>
      );
    };

    return isProtected ? (
      <Route element={<Auth allowedRoles={role} />}>
        {authenticatedRouted()}
      </Route>
    ) : role === "" ? (
      <Route element={<RedirectRouter />}>{authenticatedRouted()}</Route>
    ) : (
      <>{authenticatedRouted()}</>
    );
  };

  useEffect(() => {
    function handleVisibilityChange() {
      if (document.visibilityState === "hidden") {
        localStorage.removeItem("session_token");
      }
    }

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <>
      <Routes>
        {routingData.map((e, index) => {
          return (
            <React.Fragment key={index.toString()}>
              {recursiveRouter(e)}
            </React.Fragment>
          );
        })}

        {/* Extra Routes */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;
