import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL_PREBID, BASE_URL } from "../../config/axios";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { AddwithWhiteCircle, UpdatePencil, SearchBig, Eye } from "../AllSvg";
import Dashboardnavbarcopy from "../../layout/Dashboardnavbar";
import { formatCurrencyIndian, handleErrorToast } from "../custom";
import { UploadClientDataBulk } from "../BulkModals";



const AddClientDetails = ({ getClient }) => {
    const [formData, setFormData] = useState({
        name: '',
        client_name: '',
        address: '',
        email: '',
        contact_number: '',
        alt_contact_number: '',
        gstin_number: '',
        pos: '',
        contact_person: '',
        registration_certificate: "",
        pr_client: false,
    });

    const [isSubmitted, setIsSubmitted] = useState(false);

    const [show, setShow] = useState(false);
    const [clientData, setClientData] = useState([]);
    const [selectedClient, setSelectedClient] = useState("null");

    const handleShow = () => {

        setShow(true);
        getClients();
    }

    const handleClose = () => {
        setShow(false);

        setFormData({
            name: '',
            client_name: '',
            address: '',
            email: '',
            contact_number: '',
            alt_contact_number: '',
            gstin_number: '',
            pos: '',
            contact_person: '',
            registration_certificate: "",

        })
        setErrors({});
    }

    const [errors, setErrors] = useState({});

    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};


        const requiredFields = [
            'name',
            'address',
            'email',
            'contact_number',
            'gstin_number',
            'pos',
            'contact_person',
            'client_name',

        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);
        setIsSubmitted(true);


        return Object.keys(newErrors).length === 0;
    };


    const getClients = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/project/clientName/`);
            setClientData(res.data);
        } catch (error) {
            console.error(error);

        }
    }

    const validateGSTIN = (gstin) => {
        const gstinPattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[Z]{1}[A-Z0-9]{1}$/;
        if (!gstinPattern.test(gstin)) {
            return false;
        }
        return true;
    };
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            const formDataa = new FormData();
            formDataa.append('name', formData.name);
            formDataa.append('client_name', formData.client_name);
            formDataa.append('address', formData.address);
            formDataa.append('email', formData.email);
            formDataa.append('contact_number', formData.contact_number);
            formDataa.append('alt_contact_number', formData.alt_contact_number);
            formDataa.append('gstin_number', formData.gstin_number);
            formDataa.append('pos', formData.pos);
            formDataa.append('pr_client', formData.pr_client);
            formDataa.append('contact_person', formData.contact_person);
            formDataa.append('registration_certificate', formData.registration_certificate);

            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
            setLoading(true); //loading logic
            try {
                let res = await axios.post(`${BASE_URL}/project/client/`, formDataa, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })

                if (res.status === 200) {
                    getClient();
                    handleClose();
                    toast.dismiss(loadingToastId);
                    toast.success("Client added successfully!");

                } else {
                    alert(res);
                }
            } catch (err) {
                // alert(err);
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }

        }
    };



    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value,
        });

        setFormData({
            ...formData,
            [name]: value,
        });
        if (value.trim()) {
            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[name];
                return updatedErrors;
            });
        }
    };


    const handleFileChange = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];

        if (file) {
            if (file.type === 'application/pdf') {
                setFormData({
                    ...formData,
                    [name]: file,
                });
            } else {
                setErrors({
                    ...errors,
                    [name]: 'Invalid file type. Please select a PDF file.',
                });
            }
        } else {
            setFormData({
                ...formData,
                [name]: null,
            });
        }
    };


    const handleFileClear = (name) => {
        setFormData({
            ...formData,
            [name]: null,
        });
    };



    // Function to handle logo file change
    const handleLogoChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            if (file.type.startsWith('image/')) {
                setFormData({
                    ...formData,
                    logo: file,
                });
            } else {
                setErrors({
                    ...errors,
                    logo: 'Invalid file type. Please select an image file.',
                });
            }
        } else {
            setFormData({
                ...formData,
                logo: null,
            });
        }
    };

    console.log(selectedClient);




    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

            <button className='upload-svg' onClick={handleShow}>
                <AddwithWhiteCircle /> {"Client Details"}
            </button>
            <Modal show={show} onHide={handleClose} dialogClassName="dialog-modal-add-client-details">
                <Modal.Header closeButton>
                    <Modal.Title>Add Client Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className='modal-body-padding'>
                        <form>
                            <div className='form-flex-wrap'>

                                <div className="flex-column margin-top-20px form-group-select">
                                    <label className="">Client Name:<span className="required">*</span>
                                    </label>
                                    <select
                                        name="client_name"
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.client_name ? 'error' : inputState.client_name ? 'success' : ''}`}
                                    >
                                        <option value="">Select a Client</option>
                                        {clientData.map(client => (
                                            <option key={client.title} value={client.title}>
                                                {client.title}
                                            </option>
                                        ))}
                                    </select>


                                    <div
                                        className={`form-group-selection-arrow ${errors.client_name ? 'error-arrow' : ''}`}
                                        style={{ top: errors.client_name && "60px" }}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="25"
                                            fill="currentColor"
                                            className="bi bi-caret-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    {errors.client_name && (
                                        <span className="error-message">{errors.client_name}</span>
                                    )}

                                </div>



                                <div className="flex-column margin-top-20px">
                                    <label>GST Name:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        placeholder="GST Name"
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.name ? 'error' : inputState.name ? 'success' : ''}`}
                                    />
                                    {errors.name && (
                                        <span className="error-message">{errors.name}</span>
                                    )}
                                </div>
                                <div className="flex-column margin-top-20px">
                                    <label>E-Mail:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="email"
                                        value={formData.email}
                                        placeholder="E-Mail"
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.email ? 'error' : inputState.email ? 'success' : ''}`}
                                    />
                                    {errors.email && (
                                        <span className="error-message">{errors.email}</span>
                                    )}
                                </div>
                                <div className="flex-column margin-top-20px">
                                    <label>GST IN Number:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="gstin_number"
                                        maxLength={15}
                                        placeholder="GST Number"
                                        value={formData.gstin_number}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.gstin_number ? 'error' : inputState.gstin_number ? 'success' : ''}`}
                                    />
                                    {errors.gstin_number && (
                                        <span className="error-message">{errors.gstin_number}</span>
                                    )}
                                </div>

                                <div className="flex-column margin-top-20px">
                                    <label>Contact Person:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="contact_person"
                                        placeholder="Contact Person"
                                        value={formData.contact_person}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.contact_person ? 'error' : inputState.contact_person ? 'success' : ''}`}
                                    />
                                    {errors.contact_person && <span className="error-message">{errors.contact_person}</span>}
                                </div>
                                <div className="flex-column margin-top-20px">
                                    <label>Contact Number:<span className="required">*</span></label>
                                    <input
                                        type="number"
                                        min={1}
                                        name="contact_number"
                                        value={formData.contact_number}
                                        placeholder="Contact Number"
                                        onChange={handleInputChange}
                                        onWheel={(e) => e.target.blur()} // Prevent scrolling from affecting the input
                                        className={`form-input ${errors.contact_number ? 'error' : inputState.contact_number ? 'success' : ''}`}
                                    />
                                    {errors.contact_number && (
                                        <span className="error-message">{errors.contact_number}</span>
                                    )}
                                </div>

                                <div className="flex-column margin-top-20px">
                                    <label>Alternate Contact Number:</label>
                                    <input
                                        type="number"
                                        name="alt_contact_number"
                                        min={1}
                                        placeholder="Alternate Contact Number"
                                        onWheel={(e) => e.target.blur()} // Prevent scrolling from affecting the input
                                        value={formData.alt_contact_number}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.alt_contact_number ? 'error' : inputState.alt_contact_number ? 'success' : ''}`}
                                    />
                                </div>


                                <div className="flex-column margin-top-20px">
                                    <label>Address:<span className="required">*</span></label>
                                    <textarea
                                        type="text"
                                        name="address"
                                        placeholder="Address"
                                        value={formData.address}
                                        onChange={handleInputChange}
                                        className={`form-input form-control-field-long ${errors.address ? 'error' : inputState.address ? 'success' : ''}`}
                                    />
                                    {errors.address && (
                                        <span className="error-message">{errors.address}</span>
                                    )}
                                </div>


                                <div className="flex-column margin-top-20px">
                                    <label>Position:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="pos"
                                        placeholder="Position"
                                        value={formData.pos}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.pos ? 'error' : inputState.pos ? 'success' : ''}`}
                                    />
                                    {errors.pos && <span className="error-message">{errors.pos}</span>}
                                </div>


                                <div className="flex-column margin-top-20px">
                                    <label>Registration Certificate (PDF):</label>
                                    <div className={`file-upload-container form-input ${errors.registration_certificate ? 'error' : inputState.registration_certificate ? 'success' : ''}`}>
                                        <label className={`file-label ${errors.registration_certificate ? 'error' : ''}`} style={{ marginTop: "10px" }}>
                                            <input
                                                type="file"
                                                name="registration_certificate"
                                                accept=".pdf"
                                                onChange={handleFileChange}
                                                className={`file-input`}
                                            />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-upload" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z" />
                                                <path fill-rule="evenodd" d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z" />
                                            </svg>

                                            <span className="form-text-trasformation-uppercase" style={{ marginLeft: "20px" }}>
                                                {formData.registration_certificate ? formData.registration_certificate.name : 'No file selected'}
                                            </span>


                                            {formData.registration_certificate ? (<span className="file-clear" onClick={() => handleFileClear('registration_certificate')}>
                                                Clear
                                            </span>) : null}
                                        </label>
                                    </div>

                                </div>

                            </div>
                            <div className="flex-row justify-around" >
                                <div className="button-models">
                                    <button type="button" onClick={handleClose} className="model-button-cancel model-button   font-weight500  ">Cancel</button>
                                </div>

                                <div className="button-models">
                                    <button onClick={handleSubmit} className="model-button model-button-submit">Submit</button>
                                </div>

                            </div>

                            <p className="error-message font-size-text margin-top40px">
                                {isSubmitted && Object.keys(errors).length > 0 && (
                                    Object.keys(errors).length > 3 ? (
                                        <h5 className="text-center" style={{ marginTop: "15px" }} >Please fill all mandatory fields!</h5>
                                    ) : (
                                        Object.keys(errors).map((field) =>
                                            field.charAt(0).toUpperCase() + field.slice(1)
                                        ).join(', ') + ' are required!'
                                    )
                                )}
                            </p>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
const UpdateClientDetails = ({ i, getJobappdata, clientId }) => {

    //   ****************   Modal Section
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setErrors({});
        setShow(false);
    }

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [clientData, setClientData] = useState([]);
    const [selectedClient, setSelectedClient] = useState("null");


    const handleShow = () => {
        setShow(true);
        setFormData(i);
        // getClients();
    }


    const [formData, setFormData] = useState({
        address: '',
        email: '',
        contact_number: '',
        alt_contact_number: '',
        gstin_number: '',
        pos: '',
        contact_person: '',
    });





    const [errors, setErrors] = useState({});

    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};

        // Validation rules
        if (!formData.name.trim()) {
            newErrors.name = 'Name is required';
        }




        const requiredFields = [
            'address',
            'email',
            'contact_number',
            'gstin_number',
            'pos',
            'contact_person',

        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);
        setIsSubmitted(true);


        return Object.keys(newErrors).length === 0;
    };

    const validateGSTIN = (gstin) => {
        // GSTIN format: AAABBBCCCCCDDDE
        const gstinPattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[Z]{1}[A-Z0-9]{1}$/;



        if (!gstinPattern.test(gstin)) {
            return false;
        }

        // Perform GSTIN verification here (if needed)

        return true;
    };

    const getClients = async () => {
        try {
            const res = await axios.get(`${BASE_URL_PREBID}/project/clientName/`);
            setClientData(res.data);
        } catch (error) {
            console.error(error);

        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {

            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(`${BASE_URL_PREBID}/project/clientupdate/${i ? i.id : selectedClient}/`, formData, {
                })

                if (res.status === 200) {
                    await getJobappdata();
                    handleClose();
                    toast.dismiss(loadingToastId);
                    toast.success("Client details updated successfully!");

                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }
            } finally {
                toast.dismiss(loadingToastId);
            }

        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value,
        });

        setFormData({
            ...formData,
            [name]: value,
        });
        if (value.trim()) {
            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[name];
                return updatedErrors;
            });
        }
    };












    return (

        <>
            <button className="model-edit-button" title="Update Details" onClick={handleShow} >
                <UpdatePencil />
            </button>



            <Modal show={show} onHide={handleClose} dialogClassName="dialog-modal-add-client-details">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Update Client details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='modal-body-padding'>
                        <form>
                            <div className='form-flex-wrap'>

                                {/* <div className="flex-column margin-top-20px form-group-select">
                                    <label className="">Client Name:
                                    </label>
                                    <select
                                        name="client"
                                        onChange={(e) => {
                                            const selectedId = e.target.value;
                                            const selectedClientData = clientData.find(client => client.id === selectedId);

                                            if (selectedClientData) {
                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    name: selectedClientData.title, // Automatically update the name field in formData
                                                }));
                                            } else {
                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    name: '', // Clear the name field if no client is selected
                                                }));
                                            }
                                        }}
                                        className={`form-input form-group-selection ${errors.client ? 'error' : inputState.client ? 'success' : ''}`}
                                    >
                                        <option value="">Select a Client</option>
                                        {clientData.map(client => (
                                            <option key={client.id} value={client.id}>
                                                {client.title}
                                            </option>
                                        ))}
                                    </select>


                                    <div
                                        className={`form-group-selection-arrow ${errors.name ? 'error-arrow' : ''}`}
                                        style={{ top: errors.name && "60px" }}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="25"
                                            fill="currentColor"
                                            className="bi bi-caret-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    {errors.name && (
                                        <span className="error-message">{errors.name}</span>
                                    )}

                                </div> */}



                                <div className="flex-column margin-top-20px">
                                    <label>Client Name:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        placeholder="Client Name"
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.name ? 'error' : inputState.name ? 'success' : ''}`}
                                        disabled
                                    />
                                    {errors.name && (
                                        <span className="error-message">{errors.name}</span>
                                    )}
                                </div>
                                <div className="flex-column margin-top-20px">
                                    <label>E-Mail:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="email"
                                        value={formData.email}
                                        placeholder="E-Mail"
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.email ? 'error' : inputState.email ? 'success' : ''}`}
                                    />
                                    {errors.email && (
                                        <span className="error-message">{errors.email}</span>
                                    )}
                                </div>
                                <div className="flex-column margin-top-20px">
                                    <label>GST IN Number:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="gstin_number"
                                        maxLength={15}
                                        placeholder="GST Number"
                                        value={formData.gstin_number}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.gstin_number ? 'error' : inputState.gstin_number ? 'success' : ''}`}
                                    />
                                    {errors.gstin_number && (
                                        <span className="error-message">{errors.gstin_number}</span>
                                    )}
                                </div>

                                <div className="flex-column margin-top-20px">
                                    <label>Contact Person:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="contact_person"
                                        placeholder="Contact Person"
                                        value={formData.contact_person}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.contact_person ? 'error' : inputState.contact_person ? 'success' : ''}`}
                                    />
                                    {errors.contact_person && <span className="error-message">{errors.contact_person}</span>}
                                </div>
                                <div className="flex-column margin-top-20px">
                                    <label>Contact Number:<span className="required">*</span></label>
                                    <input
                                        type="number"
                                        min={1}
                                        name="contact_number"
                                        value={formData.contact_number}
                                        placeholder="Contact Number"
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.contact_number ? 'error' : inputState.contact_number ? 'success' : ''}`}
                                    />
                                    {errors.contact_number && (
                                        <span className="error-message">{errors.contact_number}</span>
                                    )}
                                </div>
                                <div className="flex-column margin-top-20px">
                                    <label>Alternate Contact Number:</label>
                                    <input
                                        type="number"
                                        name="alt_contact_number"
                                        placeholder="Alternate Contact Number"
                                        value={formData.alt_contact_number}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.alt_contact_number ? 'error' : inputState.alt_contact_number ? 'success' : ''}`}
                                    />
                                </div>


                                <div className="flex-column margin-top-20px">
                                    <label>Address:<span className="required">*</span></label>
                                    <textarea
                                        type="text"
                                        name="address"
                                        placeholder="Address"
                                        value={formData.address}
                                        onChange={handleInputChange}
                                        className={`form-input form-control-field-long ${errors.address ? 'error' : inputState.address ? 'success' : ''}`}
                                    />
                                    {errors.address && (
                                        <span className="error-message">{errors.address}</span>
                                    )}
                                </div>


                                <div className="flex-column margin-top-20px">
                                    <label>Position:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="pos"
                                        placeholder="Position"
                                        value={formData.pos}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.pos ? 'error' : inputState.pos ? 'success' : ''}`}
                                    />
                                    {errors.pos && <span className="error-message">{errors.pos}</span>}
                                </div>
                            </div>
                            <div className="flex-row justify-around" >
                                <div className="button-models">
                                    <button type="button" onClick={handleClose} className="model-button-cancel model-button   font-weight500  ">Cancel</button>
                                </div>

                                <div className="button-models">
                                    <button onClick={handleSubmit} className="model-button model-button-submit">Submit</button>
                                </div>

                            </div>

                            <p className="error-message font-size-text margin-top40px">
                                {isSubmitted && Object.keys(errors).length > 0 && (
                                    Object.keys(errors).length > 3 ? (
                                        <h5 className="text-center" style={{ marginTop: "15px" }} >Please fill all mandatory fields!</h5>
                                    ) : (
                                        Object.keys(errors).map((field) =>
                                            field.charAt(0).toUpperCase() + field.slice(1)
                                        ).join(', ') + ' are required!'
                                    )
                                )}
                            </p>
                        </form>
                    </div>

                </Modal.Body>
            </Modal></>
    );
};


const DeleteClient = ({ id, getJobappdata }) => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false); //loading logic

    // console.log(id)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
        setLoading(true); // loading logic
        try {
            let res = await axios.delete(
                `${BASE_URL}/project/clientupdate/${id.id}/`
            );

            if (res.status === 200) {
                await getJobappdata("null");
                handleClose();
                toast.dismiss(loadingToastId);
                toast.success("Client deleted successfully!");
            } else {
                alert(res);
            }
        } catch (err) {

            //toast Logic
            if (err.response) {
                toast.dismiss(loadingToastId);
                const errorData = err.response.data;

                if (typeof errorData.error === 'string') {
                    // Single error message
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    // Multiple error messages
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }
        } finally {
            toast.dismiss(loadingToastId);
        }
    };

    return (
        <>
            <button className="model-delete-button" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                        fill="#F72B50"
                    />
                </svg>
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
                <Modal.Header closeButton>
                    <Modal.Title>Delete Client</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Are You Sure, You want to Delete Client
                        {" "}
                        <span className="font-weight700">
                            {id.name}
                        </span>
                        ?
                    </p>

                    <div className="button-models flex-row justify-around">

                        <button
                            onClick={handleClose}
                            className="model-button font-weight500  model-button-cancel"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleFormSubmitDelete}
                            className="model-button font-weight500  model-button-delete"
                        >
                            Delete
                        </button>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
};


const Allclient = () => {

    const [allClientsData, setAllClientsData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [search1, setSearch1] = useState("")
    const [search2, setSearch2] = useState("")
    const [search3, setSearch3] = useState("")
    const [search4, setSearch4] = useState("")
    const [buffer, setBuffering] = useState(true); //buffering logic
    const [client, setClient] = useState("null");

    const getClientsData = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(`${BASE_URL_PREBID}/project/client/`);
            setAllClientsData(res.data);
        }
        catch (err) {
            handleErrorToast(err)
        } finally {
            setBuffering(false);// End Buffering
        }
    };
    useEffect(() => {
        getClientsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client]);

    //! ***********************Navigate to next page *******************************// 
    const navigate = useNavigate()
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split('/');
    const baseUrl = "/" + urlParts[3] + `/projectbyclient`;

    const allproject = (id) => {
        navigate(baseUrl + `/${id}/`)
    }
    //! ***********************Navigate to next page *******************************//
    const handleSearchChange = (e) => {
        setSearch2(e.target.value);
    };

    const handleSearch = () => {
        let filteredingData = allClientsData

        if (search1 !== '') {
            const lowercaseSelectedValue = search1.toLowerCase();
            filteredingData = filteredingData.filter(i => {
                const projectMatch = i.name.toLowerCase().includes(lowercaseSelectedValue);
                return projectMatch;
            });
        }
        if (search2 !== '') {
            const lowercaseSelectedValue = search2?.toLowerCase();
            filteredingData = filteredingData.filter(i => {
                // const clientMatch = i.client_name?.toLowerCase().includes(lowercaseSelectedValue);
                const name = i.name?.toLowerCase().includes(lowercaseSelectedValue);
                const clientMatchS = i.contact_person?.toLowerCase().includes(lowercaseSelectedValue);
                return name || clientMatchS;
            });
        }
        if (search3) {
            filteredingData = filteredingData.filter(i => {
                const numberMatch = i.contact_number.includes(search3);
                const altnumberMatch = i.alt_contact_number.includes(search3);
                return numberMatch || altnumberMatch;
            });
        }
        if (search4) {
            filteredingData = filteredingData.filter(i => {
                const gstMatch = i.gstin_number.includes(search4);
                return gstMatch;
            });
        }

        setFilteredData(filteredingData);
    };

    useEffect(() => {
        handleSearch();
    }, [search1, search2, search3, search4]);

    return (
        <>

            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

            {/*  ********** Client Details **********  */}
            <div className="">

                <div className="attendance-subcont">

                    <div className="table-searchh table-searchh-transparent">
                        <div>
                            <SearchBig />

                        </div>
                        <div>
                            <input
                                placeholder="Client Name/ Contact Person Name"
                                className="navbar-inputt font-weight400 width-20vw table-searchh-transparent"
                                value={search2}
                                onChange={handleSearchChange}
                            />
                        </div>
                        <hr className="field-cont-hr" />
                    </div>
                    {/* <div title="Client Filter " className="field-cont-div ">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 14 14"
                            fill="none"
                        >
                            <g clip-path="url(#clip0_650_3324)">
                                <path
                                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                                    fill="#707070"
                                />
                                <path
                                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                                    fill="#707070"
                                />
                                <path
                                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                                    fill="#707070"
                                />
                                <path
                                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                                    fill="#707070"
                                />
                                <path
                                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                                    fill="#707070"
                                />
                                <path
                                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                                    fill="#707070"
                                />
                                <path
                                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                                    fill="#707070"
                                />
                                <path
                                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                                    fill="#707070"
                                />
                                <path
                                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                                    fill="#707070"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_650_3324">
                                    <rect width="14" height="14" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <select

                            value={client}
                            onChange={(e) => setClient(e.target.value)}
                            className={`attendance-input-field width-15vw`}
                        >

                            <option value="null">Select Client</option>
                            {allClientsData?.sort((a, b) => a.name?.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (
                                <option key={index} value={i.id}>{i.name}</option>
                            ))}
                        </select>
                        <hr className="field-cont-hr" />

                    </div> */}
                    <div className="btn-cont">
                        <AddClientDetails getClient={getClientsData} />
                        <UploadClientDataBulk getClientData={getClientsData} />
                    </div>
                </div>
                <div className="attendance-box">
                    <div className="datagrid-container">
                        <div className="custom-table-background height-73vh">

                            <div className="table-heading-flex">

                            </div>
                            <div className="table-box">
                                <table className="custom-table">
                                    <thead className="table-heading">
                                        <tr className="custom-table-head-tr font-size-subheading font-weight600">
                                            <th className="table-heading">Sr no.</th>
                                            <th className="table-heading">Client Name</th>
                                            <th className="table-heading">Contact person</th>
                                            <th className="table-heading">Pos</th>
                                            <th className="table-heading">GST</th>
                                            <th className="table-heading">No of projects</th>
                                            <th className="table-heading">Total Amount</th>
                                            <th className="table-heading">Billing Amount</th>
                                            <th className="table-heading">Recevied Amount</th>
                                            <th className="table-heading">Balance Amount</th>
                                            <th className="table-heading">Percentage Due</th>
                                            {/* <th className="table-heading">View Projects </th> */}
                                            <th className="table-heading" style={{ paddingLeft: "45px" }}>Update</th>

                                        </tr>
                                    </thead>
                                    {buffer ? <div className="spinner"></div> : //buffering logic
                                        <tbody>

                                            {allClientsData
                                                ?.filter((e) =>
                                                    e.name?.toLowerCase().includes(search2?.toLowerCase())
                                                )
                                                .sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true }))
                                                .map((i, index) => (
                                                    <React.Fragment key={index}>
                                                        <tr className="tr-border-bottom">
                                                            <td colSpan="8"></td>
                                                        </tr>
                                                        <tr className="custom-table-head-td font-size-subheading font-weight500">
                                                            <td className="table-body">{index + 1}</td>
                                                            <td className="table-body"><div style={{ maxWidth: "120px", whiteSpace: "wrap" }}>{i.name}</div> </td>
                                                            <td className="table-body">{i.contact_person ? i.contact_person : <span className="text-center">-</span>}<br></br>{i.contact_number}</td>
                                                            <td className="table-body">{i.pos}</td>
                                                            <td className="table-body">{i.gstin_number}</td>
                                                            <td className="table-body">{i.project_count ? i.project_count : "0"}</td>
                                                            <td className="table-body">{formatCurrencyIndian(i.total_woamount ? i.total_woamount : "0")}</td>
                                                            <td className="table-body">{formatCurrencyIndian(i.billing_amount ? i.billing_amount : "0")}</td>
                                                            <td className="table-body">{formatCurrencyIndian(i.received_amount ? i.received_amount : "0")}</td>
                                                            <td className="table-body">{formatCurrencyIndian((i.total_woamount - i.received_amount) ? (i.total_woamount - i.received_amount) : "0")}</td>
                                                            <td className="table-body">{i.received_percentage ? i.received_percentage : "0"}</td>
                                                            {/* <td className="table-body">
                                                                <button className="" onClick={(e) => allproject(i.id)}>
                                                                    <Eye />
                                                                </button>
                                                            </td> */}
                                                            <td className="table-body  flex-row">
                                                                <UpdateClientDetails i={i} getJobappdata={getClientsData} />
                                                                <DeleteClient id={i} getJobappdata={getClientsData} />
                                                            </td>

                                                        </tr>
                                                    </React.Fragment>
                                                ))}
                                        </tbody>
                                    }
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export { UpdateClientDetails, AddClientDetails, DeleteClient, Allclient }