import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { BASE_URL_PREBID } from "../../config/axios";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { UpdatePencilWhite, AddwithWhiteCircle } from "../AllSvg";
import { handleErrorToast } from "../custom";
import Select from "react-select";

const Updatestages = ({ id, allEmployeeData }) => {
  //   ****************   Modal Section
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    getClientDetails();
  };

  const [allWorkStagesStats, setAllWorkstagesStatus] = useState([]);

  const getClientDetails = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL_PREBID}/project/workstagesbyprojectDetail/${id}/`
      );
      setInputFields(res.data.length > 0 ? res.data : [{
        employee: [],
        deadline_date: null,
        employee_count: "",
        completed: false,
      }]);
    } catch (err) {
      alert(err.message);
    }
  };


  const [errors, setErrors] = useState({});

  const [inputFields, setInputFields] = useState([{
    employee: [],
    deadline_date: null,
    employee_count: "",
    completed: false,
  }]);

  const options = allEmployeeData?.sort((a, b) => parseInt(a.emp_code) - parseInt(b.emp_code))?.map((e) => ({
    value: e.emp_code,
    label: `${e.emp_code} - ${e.name}`,
  }));


  const handleChange = (index, e) => {
    const { name, value } = e.target;
    setInputFields((prevFields) =>
      prevFields.map((field, idx) =>
        idx === index ? { ...field, [name]: value } : field
      )
    );
  };

  const handleCheckboxChange = (index, e) => {
    const { checked } = e.target;
    setInputFields((prevFields) =>
      prevFields.map((field, idx) =>
        idx === index ? { ...field, completed: checked } : field
      )
    );
  };

  // const handleChangeAssignedTo = (selected, index) => {
  //   console.log("selected")
  //   console.log(selected)
  //   const employee = selected?.map((item) => item.value) || [];
  //   setInputFields((prevFields) =>
  //     prevFields.map((field, idx) =>
  //       idx === index ? { ...field, employee } : field
  //     )
  //   );
  // };

  const handleChangeAssignedTo = (selected, index) => {
    console.log("selected", selected);
    const employee = Array.isArray(selected) ? selected.map((item) => item.value) : [];
    setInputFields((prevFields) =>
      prevFields.map((field, idx) =>
        idx === index ? { ...field, employee } : field
      )
    );
  };



  const [loading, setLoading] = useState(false); //loading logic

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true); //loading logic
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.put(
        `${BASE_URL_PREBID}/project/workstages-update-bulk/`,
        inputFields,
        {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      );

      if (res.status === 200) {
        await getClientDetails();
        handleClose();

      } else {
        alert(res);
      }
    } catch (err) {
      handleErrorToast(err)
    } finally {
      setLoading(false); //loading logic
      toast.dismiss(loadingToastId);
    }
  };

  console.log("inputFields")
  console.log(inputFields)

  return (
    <>
      <button className='upload-svg' onClick={handleShow}>
        <UpdatePencilWhite />
        {/* <AddwithWhiteCircle /> */}
        {" "} Update Stages

      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="dialog-modal-assign-task-small"
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">Update Stages </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              {inputFields?.map((data, index) => (
                <>
                  <h3 className="dashboard-herosection-left-h4">
                    Stage {index + 1}
                  </h3>
                  <div className="flex-column">
                    <div className="flex-row field-cont">
                      <div className="flex-column form-group-select">
                        <label className="form-labels">Stage Name:</label>
                        <input
                          name="workStages"
                          type="text"
                          value={data.workStages}
                          className="form-input"
                          readOnly={data.completed}
                        // onChange={(e) => handleChange(index, e)}
                        />
                      </div>

                      <div className="flex-row">
                        <label className="form-labels">Completed</label>
                        <hr style={{ marginTop: '10px' }} className="field-cont-hr" />
                        <div className="checkbox-toggle">
                          <input
                            id={`toggle${index + 1}`}
                            name="completed"
                            type="checkbox"
                            disabled={(index + 1) === 1}
                            checked={data.completed}
                            onChange={(e) => handleCheckboxChange(index, e)}
                            className="form-checkbox"
                          />
                          <label htmlFor={`toggle${index + 1}`}></label>
                        </div>
                      </div>
                    </div>


                    <div className="flex-row justify-around">
                      <div className="flex-column form-group-select">
                        <label className="form-labels">
                          Employees:<span className="required">*</span>
                        </label>

                        <Select
                          options={options}
                          isMulti
                          onChange={(selected) => handleChangeAssignedTo(selected, index)}
                          value={data.employee?.map((code) => ({
                            value: code,
                            label: options?.find((opt) => opt.value === code)?.label || code,
                          }))}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              width: '20vw',
                              minHeight: '50px',
                              borderRadius: '10px',
                              border: '1px solid #e3e3e3',
                              margin: '10px 25px 28px 0px',
                              color: '#4a4a4a',
                              backgroundColor: '#f7f8fa',
                              padding: '0px 10px',
                            }),
                            placeholder: (provided) => ({
                              ...provided,
                              color: '#4a4a4a',
                            }),
                            menu: (provided) => ({
                              ...provided,
                              borderRadius: '10px',
                              marginTop: '0',
                            }),
                            input: (provided) => ({
                              ...provided,
                              color: '#4a4a4a',
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              color: '#4a4a4a',
                            }),
                          }}
                        />


                      </div>

                      <div className="flex-column form-group-select">
                        <label className="form-labels">
                          Deadline:<span className="required">*</span>
                        </label>
                        <input
                          type="date"
                          name="deadline_date"
                          value={data.deadline_date}
                          onChange={(e) => handleChange(index, e)}
                          className="form-input"
                          style={{ width: "12vw", padding: "3px 10px" }}
                        />
                      </div>
                    </div>


                  </div>
                </>
              ))}


              <form>{/* ...other form elements */}</form>
            </div>

            <div className="button-models">
              <button
                type="submit"
                className="model-button model-button-submit"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Updatestages;
