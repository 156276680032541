import React, { useState, useEffect } from "react";
import axios from "axios";
import Dashboardnavbarcopy from "../../../layout/Dashboardnavbar";
import { BASE_URL, BASE_URL_PREBID } from "../../../config/axios";
import { useLocation, useNavigate } from "react-router-dom";
import { UpdateProjectAmount } from "../../../components/Modals/Allmodals";
import { AddStages, StagesManagement } from "../../../components/Modals/Addstages";
import { ToastContainer, toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import Updatestages from "../../../components/Modals/Updatestages";
import { formatCurrencyIndian, handleErrorToast, customSortByKey, sortProjects, } from "../../../components/custom";
import { CircularGrid, Eye, Sort, SearchBig } from "../../../components/AllSvg"
import Select from 'react-select';


const DeleteProject = ({ id, getJobappdata, }) => {
    const [show, setShow] = useState(false);

    // console.log(id)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [loading, setLoading] = useState(false); //loading logic

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
        setLoading(true); // loading logic
        try {
            console.log("Delete Expense Detail Api");
            let res = await axios.delete(
                `${BASE_URL}/project/projectupdate/${id.pr_code}/`
            );

            if (res.status === 200) {
                await getJobappdata("null");
                setShow(false);
            } else {
                alert(res);
            }
        } catch (err) {

            //toast Logic
            if (err.response) {
                toast.dismiss(loadingToastId);
                const errorData = err.response.data;

                if (typeof errorData.error === 'string') {
                    // Single error message
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    // Multiple error messages
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }
        } finally {
            toast.dismiss(loadingToastId);
        }
    };

    return (
        <>
            <button className="model-delete-button" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                        fill="#F72B50"
                    />
                </svg>
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
                <Modal.Header closeButton>
                    <Modal.Title>Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are You Sure, You want to Delete
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

                    <br />
                    {id.prebid_prcode} - {id.project_name}

                    <div className="button-models">

                        <button
                            onClick={handleFormSubmitDelete}
                            className="model-button model-button-cancel"
                        >
                            Delete
                        </button>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
};

const AllProject = () => {

    const [allProjectssData, setAllProjectsData] = useState([]);
    const [sortedData, setSortedData] = useState([]);
    const [search1, setSearch1] = useState("")

    const [sorting, setSorting] = useState("asc_pr");
    const [prProjects, setPrProjects] = useState("null");
    const [buffer, setBuffering] = useState(true); //buffering logic

    const handleSortChange = (e) => setSorting(e.target.value);


    useEffect(() => {

        const compareFunction = (a, b) => {
            const valA = a.prebid_prcode || ''; // Replace null with an empty string
            const valB = b.prebid_prcode || ''; // Replace null with an empty string
            const valM = a.project_short_name || ''; // Replace null with an empty string
            const valN = b.project_short_name || ''; // Replace null with an empty string

            if (sorting === "asc_pr") {

                return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
            } else if (sorting === "desc_pr") {

                return valB.localeCompare(valA, undefined, { numeric: true, sensitivity: 'base' });
            } else if (sorting === "asc_short_name") {
                // Ascending order
                if (!valM && valN) return 1; // Move null/empty values to the end
                if (valM && !valN) return -1; // Keep non-null values before null
                return valM.localeCompare(valN, undefined, { sensitivity: 'base' });
            }
            else if (sorting === "desc_short_name") {
                // Descending order
                if (!valM && valN) return -1; // Move null/empty values to the end
                if (valM && !valN) return 1; // Keep non-null values before null
                return valN.localeCompare(valM, undefined, { sensitivity: 'base' });
            }
        };

        const sortedData = [...allProjectssData].sort(compareFunction);

        setSortedData(sortedData);
    }, [sorting, allProjectssData]);



    const getProjectData = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(`${BASE_URL_PREBID}/project/allprojectdetailsaccount/`);
            setAllProjectsData(res.data);
        } catch (err) {
            handleErrorToast(err)
        } finally {
            setBuffering(false);// End Buffering
        }

    };

    useEffect(() => {
        getProjectData();
    }, []);

    //! ***********************Navigate to next page *******************************// 
    const navigate = useNavigate()

    const currentUrl = window.location.href;
    const urlParts = currentUrl.split('/');

    const ViewAllProjectDetails = (projectID) => {
        navigate(`/${urlParts[3]}/projectDetailsPage/`, {
            state: { projectID },
        });
    };
    //! ***********************Navigate to next page *******************************// 



    const handleSearch = () => {
        let filteredingData = allProjectssData

        if (search1 !== "") {
            const lowercasesearch1 = search1?.toString().toLowerCase();
            filteredingData = filteredingData.filter(
                (employee) => {
                    const codeMatch = employee?.prebid_prcode
                        ?.toString().toLowerCase()
                        .includes(lowercasesearch1.toLowerCase());

                    const projectMatch = employee?.project_name
                        ?.toString().toLowerCase()
                        .includes(lowercasesearch1.toLowerCase());

                    const shortProjectMatch = employee?.project_short_name
                        ?.toString().toLowerCase()
                        .includes(lowercasesearch1.toLowerCase());

                    return codeMatch || projectMatch || shortProjectMatch;
                }
            );
        }
        setSortedData(filteredingData);
    };

    useEffect(() => {
        handleSearch();
    }, [search1]);

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

            <Dashboardnavbarcopy url="Dashboard" name={prProjects === "null" ? "All Projects" : prProjects === "false" ? "Projects" : "PR Projects"} />


            {/*  ********** Client Details **********  */}
            <div className="content-tabs">

                <div className="attendance-subcont">

                    <div className="field-cont">

                        <div title="Project Filter " className="field-cont-div ">
                            <CircularGrid />

                            <select
                                value={prProjects}
                                onChange={(e) => setPrProjects(e.target.value)}
                                className={`attendance-input-field width-15vw`}
                            >
                                <option value="null">Select Project Type</option>
                                <option value="false">Projects</option>
                                <option value="true">PR Projects</option>

                            </select>
                            <hr className="field-cont-hr" />

                        </div>

                        <div title="Sort Filter" className="field-cont-div">
                            <Sort />
                            <select
                                className="attendance-input-field width-15vw   date-field"
                                type="text"
                                value={sorting}
                                onChange={handleSortChange}
                            >
                                <option value="asc_pr">Asc. Project Code</option>
                                <option value="desc_pr">Desc. Project Code</option>
                                <option value="asc_short_name">Asc. Project Name</option>
                                <option value="desc_short_name">Desc. Project Name</option>
                            </select>
                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">
                            <SearchBig />
                            <input
                                className="attendance-input-field width-15vw"
                                placeholder="BY Project Code/Name"
                                type="text"
                                value={search1}
                                onChange={(e) => setSearch1(e.target.value)}
                            />
                            <hr className="field-cont-hr" />
                        </div>

                    </div>
                    <div className="btn-cont">
                    </div>
                </div>
                <div className="attendance-box">
                    <div className="datagrid-container">
                        <div className="custom-table-background height-73vh">
                            <div className="table-heading-flex">

                            </div>
                            <div className="table-box">
                                <table className="custom-table">
                                    <thead className="table-heading">
                                        <tr className="custom-table-head-trr font-size-subheading font-weight400">
                                            <th className="table-heading-text">Sr no.</th>
                                            <th className="table-heading-text text-center">Project Code</th>
                                            <th className="table-heading-text width-5vw">Project Name</th>
                                            <th className="table-heading-text text-center">Client Wise Details</th>
                                            <th className="align-center font-size-text font-weight600">View Project Details</th>
                                        </tr>
                                    </thead>
                                    {buffer ? <div className="spinner"></div> : //buffering logic
                                        <tbody>
                                            {sortedData
                                                .map((i, index) => (
                                                    <React.Fragment key={index}>

                                                        <tr className="tr-border-bottom">
                                                            <td colSpan="8"></td>
                                                        </tr>
                                                        <tr className="custom-table-head-tddd font-size-label font-weight500">
                                                            <td className="table-body-text">{index + 1}</td>
                                                            <td className="table-body-text">{i.prebid_prcode}</td>
                                                            {/* <td className="table-body-text width-5vw">
                                                                {i.project_short_name ? i.project_short_name : ""}
                                                            </td> */}
                                                            <td className="table-body-text">
                                                                <div className="field-view">
                                                                    {i.project_short_name ? i.project_short_name : i.project_name}
                                                                </div>
                                                            </td>
                                                            {prProjects !== "null" ? (
                                                                <td className="table-body-text">
                                                                    {prProjects === "false" && i.pr_project_false_clients?.length > 0 ? (
                                                                        <div className="employee-card-image">
                                                                            <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                                                                <thead>
                                                                                    <tr className="">
                                                                                        <td className="padding-bottom-5px">Client Name</td>
                                                                                        <th style={{ padding: "0px 4px" }}>|</th>
                                                                                        <td className="padding-bottom-5px">Work Order Amt.</td>
                                                                                        <th style={{ padding: "0px 4px" }}>|</th>
                                                                                        <td className="padding-bottom-5px">GST Amt.</td>
                                                                                        <th style={{ padding: "0px 4px" }}>|</th>
                                                                                        <td className="padding-bottom-5px">Total Amt.</td>
                                                                                        <th style={{ padding: "0px 4px" }}>|</th>
                                                                                        <td className="padding-bottom-5px">Total Invoices</td>
                                                                                        <th style={{ padding: "0px 4px" }}>|</th>
                                                                                        <td className="padding-bottom-5px">Total Billing Amt.</td>
                                                                                        <th style={{ padding: "0px 4px" }}>|</th>
                                                                                        <td className="padding-bottom-5px">Total Outstanding Amt.</td>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {i.pr_project_false_clients.map((clientdata) => (
                                                                                        <tr key={clientdata.id}>
                                                                                            <td className="padding-bottom-5px">{clientdata.client_names}</td>
                                                                                            <th style={{ padding: "0px 4px" }}>|</th>
                                                                                            <td className="padding-bottom-5px">{formatCurrencyIndian(clientdata.woamount)}</td>
                                                                                            <th style={{ padding: "0px 4px" }}>|</th>
                                                                                            <td className="padding-bottom-5px">{formatCurrencyIndian(clientdata.without_gst_amount)}</td>
                                                                                            <th style={{ padding: "0px 4px" }}>|</th>
                                                                                            <td className="padding-bottom-5px">{formatCurrencyIndian(clientdata.woamount)}</td>
                                                                                            <th style={{ padding: "0px 4px" }}>|</th>
                                                                                            <td className="padding-bottom-5px">{clientdata.invoice_summary?.total_invoices}</td>
                                                                                            <th style={{ padding: "0px 4px" }}>|</th>
                                                                                            <td className="padding-bottom-5px">{formatCurrencyIndian(clientdata.invoice_summary?.total_bill_amount)}</td>
                                                                                            <th style={{ padding: "0px 4px" }}>|</th>
                                                                                            <td className="padding-bottom-5px">{formatCurrencyIndian(clientdata.invoice_summary?.total_outstanding_amount)}</td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    ) : prProjects === "true" && i.pr_project_true_clients?.length > 0 ? (
                                                                        <div className="employee-card-image">
                                                                            <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                                                                <thead>
                                                                                    <tr className="">
                                                                                        <td className="padding-bottom-5px">Client Name</td>
                                                                                        <th style={{ padding: "0px 4px" }}>|</th>
                                                                                        <td className="padding-bottom-5px">Work Order Amt.</td>
                                                                                        <th style={{ padding: "0px 4px" }}>|</th>
                                                                                        <td className="padding-bottom-5px">GST Amt.</td>
                                                                                        <th style={{ padding: "0px 4px" }}>|</th>
                                                                                        <td className="padding-bottom-5px">Total Amt.</td>
                                                                                        <th style={{ padding: "0px 4px" }}>|</th>
                                                                                        <td className="padding-bottom-5px">Total Invoices</td>
                                                                                        <th style={{ padding: "0px 4px" }}>|</th>
                                                                                        <td className="padding-bottom-5px">Total Billing Amt.</td>
                                                                                        <th style={{ padding: "0px 4px" }}>|</th>
                                                                                        <td className="padding-bottom-5px">Total Outstanding Amt.</td>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {i.pr_project_true_clients.map((clientdata) => (
                                                                                        <tr key={clientdata.id} className='table-row-highlight-green'>
                                                                                            <td className="padding-bottom-5px">PR-{clientdata.client_names}</td>
                                                                                            <th style={{ padding: "0px 4px" }}>|</th>
                                                                                            <td className="padding-bottom-5px">{formatCurrencyIndian(clientdata.woamount)}</td>
                                                                                            <th style={{ padding: "0px 4px" }}>|</th>
                                                                                            <td className="padding-bottom-5px">{formatCurrencyIndian(clientdata.without_gst_amount)}</td>
                                                                                            <th style={{ padding: "0px 4px" }}>|</th>
                                                                                            <td className="padding-bottom-5px">{formatCurrencyIndian(clientdata.woamount)}</td>
                                                                                            <th style={{ padding: "0px 4px" }}>|</th>
                                                                                            <td className="padding-bottom-5px">{clientdata.invoice_summary?.total_invoices}</td>
                                                                                            <th style={{ padding: "0px 4px" }}>|</th>
                                                                                            <td className="padding-bottom-5px">{formatCurrencyIndian(clientdata.invoice_summary?.total_bill_amount)}</td>
                                                                                            <th style={{ padding: "0px 4px" }}>|</th>
                                                                                            <td className="padding-bottom-5px">{formatCurrencyIndian(clientdata.invoice_summary?.total_outstanding_amount)}</td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    ) : (
                                                                        <div className="align-center ">
                                                                            <p className="text-center">-</p>
                                                                        </div>
                                                                    )}
                                                                </td>)
                                                                : (
                                                                    <td className="table-body-text">
                                                                        {i.pr_project_false_clients?.length > 0 ? (
                                                                            <div className="employee-card-image">
                                                                                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                                                                    <thead>
                                                                                        <tr className="">
                                                                                            <td className="padding-bottom-5px">Client Name</td>
                                                                                            <th style={{ padding: "0px 4px" }}>|</th>
                                                                                            <td className="padding-bottom-5px">Work Order Amt.</td>
                                                                                            <th style={{ padding: "0px 4px" }}>|</th>
                                                                                            <td className="padding-bottom-5px">GST Amt.</td>
                                                                                            <th style={{ padding: "0px 4px" }}>|</th>
                                                                                            <td className="padding-bottom-5px">Total Amt.</td>
                                                                                            <th style={{ padding: "0px 4px" }}>|</th>
                                                                                            <td className="padding-bottom-5px">Total Invoices</td>
                                                                                            <th style={{ padding: "0px 4px" }}>|</th>
                                                                                            <td className="padding-bottom-5px">Total Billing Amt.</td>
                                                                                            <th style={{ padding: "0px 4px" }}>|</th>
                                                                                            <td className="padding-bottom-5px">Total Outstanding Amt.</td>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {i.pr_project_false_clients?.map((clientdata) => (
                                                                                            <tr key={clientdata.id}>
                                                                                                <td className="padding-bottom-5px">{clientdata.client_names}</td>
                                                                                                <th style={{ padding: "0px 4px" }}>|</th>
                                                                                                <td className="padding-bottom-5px">{formatCurrencyIndian(clientdata.woamount)}</td>
                                                                                                <th style={{ padding: "0px 4px" }}>|</th>
                                                                                                <td className="padding-bottom-5px">{formatCurrencyIndian(clientdata.without_gst_amount)}</td>
                                                                                                <th style={{ padding: "0px 4px" }}>|</th>
                                                                                                <td className="padding-bottom-5px">{formatCurrencyIndian(clientdata.woamount)}</td>
                                                                                                <th style={{ padding: "0px 4px" }}>|</th>
                                                                                                <td className="padding-bottom-5px">{clientdata.invoice_summary?.total_invoices}</td>
                                                                                                <th style={{ padding: "0px 4px" }}>|</th>
                                                                                                <td className="padding-bottom-5px">{formatCurrencyIndian(clientdata.invoice_summary?.total_bill_amount)}</td>
                                                                                                <th style={{ padding: "0px 4px" }}>|</th>
                                                                                                <td className="padding-bottom-5px">{formatCurrencyIndian(clientdata.invoice_summary?.total_outstanding_amount)}</td>
                                                                                            </tr>
                                                                                        ))}
                                                                                        {i.pr_project_true_clients?.map((clientdata) => (
                                                                                            <tr key={clientdata.id} className="table-row-highlight-green">
                                                                                                <td className="padding-bottom-5px">PR-{clientdata.client_names}</td>
                                                                                                <th style={{ padding: "0px 4px" }}>|</th>
                                                                                                <td className="padding-bottom-5px">{formatCurrencyIndian(clientdata.woamount)}</td>
                                                                                                <th style={{ padding: "0px 4px" }}>|</th>
                                                                                                <td className="padding-bottom-5px">{formatCurrencyIndian(clientdata.without_gst_amount)}</td>
                                                                                                <th style={{ padding: "0px 4px" }}>|</th>
                                                                                                <td className="padding-bottom-5px">{formatCurrencyIndian(clientdata.woamount)}</td>
                                                                                                <th style={{ padding: "0px 4px" }}>|</th>
                                                                                                <td className="padding-bottom-5px">{clientdata.invoice_summary?.total_invoices}</td>
                                                                                                <th style={{ padding: "0px 4px" }}>|</th>
                                                                                                <td className="padding-bottom-5px">{formatCurrencyIndian(clientdata.invoice_summary?.total_bill_amount)}</td>
                                                                                                <th style={{ padding: "0px 4px" }}>|</th>
                                                                                                <td className="padding-bottom-5px">{formatCurrencyIndian(clientdata.invoice_summary?.total_outstanding_amount)}</td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>

                                                                        ) : (
                                                                            <div className="align-center ">
                                                                                <p className="text-center">-</p>
                                                                            </div>
                                                                        )}
                                                                    </td>)}


                                                            {/* <td className="align-center font-size-text font-weight400">
                                                                <button className="models-button model-add" onClick={(e) => allproject(i.pr_code)}>
                                                                    <Eye />
                                                                </button>
                                                            </td> */}
                                                            <td className="align-center text-center"                              >
                                                                <button title="View Project Detail Page" className="width-5vw" onClick={(e) => ViewAllProjectDetails(i.pr_code)}>
                                                                    <Eye />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                ))}
                                        </tbody>
                                    }
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AllProject
