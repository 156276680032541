import React, { useEffect, useState } from "react";
import { BASE_URL_PREBID } from "../../config/axios";
import axios from "axios";

import { formatDate } from "../Criticalissue/Date";

const NewProjects = () => {
    const [newprojectdata, setNewprojectdata] = useState([]);

    useEffect(() => {
        const getnewprojectdata = async () => {
            try {
                const res = await axios.get(`${BASE_URL_PREBID}/project/newprojects/`);
                const sortedData = res.data.sort((a, b) => new Date(b.wo_date) - new Date(a.wo_date));
                setNewprojectdata(sortedData);
            } catch (err) {
                // alert(err.message);
            }
        };
        getnewprojectdata();
    }, []);

    const currentUrl = window.location.href;
    const urlParts = currentUrl.split('/');
    const baseUrl = "/" + urlParts[3];

    return (
        <div className="invoice-cont  width-45vw height50vh cont-col cards-shadow2 bgwhite newproject-table-cont">
            <div className="justify-between cont-row align-cen bgwhite mary-10 mary10">
                <div>
                    <div className="repo-heading font-size-heading font-weight500card-heading font-sizeheading">New Projects</div>
                </div>
                <a href={baseUrl + "/allprojectdetails"} className="allprojectslink">All Projects</a>
            </div>

            <div className="global-table-cont">
                <table className="custom-table">
                    <tbody>
                        {newprojectdata.map((items, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <hr style={{ color: "#E8E8E8" }} />
                                    <div className="cont-col gap10 padx10 pady20">
                                        <div className="font-size-heading font-weight500">
                                            {items.project_short_name ? items.project_short_name : items.project_name}
                                        </div>
                                        <div className="cont-row gap20 projectdetail">
                                            <div>{items.client_name}</div>
                                            <div>{items.concern_person_name}</div>
                                            <div>
                                                {formatDate(items.wo_date)}
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};


export default NewProjects