import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL, BASE_URL_PREBID } from "../../config/axios";
import { formatDate, formattedDate, formattedDateLong } from "../Date";
import Select from "react-select";
import usePermission from "../../config/permissions";
import ExpenseDetailsAdd from "./Expense";
import { customSortByKey, formatCurrencyIndian, formatDateTime, handleErrorToast } from "../custom";
import { DropdownArrow } from "../AllSvg";

const ImprestAdd = ({ getImprest }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFormData({
      date: "",
      empcode: sessionStorage.getItem("employee_id"),
      project_name: "",
      transportation: "0",
      accomodation: "0",
      fooding: "0",
      other_name: "",
      other: "",
      no_of_days: "",
      person: "",
      // person: preselectedOption ? [preselectedOption.value] : "",
      remarks: "",
      other_details: [
        // {
        //   other_name: "",
        //   amount: "0",
        //   approved_other: 0,
        //   remaining_other: 0
        // },
      ],
    })
    setShow(false)
  };
  const handleShow = () => setShow(true);
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();

  const [formData, setFormData] = useState({
    date: "",
    empcode: sessionStorage.getItem("employee_id"),
    project_id: "",
    project_name: "",
    transportation: "0",
    accomodation: "0",
    fooding: "0",
    other_name: "",
    other: "",
    no_of_days: "",
    person: "",
    remarks: "",
    other_details: [],
  });

  // console.log("formData");
  // console.log(formData);
  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "date",
      "project_id",
      "project_name",
      "no_of_days",
      // "person"
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;

  //   setInputState({
  //     ...inputState,
  //     [name]: value.trim() ? "green" : "",
  //   });

  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "project_id") {
      const selectedProject = projectList.find((project) => project.pr_code === value);

      if (selectedProject) {
        const fullProjectName = `${selectedProject.prebid_prcode}-${selectedProject.project_short_name}`;
        let projectName = fullProjectName;
        if (fullProjectName.length > 200) {
          const maxShortNameLength = 200 - selectedProject.prebid_prcode.length - 4;
          const truncatedShortName = selectedProject.project_short_name.slice(0, Math.max(maxShortNameLength, 0));
          projectName = `${selectedProject.prebid_prcode}-${truncatedShortName}...`;
        }

        setFormData({
          ...formData,
          project_id: value,
          project_name: projectName,
        });

        setInputState({
          ...inputState,
          project_id: "green",
          project_name: "green",
        });
      } else {
        setFormData({
          ...formData,
          project_id: value,
          project_name: "",
        });

        setInputState({
          ...inputState,
          project_id: value.trim() ? "green" : "",
          project_name: "",
        });
      }
    } else {
      setInputState({
        ...inputState,
        [name]: value.trim() ? "green" : "",
      });

      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleOtherInputChange = (index, e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => {
      const newOthers = [...prevFormData.other_details];
      newOthers[index] = {
        ...newOthers[index],
        [name]: value
      };
      return {
        ...prevFormData,
        other_details: newOthers
      };
    });
  };

  const handleAddOthers = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      other_details: [
        ...prevFormData.other_details,
        {
          other_name: "",
          amount: "0",
          approved_other: 0,
          remaining_other: 0
        },
      ]
    }));
  };

  const handleRemoveOthers = (index) => {
    setFormData((prevFormData) => {
      const newOthers = [...prevFormData.other_details];
      newOthers.splice(index, 1);
      return {
        ...prevFormData,
        other_details: newOthers
      };
    });
  };

  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const updatedFormData = {
      ...formData,
      person: formData.multipleperson?.length,
    };

    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.post(
          `${BASE_URL}/wfm/imprest/`,
          updatedFormData
        );

        if (res.status === 200) {
          await getImprest();
          handleClose()
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  useEffect(() => {
    getAllempdata();
    getFilterProjects();
  }, []);

  const [allemployeedata, setAllemployee] = useState([]);

  const getAllempdata = async () => {
    try {
      const res = await axios.get(
        PERMISSION_AUTHORITY ?
          `${BASE_URL}/wfm/ourcompanyempdetailsactive/null/`
          :
          `${BASE_URL}/wfm/ourcompanyempdetailsactive/${sessionStorage.getItem("company_id")}/`
      );
      const sortedList = customSortByKey(res.data, "employee_id");

      setAllemployee(sortedList);
      // setAllemployee(res.data);
    } catch (err) {
      alert(err.message);
    }
  };

  const [projectList, setProjectList] = useState([]);
  const [buffer, setBuffering] = useState(true); //buffering logic
  const getFilterProjects = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      // const res = await axios.get(`${BASE_URL_PREBID}/project/filterprproject/false/`);
      const res = await axios.get(`${BASE_URL_PREBID}/project/projectfilterbysubcompany/null/`);
      setProjectList(res.data);
    } catch (err) {
      handleErrorToast(err)
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  const options = allemployeedata
    .sort((a, b) => parseInt(a.emp_code) - parseInt(b.emp_code))
    .map((e) => ({
      value: e.emp_code,
      label: `${e.emp_code} - ${e.name}`,
    }));

  const preselectedOption = options.find((option) => option.value === sessionStorage.getItem("employee_id"));

  // useEffect(() => {
  //   if (preselectedOption && (!formData.person || !formData.person.includes(preselectedOption.value))) {
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       person: [preselectedOption.value],
  //     }));
  //   }
  // }, [preselectedOption]);

  const handleChangecc = (selected) => {
    const multipleperson = selected.length > 0 ? selected?.map((item) => item.value) : "";
    setFormData((prevState) => ({
      ...prevState,
      multipleperson,
    }));
  };

  return (
    <>
      <button title="Add Imperest" className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        {/* <Modal.Header closeButton>
          <Modal.Title>Request Leave</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <h6 className="task-Tab-heading   font-weight500    font-size-heading">Imprest Request</h6>
              <div className="flex-column">
                <label htmlFor="date" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Date<span className="required">*</span>
                </label>
                <input
                  id="date"
                  type="date"
                  name="date"
                  onChange={handleInputChange}
                  value={formData.date}
                  className={`form-input ${errors.date ? "error" : inputState.date ? "success" : ""
                    }`}
                />
                {errors.date && (
                  <span className="error-message font-size-text ">{errors.date}</span>
                )}
              </div>

              <div className="flex-column form-group-select">
                <label className="form-labels  font-weight500    font-size-subheading">
                  For Project<span className="required">*</span>
                </label>
                <select
                  name="project_id"
                  onChange={handleInputChange}
                  value={formData.project_id}
                  className={`form-input form-group-selection form-input-background ${errors.imprest ? "error" : inputState.imprest ? "success" : ""
                    }`}>
                  <option value="">Select Project</option>
                  {projectList?.sort((a, b) => a.project_short_name?.localeCompare(b.project_short_name, 'en', { ignorePunctuation: true })).map((i, index) => (
                    <option key={index} value={i.pr_code}>{i.prebid_prcode}-{i.project_short_name}</option>
                  ))}
                </select>
                <DropdownArrow />
                {errors.project_id && (
                  <span className="error-message font-size-text ">{errors.project_id}</span>
                )}
              </div>

              <div className="flex-column">
                <label
                  htmlFor="project_name"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Project Code & Name <span className="required">(View-Only)</span>
                </label>
                <input
                  id="project_name"
                  type="text"
                  name="project_name"
                  readOnly
                  style={{ cursor: 'not-allowed' }}
                  placeholder="Code & Name"
                  onChange={handleInputChange}
                  value={formData.project_name}
                  className={`form-input ${errors.project_name
                    ? "error"
                    : inputState.project_name
                      ? "success"
                      : ""
                    }`}
                />
                {errors.project_name && (
                  <span className="error-message font-size-text ">{errors.project_name}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="total" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Total Expense (Auto)
                </label>
                <input
                  id="total"
                  type="number"
                  placeholder="5000"
                  value={
                    Number(formData.transportation) +
                    Number(formData.accomodation) +
                    Number(formData.fooding) +
                    formData.other_details?.reduce((total, other) => total + Number(other.amount || 0), 0)
                  }
                  readOnly
                  style={{ cursor: 'not-allowed' }}
                  className="form-input   font-weight500   font-size-subheading"
                />
              </div>

              <div className="flex-column">
                <label
                  htmlFor="transportation"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Transportation
                </label>
                <input
                  id="transportation"
                  type="number"
                  name="transportation"
                  placeholder="0"
                  min={0}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleInputChange}
                  value={formData.transportation}
                  className={`form-input ${errors.transportation
                    ? "error"
                    : inputState.transportation
                      ? "success"
                      : ""
                    }`}
                />
                {errors.transportation && (
                  <span className="error-message font-size-text ">{errors.transportation}</span>
                )}
              </div>

              <div className="flex-column">
                <label
                  htmlFor="accomodation"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Accommodation
                </label>
                <input
                  id="accomodation"
                  type="number"
                  name="accomodation"
                  placeholder="0"
                  min={0}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleInputChange}
                  value={formData.accomodation}
                  className={`form-input ${errors.accomodation
                    ? "error"
                    : inputState.accomodation
                      ? "success"
                      : ""
                    }`}
                />
                {errors.accomodation && (
                  <span className="error-message font-size-text ">{errors.accomodation}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Food
                </label>
                <input
                  id="fooding"
                  type="number"
                  name="fooding"
                  placeholder="0"
                  min={0}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleInputChange}
                  value={formData.fooding}
                  className={`form-input ${errors.fooding
                    ? "error"
                    : inputState.fooding
                      ? "success"
                      : ""
                    }`}
                />
                {errors.fooding && (
                  <span className="error-message font-size-text ">{errors.fooding}</span>
                )}
              </div>

              <label htmlFor="other_name" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                Other Expenses
              </label>
              {formData.other_details.map((other, index) => (
                <div className="content-tabs" key={index}>
                  <div className="flex-column">
                    <div className="expense-req">
                      <div className="flex-column">
                        <label htmlFor={`other_name_${index}`} className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                          Other Expense Name
                        </label>
                      </div >
                      {index !== 0 && (
                        <div >
                          <button type='button' title="Remove" className="flex-row action-div" onClick={() => handleRemoveOthers(index)}>
                            {/*  <button type='button' onClick={() => handleSRemoveDocument(index)}>Remove</button> */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                stroke="#707070"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M15 9L9 15"
                                stroke="#707070"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M9 9L15 15"
                                stroke="#707070"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                      )}
                    </div>
                    <input
                      id={`other_name_${index}`}
                      type="text"
                      name="other_name"
                      placeholder="Other Expense Name"
                      onChange={(e) => handleOtherInputChange(index, e)}
                      value={other.other_name}
                      className={`form-input`}
                    />
                  </div>

                  <div className="flex-column">
                    <label htmlFor={`amount_${index}`} className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                      {other.other_name ? `${other.other_name} Expenses` : 'Other Expenses'}
                    </label>
                    <input
                      id={`amount_${index}`}
                      type="number"
                      name="amount"
                      placeholder="0"
                      min={0}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => handleOtherInputChange(index, e)}
                      value={other.amount}
                      className={`form-input`}
                    />

                  </div>

                </div>

              ))}
              <button type='button' title="Add Document" className="jd-heading-main font-weight600 -right-button" onClick={handleAddOthers}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_450_2151)">
                    <path
                      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                      fill="#2576BC"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_450_2151">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Add An Other Expense
              </button>


              <div className="flex-column">
                <label
                  htmlFor="no_of_days"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Numbers of days<span className="required">*</span>
                </label>
                <input
                  id="no_of_days"
                  type="number"
                  placeholder="Days"
                  name="no_of_days"
                  min={0}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleInputChange}
                  value={formData.no_of_days}
                  className={`form-input ${errors.no_of_days
                    ? "error"
                    : inputState.no_of_days
                      ? "success"
                      : ""
                    }`}
                />
                {errors.no_of_days && (
                  <span className="error-message font-size-text ">{errors.no_of_days}</span>
                )}
              </div>


              <div className="flex-column">
                <label htmlFor="person" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Name of Person<span className="required">*</span>
                </label>

                <Select
                  options={options}
                  isMulti
                  // value={options.filter((option) => formData.person.includes(option.value))}
                  onChange={handleChangecc}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: '33vw',
                      // height: '50px',
                      borderRadius: '10px',
                      border: '1px solid #e3e3e3',
                      margin: '10px 0px 20px 0px',
                      color: '#4a4a4a',
                      backgroundColor: '#f7f8fa',
                      padding: '0px 10px',
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: '#4a4a4a',
                    }),
                    menu: (provided) => ({
                      ...provided,
                      borderRadius: '10px',
                      marginTop: '0',
                    }),
                    input: (provided) => ({
                      ...provided,
                      color: '#4a4a4a',
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: '#4a4a4a',
                    }),
                  }}

                />
                {errors.person && (
                  <span className="error-message font-size-text ">{errors.person}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="person" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Number's of Person (Auto)
                </label>
                <input
                  id="person"
                  type="number"
                  name="person"
                  // onChange={handleInputChange}
                  value={formData.multipleperson?.length}
                  readOnly
                  style={{ cursor: 'not-allowed' }}
                  className={`form-input ${errors.person ? "error" : inputState.person ? "success" : ""
                    }`}
                />
                {errors.person && (
                  <span className="error-message font-size-text ">{errors.person}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Remarks
                </label>
                <textarea
                  id="remarks"
                  type="text"
                  name="remarks"
                  placeholder="Your feedback"
                  onChange={handleInputChange}
                  // maxLength={200}
                  value={formData.remarks}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.remarks
                    ? "error"
                    : inputState.remarks
                      ? "success"
                      : ""
                    }`}
                />
                {errors.remarks && (
                  <span className="error-message font-size-text ">{errors.remarks}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};

const ImprestModelDelete = ({ id, getImprest }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [loading, setLoading] = useState(false); //loading logic

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();

    setLoading(true); //loading logic
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.delete(`${BASE_URL}/wfm/imprest/${id.id}/`);

      if (res.status === 200) {
        await getImprest();
        handleClose()
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
      setLoading(false); //loading logic
    }
  };

  return (
    <>
      <button title="Delete Imprest Request" className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Imprest</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to Delete this Imprest Request
            <div className="button-models">
              <button className="model-button   font-weight500    " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
                disabled={loading}
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const ExpenseAdd = ({ getExpansee }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setImprestExist(null)
    setShow(false)
  };
  // const handleShow = () => {
  //   getAllempdata()
  //   setShow(true)
  // };
  const handleShow = () => {
    getAllempdata(); // Fetch all employee data

    const empCode = sessionStorage.getItem("employee_id");
    const preSelectedOptions = allemployeedata
      .filter(employee => employee.emp_code === empCode)
      .map(employee => ({
        value: employee.emp_code,
        label: `${employee.emp_code} - ${employee.name}`
      }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      empcode: empCode,
      multipleperson: preSelectedOptions.map(option => option.value),
      // person: preSelectedOptions.map(option => option.value),
    }));

    setSelectedOptions(preSelectedOptions); // Set default selected options in the Select component
    setShow(true);
  };
  const [selectedOptions, setSelectedOptions] = useState([]);

  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();


  const [formData, setFormData] = useState({
    name: "",
    imprest: "",
    date: formattedDate,
    empcode: sessionStorage.getItem("employee_id"),
    project_id: "",
    project_name: "",
    transportation: 0,
    accomodation: 0,
    fooding: 0,
    approved_transportation: 0,
    approved_accomodation: 0,
    approved_fooding: 0,
    other_name: "",
    other: 0,
    other_details: [],
    no_of_days: "",
    person: 0,
    remarks: "",
    imprest_open: false,
  });
  // console.log("formData");
  // console.log(formData);

  const [imprestExist, setImprestExist] = useState(null);

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "name",
      imprestExist ? "imprest" : "project_name",
      imprestExist ? null : "project_id",
      imprestExist ? null : "no_of_days",
    ].filter(Boolean);
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;

  //   setInputState({
  //     ...inputState,
  //     [name]: value.trim() ? "green" : "",
  //   });

  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "project_id") {
      const selectedProject = projectList.find((project) => project.pr_code === value);

      if (selectedProject) {
        const fullProjectName = `${selectedProject.prebid_prcode}-${selectedProject.project_short_name}`;
        let projectName = fullProjectName;
        if (fullProjectName.length > 200) {
          const maxShortNameLength = 200 - selectedProject.prebid_prcode.length - 4;
          const truncatedShortName = selectedProject.project_short_name.slice(0, Math.max(maxShortNameLength, 0));
          projectName = `${selectedProject.prebid_prcode}-${truncatedShortName}...`;
        }

        setFormData({
          ...formData,
          project_id: value,
          project_name: projectName,
        });

        setInputState({
          ...inputState,
          project_id: "green",
          project_name: "green",
        });
      } else {
        setFormData({
          ...formData,
          project_id: value,
          project_name: "",
        });

        setInputState({
          ...inputState,
          project_id: value.trim() ? "green" : "",
          project_name: "",
        });
      }
    } else {
      setInputState({
        ...inputState,
        [name]: value.trim() ? "green" : "",
      });

      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const updatedFormData = {
      ...formData,
      person: formData?.multipleperson?.length,
      imprest_open: true,
      rh_leave_status: 'approved',
      admin_leave_status: 'approved',
      account_leave_status: 'approved',
      amount_change_reason: 'Confirmed',
    };
    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        const url = imprestExist
          ? `${BASE_URL}/wfm/expense/`
          : `${BASE_URL}/wfm/createImprestAndExpense/`;
        const data = imprestExist ? formData : updatedFormData;
        let res = await axios.post(url, data);

        if (res.status === 200) {
          if (imprestExist) {
            try {
              let imp = await axios.put(`${BASE_URL}/wfm/imprest/${formData.imprest}/`, {
                imprest_open: false
              });
              if (imp.status === 200) {
                await getExpansee();
                await getAllempdata();
                setFormData({
                  name: "",
                  imprest: "",
                  date: formattedDate,
                  empcode: sessionStorage.getItem("employee_id")
                });
                setShow(false);
              } else {
                console.log("Imprest Closing Error");
                alert("Error closing imprest: " + imp.statusText);
              }
            } catch (error) {
              console.error('Error updating imprest:', error);
              alert("Error closing imprest: " + error.message);
            }
          } else {
            await getExpansee();
            await getAllempdata();
            setFormData({
              name: "",
              imprest: "",
              date: formattedDate,
              empcode: sessionStorage.getItem("employee_id")
            });
            // setShow(false);
            handleClose();
          }
        } else {
          console.log("Expense Creation Error");
          // alert("Error creating expense: " + res.statusText);
        }

      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  useEffect(() => {
    getAllempdata();
    getFilterProjects();
  }, [imprestExist]);
  const [allemployeeImprest, setAllemployeeImprest] = useState([]);
  const [allemployeedata, setAllemployee] = useState([]);

  const getAllempdata = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/imprestbyemployeedetails/${sessionStorage.getItem("employee_id")}/`);
      setAllemployeeImprest(res.data);

      const emp = await axios.get(
        PERMISSION_AUTHORITY ?
          `${BASE_URL}/wfm/ourcompanyempdetailsactive/null/`
          :
          `${BASE_URL}/wfm/ourcompanyempdetailsactive/${sessionStorage.getItem("company_id")}/`
      );
      const getAlphaNumericParts = (code) => {
        const match = code.match(/^([A-Za-z]+)(\d+)$/); // Match alphabetic and numeric parts
        if (match) {
          return { alphaPart: match[1], numericPart: parseInt(match[2], 10) };
        } else {
          return { alphaPart: '', numericPart: 0 };
        }
      };

      const sortedList = emp.data.sort((a, b) => {
        const { alphaPart: alphaA, numericPart: numericA } = getAlphaNumericParts(a.emp_code);
        const { alphaPart: alphaB, numericPart: numericB } = getAlphaNumericParts(b.emp_code);

        if (alphaA !== alphaB) {
          return alphaA.localeCompare(alphaB); // Sort alphabetically
        } else {
          return numericA - numericB; // Then sort numerically
        }
      });
      setAllemployee(sortedList);

    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    }
  };

  const [projectList, setProjectList] = useState([]);
  const [buffer, setBuffering] = useState(true); //buffering logic
  const getFilterProjects = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      // const res = await axios.get(`${BASE_URL_PREBID}/project/filterprproject/false/`);
      const res = await axios.get(`${BASE_URL_PREBID}/project/projectfilterbysubcompany/null/`);
      setProjectList(res.data);
    } catch (err) {
      handleErrorToast(err)
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  const options = allemployeedata
    .sort((a, b) => parseInt(a.emp_code) - parseInt(b.emp_code))
    .map((e) => ({
      value: e.emp_code,
      label: `${e.emp_code} - ${e.name}`,
    }));

  const preselectedOption = options.find((option) => option.value === sessionStorage.getItem("employee_id"));

  // useEffect(() => {
  //   if (preselectedOption && (!formData.person || !formData.person.includes(preselectedOption.value))) {
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       person: [preselectedOption.value],
  //     }));
  //   }
  // }, [preselectedOption]);

  const handleChangecc = (selected) => {
    const multipleperson = selected.length > 0 ? selected.map((item) => item.value) : "";
    setSelectedOptions(selected);
    setFormData((prevState) => ({
      ...prevState,
      multipleperson,
    }));
  };
  // const handleChangecc = (selected) => {
  //   const multipleperson = selected?.map((item) => item.value) || [];
  //   setSelectedOptions(selected);
  //   if (JSON.stringify(formData.person) !== JSON.stringify(multipleperson)) {
  //     setFormData((prevState) => ({
  //       ...prevState,
  //       multipleperson,
  //     }));
  //   }
  // };

  return (
    <>
      <button className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        {/* <Modal.Header closeButton>
          <Modal.Title>Request Leave</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <div className="flex-column">
                <label htmlFor="name" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Expense Name
                </label>
                <input
                  id="name"
                  type="name"
                  name="name"
                  placeholder="Expense Name"
                  onChange={handleInputChange}
                  value={formData.name}
                  className={`form-input ${errors.name ? "error" : inputState.name ? "success" : ""
                    }`}
                />
                {errors.name && (
                  <span className="error-message font-size-text ">{errors.name}</span>
                )}
              </div>

              <div className="flex-column justify-center">
                <div className="jd-timeline">
                  <div className="leave-radio-button-inner-div">
                    <input
                      onClick={(e) => setImprestExist(true)}
                      name="imprest_exist"
                      type="radio"
                      // value={true}
                      className="age-input"
                    />
                    <label className="age-label font-weight500 font-size-heading">Existing Imprest</label></div>
                  <div className="leave-radio-button-inner-div">
                    <input
                      onClick={(e) => setImprestExist(false)}
                      name="imprest_exist"
                      type="radio"
                      // value={false}
                      className="age-input"
                    />
                    <label className="age-label font-weight500 font-size-heading">No Imprest</label>
                  </div>
                </div>
              </div>


              {imprestExist === null ?
                null :
                imprestExist !== false ?
                  <>
                    <div className="flex-column form-group-select">
                      <label className="form-labels  font-weight500    font-size-subheading">
                        Against Imprest<span className="required">*</span>
                      </label>
                      <br />
                      <select
                        name="imprest"
                        onChange={handleInputChange}
                        value={formData.imprest}
                        className={`form-input form-group-selection form-input-background ${errors.imprest ? "error" : inputState.imprest ? "success" : ""
                          }`}>
                        <option value="">Select imprest</option>
                        {allemployeeImprest.filter((imprest) => imprest.imprest_open === true).map((e, index) => (<option value={e.id}>{e.project_name}</option>))}

                        {/* {emp_code.sort((a, b) => a.emp_code - b.emp_code).map((i, index) => (<option value={i.emp_code}>{i.emp_code} - {i.name}</option>))}; */}
                      </select>
                      <div className="form-group-selection-arrow">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="25"
                          fill="currentColor"
                          class="bi bi-caret-down-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                        </svg>
                      </div>
                    </div>
                    <br />
                    {errors.imprest && (
                      <span className="error-message font-size-text ">{errors.imprest}</span>
                    )}

                  </>
                  :
                  <>

                    <div className="flex-column">
                      <label htmlFor="date" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                        Date<span className="required">*</span>
                      </label>
                      <input
                        id="date"
                        type="date"
                        name="date"
                        onChange={handleInputChange}
                        value={formData.date}
                        className={`form-input ${errors.date ? "error" : inputState.date ? "success" : ""
                          }`}
                      />
                      {errors.date && (
                        <span className="error-message font-size-text ">{errors.date}</span>
                      )}
                    </div>

                    <div className="flex-column form-group-select">
                      <label className="form-labels  font-weight500    font-size-subheading">
                        For Project<span className="required">*</span>
                      </label>
                      <select
                        name="project_id"
                        onChange={handleInputChange}
                        value={formData.project_id}
                        className={`form-input form-group-selection form-input-background ${errors.imprest ? "error" : inputState.imprest ? "success" : ""
                          }`}>
                        <option value="">Select Project</option>
                        {projectList?.sort((a, b) => a.project_short_name?.localeCompare(b.project_short_name, 'en', { ignorePunctuation: true })).map((i, index) => (
                          <option key={index} value={i.pr_code}>{i.prebid_prcode}-{i.project_short_name}</option>
                        ))}
                      </select>
                      <div className="form-group-selection-arrow">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="25"
                          fill="currentColor"
                          class="bi bi-caret-down-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                        </svg>
                      </div>
                      {errors.project_id && (
                        <span className="error-message font-size-text ">{errors.project_id}</span>
                      )}
                    </div>

                    <div className="flex-column">
                      <label
                        htmlFor="project_name"
                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                      >
                        Project Code & Name <span className="required">(View-Only)</span>
                      </label>
                      <input
                        id="project_name"
                        type="text"
                        name="project_name"
                        readOnly
                        style={{ cursor: 'not-allowed' }}
                        placeholder="Code & Name"
                        onChange={handleInputChange}
                        value={formData.project_name}
                        className={`form-input ${errors.project_name
                          ? "error"
                          : inputState.project_name
                            ? "success"
                            : ""
                          }`}
                      />
                      {errors.project_name && (
                        <span className="error-message font-size-text ">{errors.project_name}</span>
                      )}
                    </div>

                    <div className="flex-column">
                      <label
                        htmlFor="no_of_days"
                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                      >
                        Number of days
                      </label>
                      <input
                        id="no_of_days"
                        type="number"
                        min={0}
                        placeholder="Days"
                        name="no_of_days"
                        onWheel={(e) => e.target.blur()}
                        onChange={handleInputChange}
                        value={formData.no_of_days}
                        className={`form-input ${errors.no_of_days
                          ? "error"
                          : inputState.no_of_days
                            ? "success"
                            : ""
                          }`}
                      />
                      {errors.no_of_days && (
                        <span className="error-message font-size-text ">{errors.no_of_days}</span>
                      )}
                    </div>

                    <div className="flex-column">
                      <label htmlFor="person" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                        Name of Person
                      </label>

                      {/* <Select options={options} isMulti onChange={handleChangecc} /> */}
                      <Select
                        options={options}
                        isMulti
                        // value={selectedOptions }
                        // value={selectedOptions ? selectedOptions : options.filter((option) => formData.person.includes(option.value))}
                        // value={options.filter((option) => formData.person.includes(option.value))}
                        onChange={handleChangecc}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: '33vw',
                            // height: '50px',
                            borderRadius: '10px',
                            border: '1px solid #e3e3e3',
                            margin: '10px 0px 20px 0px',
                            color: '#4a4a4a',
                            backgroundColor: '#f7f8fa',
                            padding: '0px 10px',
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: '#4a4a4a',
                          }),
                          menu: (provided) => ({
                            ...provided,
                            borderRadius: '10px',
                            marginTop: '0',
                          }),
                          input: (provided) => ({
                            ...provided,
                            color: '#4a4a4a',
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            color: '#4a4a4a',
                          }),
                        }}
                      />
                      {errors.person && (
                        <span className="error-message font-size-text ">{errors.person}</span>
                      )}
                    </div>

                    <div className="flex-column">
                      <label htmlFor="person" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                        Number of Person (Auto)
                      </label>
                      <input
                        id="person"
                        type="number"
                        name="person"
                        // onChange={handleInputChange}
                        readOnly
                        style={{ cursor: 'not-allowed' }}
                        value={formData.multipleperson?.length}
                        className={`form-input ${errors.person ? "error" : inputState.person ? "success" : ""
                          }`}
                      />
                      {errors.person && (
                        <span className="error-message font-size-text ">{errors.person}</span>
                      )}
                    </div>

                    <div className="flex-column">
                      <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                        Remarks
                      </label>
                      <textarea
                        id="remarks"
                        type="text"
                        name="remarks"
                        placeholder="Your feedback"
                        onChange={handleInputChange}
                        // maxLength={200}
                        value={formData.remarks}
                        className={`form-input-textarea   font-weight400  font-size-subheading ${errors.remarks
                          ? "error"
                          : inputState.remarks
                            ? "success"
                            : ""
                          }`}
                      />
                      {errors.remarks && (
                        <span className="error-message font-size-text ">{errors.remarks}</span>
                      )}
                    </div>
                  </>
              }

              <div className="button-models">
                <button className="model-button   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

const ExpenseSubmit = ({ i, getExpansee, total }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    getSpecificExpenses()
    setShow(true)
  };

  const [updatedlist, setExpenseDetails] = useState([]);
  const [sum, setSum] = useState("");
  const getSpecificExpenses = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/expensedetailsbyexpense/${i.id}/`
      );

      const summ = res.data.reduce(
        (acc, item) => acc + parseFloat(item.item_amount),
        0
      );
      setSum(summ);
      const uniqueDatesSet = new Set();
      res.data.forEach((item) => {
        uniqueDatesSet.add(item.date);
      });

      // console.log(uniqueDatesSet)
      setExpenseDetails(Array.from(uniqueDatesSet));
    } catch (err) { }
  };


  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(`${BASE_URL}/wfm/expense/${i.id}/`, {
        utilized_amount: sum,
        remaining_amount: Number(total) - Number(sum),
        submit_for_approval: true,
        partially_change: false,
        rh_status: "pending",
      });

      if (res.status === 200) {
        await getExpansee();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-edit-button" title="Submit Expense for Approval" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <div className="parent-div">

            <div className="bdy-div">
              <h6 className="task-Tab-heading align-center font-weight500 font-size-heading">Confirm Expense Submission of "{i.name}" </h6>
              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Date :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {formattedDateLong(i.date)}
                </div>


              </div>

              <div className="flex-row">
                <label
                  htmlFor="project_name"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Expense against Imprest :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.project_name}
                </div>
              </div>

              {/* <hr className="line" /> */}
              <hr className="line" />
              <div className="children">
                <div>
                  <label htmlFor="fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                    Utilized Amount
                  </label>
                  <p className="utilize   font-weight500   -amount align-center" style={{ color: "red" }}>
                    {sum}
                  </p>
                </div>

                <div>
                  <label htmlFor="fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                    Remaining Amount
                  </label>
                  <p className="remaining-amount  font-weight500    align-center" style={{ color: "#2576bc" }}>
                    {Number(total) - Number(sum)}
                  </p>
                </div>
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel  font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmitPut}
                >
                  Confirm
                </button>
              </div>

            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal >
    </>
  );
};

const ExpensePartialSubmit = ({ i, getExpansee, total }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    getSpecificExpenses()
    setShow(true)
  };
  console.log("i")
  console.log(i)

  const [updatedlist, setExpenseDetails] = useState([]);
  const [sum, setSum] = useState("");
  const getSpecificExpenses = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/expensedetailsbyexpense/${i.id}/`
      );

      const summ = res.data.reduce(
        (acc, item) => acc + parseFloat(item.bill_approved_amt),
        0
      );
      setSum(summ);
      const uniqueDatesSet = new Set();
      res.data.forEach((item) => {
        uniqueDatesSet.add(item.date);
      });

      // console.log(uniqueDatesSet)
      setExpenseDetails(Array.from(uniqueDatesSet));
    } catch (err) { }
  };


  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(`${BASE_URL}/wfm/expense/${i.id}/`, {
        utilized_amount: sum,
        remaining_amount: Number(total) - Number(sum),
        submit_for_approval: true,
        partially_change: false,
        // rh_status: "pending",

      });

      if (res.status === 200) {
        await getExpansee();
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-edit-button" title="Partial Submit Expense" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <h6 className="task-Tab-heading align-center font-weight500 font-size-heading">Confirm Expense Submission of "{i.name}" </h6>
              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Date :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {formattedDateLong(i.date)}
                </div>
              </div>
              <div className="flex-row">
                <label
                  htmlFor="project_name"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Expense against Imprest :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.project_name}
                </div>
              </div>
              <hr className="line" />
              <div className="children">
                <div>
                  <label htmlFor="fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                    Utilized Amount
                  </label>
                  <p className="utilize   font-weight500   -amount align-center" style={{ color: "red" }}>
                    {sum}
                  </p>
                </div>
                <div>
                  <label htmlFor="fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                    Remaining Amount
                  </label>
                  <p className="remaining-amount  font-weight500    align-center" style={{ color: "#2576bc" }}>
                    {Number(total) - Number(sum)}
                  </p>
                </div>
              </div>
              <div className="button-models">
                <button className="model-button model-button-cancel  font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmitPut}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal >
    </>
  );
};

const ExpenseModelDelete = ({ id, getExpansee }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();

    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.delete(`${BASE_URL}/wfm/expense/${id.id}/`);

      if (res.status === 200) {

        try {
          let imp = await axios.put(`${BASE_URL}/wfm/imprest/${id.imprest}/`, {
            imprest_open: true
          });
          if (imp.status === 200) {
            await getExpansee();
            setShow(false);
          } else {
            console.log("Imprest Opening Error");
            alert("Error Opening imprest: " + imp.statusText);
          }
        } catch (error) {
          console.error('Error updating imprest:', error);
          alert("Error Opening imprest: " + error.message);
        }
      } else {
        alert(res);
      }
    } catch (err) {
      handleErrorToast(err)
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Expense</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to Delete Expense Request
            <div className="button-models">
              <button className="model-button  model-button-cancel font-weight500    " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};

const Expenses = ({ emp_code, toggleexpense, expensetogglemanagenet }) => {
  const [imprest, setImprest] = useState([]);
  const [buffer, setBuffering] = useState(true); //buffering logic

  const getImprest = async () => {
    setBuffering(true); // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/imprestbyemployee/${emp_code ? emp_code : sessionStorage.getItem("employee_id")}/`
      );
      setImprest(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;
        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getImprest();
  }, []);

  const [expansee, setexpansee] = useState([]);

  const getExpansee = async () => {
    setBuffering(true); // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/expensebyemployee/${emp_code ? emp_code : sessionStorage.getItem("employee_id")}/`
      );
      setexpansee(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;
        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getExpansee();
  }, []);

  const [toggleState, setToggleState] = useState(toggleexpense || 1);

  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");
  const baseUrl = "/" + urlParts[3] + `/expenseSlip`;
  let opener = "profile"
  if (expensetogglemanagenet) {
    opener = "request"
  }
  const openExenseSlip = (i) => {
    navigate(baseUrl, { state: { i, opener } });
  };
  return (
    <div>
      <div className="expense-table-cont expense-bloc-tabs">
        <div className="left-expense-bloc-tab wfm-margin">
          <button
            className={
              "expense-tab " +
              (toggleState === 1
                ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs dashboardcomponent-tabss expense-active-tab"
                : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss expense-inactive-tab")
            }
            onClick={() => {
              getImprest();
              setToggleState(1)
            }}
          >
            Imprest
          </button>
          <button
            className={
              "expense-tab " +
              (toggleState === 2
                ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs expense-active-tab"
                : "dashboardcomponent-tabs  font-weight400  font-size-subheading   expense-inactive-tab")
            }
            onClick={() => {
              getExpansee();
              setToggleState(2)
            }}
          >
            Expense
          </button>
        </div>
        {toggleState === 1 ? (
          <>
            <div className="right-expense-bloc-tab">
              <div>
                {/* <OldImprestAdd getImprest={getImprest} /> */}
                <ImprestAdd getImprest={getImprest} />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="right-expense-bloc-tab">
              <div>
                <ExpenseAdd getExpansee={getExpansee} />
              </div>
            </div>
          </>
        )}
      </div>
      <br />
      {toggleState === 1 ? (
        <>
          {/* Imprest History Details */}
          <div className="table-css-white-background expense-table-cont">
            <table className="table-css">
              <thead>
                <tr className="custom-table-head-tr">
                  <th className="table-heading">S. No.</th>
                  {/* <th className="table-heading">Emp Code</th> */}
                  <th className="table-heading">Imprest Date</th>
                  <th className="table-heading " >Dates</th>
                  <th className="table-heading">Imprest Name</th>
                  <th className="table-heading" style={{ textAlign: "center" }}>Proposed Amount</th>
                  <th className="table-heading " style={{ textAlign: "center" }}>Approved Amount</th>
                  {/* <th className="table-heading">Remaining Amount</th> */}
                  <th className="table-heading">Days And Employee Count</th>
                  {/* <th className="table-heading">No. of Days</th> */}
                  {/* <th className="table-heading">No. of People</th> */}
                  <th className="table-heading " style={{ textAlign: "center" }}>Person Name</th>
                  <th className="table-heading">Remarks</th>
                  <th className="table-heading" >Status</th>
                  {/* <th className="table-heading ">Reason</th> */}
                  <th className="table-heading" >Action</th>
                </tr>
              </thead>
              {buffer ? <div className="spinner"></div> :
                <tbody>
                  <tr className="tr-border-bottom">
                    <td colSpan={6}></td>
                  </tr>
                  {imprest.map((item, index) => {
                    return (
                      <>
                        <tr className="custom-table-head-td">
                          <td className="table-body">{index + 1}</td>
                          {/* <td className="align-left">{item.empcode}</td> */}
                          <td className="table-body">
                            {formatDate(item.date)}
                          </td>

                          <td title="Approval Dates" className="table-body">
                            <div>
                              {item.rh_leave_status === "approved" ?
                                <span
                                  request-status={item.rh_leave_status}
                                  className="request-status "
                                >
                                  Rh Approved
                                  {item.rh_update_datetime ? <><br />At: {formatDateTime(item.rh_update_datetime).date}</> : ''}
                                </span>
                                : ""}
                              <br />
                              {item.admin_leave_status === "approved" ?
                                <span
                                  request-status={item.admin_leave_status}
                                  className="request-status "
                                >
                                  Admin {item.admin_update_by ? `(${item.admin_update_by})` : ''} Approved
                                  {item.admin_update_datetime ? <><br />At: {formatDateTime(item.admin_update_datetime).date}</> : ''}
                                </span>
                                : ""}
                              <br />
                              {item.account_leave_status === "approved" ?
                                <span
                                  request-status={item.account_leave_status}
                                  className="request-status "
                                >
                                  Accounts{item.account_update_by ? `(${item.account_update_by})` : ''} Approved
                                  {item.account_update_datetime ? <><br />At: {formatDateTime(item.account_update_datetime).date}</> : ''}
                                </span>
                                : ""}
                            </div>
                          </td>

                          <td className="table-body">{item.project_name}</td>

                          <td className="table-body">
                            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                              <tbody>
                                <tr>
                                  <td className="table-body" style={{ paddingBottom: "5px" }}>Transportation</td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">{formatCurrencyIndian(item.transportation)}</td>
                                </tr>
                                <tr>
                                  <td className="table-body" style={{ paddingBottom: "5px" }}>Accommodation</td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">{formatCurrencyIndian(item.accomodation)}</td>
                                </tr>
                                <tr>
                                  <td className="table-body" style={{ paddingBottom: "5px" }}>Food</td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">{formatCurrencyIndian(item.fooding)}</td>
                                </tr>
                                <tr>
                                  <b>
                                    <td className="table-body" style={{ paddingBottom: "5px" }}>Others</td>
                                  </b>
                                  <td style={{ padding: "0px 4px" }}>
                                    :
                                  </td>
                                  <td className="align-right">
                                    <b>
                                      {item.other_details
                                        .reduce((sum, other) => sum + (parseFloat(other.amount) || 0), 0)
                                        .toLocaleString()}
                                    </b>
                                  </td>
                                </tr>
                                {item.other_details?.length > 0 ? (
                                  <>
                                    {item.other_details.map((other, index) => (
                                      <>
                                        <tr>
                                          <td style={{ paddingLeft: "5px", paddingBottom: "5px" }} className="table-body" >
                                            {other.other_name ? other.other_name : `Other-${index + 1}`}
                                          </td>
                                          <td style={{ padding: "0px 4px" }}>:</td>

                                          <td className="align-right">
                                            {formatCurrencyIndian(other.amount || 0)}
                                          </td>
                                        </tr>
                                      </>
                                    ))}
                                  </>
                                ) : (
                                  <td style={{ paddingBottom: "5px", paddingLeft: "5px" }}>
                                    No Other Amount
                                  </td>
                                )}
                                <tr>
                                  <td className="table-body" style={{ paddingBottom: "5px" }} >
                                    <b>Total </b>
                                  </td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">
                                    <b>
                                      {formatCurrencyIndian(
                                        Number(item.transportation) +
                                        Number(item.accomodation) +
                                        Number(item.fooding) +
                                        (item.other_details?.length > 0
                                          ? item.other_details.reduce((total, other) => total + Number(other.amount || 0), 0)
                                          : 0)
                                      )}
                                    </b>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          {/* {item.amount_change_reason ? <td className="align-leftt" title={`Reason For Change : \n${item.amount_change_reason}`}>

                     <b>
                       {Number(item.approved_transportation) +
                         Number(item.approved_accomodation) +
                         Number(item.approved_fooding) +
                         (item.other_details?.length > 0
                           ? item.other_details.reduce((total, other) => total + Number(other.approved_other || 0), 0)
                           : 0)}{" "}
                       Rs
                     </b>{" "}<br />
                     Transportation: {item.approved_transportation} <br />
                     Accomodation: {item.approved_accomodation} <br />
                     Food: {item.approved_fooding} <br />
                     Others : <br />
                     {item.other_details?.length > 0 ? (
                       <ul style={{ paddingRightLeft: '0', marginLeft: '0', listStylePosition: 'inside' }}>
                         {item.other_details.map((other, index) => (
                           <li key={index}>
                             <span key={index} className="form-text-trasformation-uppercase">
                               {other.other_name ? other.other_name : `Other-${index}`}: {other.approved_other || 0} <br />
                             </span>
                           </li>
                         ))}
                       </ul>
                     ) : (
                       <span>No Other Amount <br /></span>
                     )}
                   </td> :
                     <td className="align-center">
                       -
                     </td>
                   } */}

                          {item.amount_change_reason ? (
                            <td
                              className="align-leftt"
                              title={`Reason For Change : \n${item.amount_change_reason}`}
                            >
                              <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                <tbody>
                                  <tr>
                                    <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Transportation</td>
                                    <td style={{ padding: "0px 4px" }}>:</td>
                                    <td className="align-right">
                                      {formatCurrencyIndian(item.approved_transportation || 0)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Accommodation</td>
                                    <td style={{ padding: "0px 4px" }}>:</td>
                                    <td className="align-right">
                                      {formatCurrencyIndian(item.approved_accomodation || 0)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Food</td>
                                    <td style={{ padding: "0px 4px" }}>:</td>
                                    <td className="align-right">
                                      {formatCurrencyIndian(item.approved_fooding || 0)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <b>
                                      <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Others</td>
                                    </b>
                                    <td style={{ padding: "0px 4px" }}>:</td>
                                    <td className="align-right table-body" style={{ paddingBottom: "5px" }}>
                                      <b>
                                        {formatCurrencyIndian(
                                          item.other_details?.reduce(
                                            (sum, other) =>
                                              sum + (parseFloat(other.approved_other) || 0),
                                            0
                                          )
                                        )}
                                      </b>
                                    </td>
                                  </tr>
                                  {item.other_details?.length > 0 ? (
                                    <>
                                      {item.other_details.map((other, index) => (
                                        <tr key={index}>
                                          <td className="table-body" style={{ paddingLeft: '5px', paddingBottom: "5px" }}>
                                            {other.other_name
                                              ? other.other_name
                                              : `Other-${index + 1}`}
                                          </td>
                                          <td style={{ padding: "0px 4px" }}>:</td>
                                          <td className="align-right" style={{ paddingBottom: "5px" }}>
                                            {formatCurrencyIndian(other.approved_other || 0)}
                                          </td>
                                        </tr>
                                      ))}
                                    </>
                                  ) : (
                                    <tr>
                                      <td style={{ paddingBottom: "5px", paddingLeft: "5px" }} colSpan={3}>No Other Amount</td>
                                    </tr>
                                  )}
                                  <tr>
                                    <td>
                                      <b>Total</b>
                                    </td>
                                    <td style={{ padding: "0px 4px" }}>:</td>
                                    <td className="align-right">
                                      <b>
                                        {formatCurrencyIndian(
                                          Number(item.approved_transportation) +
                                          Number(item.approved_accomodation) +
                                          Number(item.approved_fooding) +
                                          (item.other_details?.length > 0
                                            ? item.other_details.reduce(
                                              (total, other) =>
                                                total + Number(other.approved_other || 0),
                                              0
                                            )
                                            : 0)
                                        )}
                                      </b>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          ) : (
                            <td className="table-body" style={{ textAlign: "center" }}>-</td>
                          )}
                          <td className="table-body">For <b>{item.no_of_days}</b> Days with <br /> <b>{item.person}</b> {item.person > 1 ? "people." : "person."}</td>
                          {/* <td className="align-center">{item.no_of_days}</td> */}
                          {/* <td className="align-center">{item.person}</td> */}
                          <td className="table-body">
                            <ul style={{ paddingLeft: "20px" }}>
                              {item.multiple_person_details.map((items) => {
                                return (
                                  <li>
                                    {items.emp_code} - {items.name}
                                    <br />
                                  </li>
                                );
                              })}
                            </ul>
                          </td>
                          <td className="table-body">{item.remarks}</td>
                          <td className="table-body"  >
                            <span
                              request-status={item.rh_leave_status}
                              className="request-status"
                              title={`${item.rh_leave_status === "rejected" ? `TL Reason For Rejection : \n${item.rejection_reason}` : ''}`}
                            >
                              {item.rh_leave_status === "pending"
                                ? "Pending For Rh Approval ⌛"
                                : item.rh_leave_status === "rejected"
                                  ? <>Rh ✖,<br />Rh Reject Reason:<br />"{item.rejection_reason}"</>
                                  : "Approved By Rh ✔,"}
                            </span>
                            {/* <br /> */}
                            <br />
                            <span
                              request-status={item.admin_leave_status}
                              className="request-status"
                              title={`${item.admin_leave_status === "rejected" ? `Admin Reason: ${item.admin_rejection_reason}` : ''}`}
                            >
                              {item.admin_leave_status === "rejected"
                                ? <>Admin ✖,<br />Admin Reject Reason: "{item.admin_rejection_reason}"</>
                                :
                                item.admin_leave_status === "pending"
                                  ? "Pending For Admin Approval ⌛,"
                                  :
                                  item.admin_leave_status === "approved"
                                    ? "Approved By Admin ✔,"
                                    : "Admin Status: " + item.admin_leave_status}
                            </span>
                            {/* <br /> */}
                            <br />
                            <span
                              request-status={item.account_leave_status}
                              className="request-status"
                              title={`${item.account_leave_status === "rejected" ? `Account Reason: ${item.account_rejection_reason}` : ''}`}
                            >
                              {item.account_leave_status === "rejected"
                                ? <>Accounts ✖,<br />Accounts Reject Reason:<br />"{item.account_rejection_reason}"</>
                                : item.account_leave_status === "pending"
                                  ? "Pending For Account Approval ⌛"
                                  :
                                  item.account_leave_status === "approved"
                                    ? "Approved By Accounts ✔,"
                                    : "Accounts Status : " + item.account_leave_status}
                            </span>
                          </td>
                          <td className="table-body">
                            <span
                              request-status={item.rh_leave_status === "rejected" || item.admin_leave_status === "rejected" || item.account_leave_status === "rejected" ? "rejected" : item.account_leave_status === "pending" ? "pending" : item.account_leave_status}
                              className="request-status table-body"
                            >

                              {" "}
                              {item.rh_leave_status === "pending" && item.admin_leave_status === "pending" && item.account_leave_status === "pending" || item.rh_leave_status === "rejected" ? (
                                <div className="table-heading">
                                  <ImprestModelDelete
                                    getImprest={getImprest}
                                    id={item}
                                  />
                                </div>
                              ) :
                                // item.rh_leave_status === "rejected" || item.admin_leave_status === "rejected" ||
                                item.account_leave_status !== "pending" ?

                                  <span title={`Transaction/Cheque No.:\n${item.account_leave_status !== "pending" ? item.account_approved_final_details : null}`}>

                                    {item.account_leave_status === "approved" ? "Process Completed" : item.rh_leave_status === "rejected" ? "Process Terminated" : ""}

                                    <br />
                                    {item.account_transaction_proof ? (
                                      <a className="modal-button-black" href={item.account_transaction_proof} target="blank">
                                        View
                                      </a>
                                    ) :
                                      ("No Proof Attached")
                                    }
                                  </span>
                                  :
                                  <div className="table-heading">
                                    In Process
                                  </div>
                              }
                            </span>
                          </td>
                        </tr>
                        <tr className="tr-border-bottom">
                          <td colSpan={6}></td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              }
            </table>
          </div>
        </>
      ) : null}

      {toggleState === 2 ? (
        <>
          {/* Expense History Details */}
          <div className="table-css-white-background expense-table-cont">
            <table className="table-css">
              <thead>
                <tr className="custom-table-head-tr">
                  <th className="table-heading">S. No.</th>
                  <th className="table-heading">Date</th>
                  {/* <th className="table-heading">Site Name</th> */}
                  {/* <th className="table-heading">Expense Name</th> */}
                  <th className="table-heading">Expense <b>Against</b> Imprest</th>
                  <th className="table-heading">Approved Imprest Amount</th>
                  <th className="table-heading">Spent Money</th>
                  <th className="table-heading">View</th>
                  <th className="table-heading">Status</th>
                  <th className="table-heading width-15vw">Remarks</th>
                  <th className="table-heading">Action</th>
                </tr>
              </thead>
              {buffer ? <div className="spinner"></div> :
                <tbody>
                  <tr className="tr-border-bottom">
                    <td colSpan={7}></td>
                  </tr>
                  {expansee.map((item, index) => {
                    return (
                      <>
                        <tr className="custom-table-head-td">
                          <td className="table-body">{index + 1}</td>
                          <td className="table-body">{formatDate(item.date)}</td>
                          <td className="table-body"><span>{item.name}</span>
                            <br />
                            <b> Against </b>
                            <br />
                            <span>{item.project_name}</span></td>
                          {/* <td className="table-body">{item.project_name}</td> */}
                          {/* <td className="table-body">{item.name}</td> */}
                          <td className="table-body">
                            <table style={{ borderCollapse: "collapse", width: "100%" }}>
                              <tbody>
                                <tr>
                                  <td className="table-body" style={{ paddingBottom: "5px" }}>Transportation</td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">
                                    {formatCurrencyIndian(item.approved_transportation || 0)}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="table-body" style={{ paddingBottom: "5px" }}>Accommodation</td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">
                                    {formatCurrencyIndian(item.approved_accomodation || 0)}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="table-body" style={{ paddingBottom: "5px" }}>Food</td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">
                                    {formatCurrencyIndian(item.approved_fooding || 0)}
                                  </td>
                                </tr>
                                <tr>
                                  <b>
                                    <td className="table-body" style={{ paddingBottom: "5px" }}>Others</td>
                                  </b>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">
                                    <b>
                                      {formatCurrencyIndian(
                                        item.other_details?.reduce(
                                          (sum, other) => sum + Number(other.approved_other || 0),
                                          0
                                        )
                                      )}
                                    </b>
                                  </td>
                                </tr>
                                {item.other_details?.length > 0 ? (
                                  <>
                                    {item.other_details.map((other, index) => (
                                      <tr key={index}>
                                        <td className="table-body" style={{ paddingLeft: "5px", paddingBottom: "5px" }} >
                                          {other.other_name ? other.other_name : `Other-${index + 1}`}
                                        </td>
                                        <td style={{ padding: "0px 4px" }}>:</td>
                                        <td className="align-right">
                                          {formatCurrencyIndian(other.approved_other || 0)}
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                ) : (
                                  <tr>
                                    <td style={{ padding: "0px 4px", paddingBottom: "5px" }} colSpan={3}>No Other Expense</td>
                                  </tr>
                                )}
                                <tr>
                                  <td className="table-body" style={{ paddingBottom: "5px" }}>
                                    <b>Total</b>
                                  </td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">
                                    <b>
                                      {formatCurrencyIndian(
                                        Number(item.approved_transportation) +
                                        Number(item.approved_accomodation) +
                                        Number(item.approved_fooding) +
                                        (item.other_details?.length > 0
                                          ? item.other_details.reduce(
                                            (total, other) => total + Number(other.approved_other || 0),
                                            0
                                          )
                                          : 0)
                                      )}
                                    </b>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          {/* <td className="align-center" >
                          {item.utilize}
                        </td>
                        <td className="align-center" >
                          {item.remaining}
                        </td> */}

                          <td className="table-body">
                            {item.imprest_account_leave_status === "pending"
                              ? <spam request-status={item.account_status_b}
                                className="request-status align-center">
                                Imprest Approval Pending ⌛
                              </spam>
                              : <ExpenseDetailsAdd
                                i={item}
                                expansee={getExpansee}
                                totall={
                                  Number(item.approved_transportation) +
                                  Number(item.approved_accomodation) +
                                  Number(item.approved_fooding) +
                                  (item.other_details?.length > 0
                                    ? item.other_details.reduce((total, other) => total + Number(other.approved_other || 0), 0)
                                    : 0)
                                }
                                utilize={item.utilized_amount}
                                remain={item.remaining_amount}
                              />}
                          </td>
                          <td className="table-body">
                            <button
                              className="allproject-dashboard"
                              title="View using Navigate"
                              onClick={(e) => openExenseSlip(item)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 30 30"
                                fill="none"
                              >
                                <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                                <path
                                  d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                  fill="#2576BC"
                                />
                              </svg>
                            </button>
                          </td>

                          <td className="table-body">
                            <span
                              request-status={item.rh_status}
                              className="request-status "
                              title={item.rh_status === "rejected" ? `Rh Reject Reason : ${item.rh_rejection_reason}` : ""}
                            >
                              {item.rh_status === "pending"
                                ? "Pending For Rh Approval ⌛"
                                : item.rh_status === "rejected"
                                  ? "Rh ✖, "
                                  : "Rh ✔, "}
                            </span>
                            <br />
                            <span
                              request-status={item.account_status_a}
                              className="request-status "
                              title={item.account_status_a === "rejected" ? `Account Reject Reason : ${item.account1_rejection_reason}` : ""}
                            >
                              {item.rh_status === "rejected" &&
                                item.admin_status === "rejected" &&
                                item.account_status_a === "rejected"
                                ? "Account Stage 1 ✖, "
                                : item.rh_status === "approved" &&
                                  item.admin_status === "approved" &&
                                  item.account_status_a === "pending"
                                  ? "Pending For Account Approval ⌛"
                                  : item.rh_status === "approved" &&
                                    item.admin_status === "approved" &&
                                    item.account_status_a === "rejected"
                                    ? "Accounts Stage 1 ✖, "
                                    : item.rh_status === "approved" &&
                                      item.admin_status === "approved" &&
                                      item.account_status_a === "approved"
                                      ? "Accounts Stage 1 ✔, "
                                      : "Accounts Stage 1: " + item.account_status_a}
                            </span>
                            <br />
                            <span
                              request-status={item.admin_status}
                              className="request-status "
                              title={item.admin_status === "rejected" ? `Admin Reject Reason : ${item.admin_rejection_reason}` : ""}
                            >
                              {item.rh_status === "rejected" &&
                                item.admin_status === "rejected"
                                ? "Admin ✖, "
                                : item.rh_status === "approved" &&
                                  item.admin_status === "pending"
                                  ? "Pending For Admin Approval ⌛,"
                                  : item.rh_status === "approved" &&
                                    item.admin_status === "rejected"
                                    ? "Admin ✖, "
                                    : item.rh_status === "approved" &&
                                      item.admin_status === "approved"
                                      ? "Admin ✔, "
                                      : "Admin Status: " + item.admin_status}
                            </span>
                            <br />
                            <span
                              request-status={item.account_status_b}
                              className="request-status "
                            >
                              {item.rh_status === "rejected" &&
                                item.admin_status === "rejected" &&
                                item.account_status_b === "rejected"
                                ? "Account Stage 2 ✖, "
                                : item.rh_status === "approved" &&
                                  item.admin_status === "approved" &&
                                  item.account_status_b === "pending"
                                  ? "Pending For Account Finalization ⌛"
                                  : item.rh_status === "approved" &&
                                    item.admin_status === "approved" &&
                                    item.account_status_b === "rejected"
                                    ? "Accounts Stage 2 ✖, "
                                    : item.rh_status === "approved" &&
                                      item.admin_status === "approved" &&
                                      item.account_status_b === "approved"
                                      ? "Accounts Stage 2 ✔, "
                                      : "Accounts Stage 2: " + item.account_status_b}
                            </span>
                          </td>

                          <td title="Remarks and Approval Dates" className="table-body">
                            <span
                              request-status={item.rh_status}
                              className="request-status "
                            >
                              {item.rh_status === "pending"
                                ? "-"
                                : item.rh_status === "rejected"
                                  ? `Rh Reject Reason : ${item.rh_rejection_reason}`
                                  : `Rh${item.rh_assigned ? `(${item.rh_assigned}) ` : ''}: Verified Amounts for Bills ${item.rh_update_datetime ? `At: ${formatDateTime(item.rh_update_datetime).date}` : ''}`}
                            </span>
                            <br />
                            <span
                              request-status={item.account_status_a}
                              className="request-status "
                            >
                              {item.account_status_a === "pending"
                                ? "-"
                                : item.account_status_a === "rejected"
                                  ? `Accounts Reject Reason : ${item.account1_rejection_reason}`
                                  : `Accounts${item.account_update_by ? `(${item.account_update_by}) ` : ''}: Bills Verified ${item.account_update_datetime ? `At: ${formatDateTime(item.account_update_datetime).date}` : ''}`}
                            </span>
                            <br />
                            <span
                              request-status={item.admin_status}
                              className="request-status "
                              title={item.admin_status === "rejected" ? `Admin Reject Reason : ${item.admin_rejection_reason}` : ""}
                            >
                              {item.admin_status === "pending"
                                ? "-"
                                : item.admin_status === "rejected"
                                  ? `Admin Rejection Reason : ${item.admin_rejection_reason}`
                                  : `Admin${item.admin_update_by ? `(${item.admin_update_by}) ` : ''}: Approved After Final Verification  ${item.admin_update_datetime ? `At: ${formatDateTime(item.admin_update_datetime).date}` : ''}`}
                            </span>
                            <br />
                            <span
                              request-status={item.account_status_b}
                              className="request-status "
                            >
                              {item.account_status_b === "pending"
                                ? "-"
                                : item.account_status_b === "rejected"
                                  ? `Final Rejection Reason : ${item.account2_rejection_reason}`
                                  : `Final${item.final_update_by ? `(${item.final_update_by}) ` : ''}: Approved And Settled ${item.final_update_datetime ? `At: ${formatDateTime(item.final_update_datetime).date}` : ''}`}
                            </span>
                          </td>

                          <td className="table-body " key={item.action}>
                            <span className="">
                              {" "}
                              {
                                item.submit_for_approval === false
                                  ? (
                                    <span
                                      request-status={item.imprest_account_leave_status}
                                      className="request-status "
                                      title={`${item.imprest_account_leave_status === "rejected" ? `Reason: ${item.account_rejection_reason}` : ''}`}
                                    > {item.imprest_account_leave_status === "rejected"
                                      ? "Imprest Account ✖, "
                                      :
                                      item.imprest_account_leave_status === "pending"
                                        ? "Imprest Approval Pending ⌛"
                                        :
                                        item.imprest_account_leave_status === "rejected"
                                          ? "Imprest Accounts ✖, "
                                          : item.imprest_account_leave_status === "approved"
                                            ?
                                            <ExpenseSubmit
                                              getExpansee={getExpansee}
                                              i={item}
                                              total={
                                                Number(item.approved_transportation) +
                                                Number(item.approved_accomodation) +
                                                Number(item.approved_fooding) +
                                                (item.other_details?.length > 0
                                                  ? item.other_details.reduce((total, other) => total + Number(other.approved_other || 0), 0)
                                                  : 0)
                                              }
                                            />
                                            : "Accounts Status: " + item.imprest_account_leave_status}
                                      <br />
                                      <ExpenseModelDelete
                                        getExpansee={getExpansee}
                                        id={item}
                                      />
                                    </span>
                                  ) :
                                  item.partially_change ?
                                    <span
                                      request-status={"rejected"}
                                      className="request-status "
                                    >Update Bills
                                      <br />
                                      <ExpensePartialSubmit
                                        getExpansee={getExpansee}
                                        i={item}
                                        total={
                                          Number(item.approved_transportation) +
                                          Number(item.approved_accomodation) +
                                          Number(item.approved_fooding) +
                                          (item.other_details?.length > 0
                                            ? item.other_details.reduce((total, other) => total + Number(other.approved_other || 0), 0)
                                            : 0)
                                        }
                                      />                                    </span> :
                                    item.account_status_b === "pending" ?
                                      <span
                                        request-status={"pending"}
                                        className="request-status "
                                      > In Process...</span> : <span
                                        request-status={"approved"}
                                        className="request-status "
                                      >Process Completed.</span>
                              }
                            </span>
                          </td>
                        </tr >
                        <tr className="tr-border-bottom">
                          <td colSpan={7}></td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              }
            </table>
          </div>
        </>
      ) : null}
    </div >
  );
};

export default Expenses;
