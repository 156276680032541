import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { BASE_URL } from "../../config/axios";
import Dashboardnavbar from "../../layout/Dashboardnavbar";
import { useLocation } from "react-router-dom";
// import { BillingDetailsTable, BillTable, ConsultancyDetails, ContractorDetails, COSTable, EmployeeTable, EOTTable, LetterTracking, MilestoneTable, Progress } from "./SiteProjectManagementComponents";
import { handleErrorToast, formatCurrencyIndian, formatRoundoffCrores, formatRoundoff3D, customSortByKey, ViewEmployeeList } from "../custom";
import { CircularGrid, Eye } from "../AllSvg";
import usePermission from "../../config/permissions";
import { AddProjectClientDetails, ProjectClientDetailsTable, ProjectWorkStagesTable, ProjectInvoicesDetailsTable, ProjectInvoicesPaymentsTable, ProjectSubmissionDocumentTable, ProjectTenderDocumentTable } from "./ProjectDetailsPageComponents"
import UpdateProjectDetaiils from "../Modals/UpdateProjectDetails";
import { formattedDateLong } from "../Date";
import { UpdateProjectsBulk } from "../BulkModals";

const ProjectDetailsPage = () => {
    const location = useLocation();
    const { projectID } = location.state;
    // console.log("location.state")
    // console.log(location.state)
    // console.log("projectID")
    // console.log(projectID)
    const department_id = sessionStorage.getItem('departmentid');
    const pr_project = sessionStorage.getItem('pr_project');

    const [buffer, setBuffering] = useState(false);

    const [clientslist, setClientsList] = useState([]);
    const [clientExists, setClientExists] = useState(false);

    const [client, setClient] = useState(null);
    const [clientwiseID, setClientWise] = useState(null);


    const [toggleStatechange, setToggleStatechange] = useState(1);
    const [project, setProject] = useState([]);
    const getProjectDetails = async () => {
        try {
            const ress = await axios.get(
                `${BASE_URL}/project/projectupdate/${projectID}/`
            );
            setProject(ress.data.project_data);
        } catch (err) {

        }
    };

    const getProjectClientList = async () => {
        setBuffering(true);
        try {
            const clientdetails = await axios.get(
                `${BASE_URL}/project/client-by-project/${projectID}/`
            );
            setClientsList(clientdetails.data);
            setClientExists(clientdetails.data?.normal_client.length > 0)
            setToggleStatechange(clientdetails.data?.normal_client.length > 0 ? 1 : 6)


            console.log("clientdetails")
            console.log(clientdetails.data?.normal_client?.length > 0)

            setClient(clientdetails.data?.normal_client[0]?.client);
            setClientWise(clientdetails.data?.normal_client[0]?.id);
            if (clientdetails.data?.normal_client?.length > 0) {
            } else {
                setClient(undefined);
                setClientWise(undefined);
            }
        } catch (err) {
            // handleErrorToast(err);
        } finally {
            setBuffering(false);
        }
    };


    const [projectEmployeeList, setProjectEmployeeList] = useState([]);
    const getProjectEmployeesList = async () => {
        try {
            const ress = await axios.get(
                `${BASE_URL}/project/employeeslistbyprojects/${projectID}/`
            );
            setProjectEmployeeList(ress.data);
        } catch (err) {

        }
    };

    const [allEmployeeData, setAllEmployeeData] = useState([]);
    const getEmployeeDetails = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/wfm/ourcompanysdep/${department_id}/`
            );

            const sortedList = customSortByKey(res.data, "emp_code");
            setAllEmployeeData(sortedList);
        } catch (err) {
            alert(err.message);
        }
    };

    useEffect(() => {
        getProjectDetails();
        getProjectClientList();

        getEmployeeDetails();
        getProjectEmployeesList();
    }, []);

    console.log("clientslist.normal_client?.length > 0")
    console.log(clientslist.normal_client?.length > 0)
    console.log("pr_project === true")
    console.log(pr_project === true)
    console.log("clientslist.normal_client?.length > 0 && pr_project === true")
    console.log(clientslist.normal_client?.length > 0 && pr_project === true)

    const department = sessionStorage.getItem('department')

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <Dashboardnavbar name={`Project Details`} url="Project Details" />

            <div className="content-tabs">

                <div className="info-box-div-bg">

                    <div className="flex-row">
                        <div className="personal-detail" style={{ width: "100%" }} >
                            <div className="project-details-button">
                                <div className="project-long">
                                    <h3 className="jd-heading-main font-weight600  font-size-heading">Project name : {project.project_short_name}</h3>
                                </div>
                                <div>
                                    {department === "Accounts" ? null :
                                        <>
                                            <UpdateProjectDetaiils
                                                id={project.pr_code}
                                                modalData={project}
                                                allEmployeeData={allEmployeeData}
                                                getProjectDetails={getProjectDetails}
                                            />
                                            <UpdateProjectsBulk />
                                        </>
                                    }
                                </div>
                            </div>

                            <div>
                                <div className="jd-heading-bottom-bold-personal"></div>
                                <div className="jd-heading-bottom-light"></div>
                            </div>
                            <div className="personal-detail-table1">
                                <table>
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">Project Description</td>
                                        <th className="personal-detail-2 font-weight600 font-size-text" style={{ paddingLeft: "70px" }}>
                                            {project.project_name ? project.project_name : '-'}
                                        </th>
                                    </tr>
                                </table>
                            </div>
                            <div className="personal-detail-table" >
                                <div>
                                    <table>
                                        <tr>
                                            <td className="personal-detail-1 font-weight500 font-size-text">Project Code</td>
                                            <th className="personal-detail-2 font-weight500 font-size-text">
                                                {project.prebid_prcode ? project.prebid_prcode : '-'}
                                            </th>
                                        </tr>
                                        <tr>
                                            <td className="personal-detail-1 font-weight500 font-size-text">Client Name</td>
                                            <th className="personal-detail-2 font-weight500 font-size-text">
                                                {project.client_name ? project.client_name : '-'}
                                            </th>
                                        </tr>
                                        <tr>
                                            <td className="personal-detail-1 font-weight500 font-size-text">Location</td>
                                            <th className="personal-detail-2 font-weight500 font-size-text">
                                                {project.location ? project.location : '-'}
                                            </th>
                                        </tr>
                                        <tr>
                                            <td className="personal-detail-1 font-weight500 font-size-text">Contract Mode</td>
                                            <th className="personal-detail-2 font-weight500 font-size-text">
                                                {project.contract_mode ? project.contract_mode : '-'}
                                            </th>
                                        </tr>
                                        <tr>
                                            <td className="personal-detail-1 font-weight500 font-size-text">Length (in Km)</td>
                                            <th className="personal-detail-2 font-weight500 font-size-text">
                                                {project.length ? formatRoundoff3D(Number(project.length)) : '-'}
                                            </th>
                                        </tr>
                                        <tr>
                                            <td className="personal-detail-1 font-weight500 font-size-text">Portal Submission Date</td>
                                            <th className="personal-detail-2 font-weight500 font-size-text">
                                                {project.portal_submission_date ? formattedDateLong(project.portal_submission_date) : '-'}
                                            </th>
                                        </tr>
                                    </table>
                                </div>
                                <div>
                                    <table>
                                        <tr>
                                            <td className="personal-detail-1 font-weight500 font-size-text">Project Cost (In Crs.)</td>
                                            <th className="personal-detail-2 font-weight500 font-size-text">
                                                {project.cost ? formatCurrencyIndian(project.cost) : '-'}
                                            </th>
                                        </tr>
                                        <tr>
                                            <td className="personal-detail-1 font-weight500 font-size-text">Remark</td>
                                            <th className="personal-detail-2 font-weight500 font-size-text">
                                                {project.remark ? project.remark : '-'}
                                            </th>
                                        </tr>
                                        <tr>
                                            <td className="personal-detail-1 font-weight500 font-size-text">Link</td>
                                            <th className="personal-detail-2 font-weight500 font-size-text">
                                                {project.link ? <a href={project.link} target="_blank" rel="noopener noreferrer">{project.link}</a> : '-'}
                                            </th>
                                        </tr>
                                        <tr>
                                            <td className="personal-detail-1 font-weight500 font-size-text">L1 Position</td>
                                            <th className="personal-detail-2 font-weight500 font-size-text">
                                                {project.l1_position ? project.l1_position : '-'}
                                            </th>
                                        </tr>
                                        <tr>
                                            <td className="personal-detail-1 font-weight500 font-size-text">Our Position</td>
                                            <th className="personal-detail-2 font-weight500 font-size-text">
                                                {project.our_position ? project.our_position : '-'}
                                            </th>
                                        </tr>
                                        <tr>
                                            <td className="personal-detail-1 font-weight500 font-size-text">Opening Date</td>
                                            <th className="personal-detail-2 font-weight500 font-size-text">
                                                {project.opening_date ? formattedDateLong(project.opening_date) : '-'}
                                            </th>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div className="personal-detail-table1">
                                <table>
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">Assigned to Employees</td>
                                        <th className="personal-detail-2 font-weight600 font-size-text flex-row">
                                            {project.assignedto?.length > 0 ? project.assignedto?.length : '-'}
                                            <hr className="field-cont-hr" />
                                            <ViewEmployeeList titlename={`Employees Assigned to "${project.project_short_name}" `} list={project.employee_detail} />
                                        </th>
                                    </tr>
                                </table>
                            </div>
                        </div >
                    </div>

                </div>

                {
                    (pr_project === "true" || clientslist.normal_client?.length === 0) ?
                        // null :
                        <div className="filter-outer-box">
                            <div className="field-cont">
                                {pr_project === "true" && clientslist.normal_client?.length !== 0 ?
                                    <div title="client Name" className="field-cont-div">
                                        <CircularGrid />
                                        <select
                                            onChange={(e) => {
                                                const selectedClient = e.target.value === "" ? undefined : e.target.value;
                                                const selectedClientId = clientslist.normal_client?.find(client => client.client === selectedClient)?.id
                                                    || clientslist.pr_project?.find(client => client.client === selectedClient)?.id;
                                                setClient(selectedClient);
                                                setClientWise(selectedClientId);
                                            }}
                                            value={client || ""}
                                            className="attendance-input-field date-field"
                                        >
                                            {clientslist.normal_client?.map((e) => (
                                                <option key={e.client} value={e.client}>{e.clientname}</option>
                                            ))}
                                            {clientslist.pr_project?.map((e) => (
                                                <option key={e.client} value={e.client}>PR-{e.clientname}</option>
                                            ))}
                                        </select>
                                    </div>
                                    : null
                                }
                            </div>
                            <div className="btn-cont">
                                {department === "Accounts" ?
                                    <AddProjectClientDetails pid={project.pr_code} getProjectDetails={getProjectDetails} clientslist={clientslist} getProjectClientList={getProjectClientList} />
                                    : null
                                }
                            </div>
                        </div>
                        : null
                }

                {/* Tabs and Content */}
                {<div className="bloc-tabss wfm-without-margin">
                    {clientExists ?
                        <>
                            <button
                                className={toggleStatechange === 1 ? "dashboardcomponent-tabs font-weight400 font-size-subheading dashboardcomponent-active-tabs" : "dashboardcomponent-tabs font-weight400 font-size-subheading"}
                                onClick={() => setToggleStatechange(1)}
                            >
                                Client Details
                            </button>
                            <button
                                className={toggleStatechange === 2 ? "dashboardcomponent-tabs font-weight400 font-size-subheading dashboardcomponent-active-tabs" : "dashboardcomponent-tabs font-weight400 font-size-subheading"}
                                onClick={() => setToggleStatechange(2)}
                            >
                                Work Stages
                            </button>

                            <button
                                className={toggleStatechange === 3 ? "dashboardcomponent-tabs font-weight400 font-size-subheading dashboardcomponent-active-tabs" : "dashboardcomponent-tabs font-weight400 font-size-subheading"}
                                onClick={() => setToggleStatechange(3)}
                            >
                                Invoices
                            </button>
                            <button
                                className={toggleStatechange === 4 ? "dashboardcomponent-tabs font-weight400 font-size-subheading dashboardcomponent-active-tabs" : "dashboardcomponent-tabs font-weight400 font-size-subheading"}
                                onClick={() => setToggleStatechange(4)}
                            >
                                Payments Received
                            </button>
                            <button
                                className={toggleStatechange === 5 ? "dashboardcomponent-tabs font-weight400 font-size-subheading dashboardcomponent-active-tabs" : "dashboardcomponent-tabs font-weight400 font-size-subheading"}
                                onClick={() => setToggleStatechange(5)}
                            >
                                Submission Documents
                            </button>
                        </>
                        : null}
                    <button
                        className={toggleStatechange === 6 ? "dashboardcomponent-tabs font-weight400 font-size-subheading dashboardcomponent-active-tabs" : "dashboardcomponent-tabs font-weight400 font-size-subheading"}
                        onClick={() => setToggleStatechange(6)}
                    >
                        Tender Documents
                    </button>
                </div>}

                <>
                    <div className="info-box-div-without-bg">
                        {toggleStatechange === 1 ?
                            <ProjectClientDetailsTable projectID={projectID} client={client} clientwiseID={clientwiseID} clientslist={clientslist} getProjectClientList={getProjectClientList} projectEmployeeList={projectEmployeeList} />
                            : null
                        }
                        {toggleStatechange === 2 ?
                            <ProjectWorkStagesTable projectID={projectID} client={client} clientwiseID={clientwiseID} clientslist={clientslist} />
                            : null
                        }
                        {toggleStatechange === 3 ?
                            <ProjectInvoicesDetailsTable projectID={projectID} client={client} clientwiseID={clientwiseID} clientslist={clientslist} />
                            : null
                        }
                        {toggleStatechange === 4 ?
                            <ProjectInvoicesPaymentsTable projectID={projectID} client={client} clientwiseID={clientwiseID} clientslist={clientslist} />
                            : null
                        }
                        {toggleStatechange === 5 ?
                            <ProjectSubmissionDocumentTable projectID={projectID} client={client} clientwiseID={clientwiseID} clientslist={clientslist} />
                            : null
                        }
                        {toggleStatechange === 6 ?
                            <ProjectTenderDocumentTable projectID={projectID} client={client} clientwiseID={clientwiseID} clientslist={clientslist} />
                            : null
                        }
                    </div>

                </>
            </div >
        </>
    );
};


export {
    ProjectDetailsPage,

};
