import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import { BASE_URL, BASE_URL_PREBID } from "../../config/axios"
import { formattedDate, formattedDateLong } from "../Date"
import { formatCurrencyIndian, handleErrorToast, ViewFile, ViewOtherFile, ViewEmployeeList } from "../custom"
import { DropdownArrow, AddwithWhiteCircle, UpdatePencil, Attachment, Eye, UpdatePencilWhite, DeleteDustbin } from "../AllSvg"
import { ToastContainer, toast } from "react-toastify";
import { StagesManagement } from "../Modals/Addstages";
import Updatestages from "../Modals/Updatestages";
import { useNavigate } from "react-router-dom";
import ReceivedAmount from "../../pages/account/Main/ReceivedAmount";
import { DeleteInvoice } from "./Invoice";


const AddProjectClientDetails = ({ pid, allEmployeeData, getProjectDetails, clientslist, getProjectClientList }) => {

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setFormData({
            project: pid,
            client: '',
            client_name: '',
            pr_project: false,
            wo_date: '',
            target_date: '',
            due_date: '',
            woamount: '',
            gst_amount: '',
            without_gst_amount: '',
            confirm_by: '',
            confirmation_mode: '',
            status: 'pending',
        });
        setErrors({});
        setShow(false);
    }
    const handleShow = () => {
        setFormData({
            project: pid,
            client: '',
            client_name: '',
            pr_project: false,
            wo_date: '',
            target_date: '',
            due_date: '',
            woamount: '',
            gst_amount: '',
            without_gst_amount: '',
            confirm_by: '',
            confirmation_mode: '',
            status: 'pending',
        });
        getClientDetails();
        setShow(true);
    };
    const [loading, setLoading] = useState(false); //loading logic
    const [formData, setFormData] = useState({
        project: pid,
        client: '',
        client_name: '',
        pr_project: false,
        wo_date: '',
        target_date: '',
        due_date: '',
        woamount: '',
        gst_amount: '',
        without_gst_amount: '',
        confirm_by: '',
        confirmation_mode: '',
        status: 'pending',
    });

    const [errors, setErrors] = useState({});

    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};

        const requiredFields = [
            'project',
            'client',
            'client_name',
            // 'pr_project',
            'wo_date',
            'target_date',
            'due_date',
            'woamount',
            'gst_amount',
            'without_gst_amount',
            'confirm_by',
            'confirmation_mode',
            'status',
        ];

        requiredFields.forEach(field => {
            if (!formData[field]) {
                newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };


    const [allClientsList, setAllClientsList] = useState([]);
    const [allClientsNameList, setAllClientsNameList] = useState([]);
    const getClientDetails = async () => {
        try {
            const clients = await axios.get(`${BASE_URL_PREBID}/project/allclient/`);
            setAllClientsList(clients.data);
            const clientNames = await axios.get(`${BASE_URL_PREBID}/project/clientName/`);
            setAllClientsNameList(clientNames.data);

        } catch (err) {
            console.error("Error fetching client data:", err.message);
            alert(err.message);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
            try {
                let res = await axios.post(`${BASE_URL_PREBID}/project/projectdetails/`, formData,)

                if (res.status === 200 || res.status === 201) {
                    await getProjectDetails();
                    await getProjectClientList();
                    handleClose();
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err)
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        } else {
            console.log('Form contains errors:', errors);
        }
    };

    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;
    //     setInputState({
    //         ...inputState,
    //         [name]: value,
    //     });
    //     setFormData({
    //         ...formData,
    //         [name]: value,
    //     });
    //     setErrors((prevErrors) => {
    //         const updatedErrors = { ...prevErrors };
    //         if (value) {
    //             delete updatedErrors[name];
    //         }
    //         return updatedErrors;
    //     });
    // };


    const handleInputChange = (e) => {
        const { name, type, checked, value } = e.target;


        if (name === "without_gst_amount") {
            const withoutGstAmount = parseFloat(value) || 0;
            const gstPercentage = 0.18;
            const gstAmount = (withoutGstAmount * gstPercentage).toFixed(2);
            const woAmount = (withoutGstAmount + parseFloat(gstAmount)).toFixed(2);

            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
                gst_amount: gstAmount,
                woamount: woAmount,
            }));

            setInputState({
                ...inputState,
                [name]: value,
            });
        }




        if (name === "client") {
            const clientExists = clientslist.normal_client?.length > 0;

            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
                pr_project: clientExists,
            }));

            setInputState((prevState) => ({
                ...prevState,
                pr_project: clientExists ? "green" : "",
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));

            setInputState({
                ...inputState,
                [name]: value,
            });
        }

        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            if (value) {
                delete updatedErrors[name];
            }
            return updatedErrors;
        });

    };


    const gstPercentage = 0.18; // 18% GST rate in decimal form
    const withoutGstAmount = parseFloat(formData.without_gst_amount);
    const gstAmount = (withoutGstAmount * gstPercentage).toFixed(2);
    const woAmount = (withoutGstAmount + parseFloat(gstAmount)).toFixed(2);

    const updatedFormData = {
        ...formData,
        gst_amount: gstAmount,
        woamount: woAmount,
    }


    // console.log("add formData")
    // console.log(formData)


    return (
        <>

            <button className='upload-svg' onClick={handleShow}>
                <AddwithWhiteCircle />
                {" "}
                Add Client
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="dialog-modal-assign-task dialog-model" >
                <Modal.Header closeButton>
                    <Modal.Title className="modal-title">Add Client Details </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

                    <div className="modal-body-padding">
                        <form>
                            <div className="form-flex-wrap">

                                <div className="flex-column form-group-select">
                                    <label className="form-labels">Client Name:<span className="required">*</span></label>
                                    <select
                                        name="client_name"
                                        value={formData.client_name}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.client_name ? 'error' : inputState.client_name ? 'success' : ''}`}
                                    >
                                        <option value="">Select a Client</option>
                                        {allClientsNameList.map((clientnames) => (
                                            <option key={clientnames.id} value={clientnames.id}>{clientnames.title}</option>
                                        ))}
                                    </select>
                                    <DropdownArrow />
                                    {errors.client_name && <span className="error-message">{errors.client_name}</span>}
                                </div>

                                <div className="flex-column form-group-select">
                                    <label className="form-labels">Client:<span className="required">*</span></label>
                                    <select
                                        name="client"
                                        value={formData.client}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.client ? 'error' : inputState.client ? 'success' : ''}`}
                                    >
                                        <option value="">Select a Client</option>
                                        {allClientsList.map((client) => (
                                            <option key={client.id} value={client.id}>{client.name}</option>
                                        ))}
                                    </select>
                                    <DropdownArrow />
                                    {errors.client && <span className="error-message">{errors.client}</span>}
                                </div>

                                <div className="flex-column">
                                    <label className="form-labels">Work Order Date:<span className="required">*</span></label>
                                    <input
                                        type="date"
                                        name="wo_date"
                                        value={formData.wo_date || ""}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.wo_date ? 'error' : inputState.wo_date ? 'success' : ''}`}
                                    />
                                    {errors.wo_date && <span className="error-message">{errors.wo_date}</span>}
                                </div>

                                <div className="flex-column">
                                    <label className="form-labels">Target Date:<span className="required">*</span></label>
                                    <input
                                        type="date"
                                        name="target_date"
                                        value={formData.target_date || ""}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.target_date ? 'error' : inputState.target_date ? 'success' : ''}`}
                                    />
                                    {errors.target_date && <span className="error-message">{errors.target_date}</span>}
                                </div>

                                <div className="flex-column">
                                    <label className="form-labels">Due Date:<span className="required">*</span></label>
                                    <input
                                        type="date"
                                        name="due_date"
                                        value={formData.due_date || ""}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.due_date ? 'error' : inputState.due_date ? 'success' : ''}`}
                                    />
                                    {errors.due_date && <span className="error-message">{errors.due_date}</span>}
                                </div>

                                <div className="flex-column">
                                    <label className="form-labels">Work Order Amount:<span className="required">*</span></label>
                                    <input
                                        type="number"
                                        name="without_gst_amount"
                                        value={formData.without_gst_amount || ""}
                                        onChange={handleInputChange}
                                        onWheel={(e) => e.target.blur()}
                                        className={`form-input ${errors.without_gst_amount ? 'error' : inputState.without_gst_amount ? 'success' : ''}`}
                                    />
                                    {errors.without_gst_amount && <span className="error-message">{errors.without_gst_amount}</span>}
                                </div>

                                <div className="flex-column">
                                    <label className="form-labels">GST Amount:<span className="required">(View Only)</span></label>
                                    <input
                                        type="number"
                                        name="gst_amount"
                                        readonly
                                        disabled
                                        style={{ cursor: 'not-allowed' }}
                                        value={formData.gst_amount || ""}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.gst_amount ? 'error' : inputState.gst_amount ? 'success' : ''}`}
                                    />
                                    {errors.gst_amount && <span className="error-message">{errors.gst_amount}</span>}
                                </div>

                                <div className="flex-column">
                                    <label className="form-labels">Total Amount:<span className="required">(View Only)</span></label>
                                    <input
                                        type="number"
                                        name="woamount"
                                        readonly
                                        disabled
                                        style={{ cursor: 'not-allowed' }}
                                        value={formData.woamount || ""}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.woamount ? 'error' : inputState.woamount ? 'success' : ''}`}
                                    />
                                    {errors.woamount && <span className="error-message">{errors.woamount}</span>}
                                </div>

                                <div className="flex-column">
                                    <label className="form-labels">Confirm By:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="confirm_by"
                                        value={formData.confirm_by || ""}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.confirm_by ? 'error' : inputState.confirm_by ? 'success' : ''}`}
                                    />
                                    {errors.confirm_by && <span className="error-message">{errors.confirm_by}</span>}
                                </div>

                                <div className="flex-column">
                                    <label className="form-labels">Confirmation Mode:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="confirmation_mode"
                                        value={formData.confirmation_mode || ""}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.confirmation_mode ? 'error' : inputState.confirmation_mode ? 'success' : ''}`}
                                    />
                                    {errors.confirmation_mode && <span className="error-message">{errors.confirmation_mode}</span>}
                                </div>

                                <div className="flex-column form-group-select">
                                    <label className="form-labels">Status:<span className="required">*</span></label>
                                    <select
                                        name="status"
                                        value={formData.status || ""}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.status ? 'error' : inputState.status ? 'success' : ''}`}
                                    >
                                        <option value="">Select a Status</option>
                                        <option value="pending">Pending</option>
                                        <option value="inprogress">In Progress</option>
                                        <option value="cancelled">Cancelled</option>
                                        <option value="completed">Completed</option>
                                        <option value="hold">Hold</option>
                                    </select>
                                    <DropdownArrow />
                                    {errors.status && <span className="error-message">{errors.status}</span>}
                                </div>


                            </div>
                            {/* <div className="flex-row">
                                <label htmlFor="pr_project" className="form-labels">
                                    PR Project {" "}
                                </label>
                                <hr className="field-cont-hr" />
                                <div className="checkbox-toggle">
                                    <input
                                        id="pr_toggle"
                                        name="pr_project"
                                        type="checkbox"
                                        className={`form-checkbox`}
                                        onChange={(e) =>
                                            handleInputChange({
                                                target: {
                                                    name: "pr_project",
                                                    value: e.target.checked,
                                                },
                                            })
                                        }
                                        checked={formData.pr_project}
                                    />
                                    <label htmlFor="pr_toggle"></label>
                                </div>
                            </div> */}
                            {errors.project && <span className="error-message">{errors.project}</span>}

                            <div className="button-models">
                                <button type="button" onClick={handleSubmit} disabled={loading} className="model-button model-button-submit">Submit</button>
                            </div>
                        </form>
                    </div>


                </Modal.Body>

            </Modal>

        </>
    );
};

const UpdateProjectClientDetails = ({ clientDetailsData, pid, allEmployeeData, getClientDetails, clientslist, getProjectClientList }) => {

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setFormData({
            project: pid,
            client: '',
            client_name: '',
            pr_project: false,
            wo_date: '',
            target_date: '',
            due_date: '',
            woamount: '',
            gst_amount: '',
            without_gst_amount: '',
            confirm_by: '',
            confirmation_mode: '',
            status: 'pending',
        });
        setErrors({});
        setShow(false);
    }
    const handleShow = () => {
        setFormData(clientDetailsData);
        getClientListDetails();
        setShow(true);
    };
    const [loading, setLoading] = useState(false); //loading logic
    const [formData, setFormData] = useState({
        project: pid,
        client: '',
        client_name: '',
        pr_project: false,
        wo_date: '',
        target_date: '',
        due_date: '',
        woamount: '',
        gst_amount: '',
        without_gst_amount: '',
        confirm_by: '',
        confirmation_mode: '',
        status: 'pending',
    });

    const [errors, setErrors] = useState({});

    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};

        const requiredFields = [
            'project',
            'client',
            'client_name',
            // 'pr_project',
            'wo_date',
            'target_date',
            'due_date',
            'woamount',
            'gst_amount',
            'without_gst_amount',
            'confirm_by',
            'confirmation_mode',
            'status',
        ];

        requiredFields.forEach(field => {
            if (!formData[field]) {
                newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };


    const [allClientsList, setAllClientsList] = useState([]);
    const [allClientsNameList, setAllClientsNameList] = useState([]);
    const getClientListDetails = async () => {
        try {
            const clients = await axios.get(`${BASE_URL_PREBID}/project/allclient/`);
            setAllClientsList(clients.data);
            const clientNames = await axios.get(`${BASE_URL_PREBID}/project/clientName/`);
            setAllClientsNameList(clientNames.data);

        } catch (err) {
            console.error("Error fetching client data:", err.message);
            alert(err.message);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
            try {
                let res = await axios.put(`${BASE_URL_PREBID}/project/projectdetailsupdate/${clientDetailsData.id}/`, formData,)

                if (res.status === 200 || res.status === 201) {
                    await getClientDetails();
                    await getProjectClientList();
                    handleClose();
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err)
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        } else {
            console.log('Form contains errors:', errors);
        }
    };

    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;
    //     setInputState({
    //         ...inputState,
    //         [name]: value,
    //     });
    //     setFormData({
    //         ...formData,
    //         [name]: value,
    //     });
    //     setErrors((prevErrors) => {
    //         const updatedErrors = { ...prevErrors };
    //         if (value) {
    //             delete updatedErrors[name];
    //         }
    //         return updatedErrors;
    //     });
    // };


    const handleInputChange = (e) => {
        const { name, type, checked, value } = e.target;

        if (name === "without_gst_amount") {
            const withoutGstAmount = parseFloat(value) || 0;
            const gstPercentage = 0.18;
            const gstAmount = (withoutGstAmount * gstPercentage).toFixed(2);
            const woAmount = (withoutGstAmount + parseFloat(gstAmount)).toFixed(2);

            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
                gst_amount: gstAmount,
                woamount: woAmount,
            }));

            setInputState({
                ...inputState,
                [name]: value,
            });
        }

        if (name === "client") {
            const clientExists = clientslist.normal_client?.length > 0;

            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
                pr_project: clientExists,
            }));

            setInputState((prevState) => ({
                ...prevState,
                pr_project: clientExists ? "green" : "",
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));

            setInputState({
                ...inputState,
                [name]: value,
            });
        }

        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            if (value) {
                delete updatedErrors[name];
            }
            return updatedErrors;
        });

    };


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target || e;

        // Handle checkbox, CreatableSelect, or standard input change
        const inputValue = type === 'checkbox' ? checked : value;

        setInputState({
            ...inputState,
            [name]: type === 'checkbox' ? (checked ? 'green' : '') : value.trim() ? 'green' : '',
        });

        setFormData({
            ...formData,
            [name]: inputValue,
        });
    };



    // console.log("UpdateformData")
    // console.log(formData)


    return (
        <>

            <button className='upload-svg' onClick={handleShow}>
                <UpdatePencilWhite />
                {" "}
                Update Client
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="dialog-modal-assign-task dialog-model" >
                <Modal.Header closeButton>
                    <Modal.Title className="modal-title">Update Client Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

                    <div className="modal-body-padding">
                        <form>
                            <div className="form-flex-wrap">

                                <div className="flex-column form-group-select">
                                    <label className="form-labels">Client Name:<span className="required">(View Only)</span></label>
                                    <select
                                        name="client_name"
                                        value={formData.client_name}
                                        onChange={handleInputChange}
                                        readonly
                                        disabled
                                        style={{ cursor: 'not-allowed' }}
                                        className={`form-input form-group-selection ${errors.client_name ? 'error' : inputState.client_name ? 'success' : ''}`}
                                    >
                                        <option value="">Select a Client</option>
                                        {allClientsNameList.map((clientnames) => (
                                            <option key={clientnames.id} value={clientnames.id}>{clientnames.title}</option>
                                        ))}
                                    </select>
                                    {errors.client_name && <span className="error-message">{errors.client_name}</span>}
                                </div>

                                <div className="flex-column form-group-select">
                                    <label className="form-labels">Client:<span className="required">(View Only)</span></label>
                                    <select
                                        name="client"
                                        value={formData.client}
                                        onChange={handleInputChange}
                                        readonly
                                        disabled
                                        style={{ cursor: 'not-allowed' }}
                                        className={`form-input form-group-selection ${errors.client ? 'error' : inputState.client ? 'success' : ''}`}
                                    >
                                        <option value="">Select a Client</option>
                                        {allClientsList.map((client) => (
                                            <option key={client.id} value={client.id}>{client.name}</option>
                                        ))}
                                    </select>
                                    {errors.client && <span className="error-message">{errors.client}</span>}
                                </div>

                                <div className="flex-column">
                                    <label className="form-labels">Work Order Date:<span className="required">*</span></label>
                                    <input
                                        type="date"
                                        name="wo_date"
                                        value={formData.wo_date || ""}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.wo_date ? 'error' : inputState.wo_date ? 'success' : ''}`}
                                    />
                                    {errors.wo_date && <span className="error-message">{errors.wo_date}</span>}
                                </div>

                                <div className="flex-column">
                                    <label className="form-labels">Target Date:<span className="required">*</span></label>
                                    <input
                                        type="date"
                                        name="target_date"
                                        value={formData.target_date || ""}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.target_date ? 'error' : inputState.target_date ? 'success' : ''}`}
                                    />
                                    {errors.target_date && <span className="error-message">{errors.target_date}</span>}
                                </div>

                                <div className="flex-column">
                                    <label className="form-labels">Due Date:<span className="required">*</span></label>
                                    <input
                                        type="date"
                                        name="due_date"
                                        value={formData.due_date || ""}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.due_date ? 'error' : inputState.due_date ? 'success' : ''}`}
                                    />
                                    {errors.due_date && <span className="error-message">{errors.due_date}</span>}
                                </div>

                                <div className="flex-column">
                                    <label className="form-labels">Work Order Amount:<span className="required">(View Only)</span></label>
                                    <input
                                        type="number"
                                        name="without_gst_amount"
                                        value={formData.without_gst_amount || ""}
                                        readonly
                                        disabled
                                        style={{ cursor: 'not-allowed' }}
                                        onChange={handleInputChange}
                                        onWheel={(e) => e.target.blur()}
                                        className={`form-input ${errors.without_gst_amount ? 'error' : inputState.without_gst_amount ? 'success' : ''}`}
                                    />
                                    {errors.without_gst_amount && <span className="error-message">{errors.without_gst_amount}</span>}
                                </div>

                                <div className="flex-column">
                                    <label className="form-labels">GST Amount:<span className="required">(View Only)</span></label>
                                    <input
                                        type="number"
                                        name="gst_amount"
                                        readonly
                                        disabled
                                        style={{ cursor: 'not-allowed' }}
                                        value={formData.gst_amount || ""}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.gst_amount ? 'error' : inputState.gst_amount ? 'success' : ''}`}
                                    />
                                    {errors.gst_amount && <span className="error-message">{errors.gst_amount}</span>}
                                </div>

                                <div className="flex-column">
                                    <label className="form-labels">Total Amount:<span className="required">(View Only)</span></label>
                                    <input
                                        type="number"
                                        name="woamount"
                                        readonly
                                        disabled
                                        style={{ cursor: 'not-allowed' }}
                                        value={formData.woamount || ""}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.woamount ? 'error' : inputState.woamount ? 'success' : ''}`}
                                    />
                                    {errors.woamount && <span className="error-message">{errors.woamount}</span>}
                                </div>

                                <div className="flex-column">
                                    <label className="form-labels">Confirm By:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="confirm_by"
                                        value={formData.confirm_by || ""}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.confirm_by ? 'error' : inputState.confirm_by ? 'success' : ''}`}
                                    />
                                    {errors.confirm_by && <span className="error-message">{errors.confirm_by}</span>}
                                </div>

                                <div className="flex-column">
                                    <label className="form-labels">Confirmation Mode:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="confirmation_mode"
                                        value={formData.confirmation_mode || ""}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.confirmation_mode ? 'error' : inputState.confirmation_mode ? 'success' : ''}`}
                                    />
                                    {errors.confirmation_mode && <span className="error-message">{errors.confirmation_mode}</span>}
                                </div>

                                <div className="flex-column form-group-select">
                                    <label className="form-labels">Status:<span className="required">*</span></label>
                                    <select
                                        name="status"
                                        value={formData.status || ""}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.status ? 'error' : inputState.status ? 'success' : ''}`}
                                    >
                                        <option value="">Select a Status</option>
                                        <option value="pending">Pending</option>
                                        <option value="inprogress">In Progress</option>
                                        <option value="cancelled">Cancelled</option>
                                        <option value="completed">Completed</option>
                                        <option value="hold">Hold</option>
                                    </select>
                                    <DropdownArrow />
                                    {errors.status && <span className="error-message">{errors.status}</span>}
                                </div>


                            </div>
                            {/* <div className="flex-row">
                                <label htmlFor="pr_project" className="form-labels">
                                    PR Project {" "}
                                </label>
                                <hr className="field-cont-hr" />
                                <div className="checkbox-toggle">
                                    <input
                                        id="pr_toggle"
                                        name="pr_project"
                                        type="checkbox"
                                        className={`form-checkbox`}
                                        onChange={(e) =>
                                            handleInputChange({
                                                target: {
                                                    name: "pr_project",
                                                    value: e.target.checked,
                                                },
                                            })
                                        }
                                        checked={formData.pr_project}
                                    />
                                    <label htmlFor="pr_toggle"></label>
                                </div>
                            </div> */}
                            {errors.project && <span className="error-message">{errors.project}</span>}

                            <div className="button-models">
                                <button type="button" onClick={handleSubmit} className="model-button model-button-submit">Submit</button>
                            </div>
                        </form>
                    </div>


                </Modal.Body>

            </Modal>

        </>
    );
};

const ProjectClientDetailsTable = ({ projectID, client, clientwiseID, clientslist, getProjectClientList, projectEmployeeList }) => {

    const [buffer, setBuffering] = useState(false);
    const [clientDetailsData, setClientDetailsData] = useState([]);

    useEffect(() => {
        if (client) {
            getClientDetails();
        }
    }, [client]);

    const getClientDetails = async () => {
        setBuffering(true);
        try {
            const response = await axios.get(
                `${BASE_URL}/project/project-details/${projectID}/${client}/`
            );
            setClientDetailsData(response.data);
        } catch (err) {
        } finally {
            setBuffering(false);
        }
    };


    const department = sessionStorage.getItem('department')

    return (
        <>

            <div className="table-css-white-background">

                <div className="heading-bg-color-white">
                    <div className="jd-heading-outer-flex ">
                        <h2 className="jd-heading-main font-weight600  font-size-heading">Client Details of "{clientDetailsData?.client_name_title}" <span className="required">{clientDetailsData?.pr_project ? "(PR Project)" : ""}</span> </h2>
                        <div className="field-cont">

                            {department === "Accounts" ?
                                <StagesManagement getproject={getProjectClientList} id={clientwiseID} />
                                :
                                (clientDetailsData?.status === "pending" ? null :
                                    <Updatestages projectID={projectID} id={clientwiseID} allEmployeeData={projectEmployeeList} />
                                )
                            }
                            <UpdateProjectClientDetails clientDetailsData={clientDetailsData} pid={projectID} getClientDetails={getClientDetails} clientslist={clientslist} getProjectClientList={getProjectClientList} />
                        </div>
                    </div>
                    <div>
                        <div className="jd-heading-bottom-bold"></div>
                        <div className="jd-heading-bottom-light"></div>
                    </div>
                </div>

                <div className="info-box-div">
                    <div className="flex-row justify-between">
                        <div className="personal-detail">

                            {buffer ? <div className="spinner-small"></div> :
                                <table className="width-40vw">
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">Project Name</td>
                                        <th className="personal-detail-2 font-weight500 font-size-text">
                                            {clientDetailsData?.project_name ? clientDetailsData?.project_name : "-"}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">Client GST Name</td>
                                        <th className="personal-detail-2 font-weight500 font-size-text">
                                            {clientDetailsData?.client_full_name ? clientDetailsData?.client_full_name : "-"}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">Work Order Date</td>
                                        <th className="personal-detail-2 font-weight500 font-size-text">
                                            {clientDetailsData?.wo_date ? formattedDateLong(clientDetailsData?.wo_date) : "-"}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">Target Date</td>
                                        <th className="personal-detail-2 font-weight500 font-size-text">
                                            {clientDetailsData?.target_date ? formattedDateLong(clientDetailsData?.target_date) : "-"}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">Due Date</td>
                                        <th className="personal-detail-2 font-weight500 font-size-text">
                                            {clientDetailsData?.due_date ? formattedDateLong(clientDetailsData?.due_date) : "-"}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">Status</td>
                                        <th className="personal-detail-2 font-weight500 font-size-text form-text-trasformation-uppercase">
                                            {clientDetailsData?.status ? clientDetailsData?.status : "-"}
                                        </th>
                                    </tr>
                                </table>
                            }
                        </div >

                        <div className="personal-detail">

                            {buffer ? <div className="spinner-small"></div> :
                                <table className="width-40vw">
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">Work Order Amount</td>
                                        <th className="personal-detail-2 font-weight500 font-size-text">
                                            {clientDetailsData?.without_gst_amount ? formatCurrencyIndian(clientDetailsData.without_gst_amount) : "-"}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">GST Amount</td>
                                        <th className="personal-detail-2 font-weight500 font-size-text">
                                            {clientDetailsData?.gst_amount ? formatCurrencyIndian(clientDetailsData.gst_amount) : "-"}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">Total Amount (with GST)</td>
                                        <th className="personal-detail-2 font-weight500 font-size-text">
                                            {clientDetailsData?.woamount ? formatCurrencyIndian(clientDetailsData.woamount) : "-"}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">Confirm By</td>
                                        <th className="personal-detail-2 font-weight500 font-size-text">
                                            {clientDetailsData?.confirm_by ? clientDetailsData?.confirm_by : "-"}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">Confirmation Mode</td>
                                        <th className="personal-detail-2 font-weight500 font-size-text">
                                            {clientDetailsData?.confirmation_mode ? clientDetailsData?.confirmation_mode : "-"}
                                        </th>
                                    </tr>
                                </table>
                            }
                        </div >
                    </div >
                </div >
            </div>

        </>
    )
}

const DeleteWorkStage = ({ i, getWorkStagesData }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

        try {
            let res = await axios.delete(
                `${BASE_URL}/project/workstageupdate/${i.wid}/`
            );

            if (res.status === 200) {
                await getWorkStagesData();
                setShow(false);
            } else {
                alert(res);
            }
        } catch (err) {
            handleErrorToast(err)
        } finally {
            toast.dismiss(loadingToastId);
        }
    };

    return (
        <>
            <button title="Delete Work Stage" className="model-delete-button" onClick={handleShow}>
                <DeleteDustbin />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
                <Modal.Header closeButton>
                    <Modal.Title>Delete Work Stage</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are You Sure, You want to Delete Work Stage "{i.workStages}"

                    <div className="button-models">

                        <button
                            onClick={handleFormSubmitDelete}
                            className="model-button model-button-delete"
                        >
                            Delete
                        </button>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
};

const ProjectWorkStagesTable = ({ projectID, client, clientwiseID }) => {

    const [buffer, setBuffering] = useState(false);
    const [workStagesData, setWorkStagesData] = useState([]);

    useEffect(() => {
        if (client) {
            getWorkStagesData();
        }
    }, [client]);

    const getWorkStagesData = async () => {
        setBuffering(true);
        try {
            const res = await axios.get(`${BASE_URL}/project/workstagesbyprojectDetail/${clientwiseID}/`);
            setWorkStagesData(res.data);
        } catch (err) {
        } finally {
            setBuffering(false);
        }
    };

    return (
        <>

            <div className="table-css-white-background">
                {/* <div className="attendance-box"> */}
                <div className="heading-bg-color-white">
                    <div className="jd-heading-outer-flex ">
                        <h2 className="jd-heading-main font-weight600  font-size-heading">Work Stages</h2>
                        <div className="field-cont">
                        </div>
                    </div>
                    <div>
                        <div className="jd-heading-bottom-bold"></div>
                        <div className="jd-heading-bottom-light"></div>
                    </div>
                </div>

                {/* <div className="datagrid-container">
                    <div className="invoice-table"> */}

                <div className="invoice-table2 " style={{ width: "100%" }}>
                    <table className="custom-table">
                        <thead>
                            <tr className="custom-table-head-trr">
                                <th className="align-center">S. No.</th>
                                <th className="align-center">Work Stage</th>
                                <th className="align-center">Percentage</th>
                                <th className="align-center width-5vw">View Employees</th>
                                <th className="align-center">Add Docs</th>
                                <th className="align-center">Action</th>
                            </tr>
                        </thead>
                        {buffer ? <div className="spinner"></div> : //buffering logic
                            <tbody>
                                {workStagesData.map((i, index) => (
                                    <React.Fragment key={index}>
                                        <tr className="tr-border-bottom">
                                            <td colSpan="6"></td>
                                        </tr>
                                        <tr className="custom-table-head-tddd font-weight500 font-size-label">
                                            <td className="align-center">{index + 1}</td>
                                            <td className="align-center">{i.workStages}</td>
                                            <td className="align-center">{i.percentage}</td>

                                            <td className="align-center" >
                                                <div className="flex-row align-center" style={{ justifyContent: "center" }}>
                                                    {i.employee_count}
                                                    <hr className="field-cont-hr" />
                                                    <ViewEmployeeList titlename={`Employees Assigned to "${i.workStages}" `} list={i.employee_detail} />
                                                </div>
                                            </td>

                                            <td className="align-center width-10vw ">

                                                {/* <div className="flex-row width-5vw"> */}
                                                <AddSubmissionDocuments projectID={projectID} clientwiseID={clientwiseID} getWorkStagesData={getWorkStagesData} workstageId={i.wid} />
                                                {/* </div> */}
                                            </td>
                                            {!i.invoice_generated && !i.completed ? <td className="align-center width-5vw ">
                                                <DeleteWorkStage i={i} getWorkStagesData={getWorkStagesData} />
                                            </td> : null}

                                        </tr>


                                    </React.Fragment>
                                ))}
                            </tbody>
                        }
                    </table>
                </div>

                {/* </div>
                </div> */}
                {/* </div> */}
            </div>

        </>
    )
}


const DeleteProjectDocuments = ({ id, getDocuments }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic


        try {
            console.log("Delete Expense Detail Api");
            let res = await axios.delete(
                `${BASE_URL}/project/documentupdate/${id.id}/`
            );

            if (res.status === 200) {
                await getDocuments();
                setShow(false);
            } else {
                alert(res);
            }
        } catch (err) {
            handleErrorToast(err)
        } finally {
            toast.dismiss(loadingToastId);
        }
    };

    return (
        <>
            <button className="model-delete-button" onClick={handleShow}>
                <DeleteDustbin />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
                <Modal.Header closeButton>
                    <Modal.Title>Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are You Sure, You want to Delete this File

                    <div className="button-models">

                        <button
                            onClick={handleFormSubmitDelete}
                            className="model-button model-button-delete"
                        >
                            Delete
                        </button>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
};


const PerformaInvoice = ({ id }) => {

    //   ****************   Modal Section
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        getClientDetails();
    };



    const [formData, setFormData] = useState({
        billing_date: '',
        invoice_number: '',
        billamount: '',
        gst_amount: '',
        wihoutgst_amount: '',
        bill_percentage: '',
        work_stages: '',
        project: ''

    });


    const [allWorkStagesStats, setAllWorkstagesStatus] = useState([]);


    const [filteredWorkStagesNames, setFilteredWorkStagesNames] = useState([])
    const [totalPercentage, setTotalPercentage] = useState([])


    const getClientDetails = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL_PREBID}/project/pendingforInvoiceforaccount/${id}/`
            );

            const ress = await axios.get(`${BASE_URL_PREBID}/project/projectdetails/${id}/`)

            const workStages = res.data

            setAllWorkstagesStatus(res.data)


            const workStageNames = workStages.map(entry => entry.workStages).join(", ");
            setFilteredWorkStagesNames(workStageNames);



            // Calculate the sum of total percentage
            const totalPercentage = workStages.reduce((acc, entry) => acc + entry.percentage, 0);

            setTotalPercentage(totalPercentage);

            const woamount = ress.data.without_gst_amount; // Assuming total amount is stored in res.data[0].woamount

            const billPercentageDecimal = totalPercentage / 100;

            const billAmount = woamount * billPercentageDecimal;

            const gstPercentage = 0.18; // 18% GST rate in decimal form

            const gstAmount = billAmount * gstPercentage;

            setFormData({
                billamount: billAmount + gstAmount,
                gst_amount: gstAmount,
                wihoutgst_amount: billAmount,
                bill_percentage: totalPercentage,
                work_stages: workStageNames,
                project: id,

            })
        } catch (err) {
            alert(err.message);
        }
    };



    const [errors, setErrors] = useState({});

    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};

        const requiredFields = [
            'invoice_number',


        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });




        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    console.log(formData)
    const [loading, setLoading] = useState(false); //loading logic


    const handleSubmit = async (e) => {
        e.preventDefault();


        const updatedFormData = {
            ...formData,
            billing_date: formattedDate

        }


        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
            setLoading(true); //loading logic
            try {
                let res = await axios.post(`${BASE_URL_PREBID}/project/generateinvoice/${id}/`, updatedFormData,)

                if (res.status === 200) {
                    window.location.reload();
                } else {
                    alert(res);
                }
            } catch (err) {
                // alert(err);
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? 'green' : '',
        });

        setFormData({
            ...formData,
            [name]: value,
        });

        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            if (value) {
                delete updatedErrors[name];
            }
            return updatedErrors;
        });
    };









    return (
        <>


            <button
                title="Generate Invoice"
                onClick={handleShow}
                className="upload-svga "

            >

                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" enable-background="new 0 0 32 32" viewBox="0 0 32 32" fill='white' id="add"><path d="M2.4375,17.9375h11.625v11.625c0,1.06854,0.86896,1.9375,1.9375,1.9375s1.9375-0.86896,1.9375-1.9375v-11.625h11.625c1.06854,0,1.9375-0.86896,1.9375-1.9375s-0.86896-1.9375-1.9375-1.9375h-11.625V2.4375C17.9375,1.36896,17.06854,0.5,16,0.5s-1.9375,0.86896-1.9375,1.9375v11.625H2.4375C1.36896,14.0625,0.5,14.93146,0.5,16S1.36896,17.9375,2.4375,17.9375z"></path></svg>

            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="dialog-modal-assign-task"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="modal-title">PerformaInvoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

                    <div className='modal-body-padding'>
                        <form >

                            <div className='form-flex-wrap'>

                                <div className="flex-column">
                                    <label>Invoice Number:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="invoice_number"
                                        value={formData.invoice_number}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.invoice_number ? 'error' : inputState.invoice_number ? 'success' : ''}`}
                                    />
                                    {errors.invoice_number && <span className="error-message">{errors.without_gst_invoice_numbermount}</span>}
                                </div>












                            </div>

                            <div className="button-models">
                                <button onClick={handleSubmit} className="model-button model-button-submit">Submit</button>
                            </div>
                        </form>
                    </div>




                </Modal.Body>
            </Modal>
        </>
    );
};


const Invoice = ({ id, getInvoiceDetails }) => {

    //   ****************   Modal Section
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        getClientDetails();
    };



    const [formData, setFormData] = useState({
        billing_date: '',
        invoice_number: '',
        billamount: '',
        gst_amount: '',
        wihoutgst_amount: '',
        bill_percentage: '',
        work_stages: '',
        project_detail: ''

    });




    const getClientDetails = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL_PREBID}/project/pendingforInvoiceforaccount/${id}/`
            );

            const ress = await axios.get(`${BASE_URL_PREBID}/project/projectdetailsupdate/${id}/`)

            const workStages = res.data



            const workStageNames = workStages.map(entry => entry.workStages).join(", ");



            // Calculate the sum of total percentage
            const totalPercentage = workStages.reduce((acc, entry) => acc + entry.percentage, 0);



            const woamount = ress.data.without_gst_amount; // Assuming total amount is stored in res.data[0].woamount

            const billPercentageDecimal = totalPercentage / 100;

            const billAmount = woamount * billPercentageDecimal;

            const gstPercentage = 0.18; // 18% GST rate in decimal form

            const gstAmount = (billAmount * gstPercentage).toFixed(2);


            setFormData({
                billamount: Number(billAmount) + Number(gstAmount),
                gst_amount: gstAmount,
                wihoutgst_amount: billAmount,
                bill_percentage: totalPercentage,
                work_stages: workStageNames,
                project_detail: id,

            })
        } catch (err) {
            alert(err.message);
        }
    };



    const [errors, setErrors] = useState({});

    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};

        const requiredFields = [
            'invoice_number',


        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });




        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    console.log(formData)
    const [loading, setLoading] = useState(false); //loading logic


    const handleSubmit = async (e) => {
        e.preventDefault();


        const updatedFormData = {
            ...formData,
            billing_date: formattedDate

        }


        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
            setLoading(true); //loading logic
            try {
                let res = await axios.post(`${BASE_URL_PREBID}/project/generateinvoice/${id}/`, updatedFormData,)

                if (res.status === 200) {
                    await getInvoiceDetails();
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId)
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? 'green' : '',
        });

        setFormData({
            ...formData,
            [name]: value,
        });

        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            if (value) {
                delete updatedErrors[name];
            }
            return updatedErrors;
        });
    };









    return (
        <>

            {/*     
        <button className="models-button model-add" onClick={handleShow}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clip-path="url(#clip0_1415_3177)">
              <path
                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                fill="#2576BC"
              />
            </g>
            <defs>
              <clipPath id="clip0_1415_3177">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button> */}
            <button
                title="Generate Invoice"
                onClick={handleShow}
                className="upload-svg "

            >Generate Invoice{" "}

                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" enable-background="new 0 0 32 32" viewBox="0 0 32 32" fill='white' id="add"><path d="M2.4375,17.9375h11.625v11.625c0,1.06854,0.86896,1.9375,1.9375,1.9375s1.9375-0.86896,1.9375-1.9375v-11.625h11.625c1.06854,0,1.9375-0.86896,1.9375-1.9375s-0.86896-1.9375-1.9375-1.9375h-11.625V2.4375C17.9375,1.36896,17.06854,0.5,16,0.5s-1.9375,0.86896-1.9375,1.9375v11.625H2.4375C1.36896,14.0625,0.5,14.93146,0.5,16S1.36896,17.9375,2.4375,17.9375z"></path></svg>

            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="dialog-modal-assign-task"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="modal-title">Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

                    <div className='modal-body-padding'>
                        <form >

                            <div className='form-flex-wrap'>

                                <div className="flex-column">
                                    <label>Invoice Number:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="invoice_number"
                                        value={formData.invoice_number}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.invoice_number ? 'error' : inputState.invoice_number ? 'success' : ''}`}
                                    />
                                    {errors.invoice_number && <span className="error-message">{errors.without_gst_invoice_numbermount}</span>}
                                </div>












                            </div>

                            <div className="button-models">
                                <button onClick={handleSubmit} className="model-button model-button-submit">Submit</button>
                            </div>
                        </form>
                    </div>




                </Modal.Body>
            </Modal>
        </>
    );
};

const ProjectInvoicesDetailsTable = ({ projectID, client, clientwiseID }) => {

    const [buffer, setBuffering] = useState(false);
    const [allWorkStagesStats, setAllWorkstagesStatus] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        if (client) {
            getInvoiceDetails();
        }
    }, [client]);

    const getInvoiceDetails = async () => {
        setBuffering(true);
        try {
            const res = await axios.get(`${BASE_URL}/project/invoicebyproject/${clientwiseID}/`);
            setFilteredData(res.data);
            const ress = await axios.get(
                `${BASE_URL}/project/pendingforInvoiceforaccount/${clientwiseID}/`
            );
            setAllWorkstagesStatus(ress.data)
        } catch (err) {
        } finally {
            setBuffering(false);
        }
    };



    const navigate = useNavigate()
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split('/');
    const baseUrl2 = "/" + urlParts[3] + `/invoiceSlip`;
    const baseUrl3 = "/" + urlParts[3] + `/performainvoiceslip`;
    const invoicePage = (pr_code) => {
        navigate(baseUrl2 + `/${pr_code}/`)
    }
    const performainvoicePage = (pr_code) => {
        navigate(baseUrl3 + `/${pr_code}/`)
    }

    const department = sessionStorage.getItem('department')

    return (
        <>

            <div className="table-css-white-background">
                {/* <div className="attendance-box"> */}
                <div className="heading-bg-color-white">
                    <div className="jd-heading-outer-flex ">
                        <h2 className="jd-heading-main font-weight600  font-size-heading">Invoice Details</h2>
                        <div className="field-cont">
                            <div>{allWorkStagesStats >= 0 ? "" : department === "Accounts" ? <Invoice id={clientwiseID} getInvoiceDetails={getInvoiceDetails} /> : null}</div>
                            {/* <Invoice id={clientwiseID} /> */}
                        </div>
                    </div>
                    <div>
                        <div className="jd-heading-bottom-bold"></div>
                        <div className="jd-heading-bottom-light"></div>
                    </div>
                </div>

                <div className="datagrid-container">
                    <div className="invoice-table">

                        <div className="invoice-table2 ">
                            <table className="project-details-table" >

                                <thead>
                                    <tr className="custom-table-head-invoices">
                                        <th className="align-center font-size-text font-weight600">Sr no.</th>
                                        <th className="align-center font-size-text font-weight600">Invoice No.</th>
                                        {/* <th className="align-center font-size-text font-weight600">Project Name</th> */}
                                        <th className="align-center font-size-text font-weight600">Client Name</th>
                                        <th className="align-center font-size-text font-weight600">Work Order Amount</th>
                                        <th className="align-center font-size-text font-weight600">GST</th>
                                        <th className="align-center font-size-text font-weight600">Total Amount</th>
                                        <th className="align-center font-size-text font-weight600">Billing Percentage</th>
                                        <th className="align-center font-size-text font-weight600">Billing Amount</th>
                                        {/* <th className="align-center font-size-text font-weight600">Balance to bill</th>  */}
                                        {/* <th className="align-center font-size-text font-weight600">Received Date</th>
                                        <th className="align-center font-size-text font-weight600">Received Amount</th> */}
                                        <th className="align-center font-size-text font-weight600">View Invoice</th>
                                        {/* <th className="align-center font-size-text font-weight600">View Perfoma Invoice</th> */}
                                        {/* <th className="align-center font-size-text font-weight600">Add Received Amt.</th> */}
                                        <th className="align-center font-size-text font-weight600">Delete Invoice</th>
                                    </tr>
                                </thead>


                                {buffer ? <div className="spinner"></div> : //buffering logic




                                    <tbody >

                                        {filteredData
                                            .sort((a, b) => a.client_name?.localeCompare(b.client_name, 'en', { ignorePunctuation: true }))

                                            .map((i, index) => (
                                                <React.Fragment key={index}>
                                                    <tr className="tr-border-bottom">
                                                        <td colSpan="8"></td>
                                                    </tr>
                                                    <tr className="custom-table-head-td">
                                                        <td className="align-center font-size-text font-weight400">{index + 1}</td>
                                                        <td className="align-center font-size-text font-weight400">{i.invoice_number}</td>
                                                        {/* <td className="align-center font-size-text font-weight400">{i.project_name} </td> */}
                                                        <td className="align-center font-size-text font-weight400">{i.client_name}<br />{i.contact_number}</td>
                                                        <td className="align-center font-size-text font-weight400">{formatCurrencyIndian(i.project_without_gst_amount)}</td>
                                                        <td className="align-center font-size-text font-weight400">{formatCurrencyIndian(i.project_gst_amount)}</td>
                                                        <td className="align-center font-size-text font-weight400">{formatCurrencyIndian(i.project_woamount)}</td>
                                                        <td className="align-center font-size-text font-weight400">{i.bill_percentage}%</td>
                                                        <td className="align-center font-size-text font-weight400">{formatCurrencyIndian(i.billamount)}</td>
                                                        {/* <td className="align-center font-size-text font-weight400">{i.woamount - i.received_amount}</td> */}
                                                        {/* <td className="align-center font-size-text font-weight400">{i.rec_date != null ? formattedDateLong(i.rec_date) : ""}</td>
                                                        <td className="align-center font-size-text font-weight400">{formatCurrencyIndian(i.amount_recived)}</td> */}
                                                        <td className="align-center font-size-text font-weight400" style={{ display: "flex" }}>
                                                            {/* <button className="allproject-dashboard  font-size-heading font-weight500" onClick={(e) => invoicePage(i.id)}>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="40"
                                                                    height="20"
                                                                    viewBox="0 0 30 30"
                                                                    fill="none">

                                                                    <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                                                                    <path
                                                                        d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                                                        fill="#2576BC"
                                                                    />

                                                                </svg>
                                                            </button> */}
                                                            <button className="models-button model-add"
                                                                onClick={(e) => invoicePage(i.id)}
                                                            >
                                                                <Eye />
                                                            </button>

                                                        </td>
                                                        {/* <td className="align-center">
                                                            <button className="models-button model-add" onClick={(e) => performainvoicePage(i.id)}>
                                                                <Eye />
                                                            </button>
                                                        </td> */}
                                                        {/* <td className="align-center">
                                                            <ReceivedAmount id={i.id} maxlimitreceive={i.billamount} />
                                                        </td> */}
                                                        <td>
                                                            <DeleteInvoice i={i} id={i.id} getInvoiceData={getInvoiceDetails} />
                                                        </td>



                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                    </tbody>



                                }
                            </table>
                        </div>

                    </div>
                </div>
                {/* </div> */}
            </div>

        </>
    )
}

const AddPaymentRecord = ({ id, invoiceList, getInvoicePayments }) => {

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setFormData({
            multiple_invoice: [],
            payment_id: '',
            amount: '',
            payment_date: '',
            payment_method: '',
            status: '',
            description: '',
            uploaded_file: null
        });

        setShow(false)
    };
    const handleShow = () => {
        setShow(true);
    };

    const [formData, setFormData] = useState({
        // invoice: '',
        multiple_invoice: [],
        payment_id: '',
        amount: '',
        payment_date: '',
        payment_method: '',
        status: '',
        description: '',
        uploaded_file: null
    });

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});
    const validateForm = () => {
        const newErrors = {};
        const requiredFields = [
            // 'invoice',
            'multiple_invoice',
            'payment_id',
            'amount',
            'payment_date',
            'payment_method',
            'status',
            'description',
            'uploaded_file',
        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
            setLoading(true); //loading logic

            const updatedformData = new FormData();
            // Append all other fields
            Object.keys(formData).forEach((key) => {
                if (key === "multiple_invoice" && Array.isArray(formData[key])) {
                    // Append each value of multiple_invoice as a separate field
                    formData[key].forEach((invoiceId) => {
                        updatedformData.append("multiple_invoice", invoiceId);
                    });
                } else if (key !== "uploaded_file" && formData[key]) {
                    updatedformData.append(key, formData[key]);
                }
            });


            const symbols = "!@#$%^&*()_-+=";
            const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
            const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            const numbers = "0123456789";
            const now = new Date();
            const date = now
                .toLocaleDateString("en-GB")
                .split("/")
                .reverse()
                .join("-"); // Format: YYYY-MM-DD
            const time = now
                .toLocaleTimeString("en-GB", { hour12: false })
                .replace(/:/g, "-"); // Format: HH-MM-SS
            const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
            let randomCode = "";
            for (let i = 0; i < 8; i++) {
                const randomIndex = Math.floor(Math.random() * allChars.length);
                randomCode += allChars[randomIndex];
            }
            const originalFile = formData.uploaded_file;
            const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile.name}`;
            const customFile = new File([originalFile], customFileName, {
                type: originalFile.type,
            });
            updatedformData.append("uploaded_file", customFile);
            console.log("updatedformData");
            console.log(updatedformData);

            try {
                let res = await axios.post(`${BASE_URL_PREBID}/project/paymentreciept/`, updatedformData)

                if (res.status === 200) {
                    await getInvoicePayments();
                    handleClose();
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId)
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? 'green' : '',
        });

        setFormData({
            ...formData,
            [name]: value,
        });

        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            if (value) {
                delete updatedErrors[name];
            }
            return updatedErrors;
        });
    };


    const handleFileChange = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];
        const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/jpeg', 'image/png'];

        if (file) {
            if (allowedTypes.includes(file.type)) {
                setFormData({
                    ...formData,
                    [name]: file,
                });
                setErrors({
                    ...errors,
                    [name]: null,
                });
            } else {
                setErrors({
                    ...errors,
                    [name]: 'Invalid file type. Please select a valid file (PDF, DOC, DOCX, JPG, JPEG, PNG).',
                });
            }
        } else {
            setFormData({
                ...formData,
                [name]: null,
            });
            setErrors({
                ...errors,
                [name]: null,
            });
        }
    };


    const handleFileClear = (name) => {
        setFormData({
            ...formData,
            [name]: null,
        });
    };

    const options = invoiceList
        .sort((a, b) => parseInt(a.invoice_number) - parseInt(b.invoice_number))
        .map((e) => ({
            value: e.id,
            label: `${e.invoice_number}`,
        }));

    const handleChangeMultipleInvoiceTo = (selected) => {
        const multiple_invoice = selected?.map((item) => item.value) || [];
        setFormData((prevState) => ({
            ...prevState,
            multiple_invoice, // Ensure this is always an array
        }));
    };

    return (
        <>
            <button
                title="Raise Payment"
                onClick={handleShow}
                className="upload-svg "

            >
                <AddwithWhiteCircle /> {" "} Payment Reciept
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="dialog-modal-assign-task dialog-model"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="modal-title">Add Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

                    <div className='modal-body-padding'>
                        <form >

                            <div className='form-flex-wrap'>

                                <div className="flex-column form-group-select">
                                    <label className="form-labels">
                                        Associate Invoice:<span className="required">*</span>
                                    </label>

                                    <Select
                                        options={options}
                                        isMulti
                                        onChange={handleChangeMultipleInvoiceTo}
                                        value={formData.multiple_invoice?.map((code) => ({
                                            value: code,
                                            label: options.find((opt) => opt.value === code)?.label || code,
                                        }))}
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                width: '33vw',
                                                minHeight: '50px',
                                                borderRadius: '10px',
                                                border: '1px solid #e3e3e3',
                                                margin: '10px 0px 20px 0px',
                                                color: '#4a4a4a',
                                                backgroundColor: '#f7f8fa',
                                                padding: '0px 10px',
                                            }),
                                            placeholder: (provided) => ({
                                                ...provided,
                                                color: '#4a4a4a',
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                borderRadius: '10px',
                                                marginTop: '0',
                                            }),
                                            input: (provided) => ({
                                                ...provided,
                                                color: '#4a4a4a',
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                color: '#4a4a4a',
                                            }),
                                        }}
                                    />

                                    {errors.multiple_invoice && (
                                        <span className="error-message font-size-text ">{errors.multiple_invoice}</span>
                                    )}
                                </div>

                                {/* <div className="flex-column form-group-select">
                                    <label className="form-labels">Invoice:<span className="required">*</span></label>
                                    <select
                                        name="invoice"
                                        value={formData.invoice}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.invoice ? 'error' : inputState.invoice ? 'success' : ''}`}
                                    >
                                        <option value="">Select a Invoice</option>
                                        {invoiceList.map((invoice) => (
                                            <option key={invoice.id} value={invoice.id}>{invoice.invoice_number}</option>
                                        ))}
                                    </select>
                                    <DropdownArrow />
                                    {errors.invoice && <span className="error-message">{errors.invoice}</span>}
                                </div> */}

                                <div className="flex-column">
                                    <label className="form-labels">Payment ID:<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        name="payment_id"
                                        placeholder="Transation ID"
                                        value={formData.payment_id || ""}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.payment_id ? 'error' : inputState.payment_id ? 'success' : ''}`}
                                    />
                                    {errors.payment_id && <span className="error-message">{errors.payment_id}</span>}
                                </div>

                                <div className="flex-column">
                                    <label className="form-labels">Amount:<span className="required">*</span></label>
                                    <input
                                        type="number"
                                        name="amount"
                                        min={0}
                                        placeholder="0"
                                        step="0.01"
                                        value={formData.amount || ""}
                                        onChange={handleInputChange}
                                        onWheel={(e) => e.target.blur()}
                                        className={`form-input ${errors.amount ? 'error' : inputState.amount ? 'success' : ''}`}
                                    />
                                    {errors.amount && <span className="error-message">{errors.amount}</span>}
                                </div>

                                <div className="flex-column">
                                    <label className="form-labels">Payment Date:<span className="required">*</span></label>
                                    <input
                                        type="date"
                                        name="payment_date"
                                        value={formData.payment_date || ""}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.payment_date ? 'error' : inputState.payment_date ? 'success' : ''}`}
                                    />
                                    {errors.payment_date && <span className="error-message">{errors.payment_date}</span>}
                                </div>

                                <div className="flex-column form-group-select">
                                    <label className="form-labels">Payment Method:<span className="required">*</span></label>
                                    <select
                                        name="payment_method"
                                        value={formData.payment_method || ""}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.payment_method ? 'error' : inputState.payment_method ? 'success' : ''}`}
                                    >
                                        <option value="">Select a Payment Method</option>
                                        <option value="credit_card">Credit Card</option>
                                        <option value="paypal">Paypal</option>
                                        <option value="bank_transfer">Bank Transfer</option>
                                        <option value="cash">Cash</option>
                                        <option value="other">Other</option>
                                    </select>
                                    <DropdownArrow />
                                    {errors.payment_method && <span className="error-message">{errors.payment_method}</span>}
                                </div>

                                <div className="flex-column form-group-select">
                                    <label className="form-labels">Status:<span className="required">*</span></label>
                                    <select
                                        name="status"
                                        value={formData.status || ""}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.status ? 'error' : inputState.status ? 'success' : ''}`}
                                    >
                                        <option value="">Select a Status</option>
                                        <option value="pending">Pending</option>
                                        <option value="inprogress">In Progress</option>
                                        <option value="failed">Failed</option>
                                        <option value="completed">Completed</option>
                                        <option value="hold">Hold</option>
                                    </select>
                                    <DropdownArrow />
                                    {errors.status && <span className="error-message">{errors.status}</span>}
                                </div>

                                <div className="flex-column">
                                    <label className="form-labels">Description:<span className="required">*</span></label>
                                    <textarea
                                        type="text"
                                        name="description"
                                        value={formData.description || ""}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.description ? 'error' : inputState.description ? 'success' : ''}`}
                                    />
                                    {errors.description && <span className="error-message">{errors.description}</span>}
                                </div>

                                <div className="flex-column margin-top-20px">
                                    <label>Attachment(PDF):<span className="required">*</span></label>
                                    <div className={`file-upload-container form-input ${errors.uploaded_file ? 'error' : inputState.uploaded_file ? 'success' : ''}`}>
                                        <label className={`file-label ${errors.uploaded_file ? 'error' : ''}`} style={{ marginTop: "10px" }}>
                                            <input
                                                type="file"
                                                name="uploaded_file"
                                                accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                                                onChange={handleFileChange}
                                                className={`file-input`}
                                            />
                                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-upload" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z" />
                                                <path fill-rule="evenodd" d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z" />
                                            </svg> */}

                                            {/* <span className="form-text-trasformation-uppercase" style={{ marginLeft: "20px" }}>
                                                {formData.uploaded_file ? formData.uploaded_file.name : 'No file selected'}
                                            </span> */}


                                            {formData.uploaded_file ? (<span className="file-clear" onClick={() => handleFileClear('uploaded_file')}>
                                                Clear
                                            </span>) : null}
                                        </label>
                                        <br /><br />
                                        {errors.uploaded_file && <span className="error-message">{errors.uploaded_file}</span>}
                                    </div>

                                </div>

                            </div>

                            <div className="button-models">
                                <button onClick={handleSubmit} className="model-button model-button-submit">Submit</button>
                            </div>
                        </form>
                    </div>




                </Modal.Body>
            </Modal >
        </>
    );
};

const DeleteInvoicePayment = ({ i, id, getInvoiceData }) => {
    const [show, setShow] = useState(false);

    const [loading, setLoading] = useState(false); //loading logic// console.log(id)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
        setLoading(true); // loading logic
        try {
            console.log("Delete Expense Detail Api");
            let res = await axios.delete(
                `${BASE_URL_PREBID}/project/paymentreciept/${id}/`
            );

            if (res.status === 200) {
                await getInvoiceData();
                setShow(false);
            } else {
                alert(res);
            }
        } catch (err) {

            //toast Logic
            if (err.response) {
                toast.dismiss(loadingToastId);
                const errorData = err.response.data;

                if (typeof errorData.error === 'string') {
                    // Single error message
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    // Multiple error messages
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }
        } finally {
            toast.dismiss(loadingToastId);
        }
    };
    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <button className="model-delete-button" onClick={handleShow}>
                <DeleteDustbin />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
                <Modal.Header closeButton>
                    <Modal.Title>Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are You Sure, You want to Delete details of Payment ID : {i.payment_id}

                    <div className="button-models">

                        <button
                            onClick={handleFormSubmitDelete}
                            className="model-button model-button-delete"
                        >
                            Delete
                        </button>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
};


const ProjectInvoicesPaymentsTable = ({ projectID, client, clientwiseID }) => {

    const [buffer, setBuffering] = useState(false);
    const [invoiceList, setInvoiceList] = useState([]);
    const [paymentData, setPaymentData] = useState([]);

    useEffect(() => {
        if (client) {
            getPaymentDetails();
        }
    }, [client]);

    const getPaymentDetails = async () => {
        setBuffering(true);
        try {
            const invoice = await axios.get(`${BASE_URL}/project/invoicebyproject/${clientwiseID}/`);
            setInvoiceList(invoice.data);

            const payment = await axios.get(`${BASE_URL}/project/paymentReceiptfilterAPI/${clientwiseID}/`);
            setPaymentData(payment.data);


        } catch (err) {
        } finally {
            setBuffering(false);
        }
    };

    const navigate = useNavigate()
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split('/');
    const baseUrl2 = "/" + urlParts[3] + `/invoiceSlip`;
    const invoicePage = (pr_code) => {
        navigate(baseUrl2 + `/${pr_code}/`)
    }

    return (
        <>

            <div className="table-css-white-background">
                {/* <div className="attendance-box"> */}
                <div className="heading-bg-color-white">
                    <div className="jd-heading-outer-flex ">
                        <h2 className="jd-heading-main font-weight600  font-size-heading">Payments Recieved</h2>
                        <div className="field-cont">
                            <AddPaymentRecord id={clientwiseID} invoiceList={invoiceList} getInvoicePayments={getPaymentDetails} />
                        </div>

                    </div>
                    <div>
                        <div className="jd-heading-bottom-bold"></div>
                        <div className="jd-heading-bottom-light"></div>
                    </div>
                </div>

                <div className="datagrid-container">
                    <div className="invoice-table">

                        <div className="invoice-table2 ">
                            <table className="project-details-table" >

                                <thead>
                                    <tr className="custom-table-head-invoices">
                                        <th className="align-center font-size-text font-weight600">Sr no.</th>
                                        <th className="align-center font-size-text font-weight600">Ref. Invoices</th>
                                        <th className="align-center font-size-text font-weight600">Payment ID</th>
                                        <th className="align-center font-size-text font-weight600">Payment Date</th>
                                        <th className="align-center font-size-text font-weight600">Amount</th>
                                        <th className="align-center font-size-text font-weight600">Payment Method</th>
                                        <th className="align-center font-size-text font-weight600">Status</th>
                                        <th className="align-center font-size-text font-weight600">Description</th>
                                        <th className="align-center font-size-text font-weight600">Attachment</th>
                                        <th className="align-center font-size-text font-weight600">Action</th>
                                    </tr>
                                </thead>
                                {buffer ? <div className="spinner"></div> : //buffering logic
                                    <tbody >
                                        {paymentData
                                            .sort((a, b) => a.client_name?.localeCompare(b.client_name, 'en', { ignorePunctuation: true }))
                                            .map((i, index) => (
                                                <React.Fragment key={index}>
                                                    <tr className="tr-border-bottom">
                                                        <td colSpan="8"></td>
                                                    </tr>
                                                    <tr className="custom-table-head-td">
                                                        <td className="align-center font-size-text font-weight400">{index + 1}</td>
                                                        {/* <td className="align-center font-size-text font-weight400">{i.invoice_number}</td> */}
                                                        <td className="align-center font-size-text font-weight400">
                                                            {/* {i.invoice_number} */}
                                                            <ul style={{ paddingLeft: "20px" }}>
                                                                {i.invoice_details?.map((items) => {
                                                                    return (
                                                                        <li>
                                                                            {items.invoice_number}
                                                                            {/* <br /> */}
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        </td>
                                                        <td className="align-center font-size-text font-weight400">{i.payment_id}</td>
                                                        <td className="align-center font-size-text font-weight400">{i.payment_date != null ? formattedDateLong(i.payment_date) : ""}</td>
                                                        {/* <td className="align-center font-size-text font-weight400" style={{ display: "flex" }}> */}
                                                        <td className="align-center font-size-text font-weight400">{formatCurrencyIndian(i.amount)}</td>
                                                        <td className="align-center font-size-text font-weight400">{i.payment_method}</td>
                                                        <td className="align-center font-size-text font-weight400">{i.status}</td>
                                                        <td className="align-center font-size-text font-weight400">{i.description}</td>

                                                        {/* </td> */}
                                                        <td className="align-center font-size-text font-weight400">
                                                            {i.uploaded_file ?
                                                                <>
                                                                    <a href={i.uploaded_file} target="blank" className="model-button width-5vw">
                                                                        <Attachment />
                                                                    </a>
                                                                </>
                                                                : "-"
                                                            }
                                                        </td>
                                                        <td className="align-center">
                                                            {/* <ReceivedAmount id={i.id} maxlimitreceive={i.billamount} /> */}
                                                            <DeleteInvoicePayment i={i} id={i.id} getInvoiceData={getPaymentDetails} />
                                                        </td>



                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                    </tbody>



                                }
                            </table>
                        </div>

                    </div>
                </div>
                {/* </div> */}
            </div>

        </>
    )
}

const AddSubmissionDocuments = ({ projectID, clientwiseID, getSubmissionDetails, workstageId }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        if (workstageId) {
            setFormData({
                ...formData,
                workstage: workstageId,
            });
        }
        getClientDetails()
        setShow(true)
    };

    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;

    const [formData, setFormData] = useState({
        projectdetailsclientwise: clientwiseID,
        workstage: workstageId || "",
        document_name: "",
        file: "",
    });


    const [inputFields, setInputFields] = useState([{ workStages: '', percentage: '', completed: false, invoice_generated: false, project_detail: clientwiseID }]);

    const getClientDetails = async () => {
        setLoading(true);
        try {
            const res = await axios.get(`${BASE_URL_PREBID}/project/workstagesbyprojectDetail/${clientwiseID}/`);
            setInputFields(res.data.length > 0 ? res.data : [{ workStages: '', percentage: '', completed: false, invoice_generated: false, project_detail: clientwiseID }]);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setLoading(false);
        }
    };


    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["workstage", "document_name", "file"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            const formDataa = new FormData();
            formDataa.append("document_name", formData.document_name);
            formDataa.append("file", formData.file);
            formDataa.append("projectdetailsclientwise", formData.projectdetailsclientwise);
            formDataa.append("workstage", formData.workstage);
            try {
                let res = await axios.post(`${BASE_URL}/project/document/`, formDataa, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                if (res.status === 200) {
                    handleClose();
                    await getSubmissionDetails();
                } else {
                    alert(res);
                }
            } catch (err) {
                //    handle
            } finally {
                setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });

        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            if (value) {
                delete updatedErrors[name];
            }
            return updatedErrors;
        });
    };

    const handleFileChange = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];

        if (file) {
            if (file.type === "application/pdf") {
                setFormData({
                    ...formData,
                    [name]: file,
                });
            } else {
                setErrors({
                    ...errors,
                    [name]: "Invalid file type. Please select a PDF file.",
                });
            }
        } else {
            setFormData({
                ...formData,
                [name]: null,
            });
        }
    };

    const handleFileClear = (name) => {
        setFormData({
            ...formData,
            [name]: null,
        });
    };

    console.log("formData")
    console.log(formData)
    console.log("workstageId")
    console.log(workstageId)

    return (
        <>
            <button className='upload-svg' onClick={handleShow}>
                <AddwithWhiteCircle />
                {" "} Document
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title className="modal-title">Add Submission Documents</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <form>
                                <div className="flex-column form-group-select">
                                    <label className="form-labels">Work Stage:<span className="required">*</span></label>

                                    {loading ?
                                        <div className="form-loader">
                                            <div className="bar-loader"></div>
                                        </div>
                                        :
                                        <>
                                            <select
                                                name="workstage"
                                                value={formData.workstage}
                                                onChange={handleInputChange}
                                                className={`form-input form-group-selection ${errors.workstage ? 'error' : inputState.workstage ? 'success' : ''}`}
                                            >
                                                <option value="">Select a WorkStage</option>
                                                {inputFields.map((stage) => (
                                                    <option key={stage.wid} value={stage.wid}>{stage.workStages}</option>
                                                ))}
                                            </select>
                                            <DropdownArrow />
                                            {errors.workstage && <span className="error-message">{errors.workstage}</span>}
                                        </>
                                    }
                                </div>

                                <div className="flex-column">
                                    <label htmlFor="document_name" className="form-labels  font-weight500   font-size-heading">
                                        Document Name
                                    </label>
                                    <input
                                        id="document_name"
                                        type="document_name"
                                        name="document_name"
                                        placeholder="Document Name"
                                        onChange={handleInputChange}
                                        value={formData.document_name}
                                        className={`form-input ${errors.document_name ? "error" : inputState.document_name ? "success" : ""
                                            }`}
                                    />
                                    {errors.document_name && (
                                        <span className="error-message font-size-text ">{errors.document_name}</span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label htmlFor="attachment" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                        Attachment
                                    </label>
                                    <input
                                        id="file"
                                        type="file"
                                        name="file"
                                        accept=".pdf"
                                        onChange={handleFileChange}
                                        className="file-input"
                                    />

                                    {/* {formData.file ? (
                                        <span
                                            className="file-clear"
                                            onClick={() => handleFileClear("file")}
                                        >
                                            Clear
                                        </span>
                                    ) : null} */}
                                    <br />
                                    {errors.file && (
                                        <span className="error-message font-size-text ">{errors.file}</span>
                                    )}
                                </div>
                            </form>
                            <div className="button-models">
                                <button className="model-button   font-weight500   " onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Adds
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

const ProjectSubmissionDocumentTable = ({ projectID, client, clientwiseID }) => {

    const [buffer, setBuffering] = useState(false);
    const [submissionDocs, setSubmissionDocs] = useState([]);

    useEffect(() => {
        if (client) {
            getSubmissionDetails();
        }
    }, [client]);

    const getSubmissionDetails = async () => {
        setBuffering(true);
        try {
            const res = await axios.get(`${BASE_URL}/project/documentfilterclientWise/${clientwiseID}/`);
            setSubmissionDocs(res.data);
        } catch (err) {
        } finally {
            setBuffering(false);
        }
    };

    return (
        <>

            <div className="table-css-white-background">
                {/* <div className="attendance-box"> */}
                <div className="heading-bg-color-white">
                    <div className="jd-heading-outer-flex ">
                        <h2 className="jd-heading-main font-weight600  font-size-heading">Submission Documents</h2>
                        <div className="field-cont">
                            <AddSubmissionDocuments projectID={projectID} clientwiseID={clientwiseID} getSubmissionDetails={getSubmissionDetails} />
                        </div>
                    </div>
                    <div>
                        <div className="jd-heading-bottom-bold"></div>
                        <div className="jd-heading-bottom-light"></div>
                    </div>
                </div>

                {/* <div className="datagrid-container">
                    <div className="invoice-table"> */}

                <div className="invoice-table2 ">
                    <table className="project-details-table">
                        <thead>
                            <tr className="custom-table-head-trr">
                                <th className="align-left font-size-text font-weight600">S. No.</th>
                                <th className="align-center font-size-text font-weight600">Work Stage</th>
                                <th className="align-center font-size-text font-weight600">Document Name</th>
                                <th className="align-center font-size-text font-weight600">Document File</th>
                                <th className="align-right font-size-text font-weight600">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {submissionDocs.map((i, index) => (
                                <React.Fragment key={index}>
                                    <tr className="tr-border-bottom">
                                        <td colSpan="6"></td>
                                    </tr>
                                    <tr className="custom-table-head-td font-weight500 font-size-label">
                                        <td className="align-left">{index + 1}</td>
                                        <td className="align-center">{i.workstage_name}</td>
                                        <td className="align-center">
                                            {i.document_name}
                                        </td>
                                        <td className="align-center">
                                            {i.file ? (
                                                // <a href={i.document_file} target="blank">
                                                //   View
                                                // </a>
                                                <ViewOtherFile filename={i.document_name} filesrc={i.file} />

                                                // <a href={i.file} download={`${i.file}`} target="blank">
                                                //     <img
                                                //         className="performance-reward-img"
                                                //         src={`${i.file}`}
                                                //         alt="Submission Doc"
                                                //     />
                                                // </a>
                                            ) : (
                                                "Document Not Attached"
                                            )}
                                        </td>

                                        <td className="align-right font-size-text font-weight400 width-5vw ">
                                            <DeleteProjectDocuments
                                                id={i}
                                                getDocuments={getSubmissionDetails}
                                            />
                                        </td>
                                    </tr>


                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* </div>
                </div> */}
                {/* </div> */}
            </div>

        </>
    )
}

const AddProjectDocuments = ({ projectID, getProjectDocuments }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;

    const [formData, setFormData] = useState({
        project: projectID,
        document_name: "",
        file: "",
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["document_name", "file"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            const formDataa = new FormData();
            formDataa.append("project", formData.project);
            formDataa.append("document_name", formData.document_name);
            formDataa.append("file", formData.file);
            try {
                let res = await axios.post(`${BASE_URL}/project/document/`, formDataa, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                if (res.status === 200) {
                    handleClose();
                    await getProjectDocuments();
                } else {
                    alert(res);
                }
            } catch (err) {
                //    handle
            } finally {
                setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });

        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            if (value) {
                delete updatedErrors[name];
            }
            return updatedErrors;
        });
    };

    const handleFileChange = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];

        if (file) {
            if (file.type === "application/pdf") {
                setFormData({
                    ...formData,
                    [name]: file,
                });
            } else {
                setErrors({
                    ...errors,
                    [name]: "Invalid file type. Please select a PDF file.",
                });
            }
        } else {
            setFormData({
                ...formData,
                [name]: null,
            });
        }
    };

    const handleFileClear = (name) => {
        setFormData({
            ...formData,
            [name]: null,
        });
    };

    return (
        <>
            <button className='upload-svg' onClick={handleShow}>
                <AddwithWhiteCircle />
                {" "} Document
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title className="modal-title">Add Tender Documents</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <form>
                                <div className="flex-column">
                                    <label htmlFor="document_name" className="form-labels  font-weight500   font-size-heading">
                                        Document Name
                                    </label>
                                    <input
                                        id="document_name"
                                        type="document_name"
                                        name="document_name"
                                        placeholder="Document Name"
                                        onChange={handleInputChange}
                                        value={formData.document_name}
                                        className={`form-input ${errors.document_name ? "error" : inputState.document_name ? "success" : ""
                                            }`}
                                    />
                                    {errors.document_name && (
                                        <span className="error-message font-size-text ">{errors.document_name}</span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label htmlFor="attachment" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                        Attachment
                                    </label>
                                    <input
                                        id="file"
                                        type="file"
                                        name="file"
                                        accept=".pdf"
                                        onChange={handleFileChange}
                                        className="file-input"
                                    />

                                    {/* {formData.file ? (
                                        <span
                                            className="file-clear"
                                            onClick={() => handleFileClear("file")}
                                        >
                                            Clear
                                        </span>
                                    ) : null} */}
                                </div>
                            </form>
                            <div className="button-models">
                                <button className="model-button   font-weight500   " onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Adds
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

const ProjectTenderDocumentTable = ({ projectID, client }) => {

    const [buffer, setBuffering] = useState(false);
    const [projectDocs, setProjectDocs] = useState([]);

    useEffect(() => {
        getProjectDocuments();
    }, []);

    const getProjectDocuments = async () => {
        setBuffering(true);
        try {

            const res = await axios.get(`${BASE_URL}/project/documentbyproject/${projectID}/`);
            setProjectDocs(res.data);

        } catch (err) {
        } finally {
            setBuffering(false);
        }
    };

    return (
        <>
            <div className="table-css-white-background">
                {/* <div className="attendance-box"> */}
                <div className="heading-bg-color-white">
                    <div className="jd-heading-outer-flex ">
                        <h2 className="jd-heading-main font-weight600  font-size-heading">Tender Documents</h2>
                        <div className="field-cont">
                            <AddProjectDocuments projectID={projectID} getProjectDocuments={getProjectDocuments} />
                        </div>
                    </div>
                    <div>
                        <div className="jd-heading-bottom-bold"></div>
                        <div className="jd-heading-bottom-light"></div>
                    </div>
                </div>

                {/* <div className="datagrid-container">
                    <div className="invoice-table"> */}

                <div className="invoice-table2 ">
                    <table className="project-details-table">
                        <thead>
                            <tr className="custom-table-head-trr">
                                <th className="align-left font-size-text font-weight600">S. No.</th>
                                <th className="align-center font-size-text font-weight600">Document Name</th>
                                <th className="align-center font-size-text font-weight600">Document File</th>
                                <th className="align-right font-size-text font-weight600">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {projectDocs.map((i, index) => (
                                <React.Fragment key={index}>
                                    <tr className="tr-border-bottom">
                                        <td colSpan="6"></td>
                                    </tr>
                                    <tr className="custom-table-head-td font-weight500 font-size-label">
                                        <td className="align-left">{index + 1}</td>
                                        <td className="align-center">
                                            {i.document_name}
                                        </td>
                                        <td className="align-center">
                                            {i.file ? (
                                                // <a href={i.document_file} target="blank">
                                                //   View
                                                // </a>
                                                <ViewOtherFile filename={i.document_name} filesrc={i.file} />

                                                // <a href={i.file} download={`${i.file}`} target="blank">
                                                //     <img
                                                //         className="performance-reward-img"
                                                //         src={`${i.file}`}
                                                //         alt="Tender Doc"
                                                //     />
                                                // </a>
                                            ) : (
                                                "Document Not Attached"
                                            )}
                                        </td>

                                        <td className="align-right font-size-text font-weight400 width-5vw ">
                                            <DeleteProjectDocuments
                                                id={i}
                                                getDocuments={getProjectDocuments}
                                            />
                                        </td>
                                    </tr>


                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* </div>
                </div> */}
                {/* </div> */}
            </div>

        </>
    )
}


export {
    AddProjectClientDetails,
    ProjectClientDetailsTable,
    ProjectWorkStagesTable,
    ProjectInvoicesDetailsTable,
    ProjectInvoicesPaymentsTable,
    ProjectSubmissionDocumentTable,
    ProjectTenderDocumentTable,
}