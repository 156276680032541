import { useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import * as XLSX from 'xlsx';
import axios from "axios";
import { handleErrorToast } from "../components/custom";
import { BASE_URL } from "../config/axios";
import { Modal } from "react-bootstrap";
import { UploadIcon, DownloadIcon } from "./AllSvg"


const UploadProjectsBulk = ({ id, getProject }) => {

    const [show, setShow] = useState(false);
    const [fileName, setFileName] = useState("");
    const [excelJson, setExcelJson] = useState(null);
    const [docErrors, setDocErrors] = useState({});
    const [docInputState, setDocInputState] = useState({});

    const bulktableRef = useRef(null);

    const handleDownload = () => {
        const table = bulktableRef.current;
        const wb = XLSX.utils.table_to_book(table, { sheet: "Project Details" });
        const wbout = XLSX.write(wb, { bookType: "xls", type: "binary" });

        const blob = new Blob([s2ab(wbout)], {
            type: "application/vnd.ms-excel",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Project Detail Format.xls";
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };

    const handleClose = () => {
        setFileName("")
        setExcelJson(null)
        setDocErrors({})
        setShow(false)
    };
    const handleShow = () => setShow(true);

    // Function to handle file change
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check for valid file type
            const validFileTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
            if (!validFileTypes.includes(file.type)) {
                setDocErrors({ document_file: "Please upload a valid Excel file (.xlsx or .xls)." });
                setFileName("");
                return;
            }

            setFileName(file.name);
            readExcel(file); // Convert the file to JSON
        } else {
            setFileName("");
        }
    };

    const checkForDuplicates = (data) => {
        const sitecodes = new Map();
        const duplicates = [];

        // Track positions of each insurance number
        data.forEach((i, index) => {
            const projectcode = i.site_prcode;
            if (sitecodes.has(projectcode)) {
                sitecodes.get(projectcode).push(index + 1); // index + 1 for 1-based position
            } else {
                sitecodes.set(projectcode, [index + 1]);
            }
        });

        // Now, filter out only the numbers that appear more than once
        sitecodes.forEach((positions, projectcode) => {
            if (positions.length > 1) {
                duplicates.push(`\Site Project Code :${projectcode} is found in position(s) ${positions.join(", ")}`);
            }
        });

        return duplicates;
    };

    const readExcel = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });

            const sheetName = workbook.SheetNames[0]; // Use the first sheet
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: null });

            const headers = json[0];
            const rows = json.slice(1);

            const convertToYYYYMMDD = (input) => {
                try {
                    let date;

                    // Handle Excel serial dates
                    if (typeof input === "number") {
                        const excelEpoch = new Date(1900, 0, 1); // Excel epoch starts on Jan 1, 1900
                        const daysOffset = input - 1; // Subtract 1 because of leap year bug
                        date = new Date(excelEpoch.getTime() + daysOffset * 86400000);
                    } else if (/^\d{4}\.\d{2}\.\d{2}$/.test(input)) {
                        // Format: YYYY.MM.DD
                        const [year, month, day] = input.split('.');
                        date = new Date(`${year}-${month}-${day}`);
                    } else if (/^\d{2}\.\d{2}\.\d{4}$/.test(input)) {
                        // Format: DD.MM.YYYY
                        const [day, month, year] = input.split('.');
                        date = new Date(`${year}-${month}-${day}`);
                    } else {
                        // Default parsing for other formats
                        date = new Date(input);
                    }

                    if (isNaN(date.getTime())) {
                        throw new Error('Invalid date format');
                    }

                    // Format the date as YYYY-MM-DD
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                    const day = String(date.getDate()).padStart(2, '0');

                    return `${year}-${month}-${day}`;
                } catch (error) {
                    return null; // Return null for invalid dates
                }
            };

            // // Create a formatted JSON object based on headers and row values
            // const formattedJson = rows.map((row) => {
            //     const obj = {};
            //     headers.forEach((header, index) => {
            //         if (["bid_tender_date", "portal_submission_date", "opening_date"].includes(header) && row[index] && typeof row[index] === "number") {
            //             obj[header] = convertToYYYYMMDD(row[index]); // Convert date
            //         } else {
            //             obj[header] = row[index] !== undefined ? row[index] : null;
            //         }
            //     });
            //     return obj;
            // });

            const formattedJson = rows.map((row) => {
                const obj = {};
                headers.forEach((header, index) => {
                    if (["bid_tender_date", "portal_submission_date", "opening_date"].includes(header) && row[index]) {
                        obj[header] = convertToYYYYMMDD(row[index]);
                    } else if (header === "assignedto" && row[index]) {
                        try {
                            // Attempt to parse the string into JSON
                            obj[header] = JSON.parse(row[index]);
                            console.log("obj[header]")
                            console.log(obj[header])
                        } catch (err) {
                            console.error(`Invalid format in assignedto: ${row[index]}`);
                            obj[header] = [];
                        }
                    } else {
                        obj[header] = row[index] !== undefined ? row[index] : null;
                    }
                });
                return obj;
            });

            // Ensure the input to checkForDuplicates is an array
            const duplicates = checkForDuplicates(formattedJson);

            console.log("duplicates")
            console.log(duplicates)

            if (duplicates.length > 0) {
                // Toast notification with line breaks for each duplicate
                toast.error(
                    `Duplicate Projects found:\n${duplicates.join("\n")}`,
                    {
                        autoClose: 5000, // Close toast after 5 seconds
                    }
                );
            }
            // Set the formatted JSON data to state
            setExcelJson(formattedJson);
        };
        reader.readAsArrayBuffer(file);
        console.log("excelJson")
        console.log(excelJson)
    };

    // Function to handle the upload button click
    const handleUpload = async () => {

        if (excelJson) {
            const duplicates = checkForDuplicates(excelJson);

            if (duplicates.length > 0) {
                toast.error(`Duplicate Data found:\n ${duplicates.join(", ")}`, {
                    autoClose: 5000,
                });
            }
            const loadingToastId = toast.loading("Uploading: Please wait...");
            try {
                const res = await axios.post(`${BASE_URL}/project/projectbulkupload/`, excelJson);
                if (res.status === 200) {
                    await getProject();
                    handleClose();
                    toast.dismiss(loadingToastId);
                    toast.success("Projects uploaded successfully.");

                } else {
                    toast.dismiss(loadingToastId);
                    toast.error("Failed to add data!");
                }
            } catch (err) {
                handleErrorToast(err);
            } finally {
                toast.dismiss(loadingToastId);
            }
        } else {
            setDocErrors({ document_file: "Please upload a valid Excel file." });
        }
    };





    return (
        <>

            <button title="Upload" className="model-button   font-weight500   model-button-print" onClick={handleShow} style={{ margin: "2px" }}>
                <UploadIcon />
            </button>

            <Modal show={show} dialogClassName="ourcompany-model">
                <Modal.Body>
                    <div className="flex-row align-center">
                        <h1 className="font-size-text task-Tab-heading align-center  font-weight500 "><Modal.Title>Upload Bulk Project Data in format :</Modal.Title> </h1>

                        <button onClick={handleDownload} className="model-button-download model-button font-weight500">
                            <DownloadIcon />
                            {"  "} Format
                        </button>
                    </div>
                    <div className="table-css-white-background" style={{ display: "none" }}>
                        <table ref={bulktableRef} className="nested-table-lightblue">
                            <thead>
                                <tr className="custom-table-head-tr">
                                    <th className="align-left">sub_company</th>
                                    <th className="table-heading-text">prebid_prcode</th>
                                    <th className="table-heading-text">project_name</th>
                                    <th className="table-heading-text">project_short_name</th>
                                    <th className="table-heading-text">project_type</th>
                                    <th className="table-heading-text">location</th>
                                    <th className="table-heading-text">concern_person</th>
                                    <th className="table-heading-text">concern_person_name</th>
                                    <th className="table-heading-text">assignedto</th>
                                    <th className="table-heading-text">tender_id</th>
                                    <th className="table-heading-text">bid_tender_date</th>
                                    <th className="table-heading-text">length</th>
                                    <th className="table-heading-text">portal_submission_date</th>
                                    <th className="table-heading-text">cost</th>
                                    <th className="table-heading-text">remark</th>
                                    <th className="table-heading-text">link</th>
                                    <th className="table-heading-text">l1_position</th>
                                    <th className="table-heading-text">our_position</th>
                                    <th className="table-heading-text">opening_date</th>
                                    <th className="table-heading-text">client_name</th>
                                    <th className="table-heading-text">contract_mode</th>
                                    <th className="table-heading-text">priority</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th className="align-left">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="table-css-white-background scroll-container"
                    >
                        <div className="table-container scrollbar" style={{ width: "100%" }}>
                            <table className="nested-table-lightblue" style={{ margin: "0px" }}>
                                <thead className="table-heading">
                                    <tr className="custom-table-head-tr modal-padding-heading">
                                        <th className="table-heading-text">S. No.</th>
                                        <th className="table-heading-text">Prebid PR Code</th>
                                        <th className="table-heading-text">Project Name</th>
                                        <th className="table-heading-text">Project Short Name</th>
                                        <th className="table-heading-text">Location</th>
                                        <th className="table-heading-text">Sub Company</th>
                                        <th className="table-heading-text">Project Type</th>
                                        <th className="table-heading-text">Concern Person</th>
                                        <th className="table-heading-text">Concern Person Name</th>
                                        <th className="table-heading-text">Assigned To</th>
                                        <th className="table-heading-text">Tender ID</th>
                                        <th className="table-heading-text">Bid Tender Date</th>
                                        <th className="table-heading-text">Length</th>
                                        <th className="table-heading-text">Portal Submission Date</th>
                                        <th className="table-heading-text">Cost</th>
                                        <th className="table-heading-text">Remark</th>
                                        <th className="table-heading-text">Link</th>
                                        <th className="table-heading-text">L1 Position</th>
                                        <th className="table-heading-text">Our Position</th>
                                        <th className="table-heading-text">Opening Date</th>
                                        <th className="table-heading-text">Client Name</th>
                                        <th className="table-heading-text">Contract Mode</th>
                                        <th className="table-heading-text">Priority</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {excelJson?.map((project, index) => (
                                        <tr key={index} className="modal-padding-heading">
                                            <td className="table-heading-text">{index + 1}</td>
                                            <td className="table-heading-text">{project.prebid_prcode}</td>
                                            <td className="table-heading-text">{project.project_name}</td>
                                            <td className="table-heading-text">{project.project_short_name}</td>
                                            <td className="table-heading-text">{project.location}</td>
                                            <td className="table-heading-text">{project.sub_company}</td>
                                            <td className="table-heading-text">{project.project_type}</td>
                                            <td className="table-heading-text">{project.concern_person}</td>
                                            <td className="table-heading-text">{project.concern_person_name}</td>
                                            <td className="table-heading-text">{project.assignedto}</td>
                                            {/* <td className="table-heading-text">
                                                {project.assignedto?.join(", ")}
                                            </td> */}
                                            <td className="table-heading-text">{project.tender_id}</td>
                                            <td className="table-heading-text">{project.bid_tender_date}</td>
                                            <td className="table-heading-text">{project.length}</td>
                                            <td className="table-heading-text">{project.portal_submission_date}</td>
                                            <td className="table-heading-text">{project.cost}</td>
                                            <td className="table-heading-text">{project.remark}</td>
                                            <td className="table-heading-text">
                                                <a href={project.link} target="_blank" rel="noopener noreferrer">
                                                    {project.link}
                                                </a>
                                            </td>
                                            <td className="table-heading-text">{project.l1_position}</td>
                                            <td className="table-heading-text">{project.our_position}</td>
                                            <td className="table-heading-text">{project.opening_date}</td>
                                            <td className="table-heading-text">{project.client_name}</td>
                                            <td className="table-heading-text">{project.contract_mode}</td>
                                            <td className="table-heading-text">{project.priority}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="flex-column field-cont-div">
                        <label htmlFor="document_file" className="form-labels announce-date font-weight500 font-size-heading">
                            Upload File
                        </label>
                        <label
                            htmlFor="document_file"
                            className={`align-center form-inputss custom-file-upload ${docErrors.document_file ? "error" : docInputState.document_file ? "success" : ""
                                }`}
                        >
                            <svg
                                className="pdf-input"
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 20 20"
                                fill="none"
                            >
                                <path
                                    d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                                    stroke="#707070"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </label>
                        <input
                            id="document_file"
                            name="document_file"
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={handleFileChange}
                            className="file-inputs align-center"
                            style={{ display: "none", position: "relative" }}
                        />
                        {fileName && <p className="file-name align-center">{fileName}</p>}
                        <br />
                        {docErrors.document_file && <span className="file-name error-message font-size-text ">{docErrors.document_file}</span>}
                    </div>

                    <h4>References:</h4>
                    <div className="reference-container">
                        <div className="reference-section">
                            <p className="reference-title">General:</p>
                            <ul className="arrow-list">
                                <li>Prebid PR Code(prebid_prcode) should be unique</li>
                                <li>For Assigned To(assignedto) give data in format : {`["<emp_code1>","<emp_code2>"]`}</li>
                            </ul>
                        </div>
                        <div className="reference-section">
                            <p className="reference-title">For Contract Mode use one of these:</p>
                            <ul className="arrow-list">
                                <li>epc</li>
                                <li>ham</li>
                                <li>bot</li>
                            </ul>
                        </div>
                        <div className="reference-section">
                            <p className="reference-title">For Priority use one of these:</p>
                            <ul className="arrow-list">
                                <li>low</li>
                                <li>medium</li>
                                <li>high</li>
                            </ul>
                        </div>

                        <div className="date-section">
                            <p className="date-text">All Dates should be in DD-MM-YYYY format</p>
                        </div>
                    </div>


                    <div className="button-models">
                        <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                            Cancel
                        </button>
                        <button className="model-button-download model-button font-weight500" onClick={handleUpload}>
                            <UploadIcon />
                            {"  "} Upload
                        </button>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

const UploadClientNameBulk = ({ id, getClientNames }) => {

    const [show, setShow] = useState(false);
    const [fileName, setFileName] = useState("");
    const [excelJson, setExcelJson] = useState(null); // State to store the converted JSON data
    const [docErrors, setDocErrors] = useState({});
    const [docInputState, setDocInputState] = useState({});

    const bulktableRef = useRef(null);
    const handleDownload = () => {
        const table = bulktableRef.current;
        const wb = XLSX.utils.table_to_book(table, { sheet: "Client Name Details" });
        const wbout = XLSX.write(wb, { bookType: "xls", type: "binary" });

        const blob = new Blob([s2ab(wbout)], {
            type: "application/vnd.ms-excel",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Client Names Format.xls";
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };

    const handleClose = () => {
        setFileName("")
        setExcelJson(null);
        setDocErrors({});
        setShow(false);
    };
    const handleShow = () => setShow(true);

    // Function to handle file change
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check for valid file type
            const validFileTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
            if (!validFileTypes.includes(file.type)) {
                setDocErrors({ document_file: "Please upload a valid Excel file (.xlsx or .xls)." });
                setFileName("");
                return;
            }

            setFileName(file.name);
            readExcel(file); // Convert the file to JSON
        } else {
            setFileName("");
        }
    };

    const checkForDuplicates = (data) => {
        const clientNameData = new Map();
        const duplicates = [];

        data.forEach((i, index) => {
            const clientName = i.title;
            if (clientNameData.has(clientName)) {
                clientNameData.get(clientName).push(index + 1); // index + 1 for 1-based position
            } else {
                clientNameData.set(clientName, [index + 1]);
            }
        });

        clientNameData.forEach((positions, clientName) => {
            if (positions.length > 1) {
                duplicates.push(`\n Client Name:${clientName} is found in position(s) ${positions.join(", ")}`);
            }
        });

        return duplicates;
    };


    const readExcel = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });

            const sheetName = workbook.SheetNames[0]; // Use the first sheet
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: null });

            const headers = json[0];
            const rows = json.slice(1);

            const convertToYYYYMMDD = (input) => {
                try {
                    let date;

                    // Handle Excel serial dates
                    if (typeof input === "number") {
                        const excelEpoch = new Date(1900, 0, 1); // Excel epoch starts on Jan 1, 1900
                        const daysOffset = input - 1; // Subtract 1 because of leap year bug
                        date = new Date(excelEpoch.getTime() + daysOffset * 86400000);
                    } else if (/^\d{4}\.\d{2}\.\d{2}$/.test(input)) {
                        // Format: YYYY.MM.DD
                        const [year, month, day] = input.split('.');
                        date = new Date(`${year}-${month}-${day}`);
                    } else if (/^\d{2}\.\d{2}\.\d{4}$/.test(input)) {
                        // Format: DD.MM.YYYY
                        const [day, month, year] = input.split('.');
                        date = new Date(`${year}-${month}-${day}`);
                    } else {
                        // Default parsing for other formats
                        date = new Date(input);
                    }

                    if (isNaN(date.getTime())) {
                        throw new Error('Invalid date format');
                    }

                    // Format the date as YYYY-MM-DD
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                    const day = String(date.getDate()).padStart(2, '0');

                    return `${year}-${month}-${day}`;
                } catch (error) {
                    return null; // Return null for invalid dates
                }
            };
            // Create a formatted JSON object based on headers and row values
            const formattedJson = rows.map((row) => {
                const obj = {};
                headers.forEach((header, index) => {
                    if (["date"].includes(header) && row[index] && typeof row[index] === "number") {
                        obj[header] = convertToYYYYMMDD(row[index]); // Convert date
                    } else {
                        obj[header] = row[index] !== undefined ? row[index] : null;
                    }
                });
                return obj;
            });

            const duplicates = checkForDuplicates(formattedJson);

            if (duplicates.length > 0) {
                toast.error(
                    `Duplicate Client Name found:\n${duplicates.join("\n")}`,
                    {
                        autoClose: 5000, // Close toast after 5 seconds
                    }
                );
            }
            setExcelJson(formattedJson);
        };
        reader.readAsArrayBuffer(file);
        console.log("excelJson")
        console.log(excelJson)
    };




    const handleUpload = async () => {
        if (excelJson) {
            const duplicates = checkForDuplicates(excelJson);

            if (duplicates.length > 0) {
                toast.error(`Duplicate Data found!\n ${duplicates.join(", ")}`, {
                    autoClose: 5000,
                });
            }

            const loadingToastId = toast.loading("Uploading: Please wait...");

            try {
                const res = await axios.post(`${BASE_URL}/project/clientnamebulk/`, excelJson);
                if (res.status === 200) {
                    await getClientNames();
                    handleClose();
                    toast.dismiss(loadingToastId);
                    toast.success("Client Names Data Added Successfully");
                }
            } catch (err) {
                handleErrorToast(err);
            } finally {
                toast.dismiss(loadingToastId);
            }
        } else {
            setDocErrors({ document_file: "Please upload a valid Excel file." });
        }
    };


    return (
        <>

            <button title="Upload" className="model-button   font-weight500   model-button-print" onClick={handleShow} style={{ margin: "2px" }}>
                <UploadIcon />
            </button>

            <Modal show={show} dialogClassName="ourcompany-model">

                <Modal.Body>
                    <div className="flex-row align-center">
                        <h1 className="font-size-text task-Tab-heading align-center  font-weight500 "><Modal.Title>Upload Bulk Client Names Data in format :</Modal.Title> </h1>

                        <button onClick={handleDownload} className="model-button-download model-button font-weight500">
                            <DownloadIcon />
                            {"  "} Format
                        </button>
                    </div>
                    <div className="table-css-white-background" style={{ display: "none" }}>
                        <table ref={bulktableRef} className="nested-table-lightblue">
                            <thead>
                                <tr className="custom-table-head-tr">
                                    <th className="align-center">title</th>
                                    <th className="align-center">sub_company</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="table-css-white-background scroll-container">
                        <div className="table-container scrollbar" style={{ width: "100%" }}>
                            <table className="nested-table-lightblue " style={{ margin: "0px" }}>
                                <thead className="table-heading">
                                    <tr className="modal-padding-heading custom-table-head-tr">
                                        <th className="table-heading-text">Name</th>
                                        <th className="table-heading-text">Sub Company</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {excelJson?.map((clientNames, index) => (
                                        <tr key={index} className="modal-padding-heading">
                                            <th className="table-heading-text">{clientNames.title}</th>
                                            <th className="table-heading-text">{clientNames.sub_company}</th>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="flex-column field-cont-div">
                        <label htmlFor="document_file" className="form-labels announce-date font-weight500 font-size-heading">
                            Upload File
                        </label>
                        <label
                            htmlFor="document_file"
                            className={`align-center form-inputss custom-file-upload ${docErrors.document_file ? "error" : docInputState.document_file ? "success" : ""
                                }`}
                        >
                            <svg
                                className="pdf-input"
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 20 20"
                                fill="none"
                            >
                                <path
                                    d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                                    stroke="#707070"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </label>
                        <input
                            id="document_file"
                            name="document_file"
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={handleFileChange}
                            className="file-inputs align-center"
                            style={{ display: "none", position: "relative" }}
                        />
                        {fileName && <p className="file-name align-center">{fileName}</p>}
                        <br />
                        {docErrors.document_file && <span className="file-name error-message font-size-text ">{docErrors.document_file}</span>}
                    </div>


                    <h4>References:</h4>
                    <div className="reference-container">
                        <div className="reference-section">
                            <p className="reference-title">General</p>
                            <ul className="arrow-list">
                                <li>Client Name should be unique.</li>
                            </ul>
                        </div>
                    </div>

                    <div className="button-models">
                        <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                            Cancel
                        </button>
                        <button className="model-button-download model-button font-weight500" onClick={handleUpload}>
                            <UploadIcon />
                            {"  "} Upload
                        </button>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

const UploadClientDataBulk = ({ id, getClientData }) => {

    const [show, setShow] = useState(false);
    const [fileName, setFileName] = useState("");
    const [excelJson, setExcelJson] = useState(null); // State to store the converted JSON data
    const [docErrors, setDocErrors] = useState({});
    const [docInputState, setDocInputState] = useState({});

    const bulktableRef = useRef(null);
    const handleDownload = () => {
        const table = bulktableRef.current;
        const wb = XLSX.utils.table_to_book(table, { sheet: "Client Data" });
        const wbout = XLSX.write(wb, { bookType: "xls", type: "binary" });

        const blob = new Blob([s2ab(wbout)], {
            type: "application/vnd.ms-excel",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Client Data Format.xls";
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };

    const handleClose = () => {
        setFileName("")
        setExcelJson(null);
        setDocErrors({});
        setShow(false);
    };
    const handleShow = () => setShow(true);

    // Function to handle file change
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check for valid file type
            const validFileTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
            if (!validFileTypes.includes(file.type)) {
                setDocErrors({ document_file: "Please upload a valid Excel file (.xlsx or .xls)." });
                setFileName("");
                return;
            }

            setFileName(file.name);
            readExcel(file); // Convert the file to JSON
        } else {
            setFileName("");
        }
    };

    const checkForDuplicates = (data) => {
        const clientData = new Map();
        const duplicates = [];

        data.forEach((i, index) => {
            const client = i.gstin_number;
            if (clientData.has(client)) {
                clientData.get(client).push(index + 1); // index + 1 for 1-based position
            } else {
                clientData.set(client, [index + 1]);
            }
        });

        clientData.forEach((positions, client) => {
            if (positions.length > 1) {
                duplicates.push(`\n Client :${client} is found in position(s) ${positions.join(", ")}`);
            }
        });

        return duplicates;
    };


    const readExcel = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });

            const sheetName = workbook.SheetNames[0]; // Use the first sheet
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: null });

            const headers = json[0];
            const rows = json.slice(1);

            const convertToYYYYMMDD = (input) => {
                try {
                    let date;

                    // Handle Excel serial dates
                    if (typeof input === "number") {
                        const excelEpoch = new Date(1900, 0, 1); // Excel epoch starts on Jan 1, 1900
                        const daysOffset = input - 1; // Subtract 1 because of leap year bug
                        date = new Date(excelEpoch.getTime() + daysOffset * 86400000);
                    } else if (/^\d{4}\.\d{2}\.\d{2}$/.test(input)) {
                        // Format: YYYY.MM.DD
                        const [year, month, day] = input.split('.');
                        date = new Date(`${year}-${month}-${day}`);
                    } else if (/^\d{2}\.\d{2}\.\d{4}$/.test(input)) {
                        // Format: DD.MM.YYYY
                        const [day, month, year] = input.split('.');
                        date = new Date(`${year}-${month}-${day}`);
                    } else {
                        // Default parsing for other formats
                        date = new Date(input);
                    }

                    if (isNaN(date.getTime())) {
                        throw new Error('Invalid date format');
                    }

                    // Format the date as YYYY-MM-DD
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                    const day = String(date.getDate()).padStart(2, '0');

                    return `${year}-${month}-${day}`;
                } catch (error) {
                    return null; // Return null for invalid dates
                }
            };
            // Create a formatted JSON object based on headers and row values
            const formattedJson = rows.map((row) => {
                const obj = {};
                headers.forEach((header, index) => {
                    if (["date"].includes(header) && row[index] && typeof row[index] === "number") {
                        obj[header] = convertToYYYYMMDD(row[index]); // Convert date
                    } else {
                        obj[header] = row[index] !== undefined ? row[index] : null;
                    }
                });
                return obj;
            });

            const duplicates = checkForDuplicates(formattedJson);

            if (duplicates.length > 0) {
                toast.error(
                    `Duplicate Client Name found:\n${duplicates.join("\n")}`,
                    {
                        autoClose: 5000, // Close toast after 5 seconds
                    }
                );
            }
            setExcelJson(formattedJson);
        };
        reader.readAsArrayBuffer(file);
        console.log("excelJson")
        console.log(excelJson)
    };


    const handleUpload = async () => {
        if (excelJson) {
            const duplicates = checkForDuplicates(excelJson);

            if (duplicates.length > 0) {
                toast.error(`Duplicate Data found!\n ${duplicates.join(", ")}`, {
                    autoClose: 5000,
                });
            }

            const loadingToastId = toast.loading("Uploading: Please wait...");

            try {
                const res = await axios.post(`${BASE_URL}/project/clientbulkupload/`, excelJson);
                if (res.status === 200) {
                    await getClientData();
                    handleClose();
                    toast.dismiss(loadingToastId);
                    toast.success("Client Names Data Added Successfully");
                }
            } catch (err) {
                handleErrorToast(err);
            } finally {
                toast.dismiss(loadingToastId);
            }
        } else {
            setDocErrors({ document_file: "Please upload a valid Excel file." });
        }
    };


    return (
        <>

            <button title="Upload" className="model-button   font-weight500   model-button-print" onClick={handleShow} style={{ margin: "2px" }}>
                <UploadIcon />
            </button>

            <Modal show={show} dialogClassName="ourcompany-model">

                <Modal.Body>
                    <div className="flex-row align-center">
                        <h1 className="font-size-text task-Tab-heading align-center  font-weight500 "><Modal.Title>Upload Bulk Client Names Data in format :</Modal.Title> </h1>

                        <button onClick={handleDownload} className="model-button-download model-button font-weight500">
                            <DownloadIcon />
                            {"  "} Format
                        </button>
                    </div>
                    <div className="table-css-white-background" style={{ display: "none" }}>
                        <table ref={bulktableRef} className="nested-table-lightblue">
                            <thead>
                                <tr className="custom-table-head-tr">
                                    <th className="align-center">client_name</th>
                                    <th className="align-center">contact_person</th>
                                    <th className="align-center">address</th>
                                    <th className="align-center">email</th>
                                    <th className="align-center">contact_number</th>
                                    <th className="align-center">alt_contact_number</th>
                                    <th className="align-center">gstin_number</th>
                                    <th className="align-center">pos</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="table-css-white-background scroll-container">
                        <div className="table-container scrollbar" style={{ width: "100%" }}>
                            <table className="nested-table-lightblue" style={{ margin: "0px" }}>
                                <thead className="table-heading">
                                    <tr className="modal-padding-heading custom-table-head-tr">
                                        <th className="table-heading-text">Client Name</th>
                                        <th className="table-heading-text">Contact Person</th>
                                        <th className="table-heading-text">Address</th>
                                        <th className="table-heading-text">Email</th>
                                        <th className="table-heading-text">Contact Number</th>
                                        <th className="table-heading-text">Alt Contact Number</th>
                                        <th className="table-heading-text">GSTIN Number</th>
                                        <th className="table-heading-text">POS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {excelJson?.map((client, index) => (
                                        <tr key={index} className="modal-padding-heading">
                                            <th className="table-heading-text">{client.client_name}</th>
                                            <th className="table-heading-text">{client.contact_person}</th>
                                            <th className="table-heading-text">{client.address}</th>
                                            <th className="table-heading-text">{client.email}</th>
                                            <th className="table-heading-text">{client.contact_number}</th>
                                            <th className="table-heading-text">{client.alt_contact_number}</th>
                                            <th className="table-heading-text">{client.gstin_number}</th>
                                            <th className="table-heading-text">{client.pos}</th>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="flex-column field-cont-div">
                        <label htmlFor="document_file" className="form-labels announce-date font-weight500 font-size-heading">
                            Upload File
                        </label>
                        <label
                            htmlFor="document_file"
                            className={`align-center form-inputss custom-file-upload ${docErrors.document_file ? "error" : docInputState.document_file ? "success" : ""
                                }`}
                        >
                            <svg
                                className="pdf-input"
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 20 20"
                                fill="none"
                            >
                                <path
                                    d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                                    stroke="#707070"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </label>
                        <input
                            id="document_file"
                            name="document_file"
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={handleFileChange}
                            className="file-inputs align-center"
                            style={{ display: "none", position: "relative" }}
                        />
                        {fileName && <p className="file-name align-center">{fileName}</p>}
                        <br />
                        {docErrors.document_file && <span className="file-name error-message font-size-text ">{docErrors.document_file}</span>}
                    </div>


                    <h4>References:</h4>
                    <div className="reference-container">
                        <div className="reference-section">
                            <p className="reference-title">General</p>
                            <ul className="arrow-list">
                                <li>GSTIN Number(gstin_number) should be unique.</li>
                            </ul>
                        </div>
                    </div>

                    <div className="button-models">
                        <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                            Cancel
                        </button>
                        <button className="model-button-download model-button font-weight500" onClick={handleUpload}>
                            <UploadIcon />
                            {"  "} Upload
                        </button>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};


// const UpdateProjectsBulk = ({ id, getProject }) => {
//     const [loading, setLoading] = useState(false);

//     const handleDownload = async () => {
//         setLoading(true);
//         try {
//             const response = await axios.get(`${BASE_URL}/project/project/`); // Replace with your API endpoint
//             const data = response.data;

//             if (!data || data.length === 0) {
//                 alert('No data available to download.');
//                 setLoading(false);
//                 return;
//             }

//             // Remove the `pr_code` field from each object in the data array
//             const filteredData = data.map(({ pr_code, ...rest }) => rest);

//             // Create a worksheet and add data
//             const worksheet = XLSX.utils.json_to_sheet(filteredData);

//             // Get the range of the worksheet
//             const range = XLSX.utils.decode_range(worksheet['!ref']);

//             // Apply bold styling to the first row (headers)
//             for (let col = range.s.c; col <= range.e.c; col++) {
//                 const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
//                 if (worksheet[cellAddress]) {
//                     worksheet[cellAddress].s = {
//                         font: { bold: true }, // Make text bold
//                     };
//                 }
//             }

//             // Highlight empty cells with red color
//             for (let row = 1; row <= range.e.r; row++) { // Start from the second row (data rows)
//                 for (let col = range.s.c; col <= range.e.c; col++) {
//                     const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
//                     const cell = worksheet[cellAddress];

//                     if (!cell || !cell.v) { // Check if cell is empty
//                         worksheet[cellAddress] = worksheet[cellAddress] || {};
//                         worksheet[cellAddress].s = {
//                             fill: { fgColor: { rgb: 'FF0000' } }, // Red fill for empty cells
//                         };
//                     }
//                 }
//             }

//             // Create a workbook and add the customized worksheet
//             const workbook = XLSX.utils.book_new();
//             XLSX.utils.book_append_sheet(workbook, worksheet, 'Projects');

//             // Write the Excel file
//             XLSX.writeFile(workbook, 'data.xlsx');
//         } catch (error) {
//             console.error('Error fetching data:', error);
//             alert('Failed to fetch data. Please try again.');
//         } finally {
//             setLoading(false);
//         }
//     };

//     return (
//         <div>
//             <button onClick={handleDownload} disabled={loading}>
//                 {loading ? 'Downloading...' : 'Download Excel'}
//             </button>
//         </div>
//     );
// };

const UpdateProjectsBulk = ({ id, getProject }) => {
    const [loading, setLoading] = useState(false);

    const [show, setShow] = useState(false);
    const [fileName, setFileName] = useState("");
    const [excelJson, setExcelJson] = useState(null);
    const [docErrors, setDocErrors] = useState({});
    const [docInputState, setDocInputState] = useState({});

    const bulktableRef = useRef(null);

    const handleDownload = async () => {
        setLoading(true);
        try {
            // Fetch data from API
            const response = await axios.get(`${BASE_URL}/project/project/`); // Replace with your API endpoint
            const data = response.data;

            // Process the data if necessary, here flattening array field
            const processedData = data.map(item => {
                if (Array.isArray(item.assignedto)) {
                    item.assignedto = item.assignedto.join(', '); // Join array elements with a comma
                }
                return item;
            });

            // Convert the processed data to a worksheet
            const worksheet = XLSX.utils.json_to_sheet(processedData);

            // Create a new workbook
            const workbook = XLSX.utils.book_new();

            // Append the worksheet to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

            // Generate Excel file and trigger download
            XLSX.writeFile(workbook, 'data.xlsx');
        } catch (error) {
            console.error('Error fetching data:', error);
            alert('Failed to fetch data. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        setFileName("")
        setExcelJson(null)
        setDocErrors({})
        setShow(false)
    };
    const handleShow = () => setShow(true);

    // Function to handle file change
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check for valid file type
            const validFileTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
            if (!validFileTypes.includes(file.type)) {
                setDocErrors({ document_file: "Please upload a valid Excel file (.xlsx or .xls)." });
                setFileName("");
                return;
            }

            setFileName(file.name);
            readExcel(file); // Convert the file to JSON
        } else {
            setFileName("");
        }
    };

    // const checkForDuplicates = (data) => {
    //     const sitecodes = new Map();
    //     const duplicates = [];

    //     // Track positions of each insurance number
    //     data.forEach((i, index) => {
    //         const projectcode = i.site_prcode;
    //         if (sitecodes.has(projectcode)) {
    //             sitecodes.get(projectcode).push(index + 1); // index + 1 for 1-based position
    //         } else {
    //             sitecodes.set(projectcode, [index + 1]);
    //         }
    //     });

    //     // Now, filter out only the numbers that appear more than once
    //     sitecodes.forEach((positions, projectcode) => {
    //         if (positions.length > 1) {
    //             duplicates.push(`\Site Project Code :${projectcode} is found in position(s) ${positions.join(", ")}`);
    //         }
    //     });

    //     return duplicates;
    // };

    // const readExcel = (file) => {
    //     const reader = new FileReader();
    //     reader.onload = (e) => {
    //         const data = new Uint8Array(e.target.result);
    //         const workbook = XLSX.read(data, { type: "array" });

    //         const sheetName = workbook.SheetNames[0]; // Use the first sheet
    //         const worksheet = workbook.Sheets[sheetName];
    //         const json = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: null });

    //         const headers = json[0];
    //         const rows = json.slice(1);

    //         const convertToYYYYMMDD = (input) => {
    //             try {
    //                 let date;

    //                 // Handle Excel serial dates
    //                 if (typeof input === "number") {
    //                     const excelEpoch = new Date(1900, 0, 1); // Excel epoch starts on Jan 1, 1900
    //                     const daysOffset = input - 1; // Subtract 1 because of leap year bug
    //                     date = new Date(excelEpoch.getTime() + daysOffset * 86400000);
    //                 } else if (/^\d{4}\.\d{2}\.\d{2}$/.test(input)) {
    //                     // Format: YYYY.MM.DD
    //                     const [year, month, day] = input.split('.');
    //                     date = new Date(`${year}-${month}-${day}`);
    //                 } else if (/^\d{2}\.\d{2}\.\d{4}$/.test(input)) {
    //                     // Format: DD.MM.YYYY
    //                     const [day, month, year] = input.split('.');
    //                     date = new Date(`${year}-${month}-${day}`);
    //                 } else {
    //                     // Default parsing for other formats
    //                     date = new Date(input);
    //                 }

    //                 if (isNaN(date.getTime())) {
    //                     throw new Error('Invalid date format');
    //                 }

    //                 // Format the date as YYYY-MM-DD
    //                 const year = date.getFullYear();
    //                 const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    //                 const day = String(date.getDate()).padStart(2, '0');

    //                 return `${year}-${month}-${day}`;
    //             } catch (error) {
    //                 return null; // Return null for invalid dates
    //             }
    //         };


    //         const formattedJson = rows.map((row) => {
    //             const obj = {};
    //             headers.forEach((header, index) => {
    //                 if (["bid_tender_date", "portal_submission_date", "opening_date"].includes(header) && row[index]) {
    //                     obj[header] = convertToYYYYMMDD(row[index]);
    //                 } else if (header === "assignedto" && row[index]) {
    //                     try {
    //                         // Attempt to parse the string into JSON
    //                         obj[header] = JSON.parse(row[index]);
    //                         console.log("obj[header]")
    //                         console.log(obj[header])
    //                     } catch (err) {
    //                         console.error(`Invalid format in assignedto: ${row[index]}`);
    //                         obj[header] = [];
    //                     }
    //                 } else {
    //                     obj[header] = row[index] !== undefined ? row[index] : null;
    //                 }
    //             });
    //             return obj;
    //         });

    //         // Ensure the input to checkForDuplicates is an array
    //         // const duplicates = checkForDuplicates(formattedJson);

    //         // if (duplicates.length > 0) {
    //         //     // Toast notification with line breaks for each duplicate
    //         //     toast.error(
    //         //         `Duplicate Projects found:\n${duplicates.join("\n")}`,
    //         //         {
    //         //             autoClose: 5000, // Close toast after 5 seconds
    //         //         }
    //         //     );
    //         // }
    //         // Set the formatted JSON data to state
    //         setExcelJson(formattedJson);
    //     };
    //     reader.readAsArrayBuffer(file);
    //     console.log("excelJson")
    //     console.log(excelJson)
    // };

    const readExcel = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });

            const sheetName = workbook.SheetNames[0]; // Use the first sheet
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: null });

            const headers = json[0];
            const rows = json.slice(1);

            const convertToYYYYMMDD = (input) => {
                try {
                    let date;
                    if (typeof input === "number") {
                        const excelEpoch = new Date(1900, 0, 1);
                        const daysOffset = input - 1;
                        date = new Date(excelEpoch.getTime() + daysOffset * 86400000);
                    } else if (/^\d{4}\.\d{2}\.\d{2}$/.test(input)) {
                        const [year, month, day] = input.split(".");
                        date = new Date(`${year}-${month}-${day}`);
                    } else if (/^\d{2}\.\d{2}\.\d{4}$/.test(input)) {
                        const [day, month, year] = input.split(".");
                        date = new Date(`${year}-${month}-${day}`);
                    } else {
                        date = new Date(input);
                    }

                    if (isNaN(date.getTime())) {
                        throw new Error("Invalid date format");
                    }

                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, "0");
                    const day = String(date.getDate()).padStart(2, "0");

                    return `${year}-${month}-${day}`;
                } catch (error) {
                    return null;
                }
            };

            const formattedJson = rows.map((row) => {
                const obj = {};
                headers.forEach((header, index) => {
                    if (["bid_tender_date", "portal_submission_date", "opening_date"].includes(header) && row[index]) {
                        obj[header] = convertToYYYYMMDD(row[index]);
                    } else if (header === "assignedto") {
                        // Handle assignedto field specifically
                        if (row[index]) {
                            const value = row[index];
                            if (typeof value === "string") {
                                obj[header] = value.includes(",")
                                    ? value.split(",").map((item) => item.trim())
                                    : [value.trim()];
                            } else if (Array.isArray(value)) {
                                obj[header] = value.map((item) => String(item).trim());
                            } else {
                                obj[header] = [String(value).trim()];
                            }
                        } else {
                            obj[header] = []; // Default to empty array if no value
                        }
                    } else {
                        obj[header] = row[index] !== undefined ? row[index] : null;
                    }
                });
                return obj;
            });

            setExcelJson(formattedJson);
        };
        reader.readAsArrayBuffer(file);
    };



    // Function to handle the upload button click
    const handleUpload = async () => {

        if (excelJson) {
            // const duplicates = checkForDuplicates(excelJson);

            // if (duplicates.length > 0) {
            //     toast.error(`Duplicate Data found:\n ${duplicates.join(", ")}`, {
            //         autoClose: 5000,
            //     });
            // }
            const loadingToastId = toast.loading("Uploading: Please wait...");
            try {
                const res = await axios.put(`${BASE_URL}/project/projectbulkupdate/`, excelJson);
                if (res.status === 200) {
                    await getProject();
                    handleClose();
                    toast.dismiss(loadingToastId);
                    toast.success("Projects uploaded successfully.");

                } else {
                    toast.dismiss(loadingToastId);
                    toast.error("Failed to add data!");
                }
            } catch (err) {
                handleErrorToast(err);
            } finally {
                toast.dismiss(loadingToastId);
            }
        } else {
            setDocErrors({ document_file: "Please upload a valid Excel file." });
        }
    };





    return (
        <>

            <button title="Upload" className="model-button   font-weight500   model-button-print" onClick={handleShow} style={{ margin: "2px" }}>
                <UploadIcon />
            </button>

            <Modal show={show} dialogClassName="ourcompany-model">
                <Modal.Body>
                    <div className="flex-row align-center">
                        <h1 className="font-size-text task-Tab-heading align-center  font-weight500 "><Modal.Title>Upload Bulk Project Data in format :</Modal.Title> </h1>

                        <button onClick={handleDownload} className="model-button-download model-button font-weight500">
                            <DownloadIcon />
                            {"  "} Format
                        </button>
                    </div>


                    <div className="table-css-white-background scroll-container"
                    >
                        <div className="table-container scrollbar" style={{ width: "100%" }}>
                            <table className="nested-table-lightblue" style={{ margin: "0px" }}>
                                <thead className="table-heading">
                                    <tr className="custom-table-head-tr modal-padding-heading">
                                        <th className="table-heading-text">Prebid PR Code</th>
                                        <th className="table-heading-text">Project Name</th>
                                        <th className="table-heading-text">Project Short Name</th>
                                        <th className="table-heading-text">Location</th>
                                        <th className="table-heading-text">Sub Company</th>
                                        <th className="table-heading-text">Project Type</th>
                                        <th className="table-heading-text">Concern Person</th>
                                        <th className="table-heading-text">Concern Person Name</th>
                                        <th className="table-heading-text">Assigned To</th>
                                        <th className="table-heading-text">Tender ID</th>
                                        <th className="table-heading-text">Bid Tender Date</th>
                                        <th className="table-heading-text">Length</th>
                                        <th className="table-heading-text">Portal Submission Date</th>
                                        <th className="table-heading-text">Cost</th>
                                        <th className="table-heading-text">Remark</th>
                                        <th className="table-heading-text">Link</th>
                                        <th className="table-heading-text">L1 Position</th>
                                        <th className="table-heading-text">Our Position</th>
                                        <th className="table-heading-text">Opening Date</th>
                                        <th className="table-heading-text">Client Name</th>
                                        <th className="table-heading-text">Contract Mode</th>
                                        <th className="table-heading-text">Priority</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {excelJson?.map((project, index) => (
                                        <tr key={index} className="modal-padding-heading">
                                            <td className="table-heading-text">{project.prebid_prcode}</td>
                                            <td className="table-heading-text">{project.project_name}</td>
                                            <td className="table-heading-text">{project.project_short_name}</td>
                                            <td className="table-heading-text">{project.location}</td>
                                            <td className="table-heading-text">{project.sub_company}</td>
                                            <td className="table-heading-text">{project.project_type}</td>
                                            <td className="table-heading-text">{project.concern_person}</td>
                                            <td className="table-heading-text">{project.concern_person_name}</td>
                                            <td className="table-heading-text">{project.assignedto}</td>
                                            {/* <td className="table-heading-text">
                                                {project.assignedto?.join(", ")}
                                            </td> */}
                                            <td className="table-heading-text">{project.tender_id}</td>
                                            <td className="table-heading-text">{project.bid_tender_date}</td>
                                            <td className="table-heading-text">{project.length}</td>
                                            <td className="table-heading-text">{project.portal_submission_date}</td>
                                            <td className="table-heading-text">{project.cost}</td>
                                            <td className="table-heading-text">{project.remark}</td>
                                            <td className="table-heading-text">
                                                <a href={project.link} target="_blank" rel="noopener noreferrer">
                                                    {project.link}
                                                </a>
                                            </td>
                                            <td className="table-heading-text">{project.l1_position}</td>
                                            <td className="table-heading-text">{project.our_position}</td>
                                            <td className="table-heading-text">{project.opening_date}</td>
                                            <td className="table-heading-text">{project.client_name}</td>
                                            <td className="table-heading-text">{project.contract_mode}</td>
                                            <td className="table-heading-text">{project.priority}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="flex-column field-cont-div">
                        <label htmlFor="document_file" className="form-labels announce-date font-weight500 font-size-heading">
                            Upload File
                        </label>
                        <label
                            htmlFor="document_file"
                            className={`align-center form-inputss custom-file-upload ${docErrors.document_file ? "error" : docInputState.document_file ? "success" : ""
                                }`}
                        >
                            <svg
                                className="pdf-input"
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 20 20"
                                fill="none"
                            >
                                <path
                                    d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                                    stroke="#707070"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </label>
                        <input
                            id="document_file"
                            name="document_file"
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={handleFileChange}
                            className="file-inputs align-center"
                            style={{ display: "none", position: "relative" }}
                        />
                        {fileName && <p className="file-name align-center">{fileName}</p>}
                        <br />
                        {docErrors.document_file && <span className="file-name error-message font-size-text ">{docErrors.document_file}</span>}
                    </div>

                    <h4>References:</h4>
                    <div className="reference-container">
                        <div className="reference-section">
                            <p className="reference-title">General:</p>
                            <ul className="arrow-list">
                                <li>Prebid PR Code(prebid_prcode) should be unique</li>
                                <li>For Assigned To(assignedto) give data in format : {`["<emp_code1>","<emp_code2>"]`}</li>
                            </ul>
                        </div>
                        <div className="reference-section">
                            <p className="reference-title">For Contract Mode use one of these:</p>
                            <ul className="arrow-list">
                                <li>epc</li>
                                <li>ham</li>
                                <li>bot</li>
                            </ul>
                        </div>
                        <div className="reference-section">
                            <p className="reference-title">For Priority use one of these:</p>
                            <ul className="arrow-list">
                                <li>low</li>
                                <li>medium</li>
                                <li>high</li>
                            </ul>
                        </div>

                        <div className="date-section">
                            <p className="date-text">All Dates should be in DD-MM-YYYY format</p>
                        </div>
                    </div>


                    <div className="button-models">
                        <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                            Cancel
                        </button>
                        <button className="model-button-download model-button font-weight500" onClick={handleUpload}>
                            <UploadIcon />
                            {"  "} Upload
                        </button>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

const UpdateClientNameBulk = ({ id, getClient }) => {
    const [loading, setLoading] = useState(false);

    const [show, setShow] = useState(false);
    const [clientData, setClientData] = useState([]);
    const [fileName, setFileName] = useState("");
    const [excelJson, setExcelJson] = useState(null);
    const [docErrors, setDocErrors] = useState({});
    const [docInputState, setDocInputState] = useState({});

    const bulktableRef = useRef(null);

    const getData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${BASE_URL}/project/clientName/`); // Replace with your API endpoint
            setClientData(response.data)
        } catch (error) {

        }
    }

    useEffect(() => {
        getData();
    }, []);

    const handleDownload = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${BASE_URL}/project/clientName/`); // Replace with your API endpoint
            const data = response.data;

            if (!data || data.length === 0) {
                alert('No data available to download.');
                setLoading(false);
                return;
            }

            // Remove the `id` field from the data for export (exclude it from the Excel file)
            const filteredData = data.map(({ id, ...rest }) => rest);

            // Create a worksheet and add data (without `id` field)
            const worksheet = XLSX.utils.json_to_sheet(filteredData);

            // Get the range of the worksheet
            const range = XLSX.utils.decode_range(worksheet['!ref']);

            // Apply bold styling to the first row (headers)
            for (let col = range.s.c; col <= range.e.c; col++) {
                const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
                if (worksheet[cellAddress]) {
                    worksheet[cellAddress].s = {
                        font: { bold: true }, // Make text bold
                    };
                }
            }

            // Highlight empty cells with red color
            for (let row = 1; row <= range.e.r; row++) { // Start from the second row (data rows)
                for (let col = range.s.c; col <= range.e.c; col++) {
                    const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
                    const cell = worksheet[cellAddress];

                    if (!cell || !cell.v) { // Check if cell is empty
                        worksheet[cellAddress] = worksheet[cellAddress] || {};
                        worksheet[cellAddress].s = {
                            fill: { fgColor: { rgb: 'FF0000' } }, // Red fill for empty cells
                        };
                    }
                }
            }

            // Create a workbook and add the customized worksheet
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'ClientName');

            // Write the Excel file
            XLSX.writeFile(workbook, 'Client.xlsx');
        } catch (error) {
            console.error('Error fetching data:', error);
            alert('Failed to fetch data. Please try again.');
        } finally {
            setLoading(false);
        }
    };





    const handleClose = () => {
        setFileName("")
        setExcelJson(null)
        setDocErrors({})
        setShow(false)
    };
    const handleShow = () => setShow(true);

    // Function to handle file change
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check for valid file type
            const validFileTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
            if (!validFileTypes.includes(file.type)) {
                setDocErrors({ document_file: "Please upload a valid Excel file (.xlsx or .xls)." });
                setFileName("");
                return;
            }

            setFileName(file.name);
            readExcel(file); // Convert the file to JSON
        } else {
            setFileName("");
        }
    };


    // const checkForDuplicates = (data) => {
    //     const sitecodes = new Map();
    //     const duplicates = [];

    //     // Track positions of each insurance number
    //     data.forEach((i, index) => {
    //         const projectcode = i.site_prcode;
    //         if (sitecodes.has(projectcode)) {
    //             sitecodes.get(projectcode).push(index + 1); // index + 1 for 1-based position
    //         } else {
    //             sitecodes.set(projectcode, [index + 1]);
    //         }
    //     });

    //     // Now, filter out only the numbers that appear more than once
    //     sitecodes.forEach((positions, projectcode) => {
    //         if (positions.length > 1) {
    //             duplicates.push(`\Site Project Code :${projectcode} is found in position(s) ${positions.join(", ")}`);
    //         }
    //     });

    //     return duplicates;
    // };

    // const readExcel = (file) => {
    //     const reader = new FileReader();
    //     reader.onload = (e) => {
    //         const data = new Uint8Array(e.target.result);
    //         const workbook = XLSX.read(data, { type: "array" });

    //         const sheetName = workbook.SheetNames[0]; // Use the first sheet
    //         const worksheet = workbook.Sheets[sheetName];
    //         const json = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: null });

    //         const headers = json[0];
    //         const rows = json.slice(1);

    //         const convertToYYYYMMDD = (input) => {
    //             try {
    //                 let date;

    //                 // Handle Excel serial dates
    //                 if (typeof input === "number") {
    //                     const excelEpoch = new Date(1900, 0, 1); // Excel epoch starts on Jan 1, 1900
    //                     const daysOffset = input - 1; // Subtract 1 because of leap year bug
    //                     date = new Date(excelEpoch.getTime() + daysOffset * 86400000);
    //                 } else if (/^\d{4}\.\d{2}\.\d{2}$/.test(input)) {
    //                     // Format: YYYY.MM.DD
    //                     const [year, month, day] = input.split('.');
    //                     date = new Date(`${year}-${month}-${day}`);
    //                 } else if (/^\d{2}\.\d{2}\.\d{4}$/.test(input)) {
    //                     // Format: DD.MM.YYYY
    //                     const [day, month, year] = input.split('.');
    //                     date = new Date(`${year}-${month}-${day}`);
    //                 } else {
    //                     // Default parsing for other formats
    //                     date = new Date(input);
    //                 }

    //                 if (isNaN(date.getTime())) {
    //                     throw new Error('Invalid date format');
    //                 }

    //                 // Format the date as YYYY-MM-DD
    //                 const year = date.getFullYear();
    //                 const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    //                 const day = String(date.getDate()).padStart(2, '0');

    //                 return `${year}-${month}-${day}`;
    //             } catch (error) {
    //                 return null; // Return null for invalid dates
    //             }
    //         };


    //         const formattedJson = rows.map((row) => {
    //             const obj = {};
    //             headers.forEach((header, index) => {
    //                 if (["bid_tender_date", "portal_submission_date", "opening_date"].includes(header) && row[index]) {
    //                     obj[header] = convertToYYYYMMDD(row[index]);
    //                 } else if (header === "assignedto" && row[index]) {
    //                     try {
    //                         // Attempt to parse the string into JSON
    //                         obj[header] = JSON.parse(row[index]);
    //                         console.log("obj[header]")
    //                         console.log(obj[header])
    //                     } catch (err) {
    //                         console.error(`Invalid format in assignedto: ${row[index]}`);
    //                         obj[header] = [];
    //                     }
    //                 } else {
    //                     obj[header] = row[index] !== undefined ? row[index] : null;
    //                 }
    //             });
    //             return obj;
    //         });

    //         // Ensure the input to checkForDuplicates is an array
    //         // const duplicates = checkForDuplicates(formattedJson);

    //         // if (duplicates.length > 0) {
    //         //     // Toast notification with line breaks for each duplicate
    //         //     toast.error(
    //         //         `Duplicate Projects found:\n${duplicates.join("\n")}`,
    //         //         {
    //         //             autoClose: 5000, // Close toast after 5 seconds
    //         //         }
    //         //     );
    //         // }
    //         // Set the formatted JSON data to state
    //         setExcelJson(formattedJson);
    //     };
    //     reader.readAsArrayBuffer(file);
    //     console.log("excelJson")
    //     console.log(excelJson)
    // };

    const readExcel = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });

            const sheetName = workbook.SheetNames[0]; // Use the first sheet
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: null });

            const headers = json[0];
            const rows = json.slice(1);

            // Assuming the data from the API is available (from `handleDownload`)
            const apiData = clientData; // Fetch the data from the API, same as in handleDownload

            // Create a formatted JSON object based on headers and row values
            const formattedJson = rows.map((row, index) => {
                const obj = {};
                headers.forEach((header, colIndex) => {
                    obj[header] = row[colIndex] !== undefined ? row[colIndex] : null;
                });
                // Add the `id` from the API to the row
                obj['id'] = apiData[index] ? apiData[index].id : null; // Ensure the id matches the row
                return obj;
            });

            // Now the formattedJson includes both the data from the Excel file and the `id` from the API
            setExcelJson(formattedJson);
        };
        reader.readAsArrayBuffer(file);
    };





    // Function to handle the upload button click
    const handleUpload = async () => {

        if (excelJson) {
            // const duplicates = checkForDuplicates(excelJson);

            // if (duplicates.length > 0) {
            //     toast.error(`Duplicate Data found:\n ${duplicates.join(", ")}`, {
            //         autoClose: 5000,
            //     });
            // }
            const loadingToastId = toast.loading("Uploading: Please wait...");
            try {
                const res = await axios.put(`${BASE_URL}/project/clientnamebulkupdate/`, excelJson);
                if (res.status === 200) {
                    await getClient();
                    handleClose();
                    toast.dismiss(loadingToastId);
                    toast.success("Clients Names uploaded successfully.");

                } else {
                    toast.dismiss(loadingToastId);
                    toast.error("Failed to add data!");
                }
            } catch (err) {
                handleErrorToast(err);
            } finally {
                toast.dismiss(loadingToastId);
            }
        } else {
            setDocErrors({ document_file: "Please upload a valid Excel file." });
        }
    };

    console.log(excelJson);




    return (
        <>

            <button title="Upload" className="model-button   font-weight500   model-button-print" onClick={handleShow} style={{ margin: "2px" }}>
                <UploadIcon />
            </button>

            <Modal show={show} dialogClassName="ourcompany-model">
                <Modal.Body>
                    <div className="flex-row align-center">
                        <h1 className="font-size-text task-Tab-heading align-center  font-weight500 "><Modal.Title>Upload Bulk Client Name in format :</Modal.Title> </h1>

                        <button onClick={handleDownload} className="model-button-download model-button font-weight500">
                            <DownloadIcon />
                            {"  "} Format
                        </button>
                    </div>


                    <div className="table-css-white-background scroll-container"
                    >
                        <div className="table-container scrollbar" style={{ width: "100%" }}>
                            <table className="nested-table-lightblue" style={{ margin: "0px" }}>
                                <thead className="table-heading">
                                    <tr className="custom-table-head-tr modal-padding-heading">
                                        <th className="table-heading-text">Title</th>
                                        <th className="table-heading-text">Subcompany</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {excelJson?.map((project, index) => (
                                        <tr key={index} className="modal-padding-heading">
                                            <td className="table-heading-text">{project.title}</td>
                                            <td className="table-heading-text">{project.sub_company}</td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="flex-column field-cont-div">
                        <label htmlFor="document_file" className="form-labels announce-date font-weight500 font-size-heading">
                            Upload File
                        </label>
                        <label
                            htmlFor="document_file"
                            className={`align-center form-inputss custom-file-upload ${docErrors.document_file ? "error" : docInputState.document_file ? "success" : ""
                                }`}
                        >
                            <svg
                                className="pdf-input"
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 20 20"
                                fill="none"
                            >
                                <path
                                    d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                                    stroke="#707070"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </label>
                        <input
                            id="document_file"
                            name="document_file"
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={handleFileChange}
                            className="file-inputs align-center"
                            style={{ display: "none", position: "relative" }}
                        />
                        {fileName && <p className="file-name align-center">{fileName}</p>}
                        <br />
                        {docErrors.document_file && <span className="file-name error-message font-size-text ">{docErrors.document_file}</span>}
                    </div>

                    <h4>References:</h4>
                    <div className="reference-container">
                        <div className="reference-section">
                            <p className="reference-title">General:</p>
                            <ul className="arrow-list">
                                <li>Prebid PR Code(prebid_prcode) should be unique</li>
                                <li>For Assigned To(assignedto) give data in format : {`["<emp_code1>","<emp_code2>"]`}</li>
                            </ul>
                        </div>
                        <div className="reference-section">
                            <p className="reference-title">For Contract Mode use one of these:</p>
                            <ul className="arrow-list">
                                <li>epc</li>
                                <li>ham</li>
                                <li>bot</li>
                            </ul>
                        </div>
                        <div className="reference-section">
                            <p className="reference-title">For Priority use one of these:</p>
                            <ul className="arrow-list">
                                <li>low</li>
                                <li>medium</li>
                                <li>high</li>
                            </ul>
                        </div>

                        <div className="date-section">
                            <p className="date-text">All Dates should be in DD-MM-YYYY format</p>
                        </div>
                    </div>


                    <div className="button-models">
                        <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                            Cancel
                        </button>
                        <button className="model-button-download model-button font-weight500" onClick={handleUpload}>
                            <UploadIcon />
                            {"  "} Upload
                        </button>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};



export {
    UploadProjectsBulk,
    UploadClientNameBulk,
    UploadClientDataBulk,
    UpdateProjectsBulk,
    UpdateClientNameBulk
}